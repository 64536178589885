import { PowerOutageDialogContentProps } from './PowerOutageDialogContent';
import { PowerOutageFeatureProperties } from './types';

export const powerOutagePropertiesToDialogProps = (
  properties: PowerOutageFeatureProperties,
): PowerOutageDialogContentProps => {
  const startDate = properties.StartDate
    ? new Date(properties.StartDate)
    : undefined;

  const restoreDate = properties.EstimatedRestoreDate
    ? new Date(properties?.EstimatedRestoreDate)
    : undefined;

  return {
    dateStart: startDate,
    expectedDateResolved: restoreDate,
    utilityCompany: properties.UtilityCompany,
    impactedCustomerCount: properties.ImpactedCustomers,
    notes: properties.Cause,
  };
};
