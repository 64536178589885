import { useCallback, useState } from 'react';

export const useSessionStorage = <T>(
  key: string,
  defaultValue: T,
): [T, (newValue: T) => void] => {
  const [value, setValue] = useState<T>(() => {
    try {
      const savedVal = sessionStorage.getItem(key);
      return savedVal ? JSON.parse(savedVal) : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  });

  const handleSetValue = useCallback(
    (newValue: T): void => {
      sessionStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    [key],
  );

  return [value, handleSetValue];
};
