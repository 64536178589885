import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { AlertCamera } from 'hooks/useAlertCameras';
import { SHORT_MONTH_DAY_12HOUR_FORMAT, formatDate } from 'shared/dates';

type OfflineMessageProps = {
  camera: AlertCamera;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[800],
    paddingTop: '56.25%', // 16:9 aspect ratio
    position: 'relative',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[50],
  },
}));

export const OfflineMessage = (props: OfflineMessageProps): JSX.Element => {
  const { camera } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography>{t('alertCameras.offline')}</Typography>
        <Typography>
          {t('alertCameras.lastUpdatedAt', {
            date: formatDate(
              camera.imageTimestamp,
              SHORT_MONTH_DAY_12HOUR_FORMAT,
            ),
          })}
        </Typography>
      </Box>
    </Box>
  );
};
