import { Layer, LayerProps } from 'shared/map-exports';
import { FeatureCollection } from 'geojson';
import { useCallback, useState } from 'react';
import { usePoisState } from 'state/usePoisState';
import ArcGisLayer from './ArcGisLayer';
import { RadioTowerIcon } from '../Icons';
import addVisible from '../../../shared/addVisible';
import { MapLayerProps, RadioTowerFeatureProperties } from '../types';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';
import {
  RadioTowerDialogContent,
  RadioTowerDialogContentProps,
} from '../RadioTowerDialogContent';

const MIN_ZOOM_VISIBILITY = 8;

const ICON_LAYER_ID = 'radio-towers-symbol';

const powerOutagePropertiesToDialogProps = (
  properties: RadioTowerFeatureProperties,
): RadioTowerDialogContentProps => {
  return {
    editDate: new Date(properties.EditDate),
    name: properties.name,
    channels: properties.channels.split('\n'),
  };
};

const postProcessData = (data: FeatureCollection): FeatureCollection => {
  // Transforms feature properties such that individual channel properties (channel1, channel2, ...)
  // are combined into a single string like "channel 1\nchannel 2\n" for display.
  const { features } = data;
  const processed = features.map((f) => {
    if (f.properties == null) return f;
    const channels = [];
    Object.entries(f.properties).forEach(([k, v]) => {
      if (k.startsWith('channel') && v != null) {
        channels.push(v);
      }
    });
    for (const k in Object.keys(f.properties)) {
      if (k.startsWith('channel')) {
        channels.push(f.properties[k]);
      }
    }
    return {
      ...f,
      properties: {
        ...f.properties,
        channels: channels.join('\n'),
      },
    };
  });

  return {
    ...data,
    features: processed,
  };
};

const RadioTowersLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const onClick = useCallback(
    (geoJsonFeatures: MapboxFeature<RadioTowerFeatureProperties>[]) => {
      const { properties } = geoJsonFeatures[0];

      setSelectedPoi({
        type: 'radioTower',
        id: properties.OBJECTID,
        PoiDialogContent: () => {
          return (
            <RadioTowerDialogContent
              {...powerOutagePropertiesToDialogProps(properties)}
            />
          );
        },
      });
    },
    [setSelectedPoi],
  );
  const onHover = useCallback(
    (geoJsonFeatures: MapboxFeature<RadioTowerFeatureProperties>[]) => {
      const { properties } = geoJsonFeatures[0];
      setHoveredId(properties.OBJECTID);
    },
    [setHoveredId],
  );
  const onHoverOff = useCallback(() => {
    setHoveredId(null);
  }, [setHoveredId]);

  useMapLayerEvents<RadioTowerFeatureProperties>({
    layerId: ICON_LAYER_ID,
    onClick,
    onHover,
    onHoverOff,
  });

  const selectedId =
    selectedPoi && selectedPoi.type === 'radioTower' ? selectedPoi.id : null;

  const radioTowersSrc = import.meta.env.VITE_RADIO_TOWERS_URL as string;
  return (
    <ArcGisLayer
      visible={visible}
      src={radioTowersSrc}
      fields={[
        'EditDate',
        'name',
        'channel1',
        'channel2',
        'channel3',
        'channel4',
        'channel5',
        'channel6',
      ]}
      precision={4}
      staleTime={24 * 60 * 60 * 1000} // 24 hours
      postProcessDataFn={postProcessData}
    >
      <Layer
        {...addVisible(radioTowersSymbolStyle(hoveredId, selectedId), visible)}
      />
    </ArcGisLayer>
  );
};

const radioTowersSymbolStyle = (
  hoveredId: number | null,
  selectedId: string | number | null | undefined,
): LayerProps => ({
  id: ICON_LAYER_ID,
  type: 'symbol',
  minzoom: MIN_ZOOM_VISIBILITY,
  layout: {
    'icon-image': [
      'case',
      [
        'in',
        ['get', 'OBJECTID'],
        ['literal', [hoveredId, selectedId].filter(Boolean)],
      ],
      'RadioTowerActive',
      'RadioTower',
    ],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': RadioTowerIcon.offset,
    'icon-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      MIN_ZOOM_VISIBILITY,
      0.8 * (RadioTowerIcon.scale ?? 1),
      20,
      1.5 * (RadioTowerIcon.scale ?? 1),
    ],
  },
});

export default RadioTowersLayer;
