import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { PaymentMethodData } from 'shared/types';

type UsePaymentMethodQueryProps = {
  userId?: number;
  verificationToken?: string;
};

const getPaymentMethod = async (
  userId: number,
  verificationToken: string,
): Promise<PaymentMethodData> => {
  const params = new URLSearchParams({
    user_id: userId.toString(),
    verification_token: verificationToken,
  });
  const res = await API.get(
    `recurly_integration/payment_method/?${params.toString()}`,
  );
  return res.data;
};

const usePaymentMethodQuery = (
  props: UsePaymentMethodQueryProps,
): UseQueryResult<PaymentMethodData | undefined> => {
  const { userId, verificationToken } = props;

  const query = useQuery({
    queryKey: ['saved-payment-method', userId],
    queryFn: () =>
      getPaymentMethod(userId as number, verificationToken as string),
    enabled: !!userId && !!verificationToken,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnMount: 'always',
  });

  return query;
};

export default usePaymentMethodQuery;
