import { forwardRef, useState } from 'react';
import {
  FormControl,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CollapsableList from 'components/CollapsableList';
import useMapLayersState from 'state/useMapLayersState';
import { ANALYTICS_EVENTS } from 'shared/analytics';
import useWebGL2State from 'state/useWebGL2State';
import { useAuthState } from 'state';
import { usePoisState } from 'state/usePoisState';
import { useMapDrawerEntityState } from 'state/useMapDrawerEntityState';
import MapBaseLayerSelector from '../MapBaseLayerSelector';
import { GeneralLayerGroup, MapBaseLayer } from '../types';
import { LayerGroupItem } from './LayerGroupItem';
import {
  LAYER_GROUPS,
  MapLayer,
  MapLayerDetail,
  MapLayers,
  MapLayersDetails,
} from '../constants';
import LayerControlItem from './LayerControlItem';
import { layerNameToEntityType, layerNameToPoiType } from '../utils';
import { MEMBERSHIP_PLANS } from '../../../constants';

type LayerGroup = 'general' | 'weather' | 'professional';

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 1,
  },
  contentContainer: {
    padding: '16px 0px',
    paddingTop: 38,
    [theme.breakpoints.down('phone')]: {
      paddingTop: 0,
    },
  },
  title: {
    padding: 16,
    paddingBottom: 8,
    fontVariant: 'all-small-caps',
    fontWeight: 'bold',
    lineHeight: '24px',
    [theme.breakpoints.down('phone')]: {
      paddingTop: 8,
    },
  },
}));

export const LayerGroups = forwardRef<HTMLDivElement>((_, ref): JSX.Element => {
  const [selectedGroup, setSelectedGroup] = useState<LayerGroup>('general');
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('phone'));
  const {
    mapBaseLayer,
    mapLayers,
    setMapBaseLayer,
    setMapLayerVisibility,
    disabledLayers,
    setDrawerOpen,
    setGroupDrawerOpen,
  } = useMapLayersState();
  const {
    showMembershipFeatures,
    showMembershipProFeatures,
    permissions: { isInternalUser },
  } = useAuthState();
  const { hasWebGL2 } = useWebGL2State();
  const history = useHistory();
  const { selectedPoi, clearSelectedPoisOfType } = usePoisState();
  const { mapDrawerEntity, setMapDrawerEntity } = useMapDrawerEntityState();

  const handleSetBaseMapLayer = (layer: MapBaseLayer): void => {
    FirebaseAnalytics.logEvent({
      name: `${ANALYTICS_EVENTS.CLICKED.BASE_MAP_LAYER_CHANGE}_${layer}`,
      params: {},
    });
    FirebaseAnalytics.logEvent({
      name: ANALYTICS_EVENTS.CLICKED.BASE_MAP_LAYER_CHANGE,
      params: { layer },
    });
    setMapBaseLayer(layer);
  };

  const handleGroupClick = (group: LayerGroup): void => {
    setSelectedGroup(group);
  };

  const handleChangeLayer = (
    mapLayer: MapLayer,
    details: MapLayerDetail,
    enabled: boolean,
  ): void => {
    if (details.minMembership === 'regular' && !showMembershipFeatures) {
      history.push(`/support_us/${MEMBERSHIP_PLANS.yearly}`);
      return;
    }
    if (details.minMembership === 'pro' && !showMembershipProFeatures) {
      history.push(`/support_us/${MEMBERSHIP_PLANS.proYearly}`);
      return;
    }
    const baseName = enabled ? 'layer_toggle_on' : 'layer_toggle_off';
    const eventName = `${baseName}_${mapLayer}`;
    FirebaseAnalytics.logEvent({
      name: eventName,
      params: {},
    });
    FirebaseAnalytics.logEvent({
      name: baseName,
      params: { mapLayer },
    });
    setMapLayerVisibility(mapLayer, enabled);
    if (!enabled) {
      if (selectedPoi && layerNameToPoiType[mapLayer]) {
        clearSelectedPoisOfType(layerNameToPoiType[mapLayer]);
      }
      if (
        mapDrawerEntity &&
        layerNameToEntityType[mapLayer] === mapDrawerEntity.type
      ) {
        setMapDrawerEntity(null);
      }
    }
  };

  const renderControlItem = (mapLayer: MapLayer): JSX.Element => {
    const layerDetails: MapLayerDetail = MapLayersDetails[mapLayer];

    const disabled =
      (layerDetails.requiresWebGL2 && !hasWebGL2) ||
      disabledLayers.includes(mapLayer);

    return (
      <LayerControlItem
        key={mapLayer}
        titleKey={layerDetails.titleKey}
        subtitleKey={layerDetails.subtitleKey}
        disabled={disabled}
        checked={!disabled && mapLayers.includes(mapLayer)}
        onChange={(enabled): void =>
          handleChangeLayer(mapLayer, layerDetails, enabled)
        }
        mapLayer={mapLayer}
        internalOnly={layerDetails.internalOnly}
        minMembership={layerDetails.minMembership}
      />
    );
  };

  const getActiveLayersInGroupNumber = (
    layerGroup: GeneralLayerGroup,
  ): number => {
    return LAYER_GROUPS[layerGroup].filter((layer: MapLayer) =>
      mapLayers.includes(layer),
    ).length;
  };

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      {!isPhone && (
        <IconButton
          aria-label={t('common.close')}
          className={classes.closeButton}
          onClick={() => setDrawerOpen(false)}
          color="inherit"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}

      <div className={classes.contentContainer}>
        <FormControl fullWidth>
          <Typography variant="h3" className={classes.title}>
            {t('map.layers.sections.style')}
          </Typography>

          <MapBaseLayerSelector
            value={mapBaseLayer}
            onChange={(layer): void => handleSetBaseMapLayer(layer)}
          />
        </FormControl>
      </div>

      <CollapsableList
        title={t('map.layers.sections.general')}
        onToggleCollapse={() => handleGroupClick('general')}
        expanded={selectedGroup === 'general'}
        listProps={{
          disablePadding: true,
        }}
      >
        <LayerGroupItem
          titleKey="map.layers.general.firePerimeters.title"
          subtitleKey="map.layers.general.firePerimeters.subtitle"
          onClick={(): void => setGroupDrawerOpen(true, 'firePerimeters')}
          totalLayers={LAYER_GROUPS.firePerimeters.length}
          activeLayers={getActiveLayersInGroupNumber('firePerimeters')}
        />
        <LayerGroupItem
          titleKey="map.layers.general.satelliteHotspots.title"
          subtitleKey="map.layers.general.satelliteHotspots.subtitle"
          onClick={(): void => setGroupDrawerOpen(true, 'satelliteHotspots')}
          totalLayers={LAYER_GROUPS.satelliteHotspots.length}
          activeLayers={getActiveLayersInGroupNumber('satelliteHotspots')}
        />
        {renderControlItem(MapLayers.FLIGHT_TRACKER)}
        {renderControlItem(MapLayers.POWER_OUTAGES)}
        {renderControlItem(MapLayers.CAMERAS)}
      </CollapsableList>
      <CollapsableList
        title={t('map.layers.sections.weather')}
        onToggleCollapse={() => handleGroupClick('weather')}
        expanded={selectedGroup === 'weather'}
        listProps={{
          disablePadding: true,
        }}
      >
        {renderControlItem(MapLayers.RED_FLAG_WARNINGS)}
        {renderControlItem(MapLayers.SURFACE_WIND)}
        {renderControlItem(MapLayers.PURPLE_AIR)}
      </CollapsableList>
      <CollapsableList
        title={t('map.layers.sections.professional')}
        onToggleCollapse={() => handleGroupClick('professional')}
        expanded={selectedGroup === 'professional'}
        listProps={{
          disablePadding: true,
        }}
      >
        <LayerGroupItem
          titleKey="map.layers.general.federalLands.title"
          subtitleKey="map.layers.general.federalLands.subtitle"
          onClick={(): void => setGroupDrawerOpen(true, 'federalLands')}
          totalLayers={LAYER_GROUPS.federalLands.length}
          activeLayers={getActiveLayersInGroupNumber('federalLands')}
        />
        <LayerGroupItem
          titleKey="map.layers.general.criticalInfrastructure.title"
          subtitleKey="map.layers.general.criticalInfrastructure.subtitle"
          onClick={(): void =>
            setGroupDrawerOpen(true, 'criticalInfrastructure')
          }
          totalLayers={LAYER_GROUPS.criticalInfrastructure.length}
          activeLayers={getActiveLayersInGroupNumber('criticalInfrastructure')}
        />
        {renderControlItem(MapLayers.EVACUATION_ZONES)}
        {isInternalUser && renderControlItem(MapLayers.EXTERNAL_GEO_EVENTS)}
      </CollapsableList>
    </div>
  );
});

LayerGroups.displayName = 'LayerGroups';
