import { Box, Link, Alert, AlertTitle } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Skeleton from '@mui/material/Skeleton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { MATCH_URL_TWITTER } from 'shared/embedHelpers';

type TweetEmbedProps = {
  tweetUrl: string;
};

const SKELETON_TEXT_HEIGHT = 20;
const SKELETON_ANIMATION = 'wave';

const useStyles = makeStyles()(() => ({
  tweetEmbed: {
    '& .twitter-tweet': {
      marginTop: '0px !important',
    },
  },
}));

const EmbedLoadingStatus = {
  LOADING: 0,
  SUCCESS: 1,
  ERROR: 2,
};

const TweetEmbed = (props: TweetEmbedProps): JSX.Element => {
  const { tweetUrl } = props;
  const tweetId = tweetUrl.match(MATCH_URL_TWITTER)?.[1] ?? '';
  const { classes } = useStyles();
  const [readyStatus, setReadyStatus] = useState(EmbedLoadingStatus.LOADING);
  const { t } = useTranslation();

  const wrapperClass =
    readyStatus === EmbedLoadingStatus.LOADING ? undefined : classes.tweetEmbed;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTweetLoad = (html: any): void => {
    if (!html) {
      setReadyStatus(EmbedLoadingStatus.ERROR);
      return;
    }

    setReadyStatus(EmbedLoadingStatus.SUCCESS);
  };

  if (readyStatus === EmbedLoadingStatus.ERROR) {
    return (
      <Alert severity="error">
        <AlertTitle>{t('geoEvent.reports.tweetError.part1')}</AlertTitle>
        {t('geoEvent.reports.tweetError.part2')}
        <Link color="error" href={tweetUrl} target="_blank">
          {t('geoEvent.reports.tweetError.part3')}
        </Link>
      </Alert>
    );
  }

  return (
    <div className={wrapperClass}>
      {readyStatus === EmbedLoadingStatus.LOADING && (
        <Box sx={{ padding: 1 }}>
          <Skeleton
            animation={SKELETON_ANIMATION}
            height={SKELETON_TEXT_HEIGHT}
            variant="text"
          />
          <Skeleton
            animation={SKELETON_ANIMATION}
            height={SKELETON_TEXT_HEIGHT}
            variant="text"
          />
          <Skeleton
            animation={SKELETON_ANIMATION}
            height={SKELETON_TEXT_HEIGHT}
            variant="text"
          />
        </Box>
      )}
      <TwitterTweetEmbed
        key={tweetId}
        tweetId={tweetId}
        onLoad={handleTweetLoad}
      />
    </div>
  );
};

export default TweetEmbed;
