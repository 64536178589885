import axios from 'axios';
import { Source, Layer, LayerProps } from 'shared/map-exports';
import { FeatureCollection, Feature } from 'geojson';
import { FONT_NAMES } from '../styles/constants';
import useTiledQueries, { Geometry } from './useTiledQueries';
import addVisible from '../../../shared/addVisible';
import { MapLayerProps } from '../types';

const PURPLEAIR_API_URL = `https://${
  import.meta.env.VITE_TILE_CACHE_DOMAIN
}/v1/sensors`;
const LAYER_TIMEOUT_MS = 10 * 60 * 1000; // 10 minutes
// PurpleAir has an aggressive rate-acl. Set to a large tile size to ensure fewer
// queries.
const LAYER_TILE_SIZE = 20;

// The response json looks like so:
//
// {
//   fields: [
//     "sensor_index",
//     "latitude",
//     "longitude",
//     "confidence",
//     "humidity_a",
//     "pm2.5_30minute"
//   ],
//   data: [[123,38.45,-122.21,90,45.12], ...]
// }
// Where the `fields` entry denotes the order of the elements of each `data`
type ApiResponse = {
  fields: string[];
  data: number[][];
};

type ApiObject = {
  sensorindex: number;
  latitude: number;
  longitude: number;
  confidence: number;
  humiditya: number;
  pm2530minute: number | undefined;
};

type ValidApiObject = {
  sensorindex: number;
  latitude: number;
  longitude: number;
  confidence: number;
  humiditya: number;
  pm2530minute: number;
};

type LayerFeature = Feature & {
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties: {
    pm25: number;
    aqi: number;
    sort: number;
    sortinv: number;
  };
};

type LayerFeatureCollection = FeatureCollection & {
  features: LayerFeature[];
};

const purpleAirCircleStyle: LayerProps = {
  type: 'circle',
  paint: {
    // Hide invalid values (== -1)
    'circle-opacity': ['interpolate', ['linear'], ['zoom'], 4, 0.5, 8, 1.0],
    'circle-radius': ['interpolate', ['linear'], ['zoom'], 2, 7, 14, 20],
    'circle-blur': [
      'interpolate',
      ['linear'],
      ['zoom'],
      4,
      0.7,
      8,
      0.3,
      9,
      0.0,
    ],
    'circle-color': [
      'interpolate',
      ['linear'],
      ['get', 'aqi'],
      0,
      'rgb(136,222,92)',
      50,
      'rgb(255,255,84)',
      100,
      'rgb(239,133,50)',
      150,
      'rgb(234,51,35)',
      200,
      'rgb(133,68,147)',
      300,
      'rgb(115,20,37)',
    ],
  },
  layout: {
    'circle-sort-key': ['get', 'sort'],
  },
};

const purpleAirLabelStyle: LayerProps = {
  type: 'symbol',
  minzoom: 6,
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-size': ['interpolate', ['linear'], ['zoom'], 6, 9, 14, 19],
    'text-field': ['get', 'aqi'],
    'text-anchor': 'center',
    'text-justify': 'auto',
    'symbol-sort-key': ['get', 'sortinv'],
    'symbol-z-order': 'source',
  },
  paint: {
    'text-halo-width': 1.5,
    // Show black text until aqi 125, when the circle color is darker.
    'text-color': ['step', ['get', 'aqi'], '#000000', 125, '#ffffff'],
  },
};

// These functions sourced from https://community.purpleair.com/t/how-to-calculate-the-us-epa-pm2-5-aqi/877
const aqiFromPm = (pm: number): number | undefined => {
  /*                                  AQI         RAW PM2.5
    Good                               0 - 50   |   0.0 – 12.0
    Moderate                          51 - 100  |  12.1 – 35.4
    Unhealthy for Sensitive Groups   101 – 150  |  35.5 – 55.4
    Unhealthy                        151 – 200  |  55.5 – 150.4
    Very Unhealthy                   201 – 300  |  150.5 – 250.4
    Hazardous                        301 – 400  |  250.5 – 350.4
    Hazardous                        401 – 500  |  350.5 – 500.4
    */
  if (pm > 350.5) {
    return calcAQI(pm, 500, 401, 500.4, 350.5); // Hazardous
  }
  if (pm > 250.5) {
    return calcAQI(pm, 400, 301, 350.4, 250.5); // Hazardous
  }
  if (pm > 150.5) {
    return calcAQI(pm, 300, 201, 250.4, 150.5); // Very Unhealthy
  }
  if (pm > 55.5) {
    return calcAQI(pm, 200, 151, 150.4, 55.5); // Unhealthy
  }
  if (pm > 35.5) {
    return calcAQI(pm, 150, 101, 55.4, 35.5); // Unhealthy for Sensitive Groups
  }
  if (pm > 12.1) {
    return calcAQI(pm, 100, 51, 35.4, 12.1); // Moderate
  }
  if (pm >= 0) {
    return calcAQI(pm, 50, 0, 12, 0); // Good
  }
  return undefined;
};

const calcAQI = (
  pm: number,
  aqiUpperBound: number,
  aqiLowerBound: number,
  pmUpperBound: number,
  pmLowerBound: number,
): number => {
  const a = aqiUpperBound - aqiLowerBound;
  const b = pmUpperBound - pmLowerBound;
  const c = pm - pmLowerBound;
  return Math.round((a / b) * c + aqiLowerBound);
};

const applyEpaPm25Correction = (
  purpleAirPm: number,
  humidity: number,
): number => {
  // Apply a correction to the PurpleAir PM2.5 values by following the recommended piecewise formula
  // described on page 26 here:
  //
  // https://cfpub.epa.gov/si/si_public_file_download.cfm?p_download_id=544231&Lab=CEMM
  const x = purpleAirPm;
  const RH = humidity;

  if (x < 30) {
    return 0.524 * x - 0.0862 * RH + 5.75;
  }
  if (x < 50) {
    return (
      (0.786 * (x / 20 - 3 / 2) + 0.524 * (1 - (x / 20 - 3 / 2))) * x -
      0.0862 * RH +
      5.75
    );
  }
  if (x < 210) {
    return 0.786 * x - 0.0862 * RH + 5.75;
  }
  if (x < 260) {
    return (
      (0.69 * (x / 50 - 21 / 5) + 0.786 * (1 - (x / 50 - 21 / 5))) * x -
      0.0862 * RH * (1 - (x / 50 - 21 / 5)) +
      2.966 * (x / 50 - 21 / 5) +
      5.75 * (1 - (x / 50 - 21 / 5)) +
      8.84 * 0.0001 * x * x * (x / 50 - 21 / 5)
    );
  }

  // x >= 260
  return 2.966 + 0.69 * x + 8.84 * 0.0001 * x * x;
};

// This function remaps the array into an object.
const responseEntriesToObjects = (responseJson: ApiResponse): ApiObject[] => {
  const { data, fields } = responseJson;
  const mapping = Object.entries(fields).map(([index, name]) => [
    name.replace('.', '').replace('_', ''),
    parseInt(index, 10),
  ]);
  return data.map((sensorData) => {
    const entries = mapping.map(([name, index]) => [name, sensorData[index]]);
    return Object.fromEntries(entries) as ApiObject;
  });
};

const isValidSensorEntry = (entry: ApiObject): boolean => {
  const { pm2530minute: pm25, confidence } = entry;
  if (pm25 === undefined) return false;
  if (isNaN(pm25)) return false;
  if (pm25 < 0) return false;
  if (pm25 > 1000) return false;
  if (confidence < 50) return false;
  return true;
};

const sensorEntryToGeojsonFeature = (entry: ValidApiObject): LayerFeature => {
  const { latitude, longitude, pm2530minute: pm25, humiditya } = entry;
  const correctedPm25 = Math.max(0, applyEpaPm25Correction(pm25, humiditya));
  const aqi = aqiFromPm(correctedPm25) || 0;
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [longitude, latitude],
    },
    properties: {
      pm25,
      aqi,
      // Maplibre seems to prefer small numbers for the sort keys, or it
      // starts to do wonky things.
      sort: Math.log(1 + correctedPm25),
      sortinv: 1 / (correctedPm25 + 1),
    },
  };
};

const fetchGeojson = async (
  geometry: Geometry,
): Promise<LayerFeatureCollection> => {
  const url = new URL(PURPLEAIR_API_URL);
  // we don't add the modified_since param to the cache layer so this does not invalidate the queries to cloudfront
  const modifiedSince = new Date().getTime() / 1000 - 3600;
  const requestFields = [
    'latitude',
    'longitude',
    // using `humidity_a` (a 1 point field) since only 5% of sensors have both humiditya and humidityB,
    // `humidity` is the average of those fields and is a 2 point field for cost
    'humidity_a',
    // This is the "ATM" channel on the PurpleAir sensor. It is the only channel that
    // has time-window moving average values returned from the API, so we must use it
    // for doing the EPA correction in `applyEpaPm25Correction()`. That formula should
    // not be used on short time-windows or realtime values.
    'pm2.5_30minute',
    'confidence',
  ];
  url.searchParams.append('fields', requestFields.join(','));
  url.searchParams.append('location_type', '0'); // outdoor sensors only
  url.searchParams.append('nwlng', geometry.xmin.toString());
  url.searchParams.append('nwlat', geometry.ymax.toString());
  url.searchParams.append('selng', geometry.xmax.toString());
  url.searchParams.append('selat', geometry.ymin.toString());
  url.searchParams.append('modified_since', modifiedSince.toString());

  const response = await axios.get(url.toString(), {
    responseType: 'json',
  });
  const entries = responseEntriesToObjects(response.data as ApiResponse);
  const validFeatures = entries.filter(isValidSensorEntry) as ValidApiObject[];
  const features = validFeatures.map(sensorEntryToGeojsonFeature);
  return {
    type: 'FeatureCollection',
    features,
  };
};

const mergeGeojson = (features: FeatureCollection[]): FeatureCollection => {
  // console.log('info custom merge geojson data', features);
  const mergedFeatures = features.reduce((a, b) => {
    return a.concat(b.features);
  }, [] as Feature[]);
  return {
    type: 'FeatureCollection',
    features: mergedFeatures,
  };
};

const PurpleAirLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const data = useTiledQueries({
    enabled: visible,
    tileSize: LAYER_TILE_SIZE,
    staleTime: LAYER_TIMEOUT_MS,
    refetchInterval: LAYER_TIMEOUT_MS,
    queryKey: ['purpleair'],
    queryFn: (tileGeometry) => fetchGeojson(tileGeometry),
    mergeFn: mergeGeojson,
  });
  return (
    <>
      <Source id="purpleair" type="geojson" data={data}>
        <Layer {...addVisible(purpleAirCircleStyle, visible)} />
        <Layer {...addVisible(purpleAirLabelStyle, visible)} />
      </Source>
    </>
  );
};

export default PurpleAirLayer;
