export enum ResponsibilityAreaId {
  FRA = 'FRA',
  LRA = 'LRA',
  SRA = 'SRA',
}

export type ResponsibilityArea = {
  OBJECTID: number;
  SRA: string;
  Shape__Area: number;
  Shape__Length: number;
};
