import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import RichTextEditor from 'components/RichTextEditor';

type InternalNotesInputProps = {
  initialValue: string;
};

const useStyles = makeStyles()((theme) => ({
  editor: {
    minHeight: 42,
  },
}));

export const InternalNotesInput = (
  props: InternalNotesInputProps,
): JSX.Element => {
  const { initialValue } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { control, formState } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ marginTop: 1 }}>
          <Typography variant="h3">
            {t('createEditGeoEvent.sections.internal')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="reporterOnlyNotes"
          control={control}
          render={({ field, fieldState }): JSX.Element => {
            return (
              <>
                <RichTextEditor
                  id="field-control-internal-notes"
                  label={t('createEditGeoEvent.inputs.reporterOnlyNotes.label')}
                  initialValue={initialValue}
                  onChange={field.onChange}
                  editable={!formState.isSubmitting}
                  error={!!fieldState.error}
                  editorClassName={classes.editor}
                />
                <FormHelperText
                  error={!!fieldState.error}
                  sx={{ marginLeft: 1.75, marginRight: 1.75 }}
                >
                  {fieldState.error?.message ||
                    t('createEditGeoEvent.inputs.reporterOnlyNotes.helperText')}
                </FormHelperText>
              </>
            );
          }}
        />
      </Grid>
    </>
  );
};
