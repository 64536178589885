import { atom, useRecoilState } from 'recoil';
import { localStorageEffect, LOCAL_STORAGE_KEY } from './localStorage';
import useAuthState from './useAuthState';

type UseCacheState = {
  setCacheState: (cacheTs: number | null) => void;
  cacheBusterTs: number | null;
};

export const MAX_CACHE_MS = 60 * 5 * 1000;

// *** For local development ***
const params = new URL(document.location.href).searchParams;
const testBuster = params.get('set_cache_buster');
const defaultCacheState = testBuster ? new Date().getTime() : null;

const cacheAtom = atom<number | null>({
  key: LOCAL_STORAGE_KEY.CACHE,
  default: defaultCacheState,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.CACHE)],
});

export const useCacheState = (): UseCacheState => {
  const {
    permissions: { canReport },
  } = useAuthState();
  const [cacheState, setCacheState] = useRecoilState<number | null>(cacheAtom);

  const evalCacheState = (): number | null => {
    // At the time we want to use the cacheTs, verify if it is too old
    if (!cacheState) {
      return null;
    }
    const nowMs = new Date().getTime();
    if (nowMs - cacheState > MAX_CACHE_MS) {
      return null;
    }
    return cacheState;
  };
  const cacheBusterTs = canReport ? null : evalCacheState();
  return {
    setCacheState,
    cacheBusterTs,
  };
};
