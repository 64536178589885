/* eslint-disable no-useless-escape */
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInboxMessages } from 'hooks/useInboxMessages';
import { getTimePass } from 'shared/dates';

// Code extracted from https://github.com/stiang/remove-markdown/blob/main/index.js
const removeMarkdown = (md: string): string => {
  try {
    const output = md
      // Remove horizontal rules
      .replace(/^(-\s*?|\*\s*?|_\s*?){3,}\s*/gm, '')
      // Remove HTML tags
      .replace(/<[^>]*>/g, '')
      // Remove setext-style headers
      .replace(/^[=\-]{2,}\s*$/g, '')
      // Remove footnotes?
      .replace(/\[\^.+?\](\: .*?$)?/g, '')
      .replace(/\s{0,2}\[.*?\]: .*?$/g, '')
      // Remove images
      .replace(/\!\[(.*?)\][\[\(].*?[\]\)]/g, '')
      // Remove inline links
      .replace(/\[([^\]]*?)\][\[\(].*?[\]\)]/g, '$1')
      // Remove blockquotes
      .replace(/^(\n)?\s{0,3}>\s?/gm, '$1')
      // .replace(/(^|\n)\s{0,3}>\s?/g, '\n\n')
      // Remove reference-style links?
      .replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '')
      // Remove atx-style headers
      .replace(
        /^(\n)?\s{0,}#{1,6}\s*( (.+))? +#+$|^(\n)?\s{0,}#{1,6}\s*( (.+))?$/gm,
        '$1$3$4$6',
      )
      // Remove * emphasis
      .replace(/([\*]+)(\S)(.*?\S)??\1/g, '$2$3')
      // Remove _ emphasis. Unlike *, _ emphasis gets rendered only if
      //   1. Either there is a whitespace character before opening _ and after closing _.
      //   2. Or _ is at the start/end of the string.
      .replace(/(^|\W)([_]+)(\S)(.*?\S)??\2($|\W)/g, '$1$3$4$5')
      // Remove code blocks
      .replace(/(`{3,})(.*?)\1/gm, '$2')
      // Remove inline code
      .replace(/`(.+?)`/g, '$1')
      // // Replace two or more newlines with exactly two? Not entirely sure this belongs here...
      // .replace(/\n{2,}/g, '\n\n')
      // // Remove newlines in a paragraph
      // .replace(/(\S+)\n\s*(\S+)/g, '$1 $2')
      // Replace strike through
      .replace(/~(.*?)~/g, '$1');
    return output;
  } catch (error) {
    return md;
  }
};

const clipToNWords = (str: string, n: number): string => {
  const words = str.split(' ');
  if (words.length <= n) {
    return str;
  }
  const clippedWords = words.slice(0, n);
  return `${clippedWords.join(' ')}...`;
};

const useStyles = makeStyles()((theme) => ({
  itemIcon: {
    minWidth: theme.spacing(3),
    marginTop: theme.spacing(1.25),
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  timeAgo: {
    marginLeft: theme.spacing(1),
    marginTop: 2,
    width: 110,
    minWidth: 100,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  statusBox: {
    width: 12,
    height: 12,
    borderRadius: 12,
  },
  unreadBgColor: {
    backgroundColor: theme.palette.primary.main,
  },
  readBgColor: {
    backgroundColor: theme.palette.grey[300],
  },
  itemTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  noMessages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const MessagesContent = (): JSX.Element => {
  const { classes, cx } = useStyles();
  const { messages, setMessageAsRead } = useInboxMessages();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = (messageId: number): void => {
    setMessageAsRead(messageId);
    history.push(`/inbox/${messageId}#allow-back`);
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {messages.length > 0 ? (
        <List dense>
          {messages.map((message) => {
            return (
              <ListItemButton
                key={message.id}
                divider
                alignItems="flex-start"
                onClick={() => handleClick(message.id)}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <Box
                    className={cx(
                      classes.statusBox,
                      message.read
                        ? classes.readBgColor
                        : classes.unreadBgColor,
                    )}
                    role="checkbox"
                    tabIndex={0}
                    aria-checked={message.read}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box className={classes.itemTitle}>
                      <Typography
                        className={cx(
                          classes.title,
                          !message.read && classes.boldText,
                        )}
                      >
                        {message.title}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        className={classes.timeAgo}
                      >
                        {getTimePass(message.dateCreated)}
                      </Typography>
                    </Box>
                  }
                  secondary={clipToNWords(
                    removeMarkdown(message.messageMarkdown),
                    15,
                  )}
                  secondaryTypographyProps={{ color: 'textPrimary' }}
                />
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        <Box className={classes.noMessages}>
          <Typography variant="h3">
            <b>{t('messagesInbox.noMessages')}</b>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MessagesContent;
