import { LayerProps } from 'shared/map-exports';
import { AircraftIconVariant } from './types';

export const DEFAULT_FILL_COLOR = '#A750FF';
export const DEFAULT_STROKE_COLOR = '#1F1F1F';
export const ACTIVE_FILL_COLOR = '#4AFB2D';
export const GROUND_FILL_COLOR = '#999999';

export const MIN_ICON_SCALE = 0.2;
export const MAX_ICON_SCALE = 0.35;

export const STROKE_LINE_WIDTH = 2.5;

// The pixel offset range to devote to altitude-based shadow offset.
// This number is divided by SHADOW_DISTANCE_ALTITUDE_MAX_FEET and then
// added to SHADOW_DISTANCE_MIN_PX to calculate the final offset.
export const SHADOW_DISTANCE_BY_ALTITUDE_RANGE_PX = 8;

// The maximum altitude to use when calculating the shadow offset.
// Any value greater than this will be offset at SHADOW_DISTANCE_BY_ALTITUDE_RANGE_PX.
export const SHADOW_DISTANCE_ALTITUDE_MAX_FEET = 18000;

// The minimum offset distance, used as a constant to add to the offset calculated
// from altitude.
export const SHADOW_DISTANCE_MIN_PX = 3;

export const fillColorForVariant = (variant: AircraftIconVariant): string => {
  if (variant === 'active' || variant === 'activeground') {
    return ACTIVE_FILL_COLOR;
  }
  if (variant === 'ground') {
    return GROUND_FILL_COLOR;
  }
  return DEFAULT_FILL_COLOR;
};

export const traceStyle: LayerProps = {
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#4AFB2D',
    'line-width': ['interpolate', ['linear'], ['zoom'], 6, 1.5, 12, 4],
    'line-gradient': [
      'interpolate',
      ['exponential', 0.8],
      ['line-progress'],
      0,
      'rgba(74,251,45,0.3)',
      1,
      'rgba(74,251,45,0.6)',
    ],
  },
};
