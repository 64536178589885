import { useRouteMatch } from 'react-router-dom';

type CameraParams = {
  cameraId?: string;
};

export const useSelectedCameraId = (): string | undefined => {
  // Using `useRouteMatch()` instead of `useParams()` since we're not always inside the GeoEvent <Router>
  const match = useRouteMatch<CameraParams>('/camera/:cameraId');
  const cameraIdStr = match?.params.cameraId;

  return cameraIdStr;
};
