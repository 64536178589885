import { useEffect, useState, useCallback } from 'react';
import initPushNotifications, {
  handlePushRegistrationFinished,
  isPushNotificationsEnabled,
} from 'shared/push';
import { getPushTokenFromLocalStorage } from 'state/localStorage';
import { useHistory } from 'react-router-dom';
import { useAuthState, useCacheState } from 'state';
import { Token } from '@capacitor/push-notifications';
import { UseQueryResult } from '@tanstack/react-query';
import {
  NotificationSettingAPI,
  NotificationSetting,
  QueryApiResult,
} from 'shared/types';
import useForegroundState from './useForegroundState';
import { useNotifications } from './useNotifications';

type NotificationsSettingsProps = {
  notificationsEnabled?: boolean;
  regionSettingsQueryEnabled?: boolean;
};

type UseArePushNotificationsEnabledHookReturn = {
  isPushEnabled: boolean;
};

type UseArePushNotificationSettingsHookReturn = {
  isPushEnabled: boolean;
  pushToken: string | null;
  subscribedNotificationSettings: UseQueryResult<
    NotificationSettingAPI,
    unknown
  >;
  allNotificationSettings: QueryApiResult<NotificationSetting[]>;
};

export const useArePushNotificationsEnabled =
  (): UseArePushNotificationsEnabledHookReturn => {
    const [isPushEnabled, setIsPushEnabled] = useState(false);

    useEffect(() => {
      async function checkPushPermissions(): Promise<void> {
        const isEnabled = await isPushNotificationsEnabled();
        setIsPushEnabled(isEnabled);
      }
      checkPushPermissions();
    }, []);
    return {
      isPushEnabled,
    };
  };

/**
 * This custom hook component serves to manage notifications within the application.
 * It first checks whether notifications are enabled and whether a push token has
 * already been registered. If a push token has not been registered, the component
 * registers one when the user navigates to the notification settings to enable
 * notifications, and comes back to the WD app. Additionally, the component provides
 * notification settings data related to specific county regions.
 */
export const useNotificationsSettings = (
  props: NotificationsSettingsProps = {},
): UseArePushNotificationSettingsHookReturn => {
  const { notificationsEnabled = false, regionSettingsQueryEnabled = true } =
    props;
  const history = useHistory();
  const { setCacheState } = useCacheState();
  const [isPushEnabled, setIsPushEnabled] = useState(notificationsEnabled);
  const [pushToken, setPushToken] = useState(getPushTokenFromLocalStorage());
  const { subscribedNotificationSettings, allNotificationSettings } =
    useNotifications({
      queryEnabled: regionSettingsQueryEnabled,
    });
  const appState = useForegroundState();
  const { user } = useAuthState();

  /**
   * When a user denies permission to receive push notifications on app start
   * and then grant permission when on this page, we have to wait for the 'registration'
   * event handler to execute so that we retry to get subscriptions with a token
   * if not token will be null and user is going to get stuck in an 'error' state
   */
  const handlePushTokenRegistration = useCallback(
    async (token: Token) => {
      await handlePushRegistrationFinished(token, user);
      setPushToken(token.value);
    },
    [user],
  );

  useEffect(() => {
    if (!appState.isActive) return;

    async function checkPushPermissions(): Promise<void> {
      const isEnabled = await isPushNotificationsEnabled();
      if (isEnabled && !getPushTokenFromLocalStorage()) {
        await initPushNotifications(
          history,
          setCacheState,
          handlePushTokenRegistration,
        );
      }
      setIsPushEnabled(isEnabled);
    }
    checkPushPermissions();
  }, [appState.isActive, handlePushTokenRegistration, history, setCacheState]);

  return {
    isPushEnabled,
    pushToken,
    subscribedNotificationSettings,
    allNotificationSettings,
  };
};
