import { useEffect, useState } from 'react';

export type ImageLoadingStatus = 'loading' | 'success' | 'error';
export const IMAGE_LOADER_BATCH_SIZE = 15;

type ImageSequenceLoadingState = {
  sources: string[];
  status: ImageLoadingStatus[];
  nextBatchStart: number;
};

/**
 * This hook tries to load an image programmatically so that the browser cache
 * will be able to show the image when it's needed for real. Note that the
 * "disable cache" setting in the browser's dev tools will prevent this from working.
 */
export const useImageLoader = (
  src?: string,
): ImageLoadingStatus | undefined => {
  const [status, setStatus] = useState<ImageLoadingStatus | undefined>();

  const handleLoad = (): void => {
    setStatus('success');
  };

  const handleError = (): void => {
    setStatus('error');
  };

  useEffect(() => {
    if (!src) {
      return;
    }

    const image = new Image();
    image.src = src;
    if (image.complete) {
      setStatus('success');
    } else {
      setStatus('loading');
      image.onload = handleLoad;
      image.onerror = handleError;
    }
    image.onload = handleLoad;
  }, [src]);

  return status;
};

/**
 * Load a sequence of images in batches. This lets us prefetch a large number of images
 * on a slow network without waiting for all of them to load simultaneously.
 *
 * The return value will be an array with the current status for each source image. The
 * status will be 'loading' for both images that are currently loading, as well as images that
 * have not started loading yet but will be loaded in a later batch.
 *
 * If no source images are provided this will return undefined.
 */
export const useImageSequenceLoader = (
  sources: string[],
): ImageLoadingStatus[] | undefined => {
  const [loadingState, setLoadingState] = useState<ImageSequenceLoadingState>();

  useEffect(() => {
    if (!sources || sources.length === 0) {
      setLoadingState(undefined);
    } else {
      setLoadingState({
        sources,
        status: new Array<ImageLoadingStatus>(sources.length).fill('loading'),
        nextBatchStart: 0,
      });
    }
  }, [sources]);

  useEffect(() => {
    if (!loadingState) return;

    const updateImageStatus = (
      src: string,
      updatedStatus: ImageLoadingStatus,
    ): void => {
      setLoadingState((prev) => {
        if (prev === undefined) return undefined;

        // Update the status for this image. If the sources have changed
        // and the image is no longer being loaded, don't update the
        // state object.
        const index = prev.sources.indexOf(src);
        if (index === -1) return prev;

        const updated = { ...prev };
        updated.status[index] = updatedStatus;
        return updated;
      });
    };

    setLoadingState((prev) => {
      if (!prev) return prev;

      const firstLoading = prev.status.indexOf('loading');

      // All images have finished loading.
      if (firstLoading === -1) {
        return prev;
      }

      // The previous batch of images are still loading.
      if (firstLoading < prev.nextBatchStart) {
        return prev;
      }

      // Start loading the next batch.
      const startIndex = prev.nextBatchStart;
      const endIndex = Math.min(
        prev.sources.length,
        startIndex + IMAGE_LOADER_BATCH_SIZE,
      );

      const updated = { ...prev };
      for (let i = startIndex; i < endIndex; i += 1) {
        const image = new Image();
        image.src = prev.sources[i];
        if (image.complete) {
          updated.status[i] = 'success';
        } else {
          image.onload = () => updateImageStatus(prev.sources[i], 'success');
          image.onerror = () => updateImageStatus(prev.sources[i], 'error');
        }
      }
      updated.nextBatchStart = endIndex;

      return updated;
    });
  }, [loadingState]);

  return loadingState?.status;
};
