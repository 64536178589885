import i18n from 'i18next';
import { enUS, es, vi, zhCN } from 'date-fns/locale';
import { format, formatDistanceToNow } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { Language } from 'i18n/utils';

type DateFnsLocale = keyof typeof locales;

const locales = { enUS, es, vi, zhCN };

const intLangToDateFnsLocale: Record<Exclude<Language, 'tl'>, DateFnsLocale> = {
  en: 'enUS',
  es: 'es',
  vi: 'vi',
  zh: 'zhCN',
};

export const SHORT_MONTH_DAY_12HOUR_FORMAT = 'MMM d, h:mm a';

export const formatDate = (date: Date, formatStr = 'PP'): string => {
  return format(date, formatStr, {
    locale: locales[intLangToDateFnsLocale[i18n.resolvedLanguage as Language]],
  });
};

export const formatDateDistanceToNow = (
  date: Date,
  options: { includeSeconds?: boolean; addSuffix?: boolean } = {},
): string => {
  return formatDistanceToNow(date, {
    locale: locales[intLangToDateFnsLocale[i18n.resolvedLanguage as Language]],
    ...options,
  });
};

/**
 * @author:davidofwatkins Not sure why dates are coming back without any timezone, but we should probably
 * fix this from the serverside. This check will prevent the front-end from breaking if we do that far
 * enough in the future.
 */
export const setNaiveISODateStringToUTC = (isoString: string): string =>
  isoString.includes('+') || isoString.endsWith('Z')
    ? isoString
    : `${isoString}Z`;

export const getTimePass = (time: string): string =>
  formatDateDistanceToNow(new Date(setNaiveISODateStringToUTC(time)), {
    addSuffix: true,
  });

export const getDateFormatted = (
  time: string,
  options?: { includeYear?: boolean },
): string => {
  const date = new Date(setNaiveISODateStringToUTC(time));
  const combiner = i18n.t('common.at');
  const dateFormat = options?.includeYear ? 'MMM d, yyyy' : 'MMM d';

  const formattedDate = formatDate(date, dateFormat);
  const formattedTime = formatDate(date, 'h:mm a');

  return `${formattedDate} ${combiner} ${formattedTime}`;
};

export const getDateFormattedInboxMessage = (time: string): string => {
  const date = new Date(setNaiveISODateStringToUTC(time));
  const combiner = i18n.t('common.at');

  const formattedDate = formatDate(date, 'EEEE, MMM d');
  const formattedTime = formatDate(date, 'h:mm a');

  return `${formattedDate} ${combiner} ${formattedTime}`;
};

export const getDateStringWithNoZeroTimeZone = (): string =>
  new Date().toISOString().slice(0, -1);

/**
 * Formats a Date object into a local ISO 8601 string without timezone information.
 *
 * The function preserves the local date and time components exactly as provided,
 * returning them in the format 'YYYY-MM-DDTHH:mm:ss'. Unlike toISOString(),
 * which converts to UTC and appends 'Z', this maintains the local time representation
 * without any timezone indicators.
 */
export const formatLocalDateTimeWithoutTimezone = (date: Date): string => {
  const isoDate = formatISO(date);
  return format(new Date(isoDate), "yyyy-MM-dd'T'HH:mm:ss");
};
