import Map from 'components/Map';
import {
  includesFullWord,
  getAbsoluteUrl,
  getEvacDescription,
  getRichTextContent,
} from 'shared/utils';
import { formatLocalDateTimeWithoutTimezone, getTimePass } from 'shared/dates';
import {
  GeoEvent,
  GeoEventRegion,
  LatLng,
  GeoEventCreateUpdateData,
} from 'shared/types';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { formatReportMediaForm } from 'components/MediaForm';
import {
  GeoEventFormValues,
  GeoCodeItem,
  LocationPlaceResult,
  RegionOption,
  ReverseGeoCodeResponse,
} from './GeoEventForm.types';
import { LinkTypes } from '../../../constants';
import { MapBoxAPI } from '../../../api';

// Maps specific reverse geocoding county names to their WD equivalents.
// Most county names are identical in both systems, but this mapping handles exceptions.
// Example: "Carson City" in reverse geocoding corresponds to "Carson" in WD.
const COUNTY_NAME_REVERSE_GEOCODING_TO_WD = {
  carson_city: 'carson',
} as const;

const reverseGeoCodeCoords = async (
  lat: number,
  lng: number,
): Promise<GeoCodeItem> => {
  const response = await MapBoxAPI.get<ReverseGeoCodeResponse>(
    `${lng},${lat}.json`,
    {
      params: {
        access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
        types: 'place,address',
        language: 'en',
        limit: '1',
        country: 'us',
      },
    },
  );
  return response.data.features[0];
};

const getLocationPlace = async (
  lat: number,
  lng: number,
): Promise<LocationPlaceResult> => {
  const geocodeFeature = await reverseGeoCodeCoords(lat, lng);

  const stateComponent = geocodeFeature.context.find((c) =>
    c.id.includes('region'),
  )?.shortCode;

  const countyComponent = geocodeFeature.context.find((c) =>
    c.id.includes('district'),
  )?.text;

  const county = countyComponent
    ?.toLowerCase()
    .replace('county', '')
    .trim()
    .replaceAll(' ', '_');

  let placeName = '';
  if (geocodeFeature.placeType[0] === 'address') {
    const [address, place] = geocodeFeature.placeName.split(',');
    if (address) placeName += address.trim();
    if (place) placeName += `, ${place.trim()}`;
    if (!placeName) placeName = geocodeFeature.text;
  } else {
    placeName = geocodeFeature.text;
  }

  return {
    county,
    state: stateComponent?.replace('US-', ''),
    placeName,
  };
};

const isSameCounty = (input: {
  wdCounty: string;
  geocodedCounty?: string;
}): boolean => {
  const { wdCounty, geocodedCounty } = input;
  if (!geocodedCounty) return false;
  return (
    geocodedCounty === wdCounty ||
    COUNTY_NAME_REVERSE_GEOCODING_TO_WD[geocodedCounty] === wdCounty
  );
};

export const getMatchingRegions = async (
  lat: number,
  lng: number,
  regionOptions: RegionOption[],
): Promise<{ placeName: string; matchingRegions: RegionOption[] }> => {
  const { county, state, placeName } = await getLocationPlace(lat, lng);

  const matches = regionOptions.filter((region) => {
    const [regionCounty, regionStateUS] = region.name.split('-');
    if (state) {
      return (
        isSameCounty({ wdCounty: regionCounty, geocodedCounty: county }) &&
        regionStateUS.includes(state)
      );
    }
    return isSameCounty({ wdCounty: regionCounty, geocodedCounty: county });
  });

  return { placeName, matchingRegions: matches };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRedundancyMessage = (errorResponse: any): string => {
  const { data: errorData } = errorResponse;

  if (errorData.length === 1) {
    const recentGeoEvent = errorData[0];
    const creator = recentGeoEvent.userCreated?.username || 'Someone';
    const timeSince = getTimePass(recentGeoEvent.dateCreated);

    return `${creator} just posted ${timeSince}: \n"${recentGeoEvent.name}"\n\nAre you sure you still want to post this incident?`;
  }

  return `${errorData.length} incidents have been created in the past few minutes for this county!\n\nAre you sure you still want to post this incident?`;
};

export const hasEvacInfoChanged = (
  prev: string | null,
  next: string,
): boolean => {
  const prevValue = (prev || '').trim();
  const nextValue = next.trim();
  return prevValue !== nextValue;
};

export const geoEventFormValuesToData = (
  values: GeoEventFormValues,
  hasEvacZones: boolean,
): GeoEventCreateUpdateData => {
  const geoEventData: GeoEventCreateUpdateData = {
    name: values.name,
    address: values.isComplexParent ? '' : values.address,
    regions: values.isComplexParent ? [] : values.regions,
    isActive: values.status === 'active',
    notificationType: values.notificationType,
    geoEventType: 'wildfire',
    lat: values.lat,
    lng: values.lng,
    reporterManaged: true,
    data: {
      geoEventType: 'wildfire',
      acreage: values.acreage,
      containment: values.containment,
      isComplexParent: values.isComplexParent,
      isFps: values.isFps,
      isPrescribed: values.isPrescribed,
      evacuationNotes: getEvacDescription({
        description: values.evacuationNotes,
        custom: false,
        hasEvacZones: false,
      }),
      temporarilyDisplayEvacZones: values.temporarilyDisplayEvacZones,
      evacuationOrders: getEvacDescription({
        description: values.evacuationOrders,
        custom: values.customOrders,
        hasEvacZones,
      }),
      evacuationWarnings: getEvacDescription({
        description: values.evacuationWarnings,
        custom: values.customWarnings,
        hasEvacZones,
      }),
      evacuationAdvisories: getEvacDescription({
        description: values.evacuationAdvisories,
        custom: values.customAdvisories,
        hasEvacZones,
      }),
      links: values.links.map((link) => {
        if (link.linkType === LinkTypes.website) {
          return { ...link, value: getAbsoluteUrl(link.value) };
        }
        return link;
      }),
      reporterOnlyNotes: getRichTextContent(values.reporterOnlyNotes),
    },
    evacZoneStatuses: values.evacZoneStatuses.map((eZS) => ({
      status: eZS.status,
      evacZone: { id: eZS.evacZone.id },
    })),
    childGeoEvents: [
      ...(values.childWildfires.map((ge) => ({
        id: ge.id,
        geoEventType: ge.geoEventType,
      })) || []),
      ...(values.childLocations.map((ge) => ({
        id: ge.id,
        geoEventType: ge.geoEventType,
      })) || []),
    ],
  };
  if (values.prescribedDateStartLocal) {
    geoEventData.data.prescribedDateStartLocal =
      formatLocalDateTimeWithoutTimezone(values.prescribedDateStartLocal);
  }
  if (values.reportMessage || values.embedUrl || values.media) {
    geoEventData.initialReport = {};
  }
  if (values.reportMessage) {
    geoEventData.initialReport!.message = values.reportMessage;
  }
  if (values.embedUrl) {
    geoEventData.initialReport!.embedUrl = values.embedUrl;
  }
  if (values.media) {
    geoEventData.initialReport!.media = [
      formatReportMediaForm({
        media: values.media,
        fileType: values.fileType,
        mediaLat: values.mediaLat,
        mediaLng: values.mediaLng,
        mediaAz: values.mediaAz,
      }),
    ];
  }
  return geoEventData;
};

export const hasRegionsChanged = (
  prevRegions: GeoEventRegion[],
  newRegions: GeoEventRegion[],
): boolean => {
  return (
    prevRegions.length !== newRegions.length ||
    prevRegions.some(
      (prevRegion, index) => prevRegion.id !== newRegions[index].id,
    )
  );
};

export const getDefaultMapCenter = (data: {
  geoEvent?: GeoEvent;
  searchParams?: URLSearchParams;
  center?: { lat: number; lng: number };
}): LatLng => {
  const { geoEvent, searchParams, center } = data;

  if (geoEvent?.id) {
    return { lat: geoEvent.lat, lng: geoEvent.lng };
  }

  const latParam = parseFloat(searchParams?.get('lat') ?? '0');
  const lngParam = parseFloat(searchParams?.get('lng') ?? '0');

  if (latParam && lngParam) {
    return { lat: latParam, lng: lngParam };
  }

  if (center && center.lat && center.lng) {
    return { lat: center.lat, lng: center.lng };
  }

  return Map.DefaultConfig.center;
};

const getErrorsListFromResponse = (
  errors: Record<string, string[]> | string[],
): string[] | null => {
  if (Array.isArray(errors)) {
    return errors;
  }

  if (typeof errors === 'object') {
    return Object.values(errors).flat();
  }

  return null;
};

export const getMessageFromGeoEventSaveErrorResponse = (
  error: AxiosError,
  t: TFunction,
): string => {
  const errorStatus = error.response?.status;

  if (errorStatus === 403) {
    return 'Error 403: You cannot submit or update incidents in this county.';
  }

  if (errorStatus === 400) {
    const errors = error?.response?.data;

    const errorsList = getErrorsListFromResponse(errors);

    if (!errorsList?.length) {
      const stringifiedError = JSON.stringify(error.response);
      return `Error 400: Cannot process submission. Double check incident details and try again. Details: ${stringifiedError}`;
    }

    const formattedErrors = errorsList
      .map((err: string) => {
        if (typeof err === 'string') {
          return err.replaceAll('_', ' ').replaceAll('-', ' ').trim();
        }
        return '';
      })
      .filter(Boolean);

    const joinedErrors = formattedErrors.join(', ');
    return `Error 400: Cannot process submission. Reason(s): ${joinedErrors}`;
  }

  return error.message || t('geoEvent.errorMessage');
};

export const validateIncidentName = (
  incidentName: string,
  t: TFunction,
): string | null => {
  if (!incidentName) return null;

  const validKeywords = ['fire', 'prescribed', 'burn', 'complex'];

  if (!includesFullWord(incidentName, validKeywords)) {
    return t('createEditGeoEvent.inputs.name.warning');
  }

  return null;
};

export const validateIncidentAddress = (
  address: string,
  t: TFunction,
): string | null => {
  if (!address) return null;

  const validKeywords = [
    'block',
    'and',
    '&',
    'cross',
    'at',
    'near',
    'off',
    'of',
  ];

  if (!includesFullWord(address, validKeywords)) {
    return t('createEditGeoEvent.inputs.address.warning');
  }

  return null;
};

export const validatePrescribedIncident = (
  incidentName: string,
  isPrescribed: boolean,
  t: TFunction,
  isDescription = false,
): string | null => {
  if (!incidentName) return null;

  if (!isPrescribed && includesFullWord(incidentName, ['prescribed'])) {
    if (isDescription) {
      return t('createEditGeoEvent.inputs.reportMessage.warning');
    }
    return t('createEditGeoEvent.prescribedBurnWarning');
  }

  return null;
};

export const validateComplexIncidentName = (
  incidentName: string,
  isComplexParent: boolean,
  t: TFunction,
): string | null => {
  if (!incidentName) return null;

  if (!isComplexParent && includesFullWord(incidentName, ['complex'])) {
    return t('createEditGeoEvent.complexNameWarning');
  }

  return null;
};
