import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { MarkerComponent } from 'shared/map-exports';
import { LatLng } from 'shared/types';
import {
  CIRCLE_STROKE_PIXELS,
  STROKE_STYLE,
  Z_INDEX_LINE_AND_CIRCLE,
} from './constants';
import { UpdateRef } from './types';

type CircleProps = {
  center: LatLng;
  children?: ReactNode;
};

export const Circle = forwardRef<UpdateRef, CircleProps>(function Circle(
  props: CircleProps,
  ref,
): JSX.Element {
  const { center, children } = props;
  const divRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => ({
    update: (measurements) => {
      if (!divRef.current) return;
      const div = divRef.current;
      const dimension = `${
        measurements.radiusPixels * 2 + CIRCLE_STROKE_PIXELS
      }px`;
      div.style.width = dimension;
      div.style.height = dimension;
      div.style.transform = `rotate(${measurements.angleRad}rad)`;
    },
  }));
  useEffect(() => {
    if (!divRef.current) return;
    const div = divRef.current;
    div.style.border = `${CIRCLE_STROKE_PIXELS}px ${STROKE_STYLE}`;
    div.style.borderRadius = '50%';
  }, []);

  return (
    <MarkerComponent
      latitude={center.lat}
      longitude={center.lng}
      style={{ zIndex: Z_INDEX_LINE_AND_CIRCLE }}
    >
      <div ref={divRef}>{children}</div>
    </MarkerComponent>
  );
});
