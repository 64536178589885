import { memo, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { noop } from 'lodash-es';
import { useFormContext, useWatch } from 'react-hook-form';
import Map from 'components/Map';
import { VisuallyBusyMapLayers } from 'components/Map/constants';
import { useMapState } from 'state';
import { GeoEvent, LatLng } from 'shared/types';
import { DynamicMapCenter } from 'components/Map/DynamicMapCenter';
import { useFormGeoEvent } from './useFormGeoEvent';

type MediaMapProps = {
  geoEvent?: GeoEvent;
  lat: number | null;
  lng: number | null;
  azFieldName: string;
};

const getDefaultMapCenter = (incidentMap: {
  center: { lat: number; lng: number };
}): LatLng => {
  const { lat, lng } = incidentMap.center;

  if (lat && lng) {
    return { lat, lng };
  }

  return Map.DefaultConfig.center;
};

const useStyles = makeStyles()((theme) => ({
  mapContainer: {
    width: '100%',
    height: 225,
    borderRadius: theme.shape.borderRadius * 1.34,
    overflow: 'hidden',
  },
}));

export const MediaMap = memo((props: MediaMapProps): JSX.Element => {
  const { geoEvent, lat, lng, azFieldName } = props;
  const { classes } = useStyles();
  const { incidentMapState } = useMapState();
  const [initialMapCenter] = useState(getDefaultMapCenter(incidentMapState));
  const { control } = useFormContext();
  const mapGeoEvent = useFormGeoEvent({ geoEvent });

  const azimuth: number = useWatch({
    control,
    name: azFieldName,
    defaultValue: 0,
  });

  return (
    <Box className={classes.mapContainer}>
      <Map
        id="media-location"
        center={initialMapCenter}
        disableMapLayers={VisuallyBusyMapLayers}
        noControls
        withPlaces={false}
      >
        <DynamicMapCenter lat={lat} lng={lng} />

        <Map.WildFireGeoEventMarker geoEvent={mapGeoEvent} />

        {lat && lng && (
          <Map.ReportMarker
            onClick={noop}
            isFadable={false}
            lat={lat}
            lng={lng}
            az={azimuth}
            overrideCoords
          />
        )}
      </Map>
    </Box>
  );
});

MediaMap.displayName = 'MediaMap';
