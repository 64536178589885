import { Marker } from 'components/Map';
import { MapPinLocation } from 'components/Map/Icons';
import { usePoisState } from 'state/usePoisState';
import { LatLng } from 'shared/types';
import { RefObject, useCallback, useMemo, useState } from 'react';
import { DrawerRefContent } from 'components/Map/MapEntityDrawer';
import { MarkerDragEvent } from 'shared/map-exports';
import { CenterMap } from './CenterMap';
import { useSetMapPinPoi } from './useMapPinPoi';

type MapPinMarkerProps = {
  drawerRef: RefObject<DrawerRefContent | null>;
};

export const MapPinMarker = (props: MapPinMarkerProps): JSX.Element | null => {
  const { drawerRef } = props;
  const { selectedPoi } = usePoisState();
  const [disableCenterMap, setDisableCenterMap] = useState(false);
  const { setSelectedMapPinPoi } = useSetMapPinPoi();

  // It's memoized because is used inside 'CenterMap' component useEffect
  const coordinates: LatLng | null = useMemo(() => {
    const mapPin = selectedPoi?.type === 'mapPin' ? selectedPoi : null;
    if (!mapPin) {
      return null;
    }

    return mapPin?.coordinates || null;
  }, [selectedPoi]);

  const handleDrag = useCallback(
    (e: MarkerDragEvent): void => {
      setSelectedMapPinPoi(e.lngLat);
    },
    [setSelectedMapPinPoi],
  );

  if (!coordinates) {
    return null;
  }

  return (
    <>
      <Marker
        icon={MapPinLocation}
        position={coordinates}
        draggable
        onDragStart={() => setDisableCenterMap(true)}
        // Wait for drag to end before setting the POI. Downstream, this avoids unnecessary metadata fetches.
        onDragEnd={handleDrag}
        scale={1.5}
        anchor="center"
      />
      <CenterMap
        latLng={disableCenterMap ? null : coordinates}
        drawerIsOpen={Boolean(drawerRef.current?.isOpen())}
        defaultSnapPointPct={0.47}
      />
    </>
  );
};
