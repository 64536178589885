import { useEffect, useState } from 'react';
import { ControlPosition, useControl, useMap, Map } from 'shared/map-exports';
import { useTranslation } from 'react-i18next';

import MapToolsIcon from 'assets/map_tools.svg?react';
import MapToolsContextMenu from 'components/Map/MapToolsContextMenu';
import { LatLng } from 'shared/types';
import MapControlButton from './MapControlButton';
import BaseMapboxGLController from './BaseMapboxGLController';

type MapToolsControlProps = {
  position?: ControlPosition;
};

type MapToolsControlContentProps = {
  map?: Map;
  onClick: (centerCoordinates: LatLng) => void;
};

const MapToolsControlContent = (
  props: MapToolsControlContentProps,
): JSX.Element => {
  const { map, onClick } = props;
  const { t } = useTranslation();

  return (
    <MapControlButton
      text={t('map.controls.tools')}
      Icon={MapToolsIcon}
      onClick={() => {
        const center = map?.getCenter();
        if (center) {
          onClick(center);
        }
      }}
    />
  );
};

class MapboxGLMapToolsController extends BaseMapboxGLController<MapToolsControlContentProps> {
  content = (): JSX.Element => (
    <MapToolsControlContent {...this.props} map={this.map} />
  );
}

const MapToolsControl = (props: MapToolsControlProps): JSX.Element => {
  const { position } = props;
  const [mapCenter, setMapCenter] = useState<LatLng | null>(null);
  const { current: map } = useMap();

  const contentProps: MapToolsControlContentProps = {
    map: map?.getMap(),
    onClick: (centerCoordinates: LatLng) => setMapCenter(centerCoordinates),
  };

  const controller = useControl(
    // @ts-ignore - https://github.com/visgl/deck.gl/issues/9211
    () => new MapboxGLMapToolsController(contentProps),
    {
      position,
    },
  ) as MapboxGLMapToolsController;
  controller.update(contentProps);
  useEffect(() => controller.render(), [controller, props]);
  return (
    <MapToolsContextMenu
      variant="drawer"
      exclude={['copyPosition']}
      pointCoordinates={mapCenter}
      onClose={() => setMapCenter(null)}
    />
  );
};

export default MapToolsControl;
