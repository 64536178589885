import { getMapZoomInteger } from 'components/Map/utils';
import { useEffect, useState } from 'react';
import { useMapInstance } from 'shared/map-exports';

export const useMapZoomInteger = (): number | undefined => {
  const map = useMapInstance();
  const [zoom, setZoom] = useState<number | undefined>();

  useEffect(() => {
    if (!map) {
      return () => {};
    }

    const handleZoomEnd = (): void => {
      setZoom(getMapZoomInteger(map));
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [map]);

  return zoom;
};
