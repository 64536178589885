import { useCallback, useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { AlertCamera } from 'hooks/useAlertCameras';
import {
  CameraTimeLapseFrameDetails,
  PlayerState,
  PlayerStatus,
  UseAlertCameraPlayerStateReturn,
} from './useAlertCameraPlayerState.types';
import { useAlertCameraTimelapseFrames } from './useAlertCameraTimelapseFrames';
import { useAlertCameraImageLoader } from './useAlertCameraImageLoader';

const cameraStatusAtom = atom<PlayerStatus>({
  key: 'ALERT_CAMERA_PLAYER_STATUS_STATE',
  default: 'streamingLive',
});

const cameraTimelapseFrameAtom = atom<CameraTimeLapseFrameDetails | null>({
  key: 'ALERT_CAMERA_TIMELAPSE_FRAME_STATE',
  default: null,
});

const cameraPlayerStateAtom = atom<PlayerState>({
  key: 'ALERT_CAMERA_PLAYER_STATE',
  default: {
    timelapseFrameNumber: 0,
    timelapseReplayTime: 0,
  },
});

/*
 * State machine controller for the alert camera player. Takes as input an `AlertCamera`,
 * and outputs the current frame to be displayed, dependent on the player status.
 *
 * Also outputs signals for UI controls:
 * - the player status (playing, paused, live)
 * - if a loading spinner should be displayed
 *
 * As well as state change callbacks:
 * - to change the player status
 * - to explicitly seek to a specific frame in the timelapse
 *
 * Note: This hook is designed specifically for storing the player's global state using Recoil.
 */
export const useAlertCameraPlayerState = (
  camera?: AlertCamera,
): UseAlertCameraPlayerStateReturn => {
  const [playerStatus, setPlayerStatus] =
    useRecoilState<PlayerStatus>(cameraStatusAtom);
  const [timelapseFrame, setTimelapseFrame] =
    useRecoilState<CameraTimeLapseFrameDetails | null>(
      cameraTimelapseFrameAtom,
    );
  const [playerState, setPlayerState] = useRecoilState<PlayerState>(
    cameraPlayerStateAtom,
  );

  const { timelapseFrameNumber, timelapseReplayTime } = playerState;

  const setTimelapseFrameNumberInternal = useCallback(
    (frameNumber: number) => {
      setPlayerState((prev) => ({
        ...prev,
        timelapseFrameNumber: frameNumber,
      }));
    },
    [setPlayerState],
  );

  const setTimelapseReplayTime = useCallback(
    (replayTime: number) => {
      setPlayerState((prev) => ({
        ...prev,
        timelapseReplayTime: replayTime,
      }));
    },
    [setPlayerState],
  );

  const { timelapseFrames, isFetchingTimelapseFrames, refetchTimelapseFrames } =
    useAlertCameraTimelapseFrames(timelapseReplayTime, camera);

  const { imageUrl, imageTimestamp } = useMemo(() => {
    if (!camera) {
      return { imageUrl: '', imageTimestamp: new Date() };
    }
    if (playerStatus === 'streamingLive' || !timelapseFrame) {
      return {
        imageUrl: camera.imageUrl,
        imageTimestamp: camera.imageTimestamp,
      };
    }
    return {
      imageUrl: timelapseFrame.imageUrl,
      imageTimestamp: new Date(timelapseFrame.imageTimestamp),
    };
  }, [camera, playerStatus, timelapseFrame]);

  // Start pre-loading the camera image(s) and track the loading state.
  const imageLoadingStatus = useAlertCameraImageLoader(
    camera?.id,
    playerStatus,
    imageUrl,
    timelapseFrames,
    timelapseFrameNumber,
    timelapseFrame,
  );

  // Entrypoint for setting the frame number for all functions below. Ensures
  // that the time-lapse frame is always in sync with the frame number.
  const setTimelapseFrameNumber = useCallback(
    (frameNumber: number) => {
      if (frameNumber < 0 || frameNumber >= timelapseFrames.length) return;
      setTimelapseFrameNumberInternal(frameNumber);
      setTimelapseFrame(timelapseFrames[frameNumber]);
    },
    [timelapseFrames, setTimelapseFrameNumberInternal, setTimelapseFrame],
  );

  const pauseTimelapse = useCallback((): void => {
    setPlayerStatus('pausedTimelapse');
  }, [setPlayerStatus]);

  const playTimelapse = useCallback(async (): Promise<void> => {
    if (playerStatus === 'streamingLive') {
      // Fetch the newest timelapse frame data and start playing.
      const newFrames = await refetchTimelapseFrames();
      if (!newFrames.data) {
        // If the timelapse frames failed to load, don't start playing.
        return;
      }
      setTimelapseFrameNumberInternal(0);
      setTimelapseFrame(newFrames.data[0]);
    }

    // If the time-lapse has played to the end, reset it.
    if (timelapseFrameNumber === timelapseFrames.length - 1) {
      setTimelapseFrameNumber(0);
    }
    setPlayerStatus('playingTimelapse');
  }, [
    playerStatus,
    refetchTimelapseFrames,
    setPlayerStatus,
    setTimelapseFrame,
    setTimelapseFrameNumber,
    setTimelapseFrameNumberInternal,
    timelapseFrameNumber,
    timelapseFrames.length,
  ]);

  const stopTimelapse = useCallback((): void => {
    setTimelapseFrameNumberInternal(0); // Reset the playback slider.
    setPlayerStatus('streamingLive');
  }, [setPlayerStatus, setTimelapseFrameNumberInternal]);

  const advanceTimelapseFrame = useCallback((): void => {
    if (timelapseFrameNumber >= timelapseFrames.length - 1) {
      pauseTimelapse();
      return;
    }
    setTimelapseFrameNumber(timelapseFrameNumber + 1);
  }, [
    timelapseFrames.length,
    pauseTimelapse,
    setTimelapseFrameNumber,
    timelapseFrameNumber,
  ]);

  return {
    playerStatus,
    // For extra security, never return a timelapse frame if the player is in live mode.
    timelapseFrame: playerStatus === 'streamingLive' ? null : timelapseFrame,
    timelapseFrameNumber,
    timelapseFramesLength: timelapseFrames.length,
    isLoading: isFetchingTimelapseFrames,
    isLoadingImage: imageLoadingStatus === 'loading',
    timelapseReplayTime,
    imageUrl,
    imageTimestamp,

    setPlayerStatus,
    setTimelapseFrame,
    setTimelapseFrameNumber,
    playTimelapse,
    pauseTimelapse,
    stopTimelapse,
    setTimelapseReplayTime,
    advanceTimelapseFrame,

    testOnlyAdvanceTimelapseFrame: advanceTimelapseFrame,
  };
};
