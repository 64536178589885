import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { getResponsiveFontSize } from 'shared/utils';
import { EVAC_ZONE_LAYER_COLORS } from '../../../constants';
import {
  EvacuationOrder,
  EvacuationWarning,
  EvacuationAdvisory,
} from '../Icons';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(20)),
    paddingBottom: theme.spacing(1),
  },
  subtitle: {
    lineHeight: 1,
  },
  evacuationOrderIcon: {
    backgroundColor: EVAC_ZONE_LAYER_COLORS.order_warning_advisory.orders,
    maskImage: `url("${EvacuationOrder.data}")`,
    maskSize: `${EvacuationOrder.width}px ${EvacuationOrder.height}px`,
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  evacuationWarningIcon: {
    backgroundColor: EVAC_ZONE_LAYER_COLORS.order_warning_advisory.warnings,
    maskImage: `url("${EvacuationWarning.data}")`,
    maskSize: `${EvacuationWarning.width}px ${EvacuationWarning.height}px`,
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  evacuationAdvisoryIcon: {
    backgroundColor: EVAC_ZONE_LAYER_COLORS.order_warning_advisory.advisories,
    maskImage: `url("${EvacuationAdvisory.data}")`,
    maskSize: `${EvacuationAdvisory.width}px ${EvacuationAdvisory.height}px`,
    width: 53,
    height: 24,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
}));

const EvacZonesLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            color="textPrimary"
          >
            {t('map.legends.evacuationZones.title')}
          </Typography>
        </ListSubheader>
      }
    >
      <List
        disablePadding
        dense
        subheader={
          <ListSubheader disableSticky>
            <Typography
              variant="subtitle1"
              className={classes.subtitle}
              color="textPrimary"
            >
              {t('map.legends.evacuationZones.california')}
            </Typography>
          </ListSubheader>
        }
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.evacuationOrderIcon}>
              <></>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('map.legends.evacuationZones.legends.order.title')}
            secondary={t('map.legends.evacuationZones.legends.order.subtitle')}
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.evacuationWarningIcon}>
              <></>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('map.legends.evacuationZones.legends.warning.title')}
            secondary={t(
              'map.legends.evacuationZones.legends.warning.subtitle',
            )}
          />
        </ListItem>
      </List>

      <List
        disablePadding
        dense
        subheader={
          <ListSubheader disableSticky>
            <Typography
              variant="subtitle1"
              className={classes.subtitle}
              color="textPrimary"
            >
              {t('map.legends.evacuationZones.oregonWashington')}
            </Typography>
          </ListSubheader>
        }
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.evacuationOrderIcon}>
              <></>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('map.legends.evacuationZones.legends.order.level')}
            secondary={t('map.legends.evacuationZones.legends.order.subtitle')}
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.evacuationWarningIcon}>
              <></>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('map.legends.evacuationZones.legends.warning.level')}
            secondary={t(
              'map.legends.evacuationZones.legends.warning.subtitle',
            )}
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.evacuationAdvisoryIcon}>
              <></>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('map.legends.evacuationZones.legends.advisory.level')}
            secondary={t(
              'map.legends.evacuationZones.legends.advisory.subtitle',
            )}
          />
        </ListItem>
      </List>
    </List>
  );
};

export default EvacZonesLegend;
