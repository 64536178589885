import { CSSProperties, ForwardedRef, forwardRef } from 'react';
import { LatLng } from 'shared/types';
import { useTranslation } from 'react-i18next';
import { MarkerComponent, Marker } from 'shared/map-exports';
import { Tooltip } from '@mui/material';
import { MeasureToolPointIcon } from 'components/Map/Icons';
import { useAllowMapLongPress } from 'hooks/useMapLongPress';
import { Z_INDEX_POINTS } from './constants';

type PointMarkerProps = {
  latLng: LatLng;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onDrag: (latLng: LatLng) => void;
};

const TAP_TARGET_PADDING_PIXELS = 22;

export const PointMarker = forwardRef(function PointMarker(
  props: PointMarkerProps,
  ref: ForwardedRef<Marker>,
): JSX.Element {
  const { latLng, onDrag, onDragEnd, onDragStart } = props;
  const { t } = useTranslation();
  const setAllowLongPress = useAllowMapLongPress();

  const pointStyle: CSSProperties = {
    WebkitTouchCallout: 'none',

    // This CSS increases the tap target size of the marker without changing
    // how it is displayed or layed out.
    position: 'relative',
    padding: `${TAP_TARGET_PADDING_PIXELS}px`,
    margin: `-${TAP_TARGET_PADDING_PIXELS}px`,
  };
  return (
    <MarkerComponent
      ref={ref}
      latitude={latLng.lat}
      longitude={latLng.lng}
      draggable
      onDragStart={(e) => onDragStart && onDragStart()}
      onDrag={(e) => onDrag(e.lngLat)}
      onDragEnd={(e) => onDragEnd && onDragEnd()}
      style={{ zIndex: Z_INDEX_POINTS, lineHeight: '1px', cursor: 'pointer' }}
    >
      <Tooltip
        title={t('map.layers.measureDistanceTool.dragToMove')}
        placement="top"
        disableTouchListener
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -TAP_TARGET_PADDING_PIXELS / 2],
              },
            },
          ],
        }}
      >
        <div
          style={pointStyle}
          onTouchStart={() => setAllowLongPress(false)}
          onTouchEnd={() => setAllowLongPress(true)}
        >
          <img
            src={MeasureToolPointIcon.data}
            width={MeasureToolPointIcon.width}
            height={MeasureToolPointIcon.height}
            alt={t('map.layers.measureDistanceTool.measureDistancePoint')}
          />
        </div>
      </Tooltip>
    </MarkerComponent>
  );
});
