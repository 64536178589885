import { CSSProperties } from 'react';
import { Typography } from '@mui/material';
import {
  LINE_STROKE_PIXELS,
  MAJOR_TICK_HEIGHT_PIXELS,
  MAJOR_TICK_STROKE_PIXELS,
  MINOR_TICK_HEIGHT_PIXELS,
  MINOR_TICK_STROKE_PIXELS,
  STROKE_STYLE,
} from './constants';
import { toLocaleStringWithAdaptiveFraction } from './utils';

type TickMarkProps = {
  isInverted: boolean;
  type: 'major' | 'minor';
  labelDistanceMiles?: number;
};

export const TickMark = (props: TickMarkProps): JSX.Element => {
  const { isInverted, type, labelDistanceMiles } = props;
  const strokePixels =
    type === 'major' ? MAJOR_TICK_STROKE_PIXELS : MINOR_TICK_STROKE_PIXELS;
  const heightPixels =
    type === 'major' ? MAJOR_TICK_HEIGHT_PIXELS : MINOR_TICK_HEIGHT_PIXELS;

  const commonStyle: CSSProperties = {
    position: 'relative',
    width: `${MAJOR_TICK_STROKE_PIXELS}px`,
    height: `${heightPixels}px`,
    borderLeft: `${strokePixels}px ${STROKE_STYLE}`,
    verticalAlign: 'top',
    display: 'inline-block',
  };

  const invertedStyle: CSSProperties = {
    ...commonStyle,
    alignSelf: 'flex-end',
    marginBottom: `-${LINE_STROKE_PIXELS}px`,
  };
  const normalStyle: CSSProperties = {
    ...commonStyle,
    height: `${heightPixels - LINE_STROKE_PIXELS}px`,
  };

  const normalLabelStyle: CSSProperties = {
    position: 'relative',
    top: `${heightPixels}px`,
    left: 0,
    right: 0,
    marginLeft: '-1.5em',
    width: '5em',
    textShadow:
      '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white',
  };
  const invertedLabelStyle: CSSProperties = {
    ...normalLabelStyle,
    top: `-${heightPixels + 5}px`,
    transform: 'rotate(180deg)',
    marginLeft: '-2em',
    textAlign: 'right',
    textShadow:
      '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white',
  };

  const label =
    labelDistanceMiles && labelDistanceMiles > 0
      ? toLocaleStringWithAdaptiveFraction(labelDistanceMiles, 'mi')
      : null;
  return (
    <div style={isInverted ? invertedStyle : normalStyle}>
      {label && type === 'major' && (
        <Typography
          variant="body2"
          style={isInverted ? invertedLabelStyle : normalLabelStyle}
        >
          {label}
        </Typography>
      )}
    </div>
  );
};
