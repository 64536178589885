import { useEffect } from 'react';
import { useMap } from 'shared/map-exports';

type ClearTimeoutMapProps = {
  clearMobileTimeout: () => void;
};

const ClearTimeoutMap = (props: ClearTimeoutMapProps): null => {
  const { clearMobileTimeout } = props;
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) {
      return undefined;
    }

    map.on('touchend', clearMobileTimeout);
    map.on('touchcancel', clearMobileTimeout);
    map.on('touchmove', clearMobileTimeout);
    map.on('pointerdrag', clearMobileTimeout);
    map.on('pointermove', clearMobileTimeout);
    map.on('moveend', clearMobileTimeout);
    map.on('gesturestart', clearMobileTimeout);
    map.on('gesturechange', clearMobileTimeout);
    map.on('gestureend', clearMobileTimeout);

    return () => {
      map.off('touchend', clearMobileTimeout);
      map.off('touchcancel', clearMobileTimeout);
      map.off('touchmove', clearMobileTimeout);
      map.off('pointerdrag', clearMobileTimeout);
      map.off('pointermove', clearMobileTimeout);
      map.off('moveend', clearMobileTimeout);
      map.off('gesturestart', clearMobileTimeout);
      map.off('gesturechange', clearMobileTimeout);
      map.off('gestureend', clearMobileTimeout);
    };
  }, [clearMobileTimeout, map]);

  return null;
};

export default ClearTimeoutMap;
