import {
  ClipboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { StructuredEvacuationsEditLayer } from 'components/Map/layers/StructuredEvacuationsLayer';
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { MapProvider } from 'shared/map-exports';
import { useHistory, useLocation } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Capacitor } from '@capacitor/core';
import {
  EvacZoneStatus,
  FormEvacZoneStatus,
  GeoEvent,
  LayerEvacZone,
  MapLocation,
  NotificationType,
  UploadImageData,
  RegionEvacZoneStyle,
} from 'shared/types';
import Map from 'components/Map';
import { DISABLE_MAP_LAYERS_FOR_FORMS } from 'components/Map/constants';
import { API } from 'api';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { useAuthState, useMapState, useSnackbarState } from 'state';
import GeoEventEvacuations from 'components/GeoEventEvacuations';
import GeoEventChangelog from 'components/GeoEventChangelog';
import {
  parseLatitudeParam,
  parseLongitudeParam,
  parseZoomParam,
  removeHTMLTags,
  uploadImage,
} from 'shared/utils';
import RichTextEditor from 'components/RichTextEditor';
import FormLinks from 'components/FormLinks';
import GeoEventsLayer from 'components/Map/layers/GeoEventsLayer';
import { useGeoEvents } from 'hooks/useGeoEvents';
import { IncidentMapStateUpdate } from 'state/useMapState';
import useRegionsQuery from 'hooks/useRegionsQuery';
import { useGeoEventEvacZones } from 'hooks/useGeoEventEvacZones';
import axios from 'axios';
import { AlertCamerasLayer } from 'components/Map/layers/AlertCamerasLayer';
import { MediaForm } from 'components/MediaForm';
import { useQueryParams } from 'hooks/useQueryParms';
import { InternalNotesInput } from 'components/InternalNotesInput';
import { FieldHelperMessages } from 'components/FieldHelperMessages';
import { validateReport } from 'pages/AddIncidentReport/ReportForm/utils';
import {
  EvacZoneStyles,
  NotificationType as NotificationTypeConstant,
  TEXTAREA_MAX_ROWS,
} from '../../../constants';
import {
  getDefaultMapCenter,
  getMatchingRegions,
  getMessageFromGeoEventSaveErrorResponse,
  getRedundancyMessage,
  hasRegionsChanged,
  geoEventFormValuesToData,
  validateIncidentAddress,
  validateIncidentName,
  validatePrescribedIncident,
  validateComplexIncidentName,
} from './GeoEventForm.utils';
import {
  GeoEventFormValues,
  GeoEventFormProps,
  RegionOption,
  Status,
  GeoEventFormQueryParams,
} from './GeoEventForm.types';
import { PushNotificationPreview } from '../../../components/PushNotificationPreview';
import useValidationSchema from './hooks/useValidationSchema';
import useInitialValues from './hooks/useInitialValues';
import { useGeoEvent } from '../../../hooks/useGeoEvent';
import { useWildfireAutocomplete } from '../../../hooks/useWildfireAutocomplete';
import { GeoEventMarker } from './GeoEventMarker';

const statusOptions: { label: string; value: Status }[] = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  formContainer: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    maxWidth: 720,
  },
  form: {
    paddingTop: theme.spacing(3),
    paddingBottom: 'max(env(safe-area-inset-bottom), 24px)',
    height: '100%',
    width: '100%',
  },
  capitalizeLabel: {
    textTransform: 'capitalize',
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
  },
  lastItem: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(2),
  },
  evacButton: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
  },
  noPushNotif: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  checkboxControl: {
    width: 'fit-content',
  },
  dateTimeLabel: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
  },
  clearDateButton: {
    '& button:first-of-type': {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.palette.grey[500],
      },
    },
  },
}));

export const GeoEventForm = (props: GeoEventFormProps): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('laptop'), {
    defaultMatches: !Capacitor.isNativePlatform(),
  });
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const defaultMapCenter = getDefaultMapCenter({ searchParams, geoEvent });
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();
  const {
    permissions: { isRxOnlyReporter, isStaffReporter },
  } = useAuthState();
  const initialLocationChangeRef = useRef(false);
  const { setActiveMapBounds } = useMapState();
  const { wildfireEvents } = useGeoEvents();
  const [queryParams] = useQueryParams<GeoEventFormQueryParams>();

  const initialLatitude = parseLatitudeParam(queryParams.lat ?? '');
  const initialLongitude = parseLongitudeParam(queryParams.lng ?? '');
  const initialZoom = parseZoomParam(queryParams.zoom ?? '');
  const initialAddress = decodeURIComponent(queryParams.address ?? '');

  const { saveGeoEvent } = useGeoEvent({ id: geoEvent?.id });

  const {
    data: regionsData,
    isLoading: isLoadingRegions,
    error: regionsError,
  } = useRegionsQuery();

  const s3AuthQuery = useQuery({
    queryKey: ['gets3Auth'],
    queryFn: () => API.get('media/storage_auth/'),
    enabled: !geoEvent,
  });

  const cacheBuster = useMemo(() => Date.now(), []);

  const regionOptions: RegionOption[] = useMemo(
    () => regionsData?.data || [],
    [regionsData],
  );

  const sortedRegionOptions = useMemo(
    // sort regionOptions by state, then by displayName for grouping in the autocomplete
    () => {
      return regionOptions.sort((a, b) => {
        if (a.state === b.state) {
          return a.displayName > b.displayName ? 1 : -1;
        }
        return a.state > b.state ? 1 : -1;
      });
    },
    [regionOptions],
  );

  const { geoEventEvacZones: availableZones } = useGeoEventEvacZones(geoEvent);

  const evacZoneStyle: RegionEvacZoneStyle =
    geoEvent?.regions[0]?.evacZoneStyle ||
    availableZones[0]?.region.evacZoneStyle ||
    EvacZoneStyles.default;

  const hasEvacZones = availableZones.length > 0;

  const validationSchema = useValidationSchema(geoEvent, hasEvacZones, t);

  const initialPrescribed = isRxOnlyReporter || !!geoEvent?.data.isPrescribed;
  const initStatusBool = geoEvent ? geoEvent.isActive : true;
  // todo: refactor the form fields to use true/false boolean, not this active/inactive string literal
  const initialStatus = initStatusBool ? 'active' : 'inactive';

  const initialValues = useInitialValues({
    geoEvent,
    initialAddress,
    initialStatus,
    initialPrescribed,
    initialLatitude,
    initialLongitude,
    initialAlertCameraId: queryParams.alertCameraId,
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const {
    control,
    formState,
    setValue,
    handleSubmit,
    watch,
    reset,
    getValues,
  } = formMethods;

  useEffect(() => {
    // Initial values changed, reset the form to reflect these changes.
    const confirmSelectedZones = getValues('confirmSelectedZones');
    // Preserve 'confirmSelectedZones' value if it was set before.
    reset({ ...initialValues, confirmSelectedZones });
  }, [reset, initialValues, getValues]);

  const handleLocationChange = useCallback(
    async (
      location: MapLocation | { lat: number; lng: number } | null,
      urlAddress?: string | null,
    ) => {
      if (!location) return;
      setValue('lat', location.lat);
      setValue('lng', location.lng);
      // Do not update the geoEvent's address or regions if we're on edit mode.
      if (geoEvent) return;
      let address = urlAddress || '';
      if ('formattedAddress' in location && location.type === 'Feature') {
        address = location.name;
        if (location.formattedAddress) {
          address = location.formattedAddress.trim();
        }
        setValue('address', address);
      }
      const { placeName, matchingRegions } = await getMatchingRegions(
        location.lat,
        location.lng,
        regionOptions,
      );
      if (!address && placeName) {
        setValue('address', placeName);
      }
      setValue(
        'regions',
        matchingRegions.map((region) => ({
          id: region.id,
          displayName: region.displayName,
          state: region.state,
          name: region.name,
          evacZoneStyle: null,
        })),
      );
    },
    [geoEvent, regionOptions, setValue],
  );

  const uploadImageMutation = useMutation({
    mutationFn: (data: UploadImageData) => uploadImage(data),
  });

  const handleSubmitGeoEvent: SubmitHandler<GeoEventFormValues> = async (
    values,
  ): Promise<void> => {
    const data = geoEventFormValuesToData(values, hasEvacZones);

    // If a new GeoEvent, upload the initial report image first
    if (
      values.media &&
      'type' in values.media &&
      data.initialReport?.media?.[0]?.url &&
      s3AuthQuery.data
    ) {
      try {
        await uploadImageMutation.mutateAsync({
          media: values.media,
          s3AuthData: s3AuthQuery.data,
          s3Key: data.initialReport.media[0].url,
        });
      } catch (e: unknown) {
        setSnackbar(t('geoEvent.errorMessage'), 'error');
        return;
      }
    }

    try {
      const mutatedGeoEvent = await saveGeoEvent(data, geoEvent);
      const snackbarMessageKey = `createEditGeoEvent.successMessage.${
        geoEvent ? 'update' : 'create'
      }`;
      setSnackbar(t(snackbarMessageKey), 'success');
      if (mutatedGeoEvent) {
        history.push(`/i/${mutatedGeoEvent.id}`);
      }
    } catch (error: unknown) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      const errorStatus = error.response?.status;

      if (errorStatus !== 409) {
        setSnackbar(getMessageFromGeoEventSaveErrorResponse(error, t), 'error');
        return;
      }

      // We should check for redundant posts (this only affects reporters).
      // If there are too many posts in the last few minutes, we should warn them
      // `confirm` is super ghetto but its only seen by reporters
      // eslint-disable-next-line
      const retryConfirm = confirm(getRedundancyMessage(error.response));

      if (!retryConfirm) return;

      try {
        const mutatedGeoEvent = await saveGeoEvent(data, geoEvent, true);
        const snackbarMessageKey = `createEditGeoEvent.successMessage.${
          geoEvent ? 'update' : 'create'
        }`;
        setSnackbar(t(snackbarMessageKey), 'success');
        if (mutatedGeoEvent) {
          history.push(`/i/${mutatedGeoEvent.id}`);
        }
      } catch (finalError: unknown) {
        if (!axios.isAxiosError(error)) {
          throw finalError;
        }

        const errorMessage =
          finalError instanceof Error
            ? finalError.message
            : t('geoEvent.errorMessage');

        setSnackbar(errorMessage, 'error');
      }
    }
  };

  const [
    name,
    address,
    acreage,
    containment,
    childWildfires,
    isComplexParent,
    isPrescribed,
    isFps,
    notificationType,
    status,
    lat,
    lng,
    regions,
    prescribedDateStartLocal,
    activeEvacuations,
    evacZoneStatuses,
    asset,
    temporarilyDisplayEvacZones,
  ] = watch([
    'name',
    'address',
    'acreage',
    'containment',
    'childWildfires',
    'isComplexParent',
    'isPrescribed',
    'isFps',
    'notificationType',
    'status',
    'lat',
    'lng',
    'regions',
    'prescribedDateStartLocal',
    'activeEvacuations',
    'evacZoneStatuses',
    'asset',
    'temporarilyDisplayEvacZones',
    'reportMessage',
  ]);

  const evacZones: LayerEvacZone[] = useMemo(() => {
    const filteredZones = availableZones.filter(
      (zone) => !evacZoneStatuses.find((eZS) => eZS.evacZone.id === zone.id),
    );
    return [
      ...filteredZones.map((zone) => ({
        uidV2: zone.uidV2,
        style: zone.region.evacZoneStyle,
      })),
      ...evacZoneStatuses.map((eZS) => ({
        uidV2: eZS.evacZone.uidV2,
        status: eZS.status,
        style: eZS.evacZone.regionEvacZoneStyle,
      })),
    ];
  }, [availableZones, evacZoneStatuses]);

  const handleEvacZoneClick = useCallback(
    (uid: string, nextStatus?: EvacZoneStatus) => {
      const evacZone = availableZones.find((zone) => zone.uidV2 === uid);

      if (!evacZone) return;

      const filteredCurrentZones = evacZoneStatuses.filter(
        (eZS) => eZS.evacZone.uidV2 !== uid,
      );

      const geoEventEvacZone = geoEvent?.evacZoneStatuses.find(
        (eZS) => eZS.evacZone.uidV2 === uid,
      );

      if (nextStatus) {
        // Add
        const updatedZone: FormEvacZoneStatus = {
          evacZone: {
            ...evacZone,
            regionEvacZoneStyle: evacZone.region.evacZoneStyle,
          },
          status: nextStatus,
        };
        if (geoEventEvacZone) {
          updatedZone.prevStatus = geoEventEvacZone.status;
        }

        setValue('evacZoneStatuses', [...filteredCurrentZones, updatedZone]);
        return;
      }
      // Remove
      setValue('evacZoneStatuses', filteredCurrentZones);
    },
    [availableZones, evacZoneStatuses, geoEvent?.evacZoneStatuses, setValue],
  );

  /**
   * Use the initial lat, lng, address to prefill address information
   * when the form first loads.
   */
  useEffect(() => {
    if (
      initialLocationChangeRef.current ||
      !initialLatitude ||
      !initialLongitude ||
      isLoadingRegions
    ) {
      return;
    }

    handleLocationChange(
      {
        lat: initialLatitude,
        lng: initialLongitude,
      },
      initialAddress,
    );
    initialLocationChangeRef.current = true;
  }, [
    handleLocationChange,
    initialLatitude,
    initialLongitude,
    initialAddress,
    isLoadingRegions,
  ]);

  const isActive = status === 'active';
  const nameChanged = geoEvent?.name?.trim() !== name.trim();
  const addressChanged =
    !geoEvent?.data.isComplexParent &&
    geoEvent?.address?.trim() !== address.trim();
  const latChanged = geoEvent?.lat !== lat;
  const lngChanged = geoEvent?.lng !== lng;
  const regionsChanged =
    !geoEvent?.data.isComplexParent &&
    hasRegionsChanged(geoEvent?.regions ?? [], regions);

  const { muiAutocompleteProps: geoEventAutocompleteProps } =
    useWildfireAutocomplete({
      enabled: isComplexParent && isStaffReporter,
      filterGeoEvents: (geoEvents) =>
        geoEvents.filter(
          (ge) => !ge.data.isComplexParent && !ge.data.isPrescribed,
        ),
      value: childWildfires,
    });

  const incidentInfoChanged =
    !!geoEvent &&
    (nameChanged ||
      addressChanged ||
      latChanged ||
      lngChanged ||
      regionsChanged);

  const currentGeoEvent = useMemo(() => {
    const data: Partial<GeoEvent> = geoEvent || {};
    return {
      ...data,
      name,
      address,
      data: {
        ...(data.data || {}),
        acreage,
        containment,
        isComplexParent,
        isFps,
        isPrescribed,
      },
      notificationType,
      isActive,
      lat,
      lng,
    } as Partial<GeoEvent>;
  }, [
    acreage,
    address,
    containment,
    geoEvent,
    isActive,
    isComplexParent,
    isFps,
    isPrescribed,
    lat,
    lng,
    name,
    notificationType,
  ]);

  const hasNewCounty = useMemo(() => {
    if (!geoEvent?.regions.length) return false;
    const geoEventRegionIds = geoEvent.regions.map((region) => region.id);
    return (
      regions.filter((region) => !geoEventRegionIds.includes(region.id))
        .length > 0
    );
  }, [geoEvent?.regions, regions]);

  const previewBody = isComplexParent
    ? removeHTMLTags(getValues('reportMessage'))
    : address;

  const isPreviewVisible = geoEvent
    ? (geoEvent.notificationType === NotificationType.Silent &&
        notificationType === NotificationType.Normal) ||
      hasNewCounty
    : !isPrescribed;

  const isNoPushNotifVisible = geoEvent
    ? ((geoEvent.notificationType === NotificationType.Silent &&
        notificationType === NotificationType.Silent) ||
        !hasNewCounty) &&
      formState.isDirty &&
      !isPreviewVisible
    : isPrescribed;

  const searchBarReturnLink = geoEvent
    ? `/incident/${geoEvent.id}/edit`
    : '/incident/create';

  const handlePaste: ClipboardEventHandler<HTMLFormElement> = (event) => {
    /**
     * This handles copy-paste on the web.  In order to use,
     * the user must first click on the page to paste
     */
    if (Capacitor.getPlatform() !== 'web' || asset !== 'image') return;

    const clipboardItems = event.clipboardData.items;

    const items: DataTransferItem[] = [].slice
      .call(clipboardItems)
      .filter((item: DataTransferItem) => item.type.indexOf('image') !== -1);

    if (items.length === 0) {
      return;
    }

    const item = items[0];
    // Get the blob of image
    const blob = item.getAsFile();

    setValue('media', blob);
  };

  const onViewportChange = useCallback(
    (viewport: IncidentMapStateUpdate) => {
      setActiveMapBounds(viewport.bounds);
    },
    [setActiveMapBounds],
  );

  if (isLoadingRegions || regionsError || s3AuthQuery.isLoading) {
    return (
      <LoadingAndErrors isLoading={isLoadingRegions || s3AuthQuery.isLoading} />
    );
  }

  const nameWarning = formState.errors.name
    ? undefined
    : validateIncidentName(name, t);
  const addressWarning = formState.errors.address
    ? undefined
    : validateIncidentAddress(address, t);
  const prescribedWarning = formState.errors.name
    ? undefined
    : validatePrescribedIncident(name, isPrescribed, t);
  const complexNameWarning = formState.errors.name
    ? undefined
    : validateComplexIncidentName(name, isComplexParent, t);

  const isEvacZonesLayerVisible =
    !!geoEvent && evacZones.length > 0 && activeEvacuations;

  return (
    <MapProvider>
      <Box
        className={classes.root}
        sx={{
          flexDirection: isLargeMediaQuery ? 'row' : 'column',
          overflow: isLargeMediaQuery ? 'hidden' : 'auto',
        }}
      >
        <Box
          sx={{
            flex: 1,
            minHeight: isLargeMediaQuery ? '100%' : 449,
            display: 'flex',
          }}
        >
          <Map
            center={defaultMapCenter}
            disableMapLayers={DISABLE_MAP_LAYERS_FOR_FORMS}
            withPlaces={false}
            searchEnabled
            searchBarReturnLink={searchBarReturnLink}
            cursor="crosshair"
            onLocationChange={handleLocationChange}
            searchApi="google"
            zoom={initialZoom}
            onViewportChange={onViewportChange}
          >
            <StructuredEvacuationsEditLayer
              visible={isEvacZonesLayerVisible}
              evacZones={evacZones}
              onClick={
                temporarilyDisplayEvacZones ? undefined : handleEvacZoneClick
              }
              cacheBuster={cacheBuster}
            />
            <AlertCamerasLayer
              visible
              interactive={false}
              withLabels
              withRealtimeUpdates={false}
            />
            <GeoEventsLayer
              isFadable
              geoEvents={wildfireEvents}
              selectedGeoEventId={geoEvent?.id}
              isEdit
            />
            <GeoEventMarker
              geoEvent={currentGeoEvent}
              onChange={handleLocationChange}
            />
            <Map.MapEvents onViewportChange={onViewportChange} />
          </Map>
        </Box>
        <Container
          maxWidth={false}
          className={isLargeMediaQuery ? classes.formContainer : undefined}
        >
          <FormProvider {...formMethods}>
            <form
              onSubmit={handleSubmit(handleSubmitGeoEvent)}
              noValidate
              className={classes.form}
              onPaste={handlePaste}
            >
              <Grid container spacing={2} sx={{ minHeight: '100%' }}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t('createEditGeoEvent.sections.incident')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          id="field-control-name"
                          label={t('createEditGeoEvent.inputs.name.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error?.message ||
                            nameWarning ||
                            prescribedWarning ||
                            complexNameWarning ||
                            t('createEditGeoEvent.inputs.name.helperText')
                          }
                          FormHelperTextProps={{
                            sx: {
                              color:
                                nameWarning ||
                                prescribedWarning ||
                                complexNameWarning
                                  ? 'warning.main'
                                  : undefined,
                            },
                          }}
                          required
                          disabled={formState.isSubmitting}
                          color={
                            nameWarning ||
                            prescribedWarning ||
                            complexNameWarning
                              ? 'warning'
                              : undefined
                          }
                          focused={
                            !!(
                              nameWarning ||
                              prescribedWarning ||
                              complexNameWarning
                            )
                          }
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="regions"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      return (
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            id="regions"
                            groupBy={(region): string => region.state}
                            options={sortedRegionOptions}
                            getOptionLabel={(region): string =>
                              `${region.displayName}, ${region.state}`
                            }
                            isOptionEqualToValue={(option, value): boolean =>
                              option.id === value.id
                            }
                            value={field.value}
                            onChange={(e, value): void => field.onChange(value)}
                            onBlur={field.onBlur}
                            disabled={formState.isSubmitting || isComplexParent}
                            renderOption={(
                              optionProps,
                              region,
                              { selected },
                            ): JSX.Element => (
                              <li {...optionProps}>
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  sx={{ marginRight: 1 }}
                                  checked={selected}
                                />
                                {region.displayName}
                              </li>
                            )}
                            renderInput={(params): JSX.Element => (
                              <TextField
                                {...params}
                                name={field.name}
                                inputRef={field.ref}
                                variant="outlined"
                                label="Counties"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        </FormControl>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          id="field-control-address"
                          label={t('createEditGeoEvent.inputs.address.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error?.message ||
                            addressWarning ||
                            t('createEditGeoEvent.inputs.address.helperText')
                          }
                          FormHelperTextProps={{
                            sx: {
                              color: addressWarning
                                ? 'warning.main'
                                : undefined,
                            },
                          }}
                          required={!isComplexParent}
                          disabled={formState.isSubmitting || isComplexParent}
                          multiline
                          minRows={1}
                          maxRows={TEXTAREA_MAX_ROWS}
                          color={addressWarning ? 'warning' : undefined}
                          focused={!!addressWarning}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-status"
                          label={t('createEditGeoEvent.inputs.status.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          disabled={
                            formState.isSubmitting ||
                            (!geoEvent && !!prescribedDateStartLocal)
                          }
                        >
                          {statusOptions.map(({ label, value }) => {
                            return (
                              <MenuItem
                                key={label}
                                value={value}
                                className={classes.capitalizeLabel}
                              >
                                {label}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="notificationType"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-notificationType"
                          label={t(
                            'createEditGeoEvent.inputs.notificationType.label',
                          )}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          className={classes.capitalizeLabel}
                          disabled={
                            formState.isSubmitting ||
                            geoEvent?.notificationType ===
                              NotificationType.Normal ||
                            isPrescribed ||
                            isComplexParent
                          }
                        >
                          {Object.values(NotificationTypeConstant).map(
                            (notifType) => {
                              return (
                                <MenuItem
                                  key={notifType}
                                  value={notifType}
                                  className={classes.capitalizeLabel}
                                >
                                  {notifType}
                                </MenuItem>
                              );
                            },
                          )}
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="acreage"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const { ref, value, onChange, ...muiFieldProps } =
                            field;
                          return (
                            <TextField
                              id="field-control-acreage"
                              label={t(
                                'createEditGeoEvent.inputs.acreage.label',
                              )}
                              fullWidth
                              {...muiFieldProps}
                              value={value ?? ''}
                              onChange={(e) =>
                                onChange(
                                  e.target.value
                                    ? Number(e.target.value)
                                    : null,
                                )
                              }
                              inputRef={ref}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              type="number"
                              inputProps={{
                                pattern: '[0-9]+([.,][0-9]+)?',
                                inputMode: 'decimal',
                                onWheel: (e) => e.currentTarget.blur(),
                              }}
                              disabled={formState.isSubmitting}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="containment"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const { ref, value, onChange, ...muiFieldProps } =
                            field;
                          return (
                            <TextField
                              id="field-control-containment"
                              label={t(
                                'createEditGeoEvent.inputs.containment.label',
                              )}
                              fullWidth
                              {...muiFieldProps}
                              value={value ?? ''}
                              onChange={(e) =>
                                onChange(
                                  e.target.value
                                    ? parseInt(e.target.value, 10)
                                    : null,
                                )
                              }
                              inputRef={ref}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              type="number"
                              inputProps={{
                                pattern: '[0-9]+([.,][0-9]+)?',
                                inputMode: 'numeric',
                                onWheel: (e) => e.currentTarget.blur(),
                              }}
                              disabled={
                                formState.isSubmitting || !!isPrescribed
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Controller
                      name="isFps"
                      control={control}
                      render={({ field }): JSX.Element => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isFps"
                                checked={field.value}
                                onChange={(event) =>
                                  field.onChange(event.target.checked)
                                }
                                id="field-control-isFps"
                              />
                            }
                            label={t(
                              'createEditGeoEvent.forwardProgressStopped',
                            )}
                            disabled={
                              formState.isSubmitting ||
                              isPrescribed ||
                              isComplexParent
                            }
                            className={classes.checkboxControl}
                          />
                        );
                      }}
                    />

                    <Controller
                      name="isPrescribed"
                      control={control}
                      render={({ field }): JSX.Element => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isPrescribed"
                                checked={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                  if (event.target.checked) {
                                    setValue(
                                      'notificationType',
                                      NotificationType.Silent,
                                    );
                                    setValue('containment', null);
                                    setValue('isFps', false);
                                  }
                                }}
                                id="field-control-isPrescribed"
                              />
                            }
                            label={t('createEditGeoEvent.prescribedBurn')}
                            disabled={
                              formState.isSubmitting ||
                              !!geoEvent ||
                              isRxOnlyReporter ||
                              isComplexParent
                            }
                            className={classes.checkboxControl}
                          />
                        );
                      }}
                    />

                    <Controller
                      name="isComplexParent"
                      control={control}
                      render={({ field }): JSX.Element => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isComplexParent"
                                checked={field.value}
                                onChange={(event) => {
                                  field.onChange(event.target.checked);
                                }}
                                id="field-control-isComplexParent"
                              />
                            }
                            label={t('createEditGeoEvent.complexIncident')}
                            disabled={
                              formState.isSubmitting ||
                              notificationType === NotificationType.Silent ||
                              geoEvent?.data?.isComplexParent ||
                              Boolean(geoEvent?.parentGeoEvents?.length) ||
                              isPrescribed ||
                              !isStaffReporter ||
                              isFps
                            }
                            className={classes.checkboxControl}
                          />
                        );
                      }}
                    />
                  </Box>
                </Grid>

                {isComplexParent && (
                  <Grid item xs={12}>
                    <Controller
                      name="childWildfires"
                      control={control}
                      render={({ field, fieldState }) => {
                        return (
                          <FormControl fullWidth>
                            <Autocomplete
                              {...geoEventAutocompleteProps}
                              value={field.value}
                              onChange={(_, value) => {
                                field.onChange(value);
                              }}
                              onBlur={field.onBlur}
                              disabled={
                                formState.isSubmitting || !isStaffReporter
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={field.name}
                                  inputRef={field.ref}
                                  variant="outlined"
                                  label={t(
                                    'createEditGeoEvent.inputs.complexIncidents.label',
                                  )}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                  required={isComplexParent}
                                />
                              )}
                            />
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                )}
                <InternalNotesInput
                  initialValue={initialValues.reporterOnlyNotes}
                />

                <Grid item xs={12}>
                  <Box sx={{ marginTop: 2 }}>
                    <Typography variant="h3">
                      {t('createEditGeoEvent.sections.links')}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <FormLinks />
                </Grid>

                {isPrescribed && (
                  <Grid item xs={12}>
                    <Controller
                      name="prescribedDateStartLocal"
                      control={control}
                      render={({ field, fieldState }): JSX.Element => {
                        return (
                          <FormControl fullWidth>
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label
                              htmlFor="field-control-prescribedDateStart"
                              className={classes.dateTimeLabel}
                            >
                              {t(
                                'createEditGeoEvent.inputs.prescribedDateStart.title',
                              )}
                            </label>

                            <DateTimePicker
                              value={field.value}
                              timezone="UTC"
                              onChange={(newValue): void => {
                                field.onChange(newValue);
                              }}
                              disablePast
                              ampm
                              label={t(
                                'createEditGeoEvent.inputs.prescribedDateStart.label',
                              )}
                              slotProps={{
                                textField: {
                                  InputProps: {
                                    id: 'field-control-prescribedDateStart',
                                    placeholder: 'MM/DD/YYYY hh:mm aa',
                                  },
                                },
                                actionBar: {
                                  actions: ['clear', 'accept'],
                                  classes: { root: classes.clearDateButton },
                                },
                              }}
                              format="MM/dd/yyyy hh:mm aa"
                            />

                            {!!fieldState.error && (
                              <FormHelperText error>
                                {fieldState.error.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                )}

                {!!geoEvent && (
                  <>
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        className={classes.evacButton}
                        startIcon={
                          activeEvacuations ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <ChevronRightIcon />
                          )
                        }
                        onClick={() =>
                          setValue('activeEvacuations', !activeEvacuations)
                        }
                        disabled={formState.isSubmitting}
                      >
                        {t('createEditGeoEvent.evacuations')}
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      {/* Ideally we would use MUI's <Collapse /> here, but there
                        are some performance problems with iOS Safari:
                        https://trello.com/c/mc3v3rWy/1045-slowdown-on-certain-add-report-pages */}
                      <Box display={activeEvacuations ? 'block' : 'none'}>
                        <GeoEventEvacuations
                          evacZones={availableZones}
                          zoneStyle={evacZoneStyle}
                        />
                      </Box>
                    </Grid>
                  </>
                )}

                {!geoEvent && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ marginTop: 1 }}>
                        <Typography variant="h3">
                          {t('createEditGeoEvent.sections.update')}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name="reportNotificationType"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const { ref, ...muiFieldProps } = field;
                          return (
                            <TextField
                              select
                              id="field-control-reportNotificationType"
                              label={t(
                                'createEditGeoEvent.inputs.reportNotificationType.label',
                              )}
                              fullWidth
                              {...muiFieldProps}
                              inputRef={ref}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              className={classes.capitalizeLabel}
                              disabled
                            >
                              {Object.values(NotificationTypeConstant).map(
                                (notifType) => {
                                  return (
                                    <MenuItem
                                      key={notifType}
                                      value={notifType}
                                      className={classes.capitalizeLabel}
                                    >
                                      {notifType}
                                    </MenuItem>
                                  );
                                },
                              )}
                            </TextField>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controller
                        name="reportMessage"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const messageText = removeHTMLTags(field.value);
                          const messageWarning = validatePrescribedIncident(
                            messageText,
                            isPrescribed,
                            t,
                            true,
                          );
                          const reportMessageWarnings = validateReport({
                            t,
                            report: removeHTMLTags(field.value),
                            fpsChecked: isFps,
                            isSilent:
                              notificationType === NotificationType.Silent,
                          });
                          const warningMessages = [
                            messageWarning,
                            ...reportMessageWarnings,
                          ].filter(Boolean) as string[];
                          const helperMessages = [];
                          if (fieldState.error?.message) {
                            helperMessages.push(fieldState.error.message);
                          } else if (warningMessages.length) {
                            helperMessages.push(...warningMessages);
                          } else {
                            helperMessages.push(
                              t(
                                'createEditGeoEvent.inputs.reportMessage.helperText',
                              ),
                            );
                          }
                          return (
                            <>
                              <RichTextEditor
                                id="field-control-report-message"
                                data-testid="report-message"
                                label={t(
                                  'createEditGeoEvent.inputs.reportMessage.label',
                                )}
                                initialValue={initialValues.reportMessage}
                                onChange={field.onChange}
                                editable={!formState.isSubmitting}
                                error={!!fieldState.error}
                                required
                                warning={!!warningMessages.length}
                              />
                              <FieldHelperMessages
                                messages={helperMessages}
                                error={!!fieldState.error}
                                sx={{
                                  marginLeft: 1.75,
                                  marginRight: 1.75,
                                  color: warningMessages.length
                                    ? 'warning.main'
                                    : undefined,
                                }}
                                data-testid="report-validation-message"
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>
                  </>
                )}

                {!geoEvent && (
                  <MediaForm
                    latFieldName="mediaLat"
                    lngFieldName="mediaLng"
                    azFieldName="mediaAz"
                  />
                )}

                {isPreviewVisible && (
                  <Grid item xs={12}>
                    <Box sx={{ marginTop: 3 }}>
                      <Typography variant="h3">
                        {t('createEditGeoEvent.sections.preview')}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {isPreviewVisible && !!name && !!previewBody && (
                  <Grid item xs={12} sm={6}>
                    <PushNotificationPreview title={name} body={previewBody} />
                  </Grid>
                )}

                {geoEvent && (
                  <GeoEventChangelog
                    geoEvent={geoEvent}
                    evacZones={availableZones}
                    zoneStyle={evacZoneStyle}
                  >
                    {incidentInfoChanged && (
                      <>
                        {nameChanged && (
                          <Typography>
                            {t('geoEventChangelog.name', {
                              prevValue: geoEvent.name,
                              newValue: name,
                            })}
                          </Typography>
                        )}
                        {addressChanged && (
                          <Typography>
                            {t('geoEventChangelog.address', {
                              prevValue: geoEvent.address,
                              newValue: address,
                            })}
                          </Typography>
                        )}
                        {latChanged && (
                          <Typography>
                            {t('geoEventChangelog.latitude', {
                              prevValue: geoEvent.lat.toFixed(6),
                              newValue: lat.toFixed(6),
                            })}
                          </Typography>
                        )}
                        {lngChanged && (
                          <Typography>
                            {t('geoEventChangelog.longitude', {
                              prevValue: geoEvent.lng.toFixed(6),
                              newValue: lng.toFixed(6),
                            })}
                          </Typography>
                        )}
                        {regionsChanged && (
                          <Typography>
                            {t('geoEventChangelog.regions')}
                          </Typography>
                        )}
                      </>
                    )}
                  </GeoEventChangelog>
                )}

                {isNoPushNotifVisible && (
                  <Grid item xs={12}>
                    <Typography className={classes.noPushNotif}>
                      {t('createEditGeoEvent.noPushNotification')}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} className={classes.lastItem}>
                  <Box sx={{ width: '100%', paddingBottom: 1 }}>
                    <Button
                      type="submit"
                      fullWidth
                      className={classes.button}
                      disabled={formState.isSubmitting}
                    >
                      {formState.isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        t(
                          `createEditGeoEvent.buttons.${
                            // eslint-disable-next-line no-nested-ternary
                            geoEvent
                              ? 'save'
                              : prescribedDateStartLocal
                                ? 'schedule'
                                : 'create'
                          }`,
                        )
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Container>
      </Box>
    </MapProvider>
  );
};
