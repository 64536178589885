import { TypographyOptions } from '@mui/material/styles/createTypography';
import { getResponsiveFontSize } from 'shared/utils';

// If you see body1bold or subtitle1bold in figma, it means to modify partial text inside of
// the typography element like (note this example should use a <Trans> .. component for translation).:
//    <Typography variant="subtitle1">
//      It starts with normal and
//      <Box component=span" fontWeight="fontWeightMedium"> ends bolded. </Box>
//    </Typography>

// Commented out variants should not be used in new pages, but were pre-existing in the codebase so are not :undefined
// here yet. Do not use:
// h4, h5, h6, body2, subtitle2
const typography: TypographyOptions = {
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontFamily: 'Roboto, sans-serif',
  h1: {
    fontSize: getResponsiveFontSize('2rem'), // 36 px
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: '0px',
    lineHeight: 'auto',
  },
  h2: {
    fontSize: getResponsiveFontSize('1.5rem'), // 24px
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 700,
    letterSpacing: '0px',
    lineHeight: 'auto',
  },
  h3: {
    fontSize: getResponsiveFontSize('1.3rem'), // 20px
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: '0px',
    lineHeight: 'auto',
  },
  // h4: undefined,
  // h5: undefined,
  // h6: undefined,
  body1: {
    fontSize: getResponsiveFontSize('1rem'), // 16px
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: 'auto',
  },
  // be careful changing body2, as it is injected into the <CssBaseline /> component that
  //   affects our whole app <body> content
  body2: {
    fontSize: getResponsiveFontSize('0.875rem'), // 14px
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: 'auto',
  },
  subtitle1: {
    fontSize: getResponsiveFontSize('.8rem'), // 12px
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: '0px',
    lineHeight: 'auto',
  },
  // subtitle2: undefined,
  button: {
    fontSize: getResponsiveFontSize('1rem'), // 16px
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: '0px',
    textTransform: 'inherit',
    lineHeight: 'auto',
  },
  // caption: undefined, // used by datetimepicker
  overline: undefined,
};

export default typography;
