import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import useDonationState from 'state/useDonationState';
import { WATCHDUTY_SCHEME } from '../../constants';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
    marginTop: theme.spacing(2),
  },
  atag: {
    textDecoration: 'none',
    width: '100%',
  },
}));

const Content = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { fromMobile, clientToken, resetDonationState } = useDonationState();
  const fromMobileRef = useRef<boolean>(fromMobile);
  const clientTokenRef = useRef<string | undefined>(clientToken);

  const isInitialDonation = !!clientTokenRef.current;

  return (
    <>
      <Helmet>
        <title>{t('donationSuccess.title')}</title>
      </Helmet>

      <div className={classes.root}>
        <Container maxWidth="sm" className={classes.container}>
          <Box className={classes.contentContainer}>
            <Typography variant="h2" align="center" sx={{ marginBottom: 4 }}>
              {t('donationSuccess.subtitle')}
            </Typography>

            <Typography align="center">
              {t('donationSuccess.message')}
            </Typography>
          </Box>

          {/* The react-dom-router link component does not consistently open the custom scheme urls correctly, use explicit A tag */}
          {fromMobileRef.current ? (
            <a
              href={`${WATCHDUTY_SCHEME}app.watchduty.org/?${
                isInitialDonation
                  ? `clientToken=${clientTokenRef.current}&transaction=donation`
                  : 'donationSuccess=true'
              }`}
              target="_blank"
              className={classes.atag}
              rel="noreferrer"
            >
              <Button
                fullWidth
                className={classes.button}
                onClick={resetDonationState}
              >
                {t('donationSuccess.button')}
              </Button>
            </a>
          ) : (
            <Button
              fullWidth
              className={classes.button}
              component={Link}
              to={
                isInitialDonation
                  ? `/?clientToken=${clientTokenRef.current}&transaction=donation`
                  : '/'
              }
              replace
              onClick={resetDonationState}
            >
              {t('donationSuccess.button')}
            </Button>
          )}
        </Container>
      </div>
    </>
  );
};

const DonationSuccess = (): JSX.Element => {
  return (
    <PageLayout data-cname="DonationSuccessPage" showBanner={false}>
      {{
        content: <Content />,
        headerBar: <HeaderBar action="noControls" />,
      }}
    </PageLayout>
  );
};

export default DonationSuccess;
