import {
  LayerProps,
  DataDrivenPropertyValueSpecification,
} from 'shared/map-exports';
import { FONT_NAMES } from 'components/Map/styles/constants';
import {
  COLOR_BUREAU_LAND_MANAGMENT,
  COLOR_BUREAU_RECLAMATION,
  COLOR_DEPARTMENT_DEFENSE,
  COLOR_FOREST_SERVICE,
  COLOR_FISH_WILD,
  COLOR_NATIONAL_PARK,
  MIN_ZOOM_VISIBILITY,
  FEDERAL_LANDS_AGENCIES,
  LAYER_FILL_OPACITY,
  LAYER_BORDER_OPACITY,
  LAYER_TEXT_OPACITY,
  FEDERAL_LANDS_SOURCE_LAYER,
} from './constants';

const colorExpression:
  | DataDrivenPropertyValueSpecification<string>
  | undefined = [
  'case',
  ['==', ['get', 'Agency'], FEDERAL_LANDS_AGENCIES.landManagment],
  COLOR_BUREAU_LAND_MANAGMENT,
  ['==', ['get', 'Agency'], FEDERAL_LANDS_AGENCIES.reclamation],
  COLOR_BUREAU_RECLAMATION,
  ['==', ['get', 'Agency'], FEDERAL_LANDS_AGENCIES.defense],
  COLOR_DEPARTMENT_DEFENSE,
  ['==', ['get', 'Agency'], FEDERAL_LANDS_AGENCIES.fishWild],
  COLOR_FISH_WILD,
  ['==', ['get', 'Agency'], FEDERAL_LANDS_AGENCIES.forest],
  COLOR_FOREST_SERVICE,
  COLOR_NATIONAL_PARK,
];

export const layerFillStyle: LayerProps = {
  type: 'fill',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': FEDERAL_LANDS_SOURCE_LAYER,
  paint: {
    'fill-color': colorExpression,
    'fill-opacity': LAYER_FILL_OPACITY,
  },
};

export const layerBorderStyle: LayerProps = {
  type: 'line',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': FEDERAL_LANDS_SOURCE_LAYER,
  paint: {
    'line-color': colorExpression,
    'line-width': ['interpolate', ['linear'], ['zoom'], 10, 2, 18, 12],
    'line-opacity': LAYER_BORDER_OPACITY,
  },
};

export const layerLabelStyle: LayerProps = {
  type: 'symbol',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': FEDERAL_LANDS_SOURCE_LAYER,
  layout: {
    'text-field': [
      'concat',
      ['get', 'unit_name'],
      '\n',
      [
        'case',
        ['==', ['get', 'unit_name'], ['get', 'Agency']],
        '',
        ['get', 'Agency'],
      ],
    ],
    'text-font': FONT_NAMES.bold,
    'text-anchor': 'center',
    'text-justify': 'auto',
    'text-size': ['interpolate', ['linear'], ['zoom'], 10, 12, 14, 16],
  },
  paint: {
    'text-color': '#1F1F1F',
    'text-halo-color': '#FFFFFF',
    'text-halo-width': ['interpolate', ['linear'], ['zoom'], 10, 1.8, 14, 2.4],
    'text-opacity': LAYER_TEXT_OPACITY,
  },
};
