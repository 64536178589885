import { Box, Grid, Typography } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GeoEvent, Report, ReportAsset } from 'shared/types';
import { MediaAlertCameraForm } from './MediaAlertCameraForm';
import AssetTypeField from './AssetField';
import { SocialEmbedField } from './SocialEmbedField';
import { ImageForm } from './ImageForm';

type MediaFormProps = {
  geoEvent?: GeoEvent;
  report?: Report;
  latFieldName: string;
  lngFieldName: string;
  azFieldName: string;
};

export const MediaForm = (props: MediaFormProps): JSX.Element => {
  const { geoEvent, report, latFieldName, lngFieldName, azFieldName } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const asset: ReportAsset = useWatch({
    control,
    name: 'asset',
    defaultValue: null,
  });

  const mediaUrl: string = useWatch({
    control,
    name: 'mediaUrl',
    defaultValue: '',
    disabled: !report,
  });

  const isReportUpdate = Boolean(geoEvent);
  const editingMediaAlertCamera = Boolean(report?.media?.[0]?.attribution);

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ marginTop: isReportUpdate ? 0 : 2 }}>
          <Typography variant="h3">
            {t('addIncidentReport.sections.media')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="asset"
          control={control}
          render={(renderProps) => (
            <AssetTypeField<'asset'>
              {...renderProps}
              latFieldName={latFieldName}
              lngFieldName={lngFieldName}
              azFieldName={azFieldName}
              report={report}
            />
          )}
        />
      </Grid>

      {(asset === 'socialEmbed' || Boolean(report?.embedUrl)) && (
        <Grid item xs={12}>
          <Controller
            name="embedUrl"
            control={control}
            render={(renderProps) => (
              <SocialEmbedField<'embedUrl'> {...renderProps} />
            )}
          />
        </Grid>
      )}

      {(asset === 'image' || Boolean(mediaUrl)) && (
        <Grid item xs={12}>
          <ImageForm
            latFieldName={latFieldName}
            lngFieldName={lngFieldName}
            azFieldName={azFieldName}
            geoEvent={geoEvent}
            report={report}
            mediaUrl={mediaUrl}
            asset={asset}
            allowCoordinates={!editingMediaAlertCamera}
          />
        </Grid>
      )}

      {asset === 'alertCamera' && !editingMediaAlertCamera && (
        <Grid item xs={12}>
          <MediaAlertCameraForm />
        </Grid>
      )}
    </>
  );
};
