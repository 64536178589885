import type { User } from 'shared/types';
import { API } from 'api';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import {
  getPushTokenFromLocalStorage,
  savePushTokenToLocalStorage,
} from '../../state/localStorage';

type DeviceRegistrationProps = {
  user?: User | null;
  lat?: number;
  lng?: number;
  push_token?: string;
};

export const registerDeviceWithWatchDuty = async (
  props: DeviceRegistrationProps,
): Promise<void> => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }
  const currentToken = getPushTokenFromLocalStorage();
  const platform = Capacitor.getPlatform();
  const token = props.push_token ?? currentToken;
  if (!token) {
    Sentry.captureMessage('no push token found during registerDevice', {
      extra: { props },
    });
    return;
  }
  const params = {
    user: props.user?.id,
    pushToken: token,
    platform,
    lat: props.lat,
    lng: props.lng,
    // in this case we are updating a stale push token with the new value
    oldPushToken: token !== currentToken ? currentToken : null,
  };

  try {
    await API.post('auth/device_registration/', params);
  } catch (e) {
    return; // don't save to local disk because we technically haven't fully registered
  }

  if (props.push_token) {
    savePushTokenToLocalStorage(props.push_token);
  }
};
