import { List, ListSubheader, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { getResponsiveFontSize } from 'shared/utils';
import { MapLayers } from '../constants';
import { RadioTowerIcon } from '../Icons';
import LegendListItem from './LegendListItem';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(20)),
  },
}));

const RadioTowersLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            color="textPrimary"
          >
            {t('map.legends.tactical.title')}
          </Typography>
        </ListSubheader>
      }
    >
      <LegendListItem
        mapLayer={MapLayers.RADIO_TOWERS}
        iconSrc={RadioTowerIcon.data}
        iconSize={26}
        primaryText={t('map.legends.tactical.legends.radioTower.title')}
        secondary={
          <>
            <Typography variant="body2">
              {t('map.legends.tactical.legends.radioTower.subtitle')}
            </Typography>
          </>
        }
        minMembership="pro"
      />
    </List>
  );
};

export default RadioTowersLegend;
