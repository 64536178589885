import { AnySource, LayerSpecification, MapStyle } from 'shared/map-exports';
import { isMobile } from '../../../shared/utils';

const API_KEY = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;
const MAPBOX_API = import.meta.env.VITE_MAPBOX_API;
const TILE_BASE = 'mapbox://';
const TILE_API_ROOT = `${MAPBOX_API}/v4/`;
const TILE_API_FORMAT = 'vector.pbf';
const SPRITE_API_ROOT = `${MAPBOX_API}/styles/v1/`;
const SPRITE_BASE = 'mapbox://sprites/';
const GLYPHS_BASE = 'mapbox://fonts/mapbox/';
const GLPYHS_API_ROOT = `${MAPBOX_API}/fonts/v1/watchduty/`;

// for some reason the shield text-color expression doesn't work in our maps
// so we override it explicitly
const SHIELD_OVERRIDE_ID = 'road-number-shield';
const SHIELD_OVERRIDE = [
  'match',
  ['coalesce', ['get', 'shield_text_color_beta'], ['get', 'shield_text_color']],
  'white',
  'hsl(0, 0%, 100%)',
  'yellow',
  'hsl(50, 100%, 70%)',
  'orange',
  'hsl(25, 100%, 75%)',
  'blue',
  'hsl(230, 57%, 44%)',
  'red',
  'hsl(0, 87%, 59%)',
  'green',
  'hsl(140, 74%, 37%)',
  'hsl(230, 18%, 13%)',
];

const getRasterFormat = (): string => {
  return isMobile() ? 'webp' : '@2x.webp';
};

const rewriteGlyphsUrl = (url: string): string => {
  return `${url.replace(GLYPHS_BASE, GLPYHS_API_ROOT)}?access_token=${API_KEY}`;
};

const rewriteTilesUrl = (
  url: string,
  mapId: string,
  modifiedTs: string,
): string => {
  const styleParam = `mapbox://styles/watchduty/${mapId}@${modifiedTs}`;
  const format = url.includes('satellite')
    ? getRasterFormat()
    : TILE_API_FORMAT;
  return `${url.replace(
    TILE_BASE,
    TILE_API_ROOT,
  )}/{z}/{x}/{y}.${format}?access_token=${API_KEY}&style=${styleParam}`;
};

const rewriteSpriteUrl = (url: string): string => {
  return `${url.replace(
    SPRITE_BASE,
    SPRITE_API_ROOT,
  )}/sprite?access_token=${API_KEY}`;
};

export const mapboxStyleToMaplibre = (style: MapStyle): MapStyle => {
  const sprite = rewriteSpriteUrl(style.sprite as string);
  const glyphs = rewriteGlyphsUrl(style.glyphs as string);
  // @ts-ignore
  const modifiedTs = style.modified;
  // @ts-ignore`
  const mapId = style.id;
  const sources = Object.fromEntries(
    Object.entries(style.sources).map((entry: [string, AnySource]) => {
      const [name, source] = entry;
      if ('url' in source && source.url) {
        const { url, ...sourceWithoutUrl } = source;
        return [
          name,
          {
            ...sourceWithoutUrl,
            tiles: [rewriteTilesUrl(url, mapId, modifiedTs)],
          },
        ];
      }
      return entry;
    }),
  );
  const layers = Object.values(style.layers).map(
    (layer: LayerSpecification) => {
      const updatedLayer = layer;
      if (layer.id === SHIELD_OVERRIDE_ID) {
        // @ts-ignore
        updatedLayer.paint['text-color'] = SHIELD_OVERRIDE;
      }
      return updatedLayer;
    },
  );
  return { ...style, glyphs, sources, layers, sprite };
};
