import { useCallback, useEffect } from 'react';
import { useAuthState } from 'state';
import { usePoisState } from 'state/usePoisState';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MapRef, useMapInstance } from 'shared/map-exports';
import { LatLng } from 'shared/types';
import usePrevious from 'hooks/usePrevious';
import { getMapZoomInteger } from 'components/Map/utils';
import { useMapZoomInteger } from 'hooks/useMapZoomInteger';
import { COORDINATE_DISPLAY_PRECISION } from '../../constants';
import { MapPinDialogContent } from '../Map/MapPinDialogContent';
import { useMapURLParams } from './useMapURLParams';

type UseSetMapPinPoiReturn = {
  setSelectedMapPinPoi: (coords: LatLng) => void;
};

const splitUrlIntoPathAndCoords = (
  url: string,
): { pathName: string; coordsString: string } => {
  const [pathName, coordsString = ''] = url.split('@');
  return { pathName, coordsString };
};

const buildCoordinatesUrlParam = (coords: LatLng, map?: MapRef): string => {
  const lat = coords.lat.toFixed(COORDINATE_DISPLAY_PRECISION);
  const lng = coords.lng.toFixed(COORDINATE_DISPLAY_PRECISION);
  const zoom = getMapZoomInteger(map);
  if (zoom) {
    return `/@${lat},${lng},${zoom}z`;
  }
  return `/@${lat},${lng}`;
};

const adjustZoomLevel = (locationPathname: string, newZoom: number): string => {
  const { pathName, coordsString } =
    splitUrlIntoPathAndCoords(locationPathname);
  const baseCoords = coordsString.replace(/,\d+z?$/, '');
  return `${pathName}@${baseCoords},${newZoom}z`;
};

/**
 * Syncs URL-based map pin selection with application state.
 * 1. Extracts lat/lng from URL params
 * 2. Updates app's POI state with selected pin
 * 3. Clears URL params when pin is deselected
 */
export const useSyncMapPinPoi = (): void => {
  const { showMembershipProFeatures } = useAuthState();
  const { selectedPois, setSelectedPoi } = usePoisState();
  const history = useHistory();
  const { latitudeParam, longitudeParam } = useMapURLParams();
  const routeMatch = useRouteMatch('/@:lat,:lng');

  // Sync URL state with POIs state if conditions are met
  useEffect(() => {
    if (showMembershipProFeatures && latitudeParam && longitudeParam) {
      setSelectedPoi({
        coordinates: { lat: latitudeParam, lng: longitudeParam },
        type: 'mapPin',
        PoiDialogContent: () => <MapPinDialogContent />,
      });
    }
  }, [
    showMembershipProFeatures,
    setSelectedPoi,
    latitudeParam,
    longitudeParam,
  ]);

  const currentLocationUrl = routeMatch?.url;

  const selectedMapPoi = selectedPois.find((poi) => poi.type === 'mapPin');
  const prevSelectedMapPoi = usePrevious(selectedMapPoi);
  // Remove the URL params when the selected map pin is deselected
  useEffect(() => {
    if (prevSelectedMapPoi && !selectedMapPoi && currentLocationUrl) {
      const { pathName } = splitUrlIntoPathAndCoords(currentLocationUrl);
      history.replace(pathName);
    }
  }, [history, currentLocationUrl, prevSelectedMapPoi, selectedMapPoi]);

  const mapZoom = useMapZoomInteger();
  const prevMapZoom = usePrevious(mapZoom);

  // Sync URL with the map's zoom level if the URL has lat/lng params
  useEffect(() => {
    if (
      selectedMapPoi &&
      prevMapZoom !== mapZoom &&
      mapZoom &&
      currentLocationUrl
    ) {
      const updatedPathname = adjustZoomLevel(currentLocationUrl, mapZoom);
      history.replace(updatedPathname);
    }
  }, [history, currentLocationUrl, mapZoom, prevMapZoom, selectedMapPoi]);
};

/**
 * A custom hook for selecting a map pin.
 * `setSelectedMapPinPoi` updates the browser's URL params to include:
 * - The selected map pin's coordinates
 * - The current zoom level (if available)
 */
export const useSetMapPinPoi = (): UseSetMapPinPoiReturn => {
  const history = useHistory();
  const map = useMapInstance();

  const setSelectedMapPinPoi = useCallback(
    (coords: LatLng): void => {
      const coordinatesPath = buildCoordinatesUrlParam(coords, map);
      history.replace(coordinatesPath);
    },
    [history, map],
  );

  return { setSelectedMapPinPoi };
};
