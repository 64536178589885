import { memo } from 'react';
import { Card, CardMedia, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

type ImageUrlPreviewProps = {
  url: string;
  onDelete: () => void;
};

type ImageDataPreviewProps = {
  data: File | Blob;
  onDelete: () => void;
};

const useStyles = makeStyles()((theme) => ({
  imagePreview: {
    minHeight: 400,
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundSize: 'contain',
    backgroundColor: theme.palette.grey[300],
  },
  deleteButton: {
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius * 2,
    height: 42,
    width: 42,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
    position: 'absolute',
    top: 16,
    right: 16,
  },
}));

export const ImageUrlPreview = memo(
  (props: ImageUrlPreviewProps): JSX.Element => {
    const { url, onDelete } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();

    return (
      <Card sx={{ position: 'relative' }}>
        <CardMedia className={classes.imagePreview} image={url} />
        <IconButton
          color="primary"
          size="medium"
          aria-label={t('imagePreview.deleteImage')}
          className={classes.deleteButton}
          onClick={onDelete}
        >
          <CloseIcon />
        </IconButton>
      </Card>
    );
  },
);

ImageUrlPreview.displayName = 'ImageUrlPreview';

export const ImageDataPreview = memo(
  (props: ImageDataPreviewProps): JSX.Element => {
    const { data, onDelete } = props;
    const { classes } = useStyles();
    const { t } = useTranslation();

    return (
      <Card sx={{ position: 'relative' }}>
        <CardMedia
          className={classes.imagePreview}
          image={URL.createObjectURL(data)}
          title={data.name}
        />
        <IconButton
          color="primary"
          size="medium"
          aria-label={t('imagePreview.deleteImage')}
          className={classes.deleteButton}
          onClick={onDelete}
        >
          <CloseIcon />
        </IconButton>
      </Card>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.data === nextProps.data;
  },
);

ImageDataPreview.displayName = 'ImageDataPreview';
