import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { getHighlightedSearchMatch } from './useMapSearch.utils';
import { SearchResult } from './useMapSearch.types';

type SearchResultRowProps = {
  searchTerm?: string;
  searchResult: SearchResult;
  onBeforeClick?: () => void;
};

type GeoEventSearchSourceProps = {
  secondaryText: string;
  tertiaryText?: string;
};

const SubtitleLayout = (props: GeoEventSearchSourceProps): JSX.Element => {
  const { secondaryText, tertiaryText } = props;

  return (
    <Stack
      direction="row"
      component="span"
      spacing={1}
      justifyContent="space-between"
      alignItems="end"
    >
      <Typography
        variant="subtitle1"
        component="span"
        color="textSecondary"
        noWrap
      >
        {secondaryText}
      </Typography>
      {tertiaryText && (
        <Typography
          variant="body2"
          component="span"
          textAlign="right"
          color="black"
          sx={{ whiteSpace: 'nowrap', overflow: 'visible' }}
        >
          {tertiaryText}
        </Typography>
      )}
    </Stack>
  );
};

export const SearchResultRow = (props: SearchResultRowProps): JSX.Element => {
  const { searchTerm, searchResult, onBeforeClick } = props;
  const { action, avatar, primaryText, secondaryText, tertiaryText } =
    searchResult;

  const handleClick = (): void => {
    if (onBeforeClick) {
      onBeforeClick();
    }
    if (action === 'click') {
      searchResult.onClick();
    }
  };

  const hrefItemProps =
    action === 'link' ? { component: Link, to: searchResult.href } : {};
  const onClickItemProps = action === 'click' ? { onClick: handleClick } : {};

  return (
    <ListItemButton
      {...hrefItemProps}
      {...onClickItemProps}
      dense
      divider
      sx={{
        paddingLeft: 1, // default padding places centered icon too far right
      }}
    >
      <ListItemIcon
        sx={{
          justifyContent: 'center',
        }}
      >
        {avatar}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography>
            {getHighlightedSearchMatch(searchTerm || '', primaryText)}
          </Typography>
        }
        secondary={
          <SubtitleLayout
            secondaryText={secondaryText}
            tertiaryText={tertiaryText}
          />
        }
      />
    </ListItemButton>
  );
};
