import { TickStop } from './types';

const MAJOR_TICK_INTERVALS_MILES: TickStop[] = [
  {
    majorTickMiles: 0.05,
    minorTicksCount: 5, // Every 0.01 miles
  },
  {
    majorTickMiles: 0.1,
    minorTicksCount: 4, // Every 0.025 miles
  },
  {
    majorTickMiles: 0.25,
    minorTicksCount: 5, // Every 0.05 miles
  },
  {
    majorTickMiles: 0.5,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 1,
    minorTicksCount: 4,
  },
  {
    majorTickMiles: 2.5,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 5,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 10,
    minorTicksCount: 4,
  },
  {
    majorTickMiles: 25,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 50,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 100,
    minorTicksCount: 4,
  },
  {
    majorTickMiles: 250,
    minorTicksCount: 5,
  },
  // Note: the following tick stops are not likely to be encountered in practice.
  {
    majorTickMiles: 500,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 1000,
    minorTicksCount: 4,
  },
  {
    majorTickMiles: 2500,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 5000,
    minorTicksCount: 5,
  },
  {
    majorTickMiles: 10000,
    minorTicksCount: 4,
  },
];

const ZOOM_TO_MAJOR_TICK_LOOKUP: Array<TickStop> = [
  MAJOR_TICK_INTERVALS_MILES[16], // zoom 0
  MAJOR_TICK_INTERVALS_MILES[15], // zoom 1
  MAJOR_TICK_INTERVALS_MILES[14], // zoom 2
  MAJOR_TICK_INTERVALS_MILES[13], // zoom 3
  MAJOR_TICK_INTERVALS_MILES[12], // zoom 4
  MAJOR_TICK_INTERVALS_MILES[11], // zoom 5
  MAJOR_TICK_INTERVALS_MILES[10], // zoom 6
  MAJOR_TICK_INTERVALS_MILES[9], // zoom 7
  MAJOR_TICK_INTERVALS_MILES[8], // zoom 8
  MAJOR_TICK_INTERVALS_MILES[7], // zoom 9
  MAJOR_TICK_INTERVALS_MILES[6], // zoom 10
  MAJOR_TICK_INTERVALS_MILES[5], // zoom 11
  MAJOR_TICK_INTERVALS_MILES[4], // zoom 12
  MAJOR_TICK_INTERVALS_MILES[3], // zoom 13
  MAJOR_TICK_INTERVALS_MILES[2], // zoom 14
  MAJOR_TICK_INTERVALS_MILES[1], // zoom 15
  MAJOR_TICK_INTERVALS_MILES[0], // zoom 16
  MAJOR_TICK_INTERVALS_MILES[0], // zoom 17
  MAJOR_TICK_INTERVALS_MILES[0], // zoom 18
  MAJOR_TICK_INTERVALS_MILES[0], // zoom 19
  MAJOR_TICK_INTERVALS_MILES[0], // zoom 20
  MAJOR_TICK_INTERVALS_MILES[0], // zoom 21
  MAJOR_TICK_INTERVALS_MILES[0], // zoom 22
];

export const getMajorTickStop = (zoom: number, lat: number): TickStop => {
  // As the latitude approaches the poles, the real-world distance between two points shrinks
  // relative to the same pixel distance at the equator. To ensure that tick marks are spaced
  // appropriately, we adjust the zoom level to compensate.
  //
  // This adjustment has the effect of placing ticks closer together by selecting a more "zoomed-in"
  // tick stop.
  //
  // The cos(lat) goes from 1.0 at the equator to 0.0 at the poles.
  const cosine = Math.abs(Math.cos((lat * Math.PI) / 180));
  // The following will adjust the "zooom" level by up to 3. This was selected based on experimentation
  // with the tool on the map.
  const latZoomAdjustment = Math.min(Math.log2(1 / cosine) + (1 - cosine), 3);
  const zoomInteger = Math.floor(zoom + latZoomAdjustment);
  if (zoomInteger >= ZOOM_TO_MAJOR_TICK_LOOKUP.length) {
    return MAJOR_TICK_INTERVALS_MILES[0];
  }
  return ZOOM_TO_MAJOR_TICK_LOOKUP[zoomInteger];
};
