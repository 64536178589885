import { useState, useEffect } from 'react';
import { UseRecurlyInstance, useRecurly } from '@recurly/react-recurly';
import { useTranslation } from 'react-i18next';
import {
  ApplePayInstance,
  ApplePayPaymentAuthorizedEvent,
  PayPalInstance,
} from '@recurly/recurly-js';
import { TFunction } from 'i18next';
import * as Sentry from '@sentry/capacitor';

type UsePaymentsProps = {
  isMembershipTransaction: boolean;
  transactionAmount: number;
  submitToken: (token: string) => void;
};

type UsePayments = {
  applePaySupported: boolean;
  applePay: ApplePayInstance | null;
  payPal: PayPalInstance;
};

const getApplePay = (
  recurly: UseRecurlyInstance,
  isMembershipTransaction: boolean,
  transactionAmount: number,
  onPaymentAuthorized: (event: ApplePayPaymentAuthorizedEvent) => void,
  t: TFunction,
): ApplePayInstance | null => {
  try {
    const applePay = recurly.ApplePay({
      country: 'US',
      currency: 'USD',
      // This label text will be displayed on the Apple Pay payment sheet
      label: isMembershipTransaction
        ? t('recurlyAccountInformation.applePay.membership')
        : t('recurlyAccountInformation.applePay.donation'),
      total: transactionAmount.toFixed(2),
      callbacks: { onPaymentAuthorized },
    });

    return applePay;
  } catch (err) {
    return null;
  }
};

const usePayments = (props: UsePaymentsProps): UsePayments => {
  const { isMembershipTransaction, transactionAmount, submitToken } = props;
  const { t } = useTranslation();
  const [applePaySupported, setApplePaySupported] = useState(true);

  const recurly = useRecurly();

  const payPal = recurly.PayPal({
    display: {
      displayName: isMembershipTransaction
        ? t('recurlyAccountInformation.payPal.membership')
        : t('recurlyAccountInformation.payPal.donation'),
      amount: transactionAmount.toFixed(2),
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPaymentAuthorized = ({ payment }: { payment: any }): void => {
    const { recurlyToken: token } = payment;
    // Submit the token to your server
    submitToken(token.id);
  };

  const applePay = getApplePay(
    recurly,
    isMembershipTransaction,
    transactionAmount,
    onPaymentAuthorized,
    t,
  );

  useEffect(() => {
    payPal.on('token', (token) => {
      submitToken(token.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payPal]);

  useEffect(() => {
    if (!applePay) return;

    applePay.on('error', (err) => {
      if (err.code === 'apple-pay-not-supported') {
        setApplePaySupported(false);
        return;
      }
      Sentry.captureException(err);
    });
  }, [applePay]);

  return { applePaySupported, applePay, payPal };
};

export default usePayments;
