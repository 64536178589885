import { useMapRenderBugDetector } from 'hooks/useMapRenderBugDetector';
import { useEffect } from 'react';
import { Map } from 'shared/map-exports';

type MapRenderBugDetectorProps = {
  map: Map | undefined;
};

export const MapRenderBugDetector = (
  props: MapRenderBugDetectorProps,
): JSX.Element | null => {
  const { map } = props;
  const isBugDetected = useMapRenderBugDetector(map);

  useEffect(() => {
    if (isBugDetected) {
      window.location.reload();
    }
  }, [isBugDetected]);

  return null;
};
