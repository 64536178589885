import { List, ListSubheader, Typography, ListItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { AirCraftType } from 'shared/types';
import { TFunction } from 'i18next';
import React from 'react';
import { getResponsiveFontSize } from 'shared/utils';
import { aircraftIconNameToDataMap } from '../layers/AircraftsLayer/utils';
import LegendListItem from './LegendListItem';
import {
  DEFAULT_STROKE_COLOR,
  fillColorForVariant,
} from '../layers/AircraftsLayer/layerStyles';
import UserStatusChip from '../UserStatusChip';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    fontSize: getResponsiveFontSize(theme.typography.pxToRem(20)),
  },
  icon: {
    marginRight: theme.spacing(0),
    width: theme.spacing(4.5),
    minWidth: theme.spacing(4.5),
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionHeader: {
    fontWeight: 'bold',
  },
  starIcon: {
    fontSize: 16,
    marginLeft: 4,
  },
}));

const buildLegendListItem = (
  t: TFunction,
  classification: AirCraftType,
): JSX.Element => {
  const icon = aircraftIconNameToDataMap[classification];
  const iconUri = icon.svg
    ? `data:image/svg+xml;base64,${window.btoa(
        ReactDOMServer.renderToStaticMarkup(
          React.cloneElement(aircraftIconNameToDataMap[classification].svg!, {
            fill: fillColorForVariant('default'),
            stroke: DEFAULT_STROKE_COLOR,
          }),
        ),
      )}`
    : icon.urls?.default;
  const titleKey = `map.legends.aircraft.legends.${classification}.title`;
  const subtitleKey = `map.legends.aircraft.legends.${classification}.subtitle`;
  return (
    <LegendListItem
      key={classification}
      iconSrc={iconUri}
      iconSize={30}
      primaryText={t(titleKey)}
      secondary={t(subtitleKey)}
    />
  );
};

const tankerSectionAircraft: Array<AirCraftType> = [
  'tankerType1Vlat',
  'tankerType1Lat',
  'tankerType2',
  'tankerType3',
  'tankerSeats',
  'tankerScooper',
];

const helicopterSectionAircraft: Array<AirCraftType> = [
  'heloType1Skycrane',
  'heloType1Chinook',
  'heloType1Blackhawk',
  'heloType2Medium',
  'heloType3Light',
];

const otherSectionAircraft: Array<AirCraftType> = [
  'fixedWing',
  'jumpShip',
  'drone',
];

const AircraftLegend = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      disablePadding
      dense
      subheader={
        <ListSubheader disableSticky className={classes.subheader}>
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            color="textPrimary"
          >
            {t('map.legends.aircraft.title')}
            <UserStatusChip type="members" sx={{ marginLeft: 0.5 }} />
          </Typography>
        </ListSubheader>
      }
    >
      <ListSubheader className={classes.sectionHeader}>
        {t('map.legends.aircraft.sections.airTanker.title')}
      </ListSubheader>
      <ListItem>
        {t('map.legends.aircraft.sections.airTanker.subtitle')}
      </ListItem>
      {tankerSectionAircraft.map((c: AirCraftType) =>
        buildLegendListItem(t, c),
      )}
      <ListSubheader className={classes.sectionHeader}>
        {t('map.legends.aircraft.sections.helicopter.title')}
      </ListSubheader>
      <ListItem>
        {t('map.legends.aircraft.sections.helicopter.subtitle')}
      </ListItem>
      {helicopterSectionAircraft.map((c: AirCraftType) =>
        buildLegendListItem(t, c),
      )}
      <ListSubheader className={classes.sectionHeader}>
        {t('map.legends.aircraft.sections.other.title')}
      </ListSubheader>
      {otherSectionAircraft.map((c: AirCraftType) => buildLegendListItem(t, c))}
    </List>
  );
};

export default AircraftLegend;
