import { atom, useRecoilState } from 'recoil';
import { LatLng } from 'shared/types';
import { useCallback, useEffect } from 'react';
import distance from '@turf/distance';
import bearing from '@turf/bearing';
import destination from '@turf/destination';
import { MapRef } from 'shared/map-exports';
import { usePoisState } from './usePoisState';

export type MeasureDistanceToolPoints = {
  start: LatLng;
  end: LatLng;
};

type MeasureDistanceToolStateReturn = {
  isToolActive: boolean;
  /* Activates the tool, and clears any existing state */
  activateTool: (start: LatLng, map: MapRef) => void;
  /* Deactivates the tool and clears any existing state */
  deactivateTool: () => void;

  onUpdateStartPointStart: () => void;
  onUpdateStartPointEnd: () => void;
  setStartPoint: (pos: LatLng) => void;
  setEndPoint: (pos: LatLng) => void;

  points: MeasureDistanceToolPoints | null;
};

type MeasureDistanceToolState = {
  active: boolean;
  points: MeasureDistanceToolPoints | null;
  preserveEndPointOffset: {
    bearingDegrees: number;
    distanceMeters: number;
  } | null;
};

const MEAURE_TOOL_STATE_ATOM_KEY = 'measureToolState';
const DEFAULT_STATE: MeasureDistanceToolState = {
  active: false,
  points: null,
  preserveEndPointOffset: null,
};

const measureDistanceToolStateAtom = atom<MeasureDistanceToolState>({
  key: MEAURE_TOOL_STATE_ATOM_KEY,
  default: DEFAULT_STATE,
});

export const useMeasureDistanceToolState =
  (): MeasureDistanceToolStateReturn => {
    const [state, setState] = useRecoilState(measureDistanceToolStateAtom);
    const { selectedPois } = usePoisState();

    const activateTool = (start: LatLng, map: MapRef): void => {
      const mapBounds = map.getBounds();
      if (!mapBounds) return;

      const lngOffset = Math.min(
        (mapBounds.getEast() - mapBounds.getWest()) / 4,
        (mapBounds.getNorth() - mapBounds.getSouth()) / 4,
      );
      setState({
        ...DEFAULT_STATE,
        active: true,
        points: {
          start,
          end: {
            lat: start.lat,
            lng: start.lng + lngOffset,
          },
        },
      });
    };
    const deactivateTool = useCallback((): void => {
      setState(DEFAULT_STATE);
    }, [setState]);

    const onUpdateStartPointStart = useCallback((): void => {
      setState((prev) => {
        if (prev.points === null) {
          return prev;
        }
        // Calculate the distance & bearing from the start to the end and
        // save it so that it can be preserved when the start point is moved.
        const { start, end } = prev.points;
        const bearingDegrees = bearing(
          [start.lng, start.lat],
          [end.lng, end.lat],
        );
        const distanceMeters = distance(
          [start.lng, start.lat],
          [end.lng, end.lat],
          { units: 'meters' },
        );

        return {
          ...prev,
          preserveEndPointOffset: {
            bearingDegrees,
            distanceMeters,
          },
        };
      });
    }, [setState]);

    const onUpdateStartPointEnd = useCallback((): void => {
      setState((prev) => ({ ...prev, preserveEndPointOffset: null }));
    }, [setState]);

    const setStartPoint = (pos: LatLng): void => {
      setState((prev) => {
        const { preserveEndPointOffset, points } = prev;
        if (points === null) {
          return prev;
        }
        if (!preserveEndPointOffset) {
          return { ...prev, points: { ...points, start: pos } };
        }
        const { bearingDegrees, distanceMeters } = preserveEndPointOffset;
        const end = destination(
          [pos.lng, pos.lat],
          distanceMeters,
          bearingDegrees,
          { units: 'meters' },
        );
        const { coordinates } = end.geometry;
        return {
          ...prev,
          points: {
            start: pos,
            end: { lat: coordinates[1], lng: coordinates[0] },
          },
        };
      });
    };

    const setEndPoint = (pos: LatLng): void => {
      setState((prev) => {
        const { points } = prev;
        if (points === null) {
          return prev;
        }
        return { ...prev, points: { ...points, end: pos } };
      });
    };

    // If the POIs are empty or if they were changed and no longer include this tool,
    // it means the user closed the POI dialog or selected something else. Deactivate the tool.
    useEffect(() => {
      if (
        selectedPois.length === 0 ||
        selectedPois.find((poi) => poi.type === 'measureTool') === undefined
      ) {
        deactivateTool();
      }
    }, [deactivateTool, selectedPois]);

    return {
      isToolActive: state.active,
      activateTool,
      deactivateTool,

      onUpdateStartPointStart,
      onUpdateStartPointEnd,
      setStartPoint,
      setEndPoint,

      points: state.points,
    };
  };
