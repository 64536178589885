import * as Sentry from '@sentry/capacitor';
import { TFunction } from 'i18next';
import { ERROR_MSG } from '../state';

export const parseRecurlyErrorToTransKey = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any,
): string => {
  let errTranslationKey = 'recurlyBillingInformation.errors.paymentGeneric';
  if (err.response && err.response.data) {
    const errData = err.response.data;
    const declineCode = errData.length ? errData[0] : undefined;
    if (
      declineCode === 'membership-exists-for-email' ||
      ('email' in errData &&
        errData.email?.[0] === 'membership-exists-for-email')
    ) {
      errTranslationKey = 'common.alreadyAMember';
    } else if (declineCode === 'credit-card-declined') {
      errTranslationKey = 'recurlyBillingInformation.errors.ccDeclined';
    } else if (declineCode === 'bad-security-code-or-expiration') {
      errTranslationKey =
        'recurlyBillingInformation.errors.badCodeOrExpiration';
    } else if (declineCode === 'bad-zipcode') {
      errTranslationKey = 'recurlyBillingInformation.errors.badZipcode';
    } else if (declineCode === 'expired-card') {
      errTranslationKey = 'recurlyBillingInformation.errors.expiredCard';
    } else if (declineCode === 'insufficient-funds') {
      errTranslationKey = 'recurlyBillingInformation.errors.insufficientFunds';
    }
  }
  return errTranslationKey;
};

export const recurlyOnErrorHandler = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSnackbar: any,
  t: TFunction,
): void => {
  const errTranslationKey = parseRecurlyErrorToTransKey(err);
  setSnackbar(t(errTranslationKey), ERROR_MSG, 15000);
  Sentry.captureException(err);
};
