/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useMap, useControl, Map } from 'shared/map-exports';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed';
import { ParticleLayer } from 'deck.gl-particle';
import { Deck, PickingInfo } from '@deck.gl/core/typed';
import useMapLayersState from 'state/useMapLayersState';
import { Client } from 'vendor/weatherlayers/weatherlayers-client.es.min';
import { MapLayerProps } from '../types';

// Number of ms before refreshing wind data.
const WIND_DATA_REFRESH_INTERVAL_MS = 10 * 60 * 1000;

const WEATHER_LAYERS_TOKEN = import.meta.env.VITE_WEATHERLAYERS_API_KEY;

// Number of particles for a screen with width 1000px.
const NUM_PARTICLES = 400;

// Screen width for which the number of particles is calculated.
const SCREEN_CONSTANT = 1000;

// Maximum number of particles.
const MAX_PARTICLES = NUM_PARTICLES;

// How long the particles stay on screen (and how long they are as a result).
const PARTICLE_MAX_AGE = 40;

// Speed multiplier for particles (also affects how long they get).
const PARTICLE_SPEED_FACTOR = 15;

// Opacity of the particles for light or dark base layers.
const PARTICLE_OPACITY_LIGHT = 0.5;
const PARTICLE_OPACITY_DARK = 0.4;

// Particle color for light or dark base layers.
const PARTICLE_COLOR_LIGHT = [100, 100, 100];
const PARTICLE_COLOR_DARK = [255, 255, 255];

// Size / line width of the particles
const PARTICLE_LINE_WIDTH = 2.2;

// Types borrowed from: https://docs.weatherlayers.com/weatherlayers-cloud/types
type TextureData = {
  data: Uint8Array | Uint8ClampedArray | Float32Array;
  width: number;
  height: number;
};

type DatasetData = {
  datetime: string;
  referenceDatetime: string;
  horizon: string;
  image: TextureData;
  datetime2: string | null;
  referenceDatetime2: string | null;
  horizon2: string | null;
  image2: TextureData | null;
  imageWeight: number;
  imageType: unknown;
  imageUnscale: [number, number] | null;
  bounds: [number, number, number, number];
};

// A simple forwarding wrapper for `MapboxOverlay` that overrides `onRemove()` to perform
// explicit cleanup of Deck.gl's underlying WebGL context. Doing so avoids a number of issues
// that result from the fact that GL contexts are cleaned up in FIFO ordering when the browser's
// limit is reached, rather than by reference counting or some other means. The side-effects are
// catastrophic in the case of Watch Duty because the oldest context is owned by the main map.
class DeckGLMapboxOverlay extends MapboxOverlay {
  control: MapboxOverlay | null = null;

  constructor(props: MapboxOverlayProps) {
    super(props);
    this.control = new MapboxOverlay(props);
  }

  setProps(props: MapboxOverlayProps): void {
    this.control?.setProps(props);
  }

  // @ts-ignore
  onAdd(map: Map): HTMLDivElement {
    // @ts-ignore
    return this.control?.onAdd(map) as HTMLDivElement;
  }

  onRemove(): void {
    // Grab a reference to the deck instance's GL context and instruct it to destroy
    // its context once the map overlay has been cleaned up.
    // eslint-disable-next-line no-underscore-dangle
    const {
      deckRenderer: { gl },
      // @ts-ignore
    } = this.control?._deck;
    this.control?.onRemove();
    const ext = gl.getExtension('WEBGL_lose_context');
    ext?.loseContext();
  }

  getDefaultPosition(): string {
    return this.control?.getDefaultPosition() || '';
  }

  pickObject(params: Parameters<Deck['pickObject']>[0]): PickingInfo | null {
    return this.control?.pickObject(params) || null;
  }

  pickMultipleObjects(
    params: Parameters<Deck['pickMultipleObjects']>[0],
  ): PickingInfo[] {
    return this.control?.pickMultipleObjects(params) || [];
  }

  pickObjects(params: Parameters<Deck['pickObjects']>[0]): PickingInfo[] {
    return this.control?.pickObjects(params) || [];
  }

  finalize(): void {
    this.control?.finalize();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DeckGLOverlay = (props: any): null => {
  const overlay = useControl(
    // @ts-ignore
    () => new DeckGLMapboxOverlay(props),
  ) as DeckGLMapboxOverlay;
  overlay.setProps(props);
  return null;
};

const fetchWindData = (): DatasetData => {
  const client = new Client({
    accessToken: WEATHER_LAYERS_TOKEN,
  });
  return client.loadDatasetData(
    'gfs/wind_10m_above_ground',
    new Date().toISOString(),
  );
};

const WindLayerGuard = (props: MapLayerProps): JSX.Element | null => {
  const { visible } = props;
  const { current: map } = useMap();
  const zoom = map?.getZoom();

  if (!visible) {
    return null;
  }

  // Fixes/hides issue with: https://trello.com/c/2qURl1Vo/1552-wind-direction-changes-based-on-zoom-level-and-browser-inactivity
  //   better to hide the incorrect data at this zoom level than show it.
  if (zoom && zoom > 15) {
    return null;
  }

  return <WindLayer />;
};

const WindLayer = (): JSX.Element | null => {
  const { isDarkBaseLayer } = useMapLayersState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [waitFinished, setWaitFinished] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setWaitFinished(true);
    }, 300);
  }, []);

  const numParticles = useMemo(
    () =>
      Math.floor(
        NUM_PARTICLES * ((windowWidth ?? SCREEN_CONSTANT) / SCREEN_CONSTANT),
      ),
    [windowWidth],
  );

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { data } = useQuery({
    queryKey: ['wind-data'],
    queryFn: fetchWindData,
    refetchInterval: WIND_DATA_REFRESH_INTERVAL_MS,
    enabled: waitFinished,
  });

  if (!data || !waitFinished) return null;

  const { image, bounds, imageUnscale } = data;

  const layer = new ParticleLayer({
    id: 'particle',
    image,
    imageUnscale,
    bounds,
    numParticles: numParticles > MAX_PARTICLES ? MAX_PARTICLES : numParticles,
    maxAge: PARTICLE_MAX_AGE,
    speedFactor: PARTICLE_SPEED_FACTOR,
    color: isDarkBaseLayer ? PARTICLE_COLOR_DARK : PARTICLE_COLOR_LIGHT,
    width: PARTICLE_LINE_WIDTH,
    opacity: isDarkBaseLayer ? PARTICLE_OPACITY_DARK : PARTICLE_OPACITY_LIGHT,
  });

  return <DeckGLOverlay layers={[layer]} />;
};

export default WindLayerGuard;
