/* eslint-disable i18next/no-literal-string */
import Helmet from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { RouteComponentProps } from 'react-router-dom';
import ReportDetail from 'components/ReportDetail';
import PageLayout from 'components/PageLayout';
import { API } from 'api';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import HeaderBar from 'components/HeaderBar';
import { Report as ReportType } from 'shared/types';

type ContentProps = {
  report: ReportType;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchReport = (reportId: string) => API.get(`reports/${reportId}`);

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100vw',
  },
}));

// This be passed from the navigation as a name or pass an id we fetch the incident object
export const Content = (props: ContentProps): JSX.Element => {
  const { report } = props;
  return (
    <>
      <Helmet>
        <title>Report</title>
      </Helmet>
      <ReportDetail report={report} />
    </>
  );
};

const Report = (props: RouteComponentProps<{ id: string }>): JSX.Element => {
  const { classes } = useStyles();
  const {
    match: {
      params: { id },
    },
  } = props;

  const query = useQuery({
    queryKey: ['reportId', id],
    queryFn: () => fetchReport(id),
  });
  const { data, isLoading, isError } = query;

  if (isLoading) {
    return (
      <PageLayout data-cname="ReportPage">
        {{
          content: (
            <Box className={classes.root}>
              <div
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CircularProgress />
              </div>
            </Box>
          ),
        }}
      </PageLayout>
    );
  }

  if (isError || !data) {
    return (
      <PageLayout data-cname="IncidentPage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title="Error" action="back" />,
        }}
      </PageLayout>
    );
  }

  const { data: report } = data;

  return (
    <PageLayout data-cname="ReportPage">
      {{ content: <Content report={report as ReportType} /> }}
    </PageLayout>
  );
};

export default Report;
