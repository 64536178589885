import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import HeaderBar from '../../components/HeaderBar';
import PageLayout from '../../components/PageLayout';
import ResetPasswordForm from './ResetPasswordForm';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('resetPassword.title')}</title>
      </Helmet>
      <ResetPasswordForm />
    </>
  );
};

const ResetPassword = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="ResetPasswordPage">
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar action="/login" title={t('resetPassword.title')} />
        ),
      }}
    </PageLayout>
  );
};

export default ResetPassword;
