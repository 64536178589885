import { forwardRef } from 'react';
import useMapLayersState from 'state/useMapLayersState';
import LegendDrawerContent from './legends/LegendDrawerContent';
import { LayerGroups } from './controls/LayerGroups';

const DrawerContent = forwardRef<HTMLDivElement>((_, ref): JSX.Element => {
  const { drawerContent } = useMapLayersState();

  if (drawerContent === 'legends') {
    return <LegendDrawerContent ref={ref} />;
  }
  return <LayerGroups ref={ref} />;
});

DrawerContent.displayName = 'DrawerContent';

export default DrawerContent;
