import { ReportAsset } from 'shared/types';

export const ASSET_OPTIONS: { transLabelKey: string; value: ReportAsset }[] = [
  {
    transLabelKey: 'addIncidentReport.inputs.asset.options.image',
    value: 'image',
  },
  {
    transLabelKey: 'addIncidentReport.inputs.asset.options.social',
    value: 'socialEmbed',
  },
  {
    transLabelKey: 'addIncidentReport.inputs.asset.options.alertCamera',
    value: 'alertCamera',
  },
];
