import { useEffect, useState } from 'react';
import { Stack, Typography, SliderOwnProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { getCameraAttributionRichText } from 'components/CameraDetails/CameraDetails.utils';
import { CameraImage } from 'components/CameraDetails/CameraImage';
import RichTextContent from 'components/RichTextContent';
import { AlertCamera } from 'hooks/useAlertCameras';
import {
  CameraTimeLapseFrameDetails,
  useAlertCameraTimelapseFrames,
} from 'hooks/useAlertCameraPlayer';
import usePrevious from 'hooks/usePrevious';
import { CameraSlider } from 'components/CameraDetails';

type CameraTimelapseProps = {
  camera: AlertCamera;
  onFrameSelect: (frame?: CameraTimeLapseFrameDetails) => void;
  initialFrameTs?: string;
};

const TIMELAPSE_REPLAY_SEC_PTZ = 60 * 15; // 15 minutes

const useStyles = makeStyles()((theme) => ({
  attribution: {
    '& p': {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.text.primary,
      margin: 0,
    },
  },
}));

/**
 * This component enables users to navigate through a selected camera's timelapse images.
 * Its logic is quite simple and distinct from the player component, rendering only necessary
 * elements. Unlike the more complex CameraPlayer, which includes player controls
 * and manages the camera player state, this component is purposely simpler. Keeping these
 * components separate is beneficial due to their distinct functionalities and complexity levels.
 */
export const CameraTimelapse = (props: CameraTimelapseProps): JSX.Element => {
  const { camera, onFrameSelect, initialFrameTs } = props;
  const [timelapseFrameNumber, setTimelapseFrameNumber] = useState(0);
  const [timelapseFrame, setTimelapseFrame] =
    useState<CameraTimeLapseFrameDetails | null>(null);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { isFetchingTimelapseFrames, timelapseFrames } =
    useAlertCameraTimelapseFrames(TIMELAPSE_REPLAY_SEC_PTZ, camera, true);
  const prevTimelapseFrames = usePrevious(timelapseFrames);

  useEffect(() => {
    if (!prevTimelapseFrames?.length && timelapseFrames.length) {
      const frameNumber = initialFrameTs
        ? Math.max(
            0,
            timelapseFrames.findIndex(
              (frame) => frame.imageTimestamp === initialFrameTs,
            ),
          )
        : timelapseFrames.length - 1;
      setTimelapseFrameNumber(frameNumber);
      setTimelapseFrame(timelapseFrames[frameNumber]);
      onFrameSelect(timelapseFrames[frameNumber]);
    }
  }, [prevTimelapseFrames, timelapseFrames, onFrameSelect, initialFrameTs]);

  const handleSliderChange: SliderOwnProps['onChange'] = async (
    _,
    value,
  ): Promise<void> => {
    const frameNumber = value as number;
    if (frameNumber < 0 || frameNumber >= timelapseFrames.length) return;
    setTimelapseFrameNumber(frameNumber);
    setTimelapseFrame(timelapseFrames[frameNumber]);
    onFrameSelect(timelapseFrames[frameNumber]);
  };

  return (
    <Stack spacing={1}>
      <CameraImage
        url={timelapseFrame?.imageUrl || camera.imageUrl}
        loading={isFetchingTimelapseFrames}
      />

      <RichTextContent
        content={getCameraAttributionRichText(camera)}
        className={classes.attribution}
      />

      <Typography>{t('addIncidentReport.inputs.alertCamera.frame')}</Typography>

      <CameraSlider
        color="accent"
        size="small"
        value={timelapseFrameNumber}
        onChange={handleSliderChange}
        min={0}
        max={Math.max(timelapseFrames.length - 1, 1)} // max at least 1 so that a value of zero displays correctly
        disabled={isFetchingTimelapseFrames}
      />
    </Stack>
  );
};
