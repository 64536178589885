import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import HeaderBar from 'components/HeaderBar';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import PageLayout from 'components/PageLayout';
import { NotificationSetting } from 'shared/types';
import { useNotificationsSettings } from 'hooks/useNotificationsSettings';
import EmptyNotifications from 'components/EmptyNotifications';
import Loader from 'components/Loader';
import NotificationsContent from './NotificationsContent';

type ContentProps = {
  regionSettings: NotificationSetting[];
  loading: boolean;
};

export const Content = (props: ContentProps): JSX.Element => {
  const { regionSettings, loading } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('notifications.notifications')}</title>
      </Helmet>
      <NotificationsContent regionSettings={regionSettings} />
      {loading && <Loader disableBackground />}
    </>
  );
};

const NotificationSettings = (): JSX.Element => {
  const { isPushEnabled, pushToken, subscribedNotificationSettings } =
    useNotificationsSettings();
  const { t } = useTranslation();

  // add `&& false` for local dev
  if (!isPushEnabled) {
    return (
      <PageLayout>
        {{
          content: <EmptyNotifications disabled />,
          headerBar: (
            <HeaderBar title="Notifications" action="back" hideAction />
          ),
        }}
      </PageLayout>
    );
  }

  if (!pushToken) {
    return (
      <PageLayout>
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar action="back" hideAction />,
        }}
      </PageLayout>
    );
  }

  const { data, isLoading, isError, isRefetching } =
    subscribedNotificationSettings;

  if (isLoading) {
    return (
      <PageLayout>
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: (
            <HeaderBar
              title={t('notifications.loading')}
              action="back"
              hideAction
            />
          ),
        }}
      </PageLayout>
    );
  }

  if (isError || !data) {
    return (
      <PageLayout>
        {{
          content: <LoadingAndErrors />,
          headerBar: (
            <HeaderBar
              title={t('notifications.error')}
              action="back"
              hideAction
            />
          ),
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {{
        content: <Content regionSettings={data.data} loading={isRefetching} />,
        headerBar: (
          <HeaderBar
            action="back"
            title={t('notifications.notifications')}
            hideAction
          />
        ),
      }}
    </PageLayout>
  );
};

export default NotificationSettings;
