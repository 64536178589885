import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useBlocker = ({
  shouldBlockNavigation,
  confirmationMessage,
  onConfirm,
}: {
  shouldBlockNavigation: boolean;
  confirmationMessage: string;
  onConfirm?: () => void;
}): void => {
  const history = useHistory();
  const location = useLocation();

  const locationPathname = location.pathname;

  useEffect(() => {
    const unblock = history.block((promptLocation) => {
      // @ts-expect-error pathname does exist on promptLocation
      const newLocationPathname = promptLocation.pathname;
      if (
        locationPathname !== newLocationPathname &&
        shouldBlockNavigation &&
        // do not block redirection to support us page
        !newLocationPathname.includes('support_us')
      ) {
        // eslint-disable-next-line no-alert
        if (window.confirm(confirmationMessage)) {
          unblock();
          if (onConfirm) onConfirm();
          history.push(newLocationPathname);
        } else {
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [
    confirmationMessage,
    history,
    locationPathname,
    onConfirm,
    shouldBlockNavigation,
  ]);
};
