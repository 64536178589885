import { Alert, AlertTitle } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GeoEvent, NotificationType } from 'shared/types';
import { PushNotificationPreview } from '../../../components/PushNotificationPreview';

type ReportNotificationPreviewProps = {
  geoEvent: GeoEvent;
};

const getNotificationTitle = (geoEvent: GeoEvent): string => {
  if (geoEvent.address) {
    return `${geoEvent.name} - ${geoEvent.address}`;
  }

  return geoEvent.name;
};

export const ReportNotificationPreview = (
  props: ReportNotificationPreviewProps,
): JSX.Element | null => {
  const { geoEvent } = props;
  const { t } = useTranslation();
  const { messageText, notificationType, status } = useWatch();

  if (notificationType === NotificationType.Normal && !!messageText) {
    return (
      <Grid item xs={12} sm={6}>
        <PushNotificationPreview
          title={getNotificationTitle(geoEvent)}
          body={messageText}
        />
      </Grid>
    );
  }

  if (notificationType === NotificationType.Silent) {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}
          align="center"
        >
          {t('addIncidentReport.silentNotificationMessage.title')}
        </Typography>
        <Typography align="center">
          {t('addIncidentReport.silentNotificationMessage.description')}
        </Typography>
      </Grid>
    );
  }

  if (status === 'inactive' && notificationType === NotificationType.Silent) {
    return (
      <Grid item xs={12}>
        <Alert severity="warning">
          <AlertTitle>
            {t('addIncidentReport.silentNotificationWarning.title')}
          </AlertTitle>
          <Typography>
            {t('addIncidentReport.silentNotificationWarning.description')}
          </Typography>
        </Alert>
      </Grid>
    );
  }

  return null;
};
