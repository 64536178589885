import { makeStyles } from 'tss-react/mui';
import { NotificationSetting } from 'shared/types';
import RegionList from 'components/RegionList';
import RegionItem from '../RegionItem';

type RegionCardProps = {
  title: string;
  regionSettings: NotificationSetting[];
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: theme.shape.borderRadius * 2.7,
    padding: theme.spacing(2, 0, 1),
  },
}));

const RegionCard = (props: RegionCardProps): JSX.Element => {
  const { regionSettings, title } = props;
  const { classes } = useStyles();

  return (
    <RegionList name={title} className={classes.container}>
      {regionSettings.map((regionSetting) => (
        <RegionItem
          key={regionSetting.region.id}
          regionSetting={regionSetting}
        />
      ))}
    </RegionList>
  );
};

export default RegionCard;
