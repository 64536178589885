import { useParams } from 'react-router-dom';
import { parseLatitudeParam, parseLongitudeParam } from 'shared/utils';

type UseMapURLParamsReturn =
  | {
      latitudeParam: number;
      longitudeParam: number;
      zoomLevelParam?: number;
    }
  | Record<string, never>;

export const useMapURLParams = (): UseMapURLParamsReturn => {
  // Routes.tsx -> <Route path="/@:lat,:lng,:zoom?"... />
  const params = useParams<{ lat: string; lng: string; zoom?: string }>();
  const { lat, lng, zoom } = params;

  const parsedLatitude = parseLatitudeParam(lat);
  const parsedLongitude = parseLongitudeParam(lng);
  const parsedZoomLevel = Number(zoom?.toLowerCase().replace('z', ''));

  if (!parsedLatitude || !parsedLongitude) {
    return {};
  }

  if (isNaN(parsedZoomLevel)) {
    return {
      latitudeParam: parsedLatitude,
      longitudeParam: parsedLongitude,
    };
  }

  return {
    latitudeParam: parsedLatitude,
    longitudeParam: parsedLongitude,
    zoomLevelParam: parsedZoomLevel,
  };
};
