import {
  SetterOrUpdater,
  atom,
  useRecoilState,
  useResetRecoilState,
} from 'recoil';
import { useCallback, useMemo } from 'react';
import { merge } from 'lodash-es';
import {
  saveData,
  clearDataForLogout,
  getAccessKey,
  getUserData,
  localStorageEffect,
  LOCAL_STORAGE_KEY,
} from './localStorage';
import { AuthState, DeepPartial, User } from '../shared/types';
import { userInGroups } from '../shared/utils';

export type PermissionData = {
  canModerate: boolean;
  canReport: boolean;
  isRxOnlyReporter: boolean;
  isInternalUser: boolean;
  isStaffReporter: boolean;
  canCreateLocation: boolean;
};

type UseAuthState = {
  isAuthenticated: boolean;
  user: User | null;
  hasActiveMembership: boolean;
  showMembershipFeatures: boolean;
  showMembershipProFeatures: boolean;
  isSupporter: boolean;
  permissions: PermissionData;
  loginSuccess: (
    key: string,
    userData: User,
    hasActiveMembership?: boolean,
  ) => void;
  setAuth: SetterOrUpdater<AuthState>;
  logout: () => void;
  updateAuth: (obj: DeepPartial<AuthState>) => void;
  isProMember: boolean;
};

export const getDefaultAuthState = (): AuthState => {
  const defaultAuthState: AuthState = {
    user: null,
    isAuthenticated: false,
    accessKey: null,
    hasActiveMembership: false,
  };

  /*
  The following is a migration that is needed in order to NOT log users
  out when they upgrade to the newwer recoil version.  This can
  probably be removed at the end 2022

  https://trello.com/c/ytCjqicY/308-finish-recoil-refactor

  */
  const accessKey = getAccessKey();
  if (accessKey) {
    defaultAuthState.accessKey = accessKey;
    defaultAuthState.user = getUserData();
  }

  return defaultAuthState;
};

export const authState = atom({
  key: 'AUTH_STATE',
  default: getDefaultAuthState(),
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.AUTH_STATE)],
});

const getPermissions = (user: User | null): PermissionData => {
  const canModerate = userInGroups(user, ['staff_reporters', 'reporters']);
  const canReport = userInGroups(user, [
    'staff_reporters',
    'reporters',
    'rx_reporters',
  ]);
  const canCreateLocation = userInGroups(user, [
    'staff_reporters',
    'reporters',
  ]);
  const isRxOnlyReporter = userInGroups(user, ['rx_reporters']);
  const isInternalUser = userInGroups(user, ['internal_users']);
  const isStaffReporter = userInGroups(user, ['staff_reporters']);
  return {
    canModerate,
    canReport,
    isRxOnlyReporter,
    isInternalUser,
    isStaffReporter,
    canCreateLocation,
  };
};

const useAuthState = (): UseAuthState => {
  const resetAuthState = useResetRecoilState(authState);
  const [auth, setAuth] = useRecoilState(authState);

  const updateAuth = useCallback(
    (obj: DeepPartial<AuthState>) => {
      setAuth((prev) => merge({}, prev, obj));
    },
    [setAuth],
  );

  const loginSuccess = useCallback(
    (key: string, userData: User, hasActiveMembership?: boolean) => {
      updateAuth({
        isAuthenticated: true,
        user: userData,
        accessKey: key,
        hasActiveMembership,
      });
      saveData(LOCAL_STORAGE_KEY.ACCESSKEY, key || null);
    },
    [updateAuth],
  );

  const logout = useCallback(() => {
    clearDataForLogout();
    resetAuthState();
    saveData(LOCAL_STORAGE_KEY.ACCESSKEY, null);
    window.location.reload();
  }, [resetAuthState]);

  const hasMembership = useCallback(() => {
    if (!auth.user) return false;
    // the hasActiveMembership key may exist on the auth model for legacy reasons if the user has not logged
    //   back in after we added the supporterStatus attributes
    return (
      auth.hasActiveMembership ||
      Boolean(auth.user?.supporterStatus?.hasActiveMembership)
    );
  }, [auth]);

  const showMembershipFeatures = useMemo(
    () =>
      hasMembership() ||
      userInGroups(auth.user, [
        'internal_users',
        'member_benefits',
        'staff_reporters',
        'rx_reporters',
        'reporters',
      ]),
    [auth, hasMembership],
  );

  const showMembershipProFeatures =
    (hasMembership() && auth.user?.supporterStatus?.planType === 'pro') ||
    userInGroups(auth.user, [
      'internal_users',
      'member_benefits',
      'staff_reporters',
      'rx_reporters',
      'reporters',
    ]);

  const isSupporter = useMemo(
    (): boolean =>
      hasMembership() ||
      auth.user?.supporterStatus?.isDonor ||
      userInGroups(auth.user, [
        'internal_users',
        'member_benefits',
        'staff_reporters',
        'rx_reporters',
        'reporters',
      ]),
    [auth, hasMembership],
  );
  const permissions = useMemo(() => getPermissions(auth.user), [auth.user]);

  const hasActiveMembership = hasMembership();

  const isProMember =
    hasActiveMembership && auth.user?.supporterStatus?.planType === 'pro';

  return {
    isAuthenticated: auth.isAuthenticated,
    user: auth.user,
    hasActiveMembership,
    isSupporter,
    showMembershipFeatures,
    showMembershipProFeatures,
    permissions,
    loginSuccess,
    setAuth,
    logout,
    updateAuth,
    isProMember,
  };
};

export default useAuthState;
