import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InboxMessageContent from 'components/InboxMessageContent';
import { InboxMessage } from 'shared/types';

type InboxMessagePopupProps = {
  open: boolean;
  onClose: () => void;
  message?: InboxMessage;
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    margin: theme.spacing(4, 3),
    borderRadius: theme.shape.borderRadius * 1.34,
  },
}));

const InboxMessagePopup = (props: InboxMessagePopupProps): JSX.Element => {
  const { open, onClose, message } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      data-testid="inbox-message-popup"
    >
      {message && <InboxMessageContent message={message} onClose={onClose} />}
    </Dialog>
  );
};

export default InboxMessagePopup;
