import {
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useMutation } from '@tanstack/react-query';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'cordova-plugin-purchase';
import BlackButton from 'components/BlackButton';
import { User } from 'shared/types';
import { useAuthState, useSnackbarState } from 'state';
import { API } from 'api';
import VectorLogo from 'components/VectorLogo';
import GrayButton from 'components/GrayButton';
import { useMembershipManagement } from 'hooks/useMembershipManagement';
import { MEMBERSHIP_PLANS } from '../../../constants';

type AccountContentProps = {
  user: User;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    overflow: 'auto',
    width: '100%',
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
  },
}));

const AccountContent = (props: AccountContentProps): JSX.Element => {
  const { user } = props;
  const { hasActiveMembership, logout } = useAuthState();
  const history = useHistory();
  const { setSnackbar } = useSnackbarState();
  const mutation = useMutation({
    mutationFn: () => API.delete(`users/${user.id}/`),
  });
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { hostedLink, isIosSubscription, isLoading } =
    useMembershipManagement();

  const deleteForm = async (): Promise<void> => {
    // eslint-disable-next-line
    const r = confirm('Are you sure you want to delete?');
    if (r === true) {
      await mutation.mutateAsync();
      history.push('/');
      logout();
      setSnackbar('Your account has been deleted', 'success');
      window.location.reload();
    }
  };

  const handleLogout = (): void => {
    history.push('/');
    logout();
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Box sx={{ flex: 1, width: '100%' }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box className={classes.logoContainer}>
                <VectorLogo sx={{ paddingTop: 3 }} />
              </Box>

              <Typography variant="h3" align="center">
                {t('myAccount.title')}
              </Typography>
            </Grid>

            <Grid item>
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  id="field-control-email"
                  fullWidth
                  name="email"
                  type="email"
                  label={t('myAccount.inputs.email.label')}
                  value={user?.email || ''}
                  disabled
                />
              </Box>
            </Grid>

            {hasActiveMembership && (
              <>
                <Grid item>
                  {hostedLink && (
                    <Button
                      fullWidth
                      className={classes.button}
                      component={Link}
                      to={{ pathname: hostedLink }}
                      target="_blank"
                      disabled={isLoading || !hostedLink}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('myAccount.buttons.manageMembership')
                      )}
                    </Button>
                  )}

                  {isIosSubscription && (
                    <GrayButton
                      fullWidth
                      className={classes.button}
                      onClick={() =>
                        CdvPurchase.store.manageSubscriptions(
                          CdvPurchase.Platform.APPLE_APPSTORE,
                        )
                      }
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('myAccount.buttons.manageMembership')
                      )}
                    </GrayButton>
                  )}
                </Grid>

                {user.supporterStatus?.planType === 'regular' && (
                  <Grid item>
                    <GrayButton
                      fullWidth
                      className={classes.button}
                      component={Link}
                      to={`/support_us/${MEMBERSHIP_PLANS.proYearly}`}
                    >
                      {t('myAccount.buttons.upgradeMembership')}
                    </GrayButton>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Button fullWidth className={classes.button} onClick={handleLogout}>
              {t('myAccount.buttons.logout')}
            </Button>
          </Grid>

          <Grid item>
            <BlackButton
              disabled={mutation.isPending}
              fullWidth
              onClick={deleteForm}
            >
              {mutation.isPending ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                t('myAccount.buttons.deleteAccount')
              )}
            </BlackButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AccountContent;
