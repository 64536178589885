import { useEffect, useRef, useState } from 'react';
import {
  OrientationLockType,
  ScreenOrientation,
} from '@capacitor/screen-orientation';

export const useDeviceOrientation = (): OrientationLockType => {
  const [orientation, setOrientation] = useState<OrientationLockType>('any');
  const isInitialOrientationSet = useRef(false);

  useEffect(() => {
    const getCurrentOrientation = async (): Promise<void> => {
      if (isInitialOrientationSet.current) return;
      const { type } = await ScreenOrientation.orientation();
      setOrientation(type);
      isInitialOrientationSet.current = true;
    };

    ScreenOrientation.addListener(
      'screenOrientationChange',
      async ({ type }) => {
        setOrientation(type);
        isInitialOrientationSet.current = true;
      },
    );

    getCurrentOrientation();

    return () => {
      ScreenOrientation.removeAllListeners();
    };
  }, []);

  return orientation;
};
