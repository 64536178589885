import { Capacitor } from '@capacitor/core';
import { Photo } from '@watchduty/camera';
import { getCameraAttributionRichText } from 'components/CameraDetails/CameraDetails.utils';
import { AlertCamera } from 'hooks/useAlertCameras';
import { ReportMediaForm } from 'shared/types';
import {
  formatFileUploadKey,
  getGPSDataFromFileAndroid,
  getGPSDataFromFileIOS,
  readFilePath,
} from 'shared/utils';

type ReportMediaInput = {
  fileType: string;
  mediaLat: string;
  mediaLng: string;
  mediaAz: number;
};

type ParsedFile = {
  fileType: string;
  file: Blob | File;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bearing?: any;
  lat?: string;
  lng?: string;
};

const formatReportMediaFormFromFile = (
  input: ReportMediaInput,
): ReportMediaForm => {
  const { fileType, mediaLat, mediaLng, mediaAz } = input;
  return {
    url: formatFileUploadKey(fileType),
    lat: mediaLat && mediaLat !== '' ? mediaLat : null,
    lng: mediaLng && mediaLng !== '' ? mediaLng : null,
    az: mediaAz ?? null,
    attribution: null,
  };
};

const formatReportMediaFormFromAlertCamera = (
  media: AlertCamera,
): ReportMediaForm => {
  return {
    url: null,
    lat: null,
    lng: null,
    az: null,
    attribution: getCameraAttributionRichText(media),
    sourceMediaUrl: media.imageUrl,
  };
};

export const formatReportMediaForm = (
  input: ReportMediaInput & { media: File | Blob | AlertCamera },
): ReportMediaForm => {
  const { media, ...rest } = input;
  if ('type' in media) {
    return formatReportMediaFormFromFile(rest);
  }
  return formatReportMediaFormFromAlertCamera(media);
};

export const parseFile = async (
  file: File | File[] | Photo,
): Promise<ParsedFile | Record<string, never>> => {
  if (Capacitor.getPlatform() === 'web') {
    // this is from the Web. We get an array, even though we are only handling 1 photo for now
    const webFile = Array.isArray(file) ? file[0] : file;

    if (!('type' in webFile)) return {};

    // type is the image mime type ie: 'image/jpeg'
    const imageTypeIndex = 1;
    const parsedFileType = webFile.type.split('/')[imageTypeIndex];

    return { fileType: parsedFileType, file: webFile };
  }

  if ('format' in file) {
    const gpsData =
      Capacitor.getPlatform() === 'ios'
        ? getGPSDataFromFileIOS(file)
        : getGPSDataFromFileAndroid(file);

    const { bearing, lat, lng } = gpsData;

    const res = await readFilePath(file);
    if (!res) return {};

    return {
      fileType: res.type,
      file: res.file,
      bearing,
      lat: lat?.toString(),
      lng: lng?.toString(),
    };
  }

  return {};
};
