import { useState, useCallback } from 'react';
import { useAuthState } from 'state';
import { MapLayerMouseEvent } from 'shared/map-exports';

export type ContextMenuCoordinatesType = {
  mouseX: number;
  mouseY: number;
  lat: number;
  lng: number;
};

type UseCtxMenuType = {
  contextMenuCoordinates: ContextMenuCoordinatesType | null;
  ctxMenuClose: () => void;
  openContextMenu: (event: MapLayerMouseEvent) => void;
};

const useContextMenu = (): UseCtxMenuType => {
  const { showMembershipProFeatures } = useAuthState();
  const [contextMenuCoordinates, setContextMenuCoordinates] =
    useState<ContextMenuCoordinatesType | null>(null);

  const ctxMenuClose = (): void => {
    setContextMenuCoordinates(null);
  };

  const openContextMenu = useCallback(
    (event: MapLayerMouseEvent): void => {
      event.preventDefault();
      if (showMembershipProFeatures) {
        setContextMenuCoordinates(
          contextMenuCoordinates === null
            ? {
                mouseX: event.originalEvent.clientX + 2,
                mouseY: event.originalEvent.clientY - 6,
                lat: event.lngLat.lat,
                lng: event.lngLat.lng,
              }
            : null,
        );
      }
    },
    [
      showMembershipProFeatures,
      contextMenuCoordinates,
      setContextMenuCoordinates,
    ],
  );

  return {
    ctxMenuClose,
    contextMenuCoordinates,
    openContextMenu,
  };
};
export default useContextMenu;
