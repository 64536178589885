import { ReactNode } from 'react';
import { useImageLoader } from 'hooks/useImageLoader';
import { CameraImage } from './CameraImage';

type CameraPreviewProps = {
  url: string;
  loading?: boolean;
  children?: ReactNode;
  mobileFullScreen?: boolean;
};

export const CameraPreview = (props: CameraPreviewProps): JSX.Element => {
  const { url, loading = false, children, mobileFullScreen = false } = props;

  const imageLoadStatus = useImageLoader(url);

  return (
    <>
      <CameraImage
        url={url}
        loading={loading || imageLoadStatus === 'loading'}
        mobileFullScreen={mobileFullScreen}
      />
      {children}
    </>
  );
};
