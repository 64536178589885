import { Box, Typography, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

type EmbedGeoEventContainmentProps = {
  isFps: boolean;
  containment: number | null;
};

const useStyles = makeStyles()((theme: Theme) => ({
  infoIcon: {
    width: 12,
    height: 12,
    marginLeft: theme.spacing(0.25),
  },
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const EmbedGeoEventContainment = (
  props: EmbedGeoEventContainmentProps,
): JSX.Element => {
  const { isFps, containment } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (isFps) {
    // This does not need a tooltip
    return (
      <Box className={classes.centeredContainer}>
        <Typography align="center">{t('geoEvent.stopped')}</Typography>
        <InfoOutlinedIcon fontSize="small" className={classes.infoIcon} />
      </Box>
    );
  }

  if (typeof containment === 'number') {
    return <Typography align="center">{`${containment}%`}</Typography>;
  }

  return <Typography align="center">-</Typography>;
};

export default EmbedGeoEventContainment;
