import { TextField } from '@mui/material';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldProps } from 'shared/types';

export const SocialEmbedField = <TName extends FieldPath<FieldValues>>(
  props: FormFieldProps<TName>,
): JSX.Element => {
  const { field, fieldState } = props;
  const { ref, ...muiFieldProps } = field;
  const { t } = useTranslation();
  const { formState } = useFormContext();

  return (
    <TextField
      id="field-control-embedUrl"
      label={t('addIncidentReport.inputs.embedUrl.label')}
      fullWidth
      {...muiFieldProps}
      inputRef={ref}
      error={!!fieldState.error}
      helperText={
        fieldState.error?.message ||
        t('addIncidentReport.inputs.embedUrl.helperText')
      }
      disabled={formState.isSubmitting}
    />
  );
};
