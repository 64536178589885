import {
  SliderOwnProps,
  Stack,
  StackOwnProps,
  Typography,
} from '@mui/material';
import { PlayerStatus } from 'hooks/useAlertCameraPlayer';
import { formatDate, getTimePass } from 'shared/dates';
import { CameraSlider } from './CameraSlider';

type TimelapseSliderProps = {
  imageTimestamp: Date;
  isLoading: boolean;
  playerStatus: PlayerStatus;
  timelapseFrameNumber: number;
  timelapseFramesLength: number;
  handleSliderChange: SliderOwnProps['onChange'];
  spacing?: StackOwnProps['spacing'];
  dark?: boolean;
};

export const TimelapseSlider = (props: TimelapseSliderProps): JSX.Element => {
  const {
    imageTimestamp,
    isLoading,
    playerStatus,
    timelapseFrameNumber,
    timelapseFramesLength,
    handleSliderChange,
    spacing,
    dark = false,
  } = props;

  const currentFrameTimeString = formatDate(imageTimestamp, 'MMM d hh:mm a');
  const currentFrameTimeRelativeString = ` • ${getTimePass(
    imageTimestamp.toISOString(),
  )}`;

  return (
    <Stack spacing={spacing} width="100%" sx={{ pointerEvents: 'auto' }}>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        paragraph
        marginTop={0.5}
        marginBottom={0.5}
        color={dark ? 'common.white' : 'text.primary'}
      >
        {currentFrameTimeString}
        {currentFrameTimeRelativeString}
      </Typography>

      <CameraSlider
        color="accent"
        size="small"
        value={timelapseFrameNumber}
        onChange={handleSliderChange}
        min={0}
        max={Math.max(timelapseFramesLength - 1, 1)} // max at least 1 so that a value of zero displays correctly
        disabled={isLoading || playerStatus === 'streamingLive'}
        sx={
          dark
            ? {
                '.MuiSlider-rail': {
                  color: 'grey.100',
                  opacity: 0.76, // 2x default opacity
                },
              }
            : undefined
        }
      />
    </Stack>
  );
};
