import { GeoEvent, MapSearchResult } from 'shared/types';
import {
  getRegionName,
  parseDMS,
  parseLatitudeParam,
  parseLongitudeParam,
} from 'shared/utils';

export const geoEventToMapSearchResult = (
  geoEvent: GeoEvent,
): MapSearchResult => {
  return {
    id: geoEvent.id.toString(),
    type: 'GeoEvent',
    text: geoEvent.name,
    placeName: getRegionName(geoEvent.regions),
    center: [geoEvent.lng, geoEvent.lat],
    original: geoEvent,
  };
};

/**
 * Parses the search term and returns either an object or a string.
 * It returns a string if it could not parse the search term into
 * latitude and longitude. It infers a negative value for longitude.
 * It can parse 'lat,lng' and DMS coordinates separated by a comma.
 *
 * @deprecated use getLatLngFromString() instead
 */
export const parseSearchTerm = (
  searchTerm: string,
): string | { latitude: number; longitude: number } => {
  const [rawLat, rawLng] = searchTerm.split(',').map((part) => part.trim());

  if (!rawLat || !rawLng) return searchTerm;

  const lat = parseLatitudeParam(rawLat);
  const lng = parseLongitudeParam(rawLng);

  if (lat && lng) return { latitude: lat, longitude: lng < 0 ? lng : lng * -1 };

  const { latitude, longitude } = parseDMS(searchTerm);

  if (latitude && longitude)
    return { latitude, longitude: longitude < 0 ? longitude : longitude * -1 };

  return searchTerm;
};
