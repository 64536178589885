import { History } from 'history';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type UseQueryParams<T extends Record<string, string | null>> = [
  params: T,
  setParams: (params: T) => void,
];

type NonNull<T> = {
  [K in keyof T]: Exclude<T[K], null | undefined>;
};

const stripEmptyParams = <T extends Record<string, string | null>>(
  params: T,
): NonNull<T> =>
  Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined && value !== '') {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {} as NonNull<T>);

const setParams = <T extends Record<string, string | null>>(
  history: History,
  params: T,
): void => {
  const paramsString = new URLSearchParams(stripEmptyParams(params)).toString();

  if (paramsString) {
    history.replace({ search: paramsString });
  } else {
    history.replace({ search: undefined });
  }
};

export const useQueryParams = <
  T extends Record<string, string | null>,
>(): UseQueryParams<T> => {
  const { search } = useLocation();
  const history = useHistory();
  const params = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search],
  ) as T;

  const setQueryParams = useCallback(
    (newParams: T) => {
      setParams(history, newParams);
    },
    [history],
  );

  return [params, setQueryParams] as const;
};
