import {
  useEditor,
  EditorContent,
  mergeAttributes,
  Extensions,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import { makeStyles } from 'tss-react/mui';
import { deepOrange } from '@mui/material/colors';
import { getResponsiveFontSize, parseContentToHTML } from 'shared/utils';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { UrlRegex } from '../../constants';

type RichTextContentProps = {
  content: string;
  className?: string;
};

// If testing locally, you may need to change this to 'https://localhost:3000/' or 'https://${IP_ADDR}:3000/'
const INTERNAL_MATCHING_URL = import.meta.env.VITE_BASE_WEB_URL;

const correctInsecureUrl = (href: string): string => {
  // eslint-disable-next-line no-script-url
  if (href.startsWith('javascript:')) {
    return '';
  }

  if (/^http:\/\//i.test(href)) {
    return href.replace(/^http:\/\//i, 'https://');
  }

  if (!/^https:\/\//i.test(href)) {
    return `https://${href}`;
  }

  return href;
};

const getTipTapExtensions = (history: History): Extensions => [
  StarterKit.configure({
    heading: false,
    blockquote: false,
    codeBlock: false,
    horizontalRule: false,
    code: false,
    history: false,
  }),
  Underline,
  Link.extend({
    inclusive: false,
    renderHTML({ HTMLAttributes }) {
      // Extended from https://github.com/ueberdosis/tiptap/blob/a70b19c0dca4367e5f882bb1692649269081a399/packages/extension-link/src/link.ts#L123

      const href = correctInsecureUrl(HTMLAttributes.href);
      const isInternalAppLink = href.startsWith(INTERNAL_MATCHING_URL);

      // Open internal links within the app
      if (isInternalAppLink) {
        const relativeHref = href.replace(INTERNAL_MATCHING_URL, '/');

        const elem = document.createElement('a');

        // Unfortunately we need to create the element manually to set a click event listener
        Object.entries(
          mergeAttributes(this.options.HTMLAttributes, {
            ...HTMLAttributes,
            href: relativeHref,
          }),
        ).forEach(([attr, val]) => elem.setAttribute(attr, val));

        // Intercept link click and use history.push to avoid reloading page
        elem.addEventListener('click', (e) => {
          e.preventDefault();
          history.push(relativeHref);
        });

        return elem;
      }

      return [
        'a',
        mergeAttributes(this.options.HTMLAttributes, {
          ...HTMLAttributes,
          href,
        }),
        0,
      ];
    },
  }).configure({
    openOnClick: false,
    autolink: true,
    validate: (href) => UrlRegex.test(href),
  }),
];

const useStyles = makeStyles()((theme) => ({
  editor: {
    '*': {
      fontFamily: theme.typography.fontFamily,
    },
    '& p': {
      fontSize: getResponsiveFontSize(theme.typography.pxToRem(16)),
    },
    '& a': {
      color: deepOrange[500],
    },
    '& li>p': {
      margin: 0,
    },
  },
}));

const RichTextContent = (props: RichTextContentProps): JSX.Element | null => {
  const { content, className } = props;
  const { classes, cx } = useStyles();
  const history = useHistory();

  const editor = useEditor(
    {
      editable: false,
      content: parseContentToHTML(content),
      extensions: getTipTapExtensions(history),
      editorProps: { attributes: { class: cx(classes.editor, className) } },
    },
    [content],
  );

  if (!editor) {
    return null;
  }

  return <EditorContent editor={editor} />;
};

export default RichTextContent;
