import { Slider } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const CameraSlider = withStyles(Slider, (theme) => ({
  root: {
    height: 8,
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  thumb: {
    width: 14,
    height: 14,
    border: '2px solid white',
    '&:before': {
      boxShadow: 'none',
    },
    boxShadow: theme.shadows[1],
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
    color: theme.palette.grey[400],
  },
}));
