import { Layer, LayerProps } from 'shared/map-exports';
import { useCallback } from 'react';
import addVisible from 'shared/addVisible';
import { usePoisState } from 'state/usePoisState';
import { PowerOutageIcon } from '../Icons';
import ArcGisLayer from './ArcGisLayer';
import { PowerOutageDialogContent } from '../PowerOutageDialogContent';
import { MapLayerProps, PowerOutageFeatureProperties } from '../types';
import { useMapLayerEvents } from './useMapLayerEvents';
import { powerOutagePropertiesToDialogProps } from '../PowerOutageDialogContent.utils';

const MIN_ZOOM_VISIBILITY = 13;
const LAYER_ID = 'power-outages-item';

const FEATURE_LAYER_URL =
  'https://services.arcgis.com/BLN4oKB0N1YSgvY8/ArcGIS/rest/services/Power_Outages_(View)/FeatureServer/0';

const getPowerOutageCircleBackgroundStyle = (
  selectedId: number | string,
): LayerProps => ({
  type: 'circle',
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'circle-radius': 14,
    'circle-color': [
      'case',
      ['==', ['get', 'OBJECTID'], selectedId],
      '#52E8EB',
      '#F7FB2D',
    ],
  },
  layout: {
    'circle-sort-key': ['get', 'sort'],
  },
});

const powerOutageCircleShadowStyle: LayerProps = {
  type: 'circle',
  minzoom: MIN_ZOOM_VISIBILITY,
  paint: {
    'circle-radius': 22,
    'circle-color': '#000',
    'circle-blur': 1.4,
  },
};

const powerOutagesSymbolStyle: LayerProps = {
  id: LAYER_ID,
  type: 'symbol',
  minzoom: MIN_ZOOM_VISIBILITY,
  layout: {
    'icon-image': PowerOutageIcon.name,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': PowerOutageIcon.offset,
    'icon-size': 1,
  },
};

export const PowerOutageItemLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();

  useMapLayerEvents<PowerOutageFeatureProperties>({
    layerId: LAYER_ID,
    onClick: useCallback(
      (geoJsonFeatures) => {
        for (const feature of geoJsonFeatures) {
          const { properties } = feature;

          setSelectedPoi({
            type: 'powerOutage',
            id: properties.OBJECTID,
            PoiDialogContent: () => {
              return (
                <PowerOutageDialogContent
                  {...powerOutagePropertiesToDialogProps(properties)}
                />
              );
            },
          });
        }
      },
      [setSelectedPoi],
    ),
  });

  const selectedID =
    selectedPoi?.type === 'powerOutage' ? selectedPoi.id || '' : '';

  return (
    <ArcGisLayer
      visible={visible}
      src={FEATURE_LAYER_URL}
      fields={[
        'Cause',
        'EstimatedRestoreDate',
        'ImpactedCustomers',
        'OBJECTID',
        'StartDate',
        'UtilityCompany',
      ]}
      precision={4}
    >
      <Layer {...addVisible(powerOutageCircleShadowStyle, visible)} />
      <Layer
        {...addVisible(
          getPowerOutageCircleBackgroundStyle(selectedID),
          visible,
        )}
      />
      <Layer {...addVisible(powerOutagesSymbolStyle, visible)} />
    </ArcGisLayer>
  );
};
