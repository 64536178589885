import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { PlaceIcon } from 'shared/types';
import { PlaceLocationPinIcon, getPlaceIcon } from './Icons';

type LocationMarkerProps = {
  name: string;
  placeIcon?: PlaceIcon;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pinContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeLabelContainer: {
    position: 'absolute',
    top: '90%',
    minWidth: 'max-content',
    maxWidth: 300,
    paddingTop: 3,
  },
  placeLabel: {
    textShadow:
      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
    fontWeight: 600,
  },
}));

const LocationMarker = (props: LocationMarkerProps): JSX.Element => {
  const { name, placeIcon } = props;
  const { classes } = useStyles();

  const icon = placeIcon ? getPlaceIcon(placeIcon) : null;

  return (
    <div className={classes.container}>
      <div className={classes.pinContainer}>
        <img
          src={PlaceLocationPinIcon.data}
          width={PlaceLocationPinIcon.width}
          height={PlaceLocationPinIcon.height}
          alt="location marker"
        />
        {!!icon && (
          <>
            <img
              src={icon.data}
              alt={name}
              width={icon.width}
              height={icon.height}
              style={{
                position: 'absolute',
                left: `calc(50% - ${icon.width / 2}px)`,
                top: `calc(50% - ${icon.height * 0.85}px)`,
              }}
            />

            <div className={classes.placeLabelContainer}>
              <Typography color="textPrimary" className={classes.placeLabel}>
                {name}
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LocationMarker;
