import { useCallback } from 'react';
import { Layer, MapGeoJSONFeature } from 'shared/map-exports';
import { EvacZoneStatus, LayerEvacZone } from 'shared/types';
import useMapLayersState from 'state/useMapLayersState';
import { usePoisState } from 'state/usePoisState';
import VisibilityToggledSource from '../VisibilityToggledSource';
import {
  SOURCE_ID,
  EVAC_ZONES_EDIT_FILL_LAYER_ID,
  FEATURE_UID_V2_FIELD_NAME,
} from './constants';
import {
  switchZoneStatus,
  getTilesUrl,
  createGroupings,
  createColorGroupings,
  getFillLayerId,
  createPatternGroupings,
} from './utils';
import {
  getFillLayerStyle,
  getLabelLayerStyle,
  getPerimeterLayerStyle,
  getUidFillStyle,
  getUidPatternStyle,
} from './layerStyles';
import { BEFORE_LAYER_ID } from '../../styles/constants';
import { useMapLayerEvents } from '../useMapLayerEvents';

type StructuredEvacuationsEditLayerProps = {
  visible: boolean;
  evacZones: LayerEvacZone[];
  onClick?: (zoneId: string, status?: EvacZoneStatus) => void;
  cacheBuster?: number;
};

/*
 * This layer is designed to facilitate the editing of evacuation zone statuses for incidents.
 * It provides interactive capabilities for updating zone statuses while maintaining
 * real-time visualization throughout the editing process.
 */
const StructuredEvacuationsEditLayer = (
  props: StructuredEvacuationsEditLayerProps,
): JSX.Element => {
  const { visible, evacZones, onClick, cacheBuster } = props;
  const { isDarkBaseLayer } = useMapLayersState();
  const { clearSelectedPois } = usePoisState();

  const clickCallback = useCallback(
    (features: MapGeoJSONFeature[]) => {
      // ensure other POIs are not opening up.
      clearSelectedPois();

      const feature = features[0];
      if (!feature || !feature.properties) return;

      const evacZone = evacZones.find((zone) => zone.uidV2 === feature.id);
      if (!evacZone) return;

      const currentStatus = evacZone.status;
      const status = switchZoneStatus(currentStatus);

      onClick && onClick(evacZone.uidV2, status);
    },
    [clearSelectedPois, evacZones, onClick],
  );

  useMapLayerEvents({
    layerId: EVAC_ZONES_EDIT_FILL_LAYER_ID,
    onClick: clickCallback,
  });

  const zonesWithStatus = evacZones.filter((evacZone) => !!evacZone.status);
  const zonesWithOutStatus = evacZones.filter((evacZone) => !evacZone.status);
  const zoneIdsWithOutStatus = zonesWithOutStatus.map(
    (evacZone) => evacZone.uidV2,
  );

  const allZoneIds = evacZones.map((evacZone) => evacZone.uidV2);
  const groups = createGroupings(zonesWithStatus);
  const colorGroups = createColorGroupings(groups);

  const colorKeys = Object.keys(colorGroups);
  colorKeys.forEach((color) => {
    const layerId = getFillLayerId(color);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMapLayerEvents({ layerId, onClick: clickCallback });
  });

  return (
    <VisibilityToggledSource
      url={getTilesUrl(cacheBuster)}
      visible={visible}
      promoteId={FEATURE_UID_V2_FIELD_NAME}
      // Do not unset `volatile` ever for this layer.
      volatile
      id={SOURCE_ID}
    >
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getFillLayerStyle(zoneIdsWithOutStatus)}
      />
      {Object.entries(colorGroups).map(([color, uids]) => (
        <Layer
          beforeId={BEFORE_LAYER_ID}
          key={color}
          id={getFillLayerId(color)}
          {...getUidFillStyle(color, uids)}
        />
      ))}
      {Object.entries(createPatternGroupings(groups)).map(([pattern, uids]) => (
        <Layer
          beforeId={BEFORE_LAYER_ID}
          key={pattern}
          {...getUidPatternStyle(pattern, uids)}
        />
      ))}
      <Layer {...getPerimeterLayerStyle(isDarkBaseLayer, allZoneIds)} />
      <Layer {...getLabelLayerStyle(allZoneIds)} />
    </VisibilityToggledSource>
  );
};

export default StructuredEvacuationsEditLayer;
