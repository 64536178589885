import { API } from 'api';
import { LocationData } from './types';

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const fetchRegions = () => API.get(`regions`);

export const postLocation = ({
  data,
  skipDuplicationCheck,
}: {
  data: LocationData;
  skipDuplicationCheck?: boolean;
  // todo: react-query function type returns can be addressed after react-query 5 upgrade
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
}) => {
  if (skipDuplicationCheck) {
    return API.post(`geo_events/?skip_duplication_check=true`, data);
  }
  return API.post(`geo_events`, data);
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const updateLocation = (data: { id: number; data: LocationData }) =>
  API.put(`geo_events/${data.id}`, data.data);
