import {
  useState,
  useCallback,
  useMemo,
  ChangeEvent,
  useRef,
  useEffect,
  forwardRef,
  Fragment,
} from 'react';
import {
  CircularProgress,
  TextField,
  Typography,
  Autocomplete,
  useMediaQuery,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import {
  SearchOutlined as SearchOutlinedIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import { debounce } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';
import useMapState from 'state/useMapState';
import { MapLocation, MapSearchApi, MapSearchResult } from 'shared/types';
import { useMapSearchState } from 'state/useMapSearchState';
import {
  getMapSearchResultFormattedAddress,
  getMapSearchResultName,
  mapSearchResultToMapLocation,
  getRandomId,
  getLatLngLocationName,
  isMobile,
} from 'shared/utils';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import useMapLayersState from 'state/useMapLayersState';
import { getGeoEventIcon } from '../Icons';
import { geoEventToMapSearchResult, parseSearchTerm } from './utils';
import useStyles from './styles';
import { fetchGeoEvents, fetchLocations, getGoogleCoords } from './api';
import SearchOptionItem from './SearchOptionItem';
import { getHighlightedSearchMatch } from '../../../hooks/useMapSearch';

/**
 * It's safe to ignore the following warning
 * "Material-UI: The value provided to Autocomplete is invalid.
 * None of the options match with...", we could fix it by adding
 * the "freeSolo" property to the autocomplete component, but that
 * would get rid of the "No Results found" message.
 */

type SearchBarProps = {
  onLocationChange?: (locationDetails: MapLocation | null) => void;
  fullScreenView?: boolean;
  formField?: boolean;
  drawerSearch?: boolean;
  label?: string;
  error?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onClear?: () => void;
  onCancel?: () => void;
  initialSearchTerm?: string;
  name?: string;
  includeGeoEvents?: boolean;
  returnLink?: string;
  searchApi?: MapSearchApi;
  placeholder?: string;
  initialIncidents?: MapSearchResult[];
  totalIncidents?: number;
  hasScrolled?: boolean;
};

type EndAdornmentProps = {
  loading: boolean;
  clearable: boolean;
  onClear: () => void;
  size?: 'small' | 'big';
};

const MIN_SEARCH_TERM_LENGTH = 3;

const EndAdornment = (props: EndAdornmentProps): JSX.Element | null => {
  const { loading, clearable, onClear, size = 'small' } = props;
  const { classes, cx } = useStyles();

  if (loading) {
    return <CircularProgress color="inherit" size={size === 'big' ? 20 : 14} />;
  }

  if (clearable) {
    return (
      <CancelIcon
        className={cx(
          classes.icon,
          classes.pointer,
          size === 'big' && classes.iconFullView,
        )}
        onClick={(e) => {
          e.stopPropagation();
          onClear();
        }}
        data-testid="clear-search"
      />
    );
  }

  return null;
};

/**
 * @deprecated by `useMapSearch()`. This component should be removed soon, and the
 * incident create/edit form search should be reimplemented using `useMapSearch()`.
 */
const SearchBar = forwardRef((props: SearchBarProps, ref): JSX.Element => {
  const {
    onLocationChange,
    fullScreenView = false,
    formField = false,
    drawerSearch = false,
    label,
    error = false,
    onBlur,
    initialSearchTerm = '',
    name = 'noAutoFill',
    includeGeoEvents = false,
    returnLink = '/',
    searchApi = 'mapbox',
    placeholder,
    onFocus,
    onCancel,
    initialIncidents = [],
    totalIncidents = 0,
    hasScrolled = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [focused, setFocused] = useState(false);
  const {
    value,
    results,
    pristine,
    setSearchResults,
    setSelectedValue,
    reset,
    setFormPristine,
  } = useMapSearchState();
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { incidentMapState } = useMapState();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('phone'));
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const { drawerOpen } = useMapLayersState();

  useEffect(() => {
    if (fullScreenView) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }

    return () => {
      if (!fullScreenView) reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenView]);

  useEffect(() => {
    if (initialSearchTerm) {
      // Update search map state with the initial search term
      setSelectedValue({
        id: '',
        type: 'Feature',
        address: '',
        text: initialSearchTerm,
        placeName: '',
        center: [],
      });
    }
  }, [setSelectedValue, initialSearchTerm]);

  const currentMapLat = incidentMapState.center.lat;
  const currentMapLng = incidentMapState.center.lng;

  const selectLatLngLocation = useCallback(
    (lat: number, lng: number) => {
      if (!onLocationChange) return;

      const locName = getLatLngLocationName(lat, lng);

      setSelectedValue({
        id: getRandomId().toString(),
        type: 'LatLngLocation',
        text: locName,
        placeName: '',
        center: [lng, lat],
      });

      inputRef.current?.blur();

      onLocationChange({
        name: locName,
        lat,
        lng,
        place: false,
        type: 'LatLngLocation',
      });
    },
    [onLocationChange, setSelectedValue],
  );

  const searchLocations = useCallback(
    async (term: string) => {
      const parsedSearchTerm = parseSearchTerm(term);
      if (typeof parsedSearchTerm !== 'string') {
        selectLatLngLocation(
          parsedSearchTerm.latitude,
          parsedSearchTerm.longitude,
        );
        return;
      }

      if (loading) return;

      setSearchResults([]);

      if (!term || term.length < MIN_SEARCH_TERM_LENGTH) return;

      setLoading(true);
      try {
        const proximity = `${currentMapLng},${currentMapLat}`;
        let data = [];

        if (includeGeoEvents) {
          const [res1, res2] = await Promise.allSettled([
            fetchGeoEvents(term),
            fetchLocations(term, proximity, searchApi),
          ]);

          const geoEvents = res1.status === 'fulfilled' ? res1.value : [];
          const locations = res2.status === 'fulfilled' ? res2.value : [];

          const geoEventLocations = geoEvents.map(geoEventToMapSearchResult);
          data = geoEventLocations.concat(locations);
        } else {
          data = await fetchLocations(term, proximity, searchApi);
        }

        setSearchResults(data);
        if (!open && !value?.id) setOpen(true);
      } catch (err) {
        Sentry.captureException(err);
      }
      setLoading(false);
    },
    [
      loading,
      setSearchResults,
      selectLatLngLocation,
      currentMapLng,
      currentMapLat,
      includeGeoEvents,
      open,
      value?.id,
      searchApi,
    ],
  );

  const handleSearchLocations = useMemo(
    () => debounce(searchLocations, 500),
    [searchLocations],
  );

  const handleSearchTermChange = (
    newInputValue: string,
    reason: AutocompleteInputChangeReason,
  ): void => {
    setSearchTerm(newInputValue);
    if (reason === 'input') {
      // Only trigger a search when the user is typing
      handleSearchLocations(newInputValue);
    }
    if (pristine) {
      setFormPristine(false);
    } else if (!newInputValue) {
      setFormPristine(true);
    }
  };

  const handleOnValueChange = async (
    _: ChangeEvent<object>,
    newValue: string | MapSearchResult | null,
  ): Promise<void> => {
    if (typeof newValue === 'string') return;

    if (newValue === null) {
      setSelectedValue(newValue);
      return;
    }

    let mapLocation: MapSearchResult = { ...newValue };

    if (mapLocation.type === 'GeoEvent') {
      if (drawerSearch && onLocationChange) {
        inputRef.current?.blur();
        onLocationChange(mapSearchResultToMapLocation(mapLocation));
      } else {
        history.push(`/i/${mapLocation.id}`);
      }
      return;
    }

    if (mapLocation.center.length === 0) {
      // Map search result is a google place prediction, get place by id
      const response = await getGoogleCoords(mapLocation.id);
      if (response) {
        mapLocation = {
          ...mapLocation,
          center: response.coords,
          placeAddress: response.formattedAddress,
        };
      }
    }

    setSelectedValue(mapLocation);

    if (mapLocation && onLocationChange) {
      inputRef.current?.blur();
      onLocationChange(mapSearchResultToMapLocation(mapLocation));
    }
  };

  const handleClear = (): void => {
    reset();
    setSearchTerm('');
    setOpen(false);
    if (!fullScreenView && onLocationChange) onLocationChange(null);
  };

  const handleOpen = (): void => {
    if (isPhone && !fullScreenView && !formField && !drawerSearch) {
      // We're on small devices and not on "fullScreenView" | "drawerSearch" mode, i.e. main map view on mobile (native or web)
      reset();
      history.push('/search_results', { returnLink });
    } else if (results.length) {
      setOpen(true);
    }
  };

  const getListBoxClassName = (): string | undefined => {
    if (drawerSearch) return classes.listboxDrawerSearch;
    if (!fullScreenView) return undefined;
    if (!isMobile()) return classes.listboxFullView;
    return !focused ? classes.listboxFullView : undefined;
  };

  const getPlaceholder = (): string | undefined => {
    if (placeholder) return placeholder;
    if (formField) return undefined;

    if (includeGeoEvents) {
      return t('searchBar.geoEventsPlaceholder');
    }
    return t('searchBar.placeholder');
  };

  const withInitialOptions = initialIncidents.length > 0;

  return (
    <Autocomplete
      classes={{
        root: cx(
          classes.root,
          open &&
            !loading &&
            !fullScreenView &&
            !formField &&
            !drawerSearch &&
            classes.rootOpen,
          fullScreenView && classes.rootFullView,
          formField && classes.rootFormField,
          drawerSearch && classes.rootDrawerSearch,
          drawerSearch && hasScrolled && classes.rootDrawerSearchWithShadow,
        ),
        inputRoot: cx(
          classes.inputRoot,
          fullScreenView && classes.inputRootFullView,
          formField && classes.inputRootFormField,
          drawerSearch && classes.inputRootDrawerSearch,
        ),
        input: cx(
          classes.input,
          fullScreenView && classes.inputFullView,
          formField && classes.inputFormField,
        ),
        popper: cx(
          loading && classes.hide,
          fullScreenView && classes.popperFullView,
          drawerSearch && classes.popperDrawerSearch,
        ),
        paper: cx(
          classes.paper,
          fullScreenView && classes.paperFullView,
          formField && classes.paperFormField,
          drawerSearch && classes.paperDrawerSearch,
        ),
        noOptions: classes.noOptions,
        option: cx(
          classes.option,
          drawerSearch ? classes.optionDrawerSearch : undefined,
        ),
        listbox: getListBoxClassName(),
      }}
      ref={containerRef}
      disablePortal={fullScreenView || drawerSearch}
      open={!searchTerm && withInitialOptions ? true : open}
      value={value}
      onChange={handleOnValueChange}
      onOpen={handleOpen}
      onClose={
        fullScreenView || drawerSearch ? undefined : (): void => setOpen(false)
      }
      isOptionEqualToValue={(option, selectedValue): boolean =>
        option.id === selectedValue.id
      }
      getOptionLabel={(option): string => {
        if (typeof option === 'string') return '';
        return getMapSearchResultName(option);
      }}
      options={!searchTerm ? initialIncidents : results}
      loading={loading}
      filterOptions={(options): MapSearchResult[] => options}
      noOptionsText={
        searchTerm.length >= MIN_SEARCH_TERM_LENGTH &&
        (drawerSearch ? (
          <>
            <Typography sx={{ paddingTop: 1 }}>
              {t('searchBar.noResults')}
            </Typography>
          </>
        ) : (
          t('searchBar.noResults')
        ))
      }
      clearOnBlur={false}
      onInputChange={(_, newInputValue, reason): void =>
        handleSearchTermChange(newInputValue, reason)
      }
      onScroll={fullScreenView ? (e): void => e.preventDefault() : undefined}
      // Needed for "clear" input use case
      inputValue={searchTerm}
      onFocus={() => {
        setFocused(true);
        if (onFocus) onFocus();
      }}
      onBlur={() => {
        setFocused(false);
        if (onBlur) onBlur();
      }}
      // Fixes bug where the input is focused when selecting a geo event or opening the
      // layers/legend drawer causing the bottom sheet modal to open on mobile (iOS only).
      disabled={!!selectedGeoEventId || drawerOpen}
      renderInput={(params): JSX.Element => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <TextField
                {...params}
                name={name}
                inputRef={formField ? ref : inputRef}
                variant={formField ? 'outlined' : 'standard'}
                label={label}
                placeholder={getPlaceholder()}
                InputProps={{
                  ...params.InputProps,
                  startAdornment:
                    fullScreenView || formField ? undefined : (
                      <SearchOutlinedIcon className={classes.icon} />
                    ),
                  endAdornment: !searchTerm ? null : (
                    <EndAdornment
                      loading={loading}
                      clearable={
                        results.length > 0 ||
                        searchTerm.length >= MIN_SEARCH_TERM_LENGTH
                      }
                      onClear={handleClear}
                      size={fullScreenView || formField ? 'big' : 'small'}
                    />
                  ),
                  ...(formField ? {} : { disableUnderline: true }),
                }}
                error={error}
              />
            </Box>
            {drawerSearch && focused && (
              <Button
                sx={(th) => ({
                  padding: '2px 2px',
                  marginLeft: '12px',
                  textTransform: 'none',
                  fontSize: th.typography.subtitle1.fontSize,
                  fontWeight: th.typography.fontWeightBold,
                  minWidth: 'fit-content',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  color: th.palette.accent.main,
                })}
                disableRipple
                onClick={() => {
                  handleClear();
                  setTimeout(() => {
                    inputRef.current?.blur();
                    if (onCancel) onCancel();
                  }, 0);
                }}
              >
                {t('common.cancel')}
              </Button>
            )}
          </Box>

          {drawerSearch && !loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 3,
              }}
            >
              <Typography variant="h3">
                {!searchTerm && withInitialOptions && t('searchBar.incidents')}
                {(searchTerm.length >= MIN_SEARCH_TERM_LENGTH ||
                  results.length > 0) &&
                  t('searchBar.results')}
              </Typography>

              <Typography variant="body2" color="secondary">
                {!searchTerm &&
                  withInitialOptions &&
                  t('searchDrawer.lastUpdate')}
              </Typography>
            </Box>
          )}
        </>
      )}
      renderOption={(optionProps, option): JSX.Element => {
        const optionName = getMapSearchResultName(option);
        const icon =
          option.type === 'GeoEvent' && option.original
            ? getGeoEventIcon(option.original)
            : undefined;

        return (
          <SearchOptionItem
            key={`${option.id}-${option.type}`}
            optionName={optionName}
            primaryText={getHighlightedSearchMatch(searchTerm, optionName)}
            secondaryText={getMapSearchResultFormattedAddress(
              optionName,
              option.placeName,
            )}
            drawerItem={drawerSearch}
            optionProps={optionProps}
            imgAlt={option.text}
            icon={icon}
            lastUpdatedAt={
              withInitialOptions ? option.original?.dateModified : undefined
            }
          />
        );
      }}
      groupBy={() => ''}
      renderGroup={(params) => {
        return (
          <Fragment key="render-group">
            <li>
              <ul style={{ paddingLeft: 0 }}>{params.children}</ul>
            </li>

            {drawerSearch && withInitialOptions && results.length === 0 && (
              <Box component="li" className={classes.footer}>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  sx={{ fontWeight: 'bold' }}
                >
                  {t('searchDrawer.geoEventsCount', {
                    visible: initialIncidents.length,
                    total: totalIncidents,
                  })}
                </Typography>
              </Box>
            )}
          </Fragment>
        );
      }}
    />
  );
});

SearchBar.displayName = 'SearchBar';

export default SearchBar;
