import { App as CapacitorApp } from '@capacitor/app';
import { createRoot } from 'react-dom/client';
import { Capacitor } from '@capacitor/core';
import * as SentryReact from '@sentry/react';
import { rewriteFramesIntegration } from '@sentry/react';
import * as Sentry from '@sentry/capacitor';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import './css/react-spring-bottom-sheet.css';
import { applyPolyfills } from './shared/polyfills';

import App from './App';
import './css/index.css';
import history from './shared/history';
import { setDeviceInfo } from './shared/mobile';

// import for bundling
import './i18n/config';

applyPolyfills();

const handleAndroidBackButton = (): void => {
  if (window.location.pathname !== '/') {
    history.back();
    return;
  }

  // Back button on home page closes app
  CapacitorApp.exitApp();
};

const modifyStacktraceFilenames = (
  frame: Sentry.StackFrame,
): Sentry.StackFrame => {
  // This updates the stacktrace filenames to help point to our map files
  if (!frame.filename) {
    return frame;
  }
  // eslint-disable-next-line
  frame.filename = frame.filename.replace(/^.*\/assets\//, '/assets/');
  return frame;
};

const SENTRY_IGNORE_LIST = [
  // This is a known error that android raises when using the AppUpdate capacitor plugin.
  'Install Error(',
  // This is raised by appupdate logic as well
  'Failed to bind to the service.',
  // Axios generated
  'Network Error',
  // This is raised by axios for bad reponse codes, but we have no information on what failed
  'Request failed with status code',
  // Catchall for random network errors in axios and other libraries
  'Failed to fetch',
  // This is a capacitor exception only raised in ios, not sure what it is, but doesn't seem to affect users
  'The file "lookup"',
  'The file “lookup”', // beware of smart quotes from iOS >:(
  // Nothing actionable here
  'Failed to bind to the service.',
  // Nothing actionable here
  'Load failed',
  // Nothing actionable here
  'not available',
  // Nothing actionable here
  'ResizeObserver loop completed with undelivered notifications.',
  // Empty tiles from our custome tilesets
  /AJAXError:\s*\(403\):\s*https:\/\/(staging\.)?tiles\.watchduty\.org\/maptiles\//,
  // Safari Webkit bug - https://github.com/mapbox/mapbox-gl-js/issues/8480, https://bugs.webkit.org/show_bug.cgi?id=215771
  'Fetch is aborted',
];

const initSentry = (): void => {
  if (!import.meta.env.VITE_SENTRY_DSN) {
    return;
  }
  // This prefix tells sentry parse the relative paths of our source mappings with this prefix + relative path
  const isNative = Capacitor.isNativePlatform();
  // We could use package.version but since we're not updating that currently - lets include the release shah
  //   After enabling on native - we should use CapacitorApp.getInfo() for native app version
  // only giving a release shah for production because it clutters up our "releases" when every local build
  //   pushes a new one if the environment is staging
  const release =
    import.meta.env.VITE_ENV === 'production'
      ? import.meta.env.VITE_GIT_SHA
      : 'pre-release-testing';
  Sentry.init(
    {
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV,
      release,
      // https://github.com/getsentry/sentry-capacitor/issues/63
      enableNative: isNative,
      integrations: [
        rewriteFramesIntegration({ iteratee: modifyStacktraceFilenames }),
      ],
      ignoreErrors: SENTRY_IGNORE_LIST,
      dist: '1', // we only vary release, not the dist. This matches the dist for source map bundles we upload,
      enableCaptureFailedRequests: false,
    },
    // Forward the init method from @sentry/react
    SentryReact.init,
  );
  Sentry.setTag('isNative', isNative);
};

const init = async (): Promise<void> => {
  initSentry();
  await setDeviceInfo();
  defineCustomElements(window);

  CapacitorApp.addListener('backButton', handleAndroidBackButton);
  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    /**
     * Strict mode only affects development builds, and it's currently breaking react-spring-bottom-sheet lib.
     * We can either disable it or be more specific in the components we want this feature on.
     * We should probably replace react-spring-bottom-sheet with a newer library that supports React v18.
     * Check this discussion for more context - https://github.com/facebook/react/issues/16362
     */
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>
  );
};

init();
