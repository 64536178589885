/* eslint-disable react/no-unused-prop-types */
import { FC, SVGProps, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Map, ControlPosition, useControl } from 'shared/map-exports';
import { NearMeOutlined, NearMe } from '@mui/icons-material';
import { debounce } from 'lodash-es';
import NearMeDisabledOutLined from 'assets/near_me_disabled.svg?react';
import { UserPosition } from 'shared/types';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import MapControlButton from './MapControlButton';
import BaseMapboxGLController from './BaseMapboxGLController';

type GeolocateControlProps = {
  userPosition: UserPosition;
  position?: ControlPosition | undefined;
  map?: Map;
};

const floatsAreEqual = (a: number, b: number, tolerance: number): boolean =>
  Math.abs(a - b) < tolerance;

const GeolocateControl = (props: GeolocateControlProps): null => {
  const { position } = props;
  // @ts-ignore - https://github.com/visgl/deck.gl/issues/9211
  const controller = useControl(() => new MapboxGLGeolocateController(props), {
    position,
  }) as MapboxGLGeolocateController;

  controller.update(props);
  useEffect(() => controller.render(), [controller, props]);
  return null;
};

class MapboxGLGeolocateController extends BaseMapboxGLController<GeolocateControlProps> {
  content = (): JSX.Element => (
    <GeolocateControlContent map={this.map} {...this.props} />
  );
}

const GeolocateControlContent = (props: GeolocateControlProps): JSX.Element => {
  const { map, userPosition } = props;
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const { lat, lng, locationEnabled, getUserPosition } = userPosition;

  useEffect(() => {
    if (!active || !lat || !lng) return;

    map?.easeTo({ center: [lng, lat], zoom: 13 });
  }, [active, lat, lng, map]);

  const updateUserPosition = useCallback(async () => {
    if (locationEnabled && lng && lat) {
      map?.easeTo({ center: [lng, lat], zoom: 13 });
    }

    await getUserPosition();

    if (!active) setActive(true);
  }, [active, getUserPosition, lat, lng, locationEnabled, map]);

  const handleClick = useMemo(
    () => debounce(updateUserPosition, 350),
    [updateUserPosition],
  );

  const mapCenter = map?.getCenter();

  const haveUserPosition = userPosition?.lat && userPosition?.lng;

  const isAtUserPosition =
    floatsAreEqual(userPosition?.lat ?? 0, mapCenter?.lat ?? 0, 0.0001) &&
    floatsAreEqual(userPosition?.lng ?? 0, mapCenter?.lng ?? 0, 0.0001);

  const getLocationIconComponent = ():
    | FC<SVGProps<SVGSVGElement>>
    | OverridableComponent<SvgIconTypeMap<object, 'svg'>> => {
    if (!haveUserPosition || !locationEnabled) {
      return NearMeDisabledOutLined;
    }

    if (!isAtUserPosition) {
      return NearMeOutlined;
    }

    return NearMe;
  };

  return (
    <MapControlButton
      text={t('map.controls.nearMe')}
      onClick={handleClick}
      Icon={getLocationIconComponent()}
    />
  );
};

export default GeolocateControl;
