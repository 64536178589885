import { AlertCamera, AlertCameraGroup } from './useAlertCameras.types';

type LatLngToCamera = {
  [key: string]: AlertCamera[];
};

/**
 * Group cameras by their lat/lng coordinates so we can display them as related
 * cameras on the map. This also updates cameras with more recent realtime data
 * (azimuth, FOV) so that all rendered icons and viewsheds are painted with the
 * current positioning.
 */
export const groupCamerasByLocation = (
  cameras: AlertCamera[],
): AlertCameraGroup[] => {
  const latLngToCamera: LatLngToCamera = cameras.reduce(
    (acc, camera: AlertCamera) => {
      const key = `${camera.approximateLatlng.lat},${camera.approximateLatlng.lng}`;

      if (acc[key]) {
        acc[key] = [...acc[key], camera];
      } else {
        acc[key] = [camera];
      }

      return acc;
    },
    {},
  );

  return Object.values(latLngToCamera).map((camerasAtLatLng: AlertCamera[]) => {
    const groupId = camerasAtLatLng
      .map((cam) => cam.id)
      .sort()
      .join('-');

    // Set the latlng for all cameras to that of the first camera in the group.
    // This ensures that camera ptz arrows and view sheds are drawn at the correct location.
    const { latlng } = camerasAtLatLng[0];

    const cameraGroup = camerasAtLatLng.map((camera) => ({
      ...camera,
      latlng,
      cameraGroupId: groupId,
    }));

    return {
      id: groupId,
      latlng,
      hasPtz: camerasAtLatLng.some((camera) => camera.hasPtz),
      cameras: cameraGroup,
    };
  });
};
