import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { API, CacheAPI } from 'api';
import { WildfireListItem, LocationListItem } from 'shared/types';
import { useAuthState, useCacheState } from 'state';
import { MAIN_REFETCH_INTERVAL_MS } from 'components/Map/constants';
import { useSelectedWildfireGeoEventId } from './useSelectedWildfireGeoEventId';

type UseGeoEventsProps = {
  enabled?: boolean;
};

type UseGeoEvents = {
  allGeoEvents: (WildfireListItem | LocationListItem | undefined)[];
  wildfireEvents: WildfireListItem[];
  locations: LocationListItem[];
};

type GeoEventResponseList = Array<WildfireListItem | LocationListItem>;

const GEO_EVENTS_URL = 'geo_events/';

const getStaleTime = (canReport: boolean): number | undefined => {
  // don't cache anything for reporters on the main map
  if (canReport) {
    return undefined;
  }
  return MAIN_REFETCH_INTERVAL_MS;
};

const getRefetchInterval = (
  canReport: boolean,
  hasSelectedGeoEvent: boolean,
): number | false => {
  if (canReport) {
    // 30 seconds
    return 30 * 1000;
  }
  if (hasSelectedGeoEvent) {
    return false;
  }
  return MAIN_REFETCH_INTERVAL_MS;
};

const fetchGeoEvents = (
  cacheBusterTs: number | null,
): Promise<AxiosResponse<GeoEventResponseList>> => {
  const params = {
    geoEventTypes: '*',
    ...(cacheBusterTs ? { ts: cacheBusterTs } : {}),
  };

  if (cacheBusterTs) {
    return CacheAPI.get<GeoEventResponseList>(GEO_EVENTS_URL, {
      params,
    });
  }

  return API.get<GeoEventResponseList>(GEO_EVENTS_URL, {
    params,
  });
};

export const useGeoEvents = (props: UseGeoEventsProps = {}): UseGeoEvents => {
  const { enabled = true } = props;
  const { cacheBusterTs } = useCacheState();
  const {
    permissions: { canReport },
  } = useAuthState();
  const selectedGeoEventId = useSelectedWildfireGeoEventId();

  const { data: results } = useQuery({
    queryKey: ['geoEvents', cacheBusterTs],
    queryFn: () => fetchGeoEvents(cacheBusterTs),
    staleTime: getStaleTime(canReport),
    refetchInterval: () => getRefetchInterval(canReport, !!selectedGeoEventId),
    enabled,
  });

  const data: (WildfireListItem | LocationListItem)[] = useMemo(
    () => results?.data ?? [],
    [results],
  );

  const { wildfireEvents, locations } = useMemo(() => {
    const fireEvents = data.filter(
      (item): item is WildfireListItem => item?.geoEventType === 'wildfire',
    );

    const locationEvents = data.filter(
      (item): item is LocationListItem => item?.geoEventType === 'location',
    );

    return {
      wildfireEvents: fireEvents,
      locations: locationEvents,
    };
  }, [data]);

  return { allGeoEvents: data, wildfireEvents, locations };
};
