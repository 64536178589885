import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CameraIcon from '@mui/icons-material/CameraAlt';
import { useHistory } from 'react-router-dom';
import { getDeviceInfo } from 'state/localStorageTyped';

type ButtonCameraProps = {
  geoEventId?: number;
};

const useStyles = makeStyles()((theme) => ({
  menu: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius * 2,
    height: 42,
    width: 42,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  icon: {
    width: 24,
    height: 24,
  },
}));

const ButtonCamera = (props: ButtonCameraProps): JSX.Element | null => {
  const { geoEventId } = props;
  const { classes } = useStyles();
  const history = useHistory();
  const deviceInfo = getDeviceInfo();

  const handleCreateReport = (): void => {
    if (geoEventId) {
      history.push(`/add_report/${geoEventId}`);
    } else {
      history.push(`/add_report`);
    }
  };

  // For non-staff users, this should only be visible in native
  if (deviceInfo?.isWeb) {
    return null;
  }

  return (
    <IconButton
      color="primary"
      onClick={handleCreateReport}
      className={classes.button}
      size="medium"
      aria-label="submit a photo"
    >
      <CameraIcon fontSize="small" className={classes.icon} />
    </IconButton>
  );
};

export default ButtonCamera;
