import { ReactNode } from 'react';
import { Source } from 'shared/map-exports';
import * as ESRI from '@esri/arcgis-rest-feature-service';
import { FeatureCollection, Feature } from 'geojson';
// @ts-ignore
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';
import useTiledQueries, { Geometry } from './useTiledQueries';

type ArcGisLayerProps = {
  visible: boolean;
  src: string;
  children: ReactNode;
  where?: string;
  orderBy?: string;
  precision?: number;
  attribution?: string;
  staleTime?: number;
  fields?: string[];
  tileSize?: number;
  postProcessDataFn?: (data: FeatureCollection) => FeatureCollection;
  // eslint-disable-next-line react/no-unused-prop-types
  onData?: (data: FeatureCollection) => FeatureCollection;
  // eslint-disable-next-line react/no-unused-prop-types
  cacheBusterTs?: number | null;
};

const DEFAULT_LAYER_TIMEOUT_MS = 86400 * 1000; // 1 day

// Size of one edge of tile (in degrees lat/lon) when fetching data from ArcGIS.
// The smaller the number, the more REST queries are issued with fewer results returned in each.
const DEFAULT_TILE_SIZE_DEG = 5.0;

// WGS-84 coordinate system for results. See: https://spatialreference.org/ref/epsg/wgs-84/
const WKID = 4326;

const mergeGeojson = (data: FeatureCollection[]): FeatureCollection => {
  const allFeatures = data.reduce(
    (a, b) => a.concat(b.features),
    [] as Feature[],
  );
  const allFeaturesUnique = [
    ...new Map(allFeatures.map((item) => [item.id, item])).values(),
  ];
  return {
    type: 'FeatureCollection',
    features: allFeaturesUnique,
  };
};

const fetchGeojson = async (
  props: ArcGisLayerProps,
  tileGeometry: Geometry,
): Promise<FeatureCollection> => {
  const { onData = (data) => data } = props;
  const json = await fetchFeatures(props, tileGeometry);
  return onData(json);
};

const fetchFeatures = async (
  props: ArcGisLayerProps,
  tileGeometry: Geometry,
): Promise<FeatureCollection> => {
  const { src, orderBy, precision, fields = [], cacheBusterTs } = props;
  let { where = '1=1' } = props;
  if (cacheBusterTs) {
    // https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/queryfeatures-returns-cached-features/td-p/1224288
    const nowEq = `${cacheBusterTs}=${cacheBusterTs}`;
    if (where === '1=1') {
      where = nowEq;
    } else {
      where += ` AND ${nowEq}`;
    }
  }

  const queryGeometry: ESRI.IGeometry = {
    ...tileGeometry,
    spatialReference: { wkid: WKID },
  };

  // The OBJECTID is required so that `mergeGeojson()` can refer
  // to it and remove duplicate objects.
  if (!fields.includes('OBJECTID') && !fields.includes('FID'))
    fields.push('OBJECTID');

  const result = await ESRI.queryFeatures({
    url: src,
    where,
    returnGeometry: true,
    inSR: { wkid: WKID },
    outSR: { wkid: WKID },
    resultType: 'tile',
    geometryType: 'esriGeometryEnvelope',
    spatialRel: 'esriSpatialRelIntersects',
    geometry: queryGeometry,
    outFields: fields,
    geometryPrecision: precision,
    orderByFields: orderBy,
    f: 'json',
  });

  const geojson = arcgisToGeoJSON(result);
  return geojson as FeatureCollection;
};

const ArcGisLayer = (props: ArcGisLayerProps): JSX.Element => {
  const {
    src,
    visible,
    children,
    where = '1=1',
    orderBy,
    precision,
    attribution = '',
    staleTime = DEFAULT_LAYER_TIMEOUT_MS,
    fields,
    tileSize = DEFAULT_TILE_SIZE_DEG,
    postProcessDataFn,
  } = props;
  if (!Number.isInteger(tileSize))
    throw Error('tileSize prop must be an integer');

  const data = useTiledQueries({
    enabled: visible,
    tileSize,
    staleTime,
    queryKey: [src, where, orderBy, fields, precision],
    queryFn: (tileGeometry) => fetchGeojson(props, tileGeometry),
    mergeFn: (d) => {
      const merged = mergeGeojson(d);
      if (postProcessDataFn) return postProcessDataFn(merged);
      return merged;
    },
  });

  return (
    data && (
      <Source type="geojson" attribution={attribution} data={data} maxzoom={12}>
        {children}
      </Source>
    )
  );
};

export default ArcGisLayer;
