import { ReactNode } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Collapse,
  List,
  ListProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type CollapsableListProps = {
  children: ReactNode;
  title: string;
  onToggleCollapse?: () => void;
  expanded?: boolean;
  listProps?: ListProps;
};

const useStyles = makeStyles()((theme) => ({
  header: {
    lineHeight: theme.typography.pxToRem(26),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: 56,
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'flex-start',
  },
}));

const CollapsableList = (props: CollapsableListProps): JSX.Element => {
  const { children, title, onToggleCollapse, expanded, listProps } = props;
  const { classes } = useStyles();

  return (
    <Box>
      <Button
        variant="text"
        fullWidth
        onClick={onToggleCollapse}
        className={classes.header}
        aria-labelledby={`list-header-${title}`}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {expanded ? (
            <ExpandMoreIcon sx={{ color: 'text.primary' }} />
          ) : (
            <ChevronRightIcon sx={{ color: 'text.primary' }} />
          )}
          <Typography
            variant="h3"
            color="textPrimary"
            fontWeight="bold"
            id={`list-header-${title}`}
          >
            {title}
          </Typography>
        </Stack>
      </Button>
      <Collapse in={expanded}>
        <List {...listProps}>{children}</List>
      </Collapse>
    </Box>
  );
};

export default CollapsableList;
