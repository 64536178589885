import { Container, Typography, Grid, Box, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { RouteComponentProps } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { API } from 'api';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { User } from 'shared/types';
import { ActiveFireIcon } from 'components/Map/Icons';
import VerifiedIcon from '@mui/icons-material/VerifiedUser';

type ContentProps = {
  user: User;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchUserProfile = (userId: string) => {
  return API.get(`users/${userId}`);
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
  },
  top: {
    width: '100%',
    height: 200,
    backgroundColor: theme.palette.primary.main,
  },
  bio: {
    fontSize: theme.typography.body1.fontSize,
    '& a': {
      color: 'rgba(255, 87, 34, 1)',
    },
  },
  avatarContainer: {
    position: 'relative',
    minHeight: '64px',
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: 250,
    height: 250,
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.grey[300],
  },
  spacedTypography: {
    paddingTop: theme.spacing(1),
  },
  verifiedIcon: {
    height: 14,
    width: 14,
    marginLeft: 4,
  },
}));

export const Content = (props: ContentProps): JSX.Element => {
  const { user } = props;
  const { classes } = useStyles();

  if (!user.userprofile) {
    return <LoadingAndErrors />;
  }

  return (
    <>
      <Helmet>
        <title>{user.displayName}</title>
      </Helmet>

      <Box className={classes.root}>
        <Box className={classes.top} />

        <Container maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.avatarContainer}>
                <Avatar
                  alt={user.displayName}
                  src={user.userprofile.avatarUrl || ActiveFireIcon.data}
                  className={classes.avatar}
                  imgProps={{
                    style: {
                      objectFit: user.userprofile.avatarUrl ? 'cover' : 'none',
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">{user.displayName}</Typography>
            </Grid>

            <Grid item xs={12}>
              {!!user.organizations.length && (
                <Box className={classes.spacedTypography}>
                  <Typography color="black">
                    {user.organizations[0].name}
                    {user.isVerified && (
                      <VerifiedIcon
                        className={classes.verifiedIcon}
                        color="primary"
                      />
                    )}
                  </Typography>
                </Box>
              )}

              {user.userprofile.headline && (
                <Box className={classes.spacedTypography}>
                  <Typography color="textSecondary">
                    {user.userprofile.headline}
                  </Typography>
                </Box>
              )}
            </Grid>

            {user.userprofile.bio && (
              <Grid item xs={12}>
                <ReactMarkdown className={classes.bio}>
                  {user.userprofile.bio}
                </ReactMarkdown>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const UserProfile = (
  props: RouteComponentProps<{ userId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { userId },
    },
  } = props;
  const { t } = useTranslation();
  const query = useQuery({
    queryKey: ['user', userId],
    queryFn: () => fetchUserProfile(userId),
  });

  const { data, isLoading, isError } = query;

  if (isLoading) {
    return (
      <PageLayout data-cname="UserProfilePage">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title={t('common.loading')} action="back" />,
        }}
      </PageLayout>
    );
  }

  if (isError || !data) {
    return (
      <PageLayout data-cname="UserProfilePage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title={t('common.error')} action="back" />,
        }}
      </PageLayout>
    );
  }

  const user = data.data as User;

  return (
    <PageLayout isLoading={isLoading} data-cname="UserProfilePage">
      {{
        content: <Content user={user} />,
        headerBar: <HeaderBar action="/" hideAction />,
      }}
    </PageLayout>
  );
};

export default UserProfile;
