// This detects if the map is not rendering properly per the investigation on
// https://trello.com/c/kmVx4ZZS/1198-base-map-doesnt-always-load-blank-or-white
//
// Out of two observed instances of the bug appearing, the HTML canvas that is used
// to get image data for map style sprites (highway shields, icons, etc) was empty
// both times.
//
// This hook detects if this canvas is indeed empty.

import { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/capacitor';

const CHECK_INTERVAL_MS = 1000;

const isSpriteCanvasBlank = (map: maplibregl.Map): boolean => {
  // For performance reasons, check only the contents of the one sprite within
  // the sprite canvas. This assumes that the sprite canvas is either fully
  // blank or fully populated with sprite data.
  if (!map.loaded()) return false;

  const { images } = map.style.imageManager;
  const imageNames = Object.keys(images);
  if (!images || imageNames.length === 0) {
    Sentry.captureMessage('map-render-bug-no-image-manager-images-found');
    return false;
  }
  const firstSprite = Object.entries(images).find(
    ([, { spriteData }]) => !!spriteData,
  );

  if (!firstSprite || !firstSprite[1].spriteData) {
    Sentry.captureMessage('map-render-bug-no-sprite-data-found');
    return false;
  }
  const [, { spriteData }] = firstSprite;
  const { context } = spriteData;

  const imageData = context.getImageData(
    spriteData.x,
    spriteData.y,
    spriteData.width,
    spriteData.height,
  );
  // It's faster to check each pixel vs each channel in each pixel.
  const uint32Array = new Uint32Array(imageData.data.buffer);
  return !uint32Array.some((value) => value !== 0);
};

// NOTE: This is not currently used, but will be again. For now, it's disabled.
// See: https://trello.com/c/aAnD2djO/1730-map-bug-turn-off-screenshot-uploader

// const captureMapCanvasAsPng = (map: maplibregl.Map): Promise<ArrayBuffer> => {
//   return new Promise((resolve, _) => {
//     map.once('render', () => {
//       map.getCanvas().toBlob(
//         (blob) => {
//           if (blob === null) {
//             Sentry.captureMessage('map-render-bug-capture-canvas-failed');
//             return;
//           }
//           blob.arrayBuffer().then(resolve);
//         },
//         'image/png',
//         1.0
//       );
//     });
//     map.triggerRepaint();
//   });
// };

const sendMessageToSentry = async (map: maplibregl.Map): Promise<void> => {
  // const canvasPng = await captureMapCanvasAsPng(map);
  // Sentry.getCurrentScope().addAttachment({
  //   filename: 'map-canvas.png',
  //   data: new Uint8Array(canvasPng),
  //   contentType: 'image/png'
  // });
  Sentry.captureMessage('map-render-bug-detected');
  // Sentry.getCurrentScope().clearAttachments();
  await Sentry.getCurrentScope().getClient()?.flush(2000);
};

// This will only run for internal users until the approach is validated.
export const useMapRenderBugDetector = (
  map: maplibregl.Map | undefined,
): boolean => {
  const intervalRef = useRef<NodeJS.Timer>();
  const [bugDetected, setBugDetected] = useState(false);
  const [bugSelfHealed, setBugSelfHealed] = useState(false);

  // Set up an interval to check on the map's health.
  useEffect(() => {
    if (!map) {
      return () => {};
    }
    intervalRef.current = setInterval(async () => {
      if (isSpriteCanvasBlank(map)) {
        if (!bugDetected) {
          // Important to wait for the sentry message to be delivered before allowing any
          // side-effects of `bugDetected == true` to trigger (such as reloading the page).
          await sendMessageToSentry(map);
          setBugDetected(true);
        }
      } else if (bugDetected && !bugSelfHealed) {
        // This should never happen, but log it so we can check our assumption: the sprite
        // canvas has somehow re-populated itself, and we expect this never to happen without
        // a full app reload.
        Sentry.captureMessage(
          'map-render-bug-detected-then-sprite-repopulated',
        );

        // Only log this Sentry message once per app reload.
        setBugSelfHealed(true);
      }
    }, CHECK_INTERVAL_MS);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [map, bugDetected, bugSelfHealed]);

  return bugDetected;
};
