import { IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type CameraFullScreenButtonProps = {
  cameraId: string;
  disabled?: boolean;
};

export const CameraFullScreenButton = (
  props: CameraFullScreenButtonProps,
): JSX.Element => {
  const { cameraId, disabled = false } = props;
  const { t } = useTranslation();

  return (
    <IconButton
      component={Link}
      to={`/camera/${cameraId}/fullscreen`}
      disabled={disabled}
      aria-label={t('alertCameras.viewFullScreen')}
    >
      <FullscreenIcon fontSize="medium" sx={{ color: 'text.primary' }} />
    </IconButton>
  );
};
