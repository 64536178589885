import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10vh',
  },
}));

const Unauthorized = (): JSX.Element => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="h3">{t('unauthorized.description')}</Typography>
    </Box>
  );
};

export default Unauthorized;
