import { useCallback, useEffect } from 'react';
import { useMap, MarkerDragEvent, MapMouseEvent } from 'shared/map-exports';
import { EVAC_ZONES_EDIT_FILL_LAYER_ID } from 'components/Map/layers/StructuredEvacuationsLayer';
import Map from 'components/Map';
import { getActiveZonesLayerIds } from 'components/Map/layers/StructuredEvacuationsLayer/utils';
import { GeoEventMarkerProps } from './GeoEventForm.types';

export const GeoEventMarker = (props: GeoEventMarkerProps): JSX.Element => {
  const { geoEvent, onChange } = props;
  const { current: map } = useMap();

  const handleClick = useCallback(
    (event: MapMouseEvent) => {
      const features = map?.queryRenderedFeatures(event.point, {
        layers: [EVAC_ZONES_EDIT_FILL_LAYER_ID, ...getActiveZonesLayerIds()],
      });
      // Return early if click was on an evacuation zone
      if (features && features.length > 0) return;
      const { lat, lng } = event.lngLat;
      onChange({ lat, lng });
      map?.panTo({ lat, lng });
    },
    [map, onChange],
  );

  const handleDrag = (event: MarkerDragEvent): void => {
    const { lat, lng } = event.lngLat;
    onChange({ lat, lng });
  };

  useEffect(() => {
    map?.on('click', handleClick);

    return () => {
      map?.off('click', handleClick);
    };
  }, [map, handleClick]);

  return (
    <Map.WildFireGeoEventMarker
      geoEvent={geoEvent}
      draggable
      onDragEnd={handleDrag}
      anchor={geoEvent.data?.isComplexParent ? 'center' : 'bottom'}
    />
  );
};
