import { RouteComponentProps } from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import HeaderBar from 'components/HeaderBar';
import { isPhone } from 'shared/utils';
import { CameraFullscreenDetails } from './CameraFullscreenDetails';
import { MobileFullscreenCamera } from './MobileFullscreenCamera';

export const FullscreenCamera = (
  props: RouteComponentProps<{ cameraId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { cameraId },
    },
  } = props;

  return (
    <PageLayout data-cname="FullscreenCameraPage">
      {{
        content: isPhone() ? (
          <MobileFullscreenCamera />
        ) : (
          <CameraFullscreenDetails />
        ),
        headerBar: isPhone() ? undefined : (
          <HeaderBar
            action={`/camera/${cameraId}`}
            hideAction
            showSupportUs
            dark
          />
        ),
      }}
    </PageLayout>
  );
};
