import { useCallback } from 'react';
import { Resetter, atom, useRecoilState, useResetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { generateClientToken } from 'shared/utils';
import { MembershipPlan } from 'shared/types';
import { MEMBERSHIP_PLANS } from '../constants';
import { LOCAL_STORAGE_KEY, localStorageEffect } from './localStorage';

type IapProduct = {
  id: string;
  price: string;
};

export type IapCheckoutDetails = {
  clientToken: string;
  externalAccountCode: string;
  email: string;
  firstName: string;
  lastName: string;
  optInToUpdates: boolean;
  verificationToken: string;
};

export type IapProductId =
  (typeof IAP_PRODUCT_IDS)[keyof typeof IAP_PRODUCT_IDS];

type IapState = {
  products: Record<string, IapProduct>;
  checkoutDetails: IapCheckoutDetails;
  selectedProductId: IapProductId;
  plan: MembershipPlan;
  loading: boolean;
};

type UseIapState = IapState & {
  setProduct: (id: string, price: string) => void;
  setCheckoutDetails: (details: Partial<IapCheckoutDetails>) => void;
  setSelectedProductId: (plan: MembershipPlan) => void;
  setLoading: (loading: boolean) => void;
  resetIapState: Resetter;
};

export const IAP_PRODUCT_IDS = {
  [MEMBERSHIP_PLANS.yearly]: 'wd_annual_recurring_membership',
  [MEMBERSHIP_PLANS.proYearly]: 'wd_pro_yearly_iap',
  [MEMBERSHIP_PLANS.proMonthly]: 'wd_pro_monthly_iap',
} as const;

export const MEMBERSHIP_TITLE_TRANS_KEYS = {
  [MEMBERSHIP_PLANS.yearly]:
    'membershipCheckout.sections.membership.title.regular',
  [MEMBERSHIP_PLANS.proYearly]:
    'membershipCheckout.sections.membership.title.pro',
  [MEMBERSHIP_PLANS.proMonthly]:
    'membershipCheckout.sections.membership.title.pro',
};

export const MEMBERSHIP_DESCRIPTION_TRANS_KEYS = {
  [MEMBERSHIP_PLANS.yearly]:
    'membershipCheckout.sections.membership.description.yearly',
  [MEMBERSHIP_PLANS.proYearly]:
    'membershipCheckout.sections.membership.description.yearly',
  [MEMBERSHIP_PLANS.proMonthly]:
    'membershipCheckout.sections.membership.description.monthly',
};

const DEFAULT_IAP_STATE: IapState = {
  products: {},
  checkoutDetails: {
    clientToken: generateClientToken(),
    externalAccountCode: uuidv4(),
    email: '',
    firstName: '',
    lastName: '',
    optInToUpdates: false,
    verificationToken: '',
  },
  selectedProductId: IAP_PRODUCT_IDS.yearly,
  plan: MEMBERSHIP_PLANS.yearly,
  loading: false,
};

const iapStateAtom = atom({
  key: LOCAL_STORAGE_KEY.IAP_STATE,
  default: DEFAULT_IAP_STATE,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.IAP_STATE)],
});

const useIapState = (): UseIapState => {
  const resetIapState = useResetRecoilState(iapStateAtom);
  const [iapState, setIapState] = useRecoilState(iapStateAtom);

  const setProduct = useCallback(
    (id: string, price: string) => {
      setIapState((prev) => ({
        ...prev,
        products: { ...prev.products, [id]: { id, price } },
      }));
    },
    [setIapState],
  );

  const setCheckoutDetails = useCallback(
    (details: Partial<IapCheckoutDetails>) => {
      setIapState((prev) => ({
        ...prev,
        checkoutDetails: { ...prev.checkoutDetails, ...details },
      }));
    },
    [setIapState],
  );

  const setSelectedProductId = useCallback(
    (plan: MembershipPlan) => {
      setIapState((prev) => ({
        ...prev,
        selectedProductId: IAP_PRODUCT_IDS[plan],
        plan,
      }));
    },
    [setIapState],
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      setIapState((prev) => ({ ...prev, loading }));
    },
    [setIapState],
  );

  return {
    loading: iapState.loading,
    products: iapState.products,
    checkoutDetails: iapState.checkoutDetails,
    selectedProductId: iapState.selectedProductId,
    plan: iapState.plan,
    setProduct,
    setCheckoutDetails,
    setSelectedProductId,
    setLoading,
    resetIapState,
  };
};

export default useIapState;
