import {
  CircularProgress,
  CircularProgressProps,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

type LoadingAndErrorsProps = {
  isLoading?: boolean;
  errorMessage?: string;
  textColor?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  progressColor?: CircularProgressProps['color'];
};

const useStyles = makeStyles()((theme) => {
  const { spacing } = theme;

  return {
    loadingWrapper: {
      position: 'absolute',
    },
    errorMessage: {
      padding: spacing(2),
    },
    spinner: {
      color: 'black',
    },
  };
});

export const LoadingAndErrors = (props: LoadingAndErrorsProps): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { isLoading, errorMessage, textColor = 'error', progressColor } = props;
  const errorText = errorMessage || t('common.unknownError');

  if (isLoading) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '20vh' }}
      >
        <Grid item>
          <CircularProgress
            className={progressColor ? undefined : classes.spinner}
            color={progressColor}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '10vh' }}
    >
      <Grid item>
        <Typography
          color={textColor}
          align="center"
          className={classes.errorMessage}
          variant="h3"
        >
          {errorText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoadingAndErrors;
