import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { Poi, PoiType } from 'shared/types';

type SelectedPoisState = {
  selectedPois: Poi[];
  activeIndex: number;
  lastSelectionTimestamp: number;
};

type UsePoisState = {
  selectedPois: Poi[];
  selectedPoi?: Poi;
  activeIndex: number;
  setSelectedPoi: (poi: Poi) => void;
  clearSelectedPois: () => void;
  clearSelectedPoisOfType: (type: PoiType) => void;
  selectPreviousPoi: () => void;
  selectNextPoi: () => void;
};

// Any POIs that are selected during this time window are considered part of the same event.
const CLICK_SAME_EVENT_WINDOW_MS = 100;

// Earlier in the array = first seen in the POI dialog.
const POI_TYPE_ORDER: PoiType[] = [
  'externalGeoEvent',
  'activeEvacZone',
  'evacZone',
  'firePerimeter',
  'place',
  'powerOutage',
  'electricalLine',
  'gasPipeline',
  'radioTower',
  'electricRetail',
  'rfw',
  'responsibilityArea',
  'measureTool',
];

// These POI types can only have one instance selected/active at a time. When they
// are selected, they replace all other selected POIs.
const SINGLETON_POI_TYPES: PoiType[] = ['mapPin', 'measureTool', 'aircraft'];

const MUTUALLY_EXCLUSIVE_POI_TYPES: Record<'activeEvacZone', PoiType[]> = {
  activeEvacZone: ['evacZone'],
};

const DEFAULT_SELECTED_POIS_STATE: SelectedPoisState = {
  selectedPois: [],
  activeIndex: 0,
  lastSelectionTimestamp: 0,
};

const poiStateAtom = atom({
  key: 'POIS_STATE',
  default: DEFAULT_SELECTED_POIS_STATE,
});

export const sortPoisByType = (pois: Poi[], order: PoiType[]): Poi[] =>
  pois.sort((a, b) => {
    const aIndex = order.indexOf(a.type);
    const bIndex = order.indexOf(b.type);
    return aIndex - bIndex;
  });

// Handle mutually exclusive POI types
// Some POI types are incompatible with each other and cannot be selected simultaneously.
// This function filters out any previously selected POIs that are mutually exclusive
// with the newly selected POI type.
const filterMutuallyExclusivePois = (
  poiType: PoiType,
  selectedPois: Poi[],
): Poi[] => {
  const poiTypesToExclude = (MUTUALLY_EXCLUSIVE_POI_TYPES[poiType] ||
    []) as PoiType[];

  if (!poiTypesToExclude.length) {
    return selectedPois;
  }

  return selectedPois.filter((p) => !poiTypesToExclude.includes(p.type));
};

export const usePoisState = (): UsePoisState => {
  const [poiState, setPoiState] = useRecoilState(poiStateAtom);

  const setSelectedPoi = useCallback(
    (poi: Poi) => {
      setPoiState((prev) => {
        const selectionTimestamp = Date.now();

        // Any calls to setSelectedPoi within CLICK_SAME_EVENT_WINDOW_MS of the last selection are considered
        // to be part of the same event. The inverse means that if the last selection was more than
        // CLICK_SAME_EVENT_WINDOW_MS ago, the selection is considered a new event, and the new POI replaces
        // all current selections.
        if (
          prev.lastSelectionTimestamp + CLICK_SAME_EVENT_WINDOW_MS <
          selectionTimestamp
        ) {
          return {
            selectedPois: [poi],
            activeIndex: 0,
            lastSelectionTimestamp: selectionTimestamp,
          };
        }

        if (SINGLETON_POI_TYPES.includes(poi.type)) {
          return {
            selectedPois: [poi],
            activeIndex: 0,
            lastSelectionTimestamp: selectionTimestamp,
          };
        }

        // Getting here implies that this newest POI was selected within the time window that
        // all selections are considered to be part of the same event. If there is already a singleton
        // POI selected, do nothing.
        if (
          prev.selectedPois.find((p) => SINGLETON_POI_TYPES.includes(p.type))
        ) {
          return prev;
        }

        // If this poi is already in the selected list, do nothing. It's important this happens
        // after the check with CLICK_SAME_EVENT_WINDOW_MS.
        if (
          prev.selectedPois.find((p) => p.type === poi.type && p.id === poi.id)
        ) {
          return prev;
        }

        const currentlySelectedPois = filterMutuallyExclusivePois(
          poi.type,
          prev.selectedPois,
        );

        const { activeIndex } = prev;

        return {
          selectedPois: sortPoisByType(
            [...currentlySelectedPois, poi],
            POI_TYPE_ORDER,
          ),
          activeIndex,
          lastSelectionTimestamp: selectionTimestamp,
        };
      });
    },
    [setPoiState],
  );

  const clearSelectedPois = useCallback(() => {
    setPoiState({
      selectedPois: [],
      activeIndex: 0,
      lastSelectionTimestamp: 0,
    });
  }, [setPoiState]);

  const clearSelectedPoisOfType = useCallback(
    (type: PoiType) => {
      setPoiState((prev) => {
        return {
          ...prev,
          selectedPois: prev.selectedPois.filter((poi) => poi.type !== type),
          activeIndex: 0,
        };
      });
    },
    [setPoiState],
  );

  const selectPreviousPoi = useCallback(() => {
    setPoiState((prev) => ({
      ...prev,
      activeIndex: Math.max(0, prev.activeIndex - 1),
    }));
  }, [setPoiState]);

  const selectNextPoi = useCallback(() => {
    setPoiState((prev) => ({
      ...prev,
      activeIndex: Math.min(prev.selectedPois.length - 1, prev.activeIndex + 1),
    }));
  }, [setPoiState]);

  const selectedPoi = poiState.selectedPois[poiState.activeIndex];

  return {
    selectedPois: poiState.selectedPois,
    selectedPoi,
    activeIndex: poiState.activeIndex,
    setSelectedPoi,
    clearSelectedPois,
    clearSelectedPoisOfType,
    selectPreviousPoi,
    selectNextPoi,
  };
};
