import { useEffect } from 'react';
import { useMap } from 'shared/map-exports';
import { DEFAULT_VIEW_STATE } from '.';

type DynamicMapCenterProps = {
  lat?: number | null;
  lng?: number | null;
  zoom?: number;
};

export const DynamicMapCenter = (props: DynamicMapCenterProps): null => {
  const { lat, lng, zoom = DEFAULT_VIEW_STATE.minZoom } = props;
  const { current: map } = useMap();

  useEffect(() => {
    if (!lat || !lng || isNaN(lat) || isNaN(lng)) {
      return;
    }

    map?.easeTo({
      center: [lng, lat],
      zoom,
    });
  }, [lat, lng, map, zoom]);

  return null;
};
