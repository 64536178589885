export const SOURCE_ID = 'evac_zones_ca';
export const ZONES_SOURCE_LAYER = 'zones';
export const LABELS_SOURCE_LAYER = 'labels';
export const EVAC_ZONES_EDIT_FILL_LAYER_ID = 'zones-fill';
export const VIEW_SOURCE_ID = `${SOURCE_ID}_VIEW`;

// always show with minzoom at 1
export const ACTIVE_ZONES_LAYER_MIN_ZOOM = 1;
export const INACTIVE_ZONES_LAYER_MIN_ZOOM = 9;
export const LABEL_LAYER_MIN_ZOOM = 9;

// The field in the maptiles features that contains the zone uid.
export const FEATURE_UID_V2_FIELD_NAME = 'zone_uid_v2';

export const FILL_ID_NO_STATUS_POSTFIX = 'no_status';
export const FILL_ID_NO_STATUS = `layer_color_${FILL_ID_NO_STATUS_POSTFIX}`;
