import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AlertCamera } from 'hooks/useAlertCameras';
import { useDeviceOrientation } from 'hooks/useScreenOrientation';
import usePrevious from 'hooks/usePrevious';
import { isPhone } from 'shared/utils';
import { PlayerStatus } from './useAlertCameraPlayerState.types';

/**
 * A custom hook for redirecting the user to the fullscreen page when the device
 * orientation changes and the player is playing a timelapse.
 */
export const useCameraRedirectOnScreenRotation = (
  playerStatus: PlayerStatus,
  camera?: AlertCamera,
): void => {
  const screenOrientation = useDeviceOrientation();
  const history = useHistory();
  const location = useLocation();
  const prevScreenOrientation = usePrevious(screenOrientation);

  // Effect: Handle device orientation changes for fullscreen mode
  // When a user rotates their device into landscape view and the player is already
  // playing, redirect the user to the fullscreen page.
  useEffect(() => {
    if (
      camera?.id &&
      isPhone() &&
      playerStatus === 'playingTimelapse' &&
      screenOrientation.includes('landscape') &&
      prevScreenOrientation?.includes('portrait') &&
      location.pathname === `/camera/${camera.id}`
    ) {
      history.push(`/camera/${camera.id}/fullscreen`);
    }
  }, [
    screenOrientation,
    playerStatus,
    location.pathname,
    camera?.id,
    history,
    prevScreenOrientation,
  ]);
};
