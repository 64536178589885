/*
 * This file is a collection of map-library-specific exports that are commonly used across the app.
 * It exists to make it easier to switch between map libraries without having to change imports in many files.
 */
import 'maplibre-gl/dist/maplibre-gl.css';

import {
  LngLat,
  LngLatBounds,
  MapMouseEvent as GLMapMouseEvent,
  MapGeoJSONFeature,
} from 'maplibre-gl';
import { MapRef, useMap } from 'react-map-gl/maplibre';

export { Map, LngLat, LngLatBounds, Marker } from 'maplibre-gl';
export type MapMouseEvent = GLMapMouseEvent & {
  features?: MapGeoJSONFeature[] | undefined;
};
export type {
  LngLatBoundsLike,
  FitBoundsOptions,
  PointLike,
  DataDrivenPropertyValueSpecification,
  FilterSpecification,
  ExpressionSpecification,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  MapLayerTouchEvent,
  PositionAnchor,
  LayerSpecification,
} from 'maplibre-gl';

export {
  Map as MapComponent,
  MapProvider,
  Marker as MarkerComponent,
  useMap,
  useControl,
  Layer,
  Source,
  ScaleControl,
  AttributionControl,
  Popup,
} from 'react-map-gl/maplibre';
export type {
  LayerProps,
  SourceProps,
  ViewState,
  MarkerDragEvent,
  MapRef,
  ControlPosition,
  MapStyle,
  AnyLayer,
  AnySource,
} from 'react-map-gl/maplibre';

// Wrappers
export const LngLatBoundsFromLngLat = (lngLat: LngLat): LngLatBounds => {
  return LngLatBounds.fromLngLat(lngLat);
};

/**
 * Returns the map instance regardless of context source:
 * - current: from direct parent-child relationship via Map
 * - default: from broader scope management via MapProvider
 *
 * This handles both component composition patterns: direct nesting or provider-based.
 */
export const useMapInstance = (): MapRef | undefined => {
  const { current, default: defaultMap } = useMap();
  return current || defaultMap;
};
