import { App as CapacitorApp, AppState } from '@capacitor/app';
import { PluginListenerHandle, Capacitor } from '@capacitor/core';
import { useState, useEffect, useCallback, useRef } from 'react';

// Allows you to know if the application is "active" (foreground) or not (background)
// on mobile devices. Useful for situations when you want to react to this state change.
const useForegroundState = (): AppState => {
  // Consider we start on "active" state
  const [appState, setAppState] = useState<AppState>({ isActive: true });
  const subscriptionRef = useRef<PluginListenerHandle | null>(null);

  const getAppState = useCallback(async () => {
    const response = await CapacitorApp.getState();
    setAppState(response);
  }, []);

  const setListener = useCallback(async () => {
    if (!Capacitor.isNativePlatform()) return;

    const subscription = await CapacitorApp.addListener(
      'appStateChange',
      (nextAppState) => {
        setAppState(nextAppState);
      },
    );
    subscriptionRef.current = subscription;
  }, []);

  const removeListener = useCallback(async () => {
    if (subscriptionRef.current) {
      await subscriptionRef.current.remove();
      subscriptionRef.current = null;
    }
  }, []);

  useEffect(() => {
    setListener();

    return () => {
      removeListener();
    };
  }, [removeListener, setListener]);

  // Get app state as soon as this component mounts
  useEffect(() => {
    getAppState();
  }, [getAppState]);

  return appState;
};

export default useForegroundState;
