// 99999 is the z-index of the map control buttons. See src/css/index.css.
// Order of z-indexes:
// * 99999: map control buttons
// * 99998: measure tool point markers
// * 99997: measure tool text labels
// * 99996: measure tool circles
export const Z_INDEX_POINTS = 99999 - 1;
export const Z_INDEX_LABELS = Z_INDEX_POINTS - 1;
export const Z_INDEX_LINE_AND_CIRCLE = Z_INDEX_POINTS - 2;

export const CIRCLE_STROKE_PIXELS = 3;
export const LINE_STROKE_PIXELS = 3;
export const MAJOR_TICK_STROKE_PIXELS = 3;
export const MAJOR_TICK_HEIGHT_PIXELS = 17;
export const MINOR_TICK_STROKE_PIXELS = 2;
export const MINOR_TICK_HEIGHT_PIXELS = 10;
export const STROKE_STYLE = 'solid black';
