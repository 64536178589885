import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { API, CacheAPI } from 'api';
import { Report } from 'shared/types';
import { useCacheState } from 'state';
import { AxiosResponse } from 'axios';

const fetchReports = (
  geoEventId: number,
  cacheBusterTs: number | null,
): Promise<AxiosResponse<Report[]>> => {
  const baseUrl = `reports/?geo_event_id=${geoEventId}&is_moderated=true&is_active=true&has_lat_lng=true`;
  if (cacheBusterTs) {
    return CacheAPI.get<Report[]>(`${baseUrl}&ts=${cacheBusterTs}`);
  }
  return API.get<Report[]>(baseUrl);
};

const useReportsQuery = (
  selectedGeoEventId?: number,
): UseQueryResult<AxiosResponse<Report[]> | undefined> => {
  const { cacheBusterTs } = useCacheState();

  const query = useQuery({
    queryKey: ['reports', selectedGeoEventId, cacheBusterTs],
    queryFn: () => fetchReports(selectedGeoEventId as number, cacheBusterTs),
    enabled: !!selectedGeoEventId,
  });

  return query;
};

export default useReportsQuery;
