import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Cancel, SearchOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'hooks/useQueryParms';
import { MapSearchURLParams } from 'hooks/useMapSearch';
import Sticky from 'react-sticky-el';
import { Props as ReactStickyProps } from 'react-sticky-el/lib/basic-version';
import { makeStyles } from 'tss-react/mui';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import useMapLayersState from 'state/useMapLayersState';
import { useAuthState } from '../../../state';

type SearchBarProps = {
  onCancel?: () => void;
  scrollbarRef?: React.RefObject<HTMLDivElement | Element>;
  onFocus?: () => void;
  onBeforeFocusDrawerElement?: () => void;
};

const useStyles = makeStyles()((theme) => ({
  sticky: {
    background: theme.palette.background.default,
    zIndex: theme.zIndex.drawer + 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 8px)',
      bottom: 0,
      left: 4,
      zIndex: -1,
      boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
}));

/**
 * Avoid an error message from react-sticky-el when scrollElement is not provided
 */
const StickyGuard = (props: Partial<ReactStickyProps>): JSX.Element => {
  const { children, scrollElement } = props;

  if (!scrollElement) {
    return <>{children}</>;
  }

  return <Sticky {...props}>{children}</Sticky>;
};

export const SearchBar = (props: SearchBarProps): JSX.Element => {
  const { onCancel, scrollbarRef, onFocus, onBeforeFocusDrawerElement } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('tablet'));
  const [{ search: searchQuery = '', searchType }, setParams] =
    useQueryParams<MapSearchURLParams>();
  const [isFocused, setIsFocused] = useState(false);
  const { showMembershipProFeatures } = useAuthState();
  const isHistoricalSearch =
    searchType === 'inactive' && showMembershipProFeatures;
  const { classes } = useStyles();
  const selectedWildfireGeoEventId = useSelectedWildfireGeoEventId();
  const { drawerOpen: layersDrawerOpen } = useMapLayersState();

  const setSearchQuery = (newValue: MapSearchURLParams['search']): void => {
    setParams({ search: newValue, searchType });
  };

  const handleClear = (): void => {
    setSearchQuery(null);
  };

  const handleClearDrawer = (): void => {
    setSearchQuery(null);
    if (onCancel) onCancel();
  };

  const toggleHistoricalFilter = (): void => {
    if (isHistoricalSearch) {
      setParams({ search: searchQuery, searchType: null });
    } else {
      setParams({ search: searchQuery, searchType: 'inactive' });
    }
  };

  const handleMouseDown = (): void => {
    if (onBeforeFocusDrawerElement) onBeforeFocusDrawerElement();
  };

  return (
    <StickyGuard
      scrollElement={scrollbarRef?.current as HTMLElement}
      stickyClassName={classes.sticky}
    >
      <Stack
        sx={{
          // Explicit background to make sticky scroll shadow work
          backgroundColor: 'background.paper',
        }}
      >
        <Stack
          direction="row"
          sx={{
            marginX: 2,
            marginTop: isLargeMediaQuery ? 2 : 0,
            marginBottom: 1,
          }}
        >
          <TextField
            size="small"
            fullWidth
            placeholder={t('searchBar.geoEventsPlaceholder')}
            variant="filled"
            className="watchduty-filled-nolabel"
            value={searchQuery || ''}
            onChange={(event) => setSearchQuery(event.target.value)}
            onFocus={() => {
              if (onFocus) onFocus();
              setIsFocused(true);
            }}
            onBlur={() => {
              // keep focus for a moment in case we are unfocusing for a button that will disappear
              setTimeout(() => setIsFocused(false), 100);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClear}
                    onMouseDown={handleMouseDown}
                    sx={{ marginRight: -1 }}
                  >
                    <Cancel fontSize="small" sx={{ width: '0.8em' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            // Workaround: Prevent search drawer from opening to avoid interference with
            // end-to-end tests when selecting an incident or opening the layers drawer.
            // Only needed for iOS (simulator and SauceLabs), but doesn't affect any functionality.
            disabled={!!selectedWildfireGeoEventId || layersDrawerOpen}
          />

          {!isLargeMediaQuery && isFocused && (
            <Button
              size="small"
              variant="text"
              sx={{ color: 'accent.main', marginRight: -1 }}
              onClick={handleClearDrawer}
            >
              {t('common.cancel')}
            </Button>
          )}
        </Stack>
        {showMembershipProFeatures && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginX: 2, marginY: 1 }}
          >
            <Typography>{t('searchBar.historicalIncidents')}</Typography>
            <Switch
              onMouseDown={handleMouseDown}
              checked={searchType === 'inactive'}
              onChange={toggleHistoricalFilter}
            />
          </Stack>
        )}
      </Stack>
    </StickyGuard>
  );
};
