import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { localStorageEffect } from './localStorage';
import { getDeviceInfo } from './localStorageTyped';

type DownloadAppBannerState = {
  lastShownTimestamp: number;
};

type UseDownloadAppBannerState = {
  showBanner: boolean;
  setBannerShown: () => void;
};

const FOURTEEN_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 14;

const DEFAULT_STATE: DownloadAppBannerState = {
  lastShownTimestamp: 0,
};

export const downloadAppBannerStateAtom = atom<DownloadAppBannerState>({
  key: 'DOWNLOAD_APP_BANNER_STATE',
  default: DEFAULT_STATE,
  effects: [localStorageEffect('DOWNLOAD_APP_BANNER_STATE')],
});

const shouldShowBanner = (lastShownTimestamp: number): boolean => {
  const deviceInfo = getDeviceInfo();

  if (!(deviceInfo?.isWeb && deviceInfo?.isMobilePlatform)) {
    return false;
  }

  const nowMs = Date.now();

  return nowMs - lastShownTimestamp > FOURTEEN_DAYS_IN_MS;
};

const useDownloadAppBannerState = (): UseDownloadAppBannerState => {
  const [downloadAppBannerState, setDownloadAppBannerState] = useRecoilState(
    downloadAppBannerStateAtom,
  );

  const setBannerShown = useCallback(() => {
    setDownloadAppBannerState({ lastShownTimestamp: Date.now() });
  }, [setDownloadAppBannerState]);

  const showBanner = shouldShowBanner(
    downloadAppBannerState.lastShownTimestamp,
  );

  return { showBanner, setBannerShown };
};

export default useDownloadAppBannerState;
