import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GeoEvent, GeoEventFormStatus } from 'shared/types';
import { DEFAULT_LAT, DEFAULT_LON } from '../../constants';

type UseMapGeoEventProps<T> = {
  geoEvent: T;
};

type OptionalGeoEvent<T> = T extends GeoEvent ? GeoEvent : Partial<GeoEvent>;

const createGeoEventMap = <T extends GeoEvent | undefined>(
  geoEvent: T,
  status: GeoEventFormStatus,
  acreage: number | null,
  containment: number | null,
  isFps: boolean,
  isPrescribed: boolean,
  isReportUpdate: boolean,
  geoEventLat: string,
  geoEventLng: string,
): OptionalGeoEvent<T> => {
  return {
    ...(geoEvent || {}),
    isActive: status === 'active',
    data: {
      geoEventType: 'wildfire',
      evacuationOrders: '',
      evacuationWarnings: '',
      evacuationNotes: '',
      evacuationAdvisories: '',
      links: [],
      ...(geoEvent ? geoEvent.data : {}),
      acreage,
      containment,
      isFps,
      isPrescribed,
    },
    ...(isReportUpdate
      ? {}
      : {
          lat: Number(geoEventLat),
          lng: Number(geoEventLng),
        }),
  } as OptionalGeoEvent<T>;
};

/**
 * Provides a copy of the geo event, updated with active form values
 */
export const useFormGeoEvent = <T extends GeoEvent | undefined>(
  props: UseMapGeoEventProps<T>,
): OptionalGeoEvent<T> => {
  const { geoEvent } = props;
  const { control } = useFormContext();

  const isReportUpdate = Boolean(geoEvent);

  const containment: number | null = useWatch({
    control,
    name: 'containment',
    defaultValue: geoEvent?.data.containment ?? null,
  });
  const acreage: number | null = useWatch({
    control,
    name: 'acreage',
    defaultValue: geoEvent?.data.acreage ?? null,
  });
  const isFps: boolean = useWatch({
    control,
    name: 'isFps',
    defaultValue: !!geoEvent?.data.isFps,
  });
  const status: GeoEventFormStatus = useWatch({
    control,
    name: 'status',
    defaultValue: geoEvent?.isActive ? 'active' : 'inactive',
  });
  const isPrescribed: boolean = useWatch({
    control,
    name: 'isPrescribed',
    defaultValue: !!geoEvent?.data.isPrescribed,
    disabled: isReportUpdate,
  });
  const geoEventLat: string = useWatch({
    control,
    name: 'lat',
    defaultValue: geoEvent?.lat ?? DEFAULT_LAT.toString(),
    disabled: isReportUpdate,
  });
  const geoEventLng: string = useWatch({
    control,
    name: 'lng',
    defaultValue: geoEvent?.lng ?? DEFAULT_LON.toString(),
    disabled: isReportUpdate,
  });

  const mapGeoEvent = useMemo(
    () =>
      createGeoEventMap(
        geoEvent as T,
        status,
        acreage,
        containment,
        isFps,
        isPrescribed,
        isReportUpdate,
        geoEventLat,
        geoEventLng,
      ),
    [
      geoEvent,
      status,
      acreage,
      containment,
      isFps,
      isPrescribed,
      isReportUpdate,
      geoEventLat,
      geoEventLng,
    ],
  );

  return mapGeoEvent;
};
