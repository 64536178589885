import {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
  CSSProperties,
} from 'react';
import { range } from 'lodash-es';
import { TickStop, UpdateRef } from './types';
import {
  LINE_STROKE_PIXELS,
  MAJOR_TICK_HEIGHT_PIXELS,
  STROKE_STYLE,
} from './constants';
import { TickMark } from './TickMark';
import { isDisplayInvertedForAngle } from './utils';
import { getMajorTickStop } from './tickStops';

// The minimum # of ticks to display. This is a performance optimization to avoid
// changing the number of ticks too frequenly: changing the number of ticks causes visual
// stutter. We always use a multiple of this number.
const MIN_TICKS_COUNT = 50;
// Above this many ticks, performance starts to degrade on an M1 Mac.
const MAX_TICKS_COUNT = 500;

export const Ticks = forwardRef<UpdateRef>(function Ticks(_, ref): JSX.Element {
  const ticksParentDivRef = useRef<HTMLDivElement>(null);
  const ticksChildDivRef = useRef<HTMLDivElement>(null);
  const ticksChildDivInvertedRef = useRef<HTMLDivElement>(null);
  const [lastTickStop, setLastTickStop] = useState<TickStop>(
    getMajorTickStop(0, 0),
  );
  const [ticksCount, setTicksCount] = useState<number>(MIN_TICKS_COUNT);
  useImperativeHandle(ref, () => ({
    update: (newMeasurements) => {
      const { radiusPixels, radiusMiles, angleRad, tickStop } = newMeasurements;
      if (
        !ticksChildDivRef.current ||
        !ticksParentDivRef.current ||
        !ticksChildDivInvertedRef.current
      )
        return;
      const parentDiv = ticksParentDivRef.current;
      const childDiv = ticksChildDivRef.current;
      const childInvertedDiv = ticksChildDivInvertedRef.current;

      const dimension = `${radiusPixels}px`;
      parentDiv.style.width = dimension;

      const pixelsPerMile = radiusPixels / radiusMiles;
      const minorTickIntervalPixels =
        (pixelsPerMile * tickStop.majorTickMiles) / tickStop.minorTicksCount;

      childDiv.style.width = `${ticksCount * minorTickIntervalPixels}px`;
      childInvertedDiv.style.width = `${
        ticksCount * minorTickIntervalPixels
      }px`;

      const inverted = isDisplayInvertedForAngle(angleRad);
      if (inverted) {
        childDiv.style.display = 'none';
        childInvertedDiv.style.display = 'flex';
      } else {
        childDiv.style.display = 'flex';
        childInvertedDiv.style.display = 'none';
      }

      // Update local state used by this component.
      const newTicksCount =
        Math.ceil(radiusPixels / minorTickIntervalPixels / MIN_TICKS_COUNT) *
        MIN_TICKS_COUNT;
      setTicksCount(Math.min(newTicksCount, MAX_TICKS_COUNT));
      setLastTickStop(tickStop);
    },
  }));

  const commonChildStyle: CSSProperties = {
    position: 'relative',
    whiteSpace: 'nowrap',
    verticalAlign: 'flex',
    flex: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
  };

  // The component structure below has two copies of the tick marks: one for
  // the normal display and one for the inverted display. Switching between them
  // using CSS's `display` property on a single <div> is less janky than updating the
  // existing tick marks "flex" properties.
  return (
    <div
      ref={ticksParentDivRef}
      style={{
        position: 'relative',
        top: '50%',
        left: '50%',
        height: '10em',
        overflowX: 'clip',
      }}
    >
      <div
        ref={ticksChildDivRef}
        style={{
          ...commonChildStyle,
          marginTop: `-${LINE_STROKE_PIXELS / 2}px`,
          borderTop: `${LINE_STROKE_PIXELS}px ${STROKE_STYLE}`,
        }}
      >
        {range(0, ticksCount + 1).map((i) => (
          <TickMark
            key={i}
            isInverted={false}
            type={
              i % lastTickStop.minorTicksCount === 0 && i > 0
                ? 'major'
                : 'minor'
            }
            labelDistanceMiles={
              (lastTickStop.majorTickMiles * i) / lastTickStop.minorTicksCount
            }
          />
        ))}
      </div>
      <div
        ref={ticksChildDivInvertedRef}
        style={{
          ...commonChildStyle,
          marginTop: `-${MAJOR_TICK_HEIGHT_PIXELS - LINE_STROKE_PIXELS / 2}px`,
          borderBottom: `${LINE_STROKE_PIXELS}px ${STROKE_STYLE}`,
        }}
      >
        {range(0, ticksCount + 1).map((i) => (
          <TickMark
            key={i}
            isInverted
            type={
              i % lastTickStop.minorTicksCount === 0 && i > 0
                ? 'major'
                : 'minor'
            }
            labelDistanceMiles={
              (lastTickStop.majorTickMiles * i) / lastTickStop.minorTicksCount
            }
          />
        ))}
      </div>
    </div>
  );
});
