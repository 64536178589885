import { useMemo } from 'react';
import {
  ChildLocation,
  GeoEvent,
  NotificationType,
  RelatedGeoEvent,
} from 'shared/types';
import { getCustomEvacDescriptionFlags } from 'shared/utils';
import { GeoEventFormValues, Status } from '../GeoEventForm.types';

export type UseInitialValuesProps = {
  geoEvent?: GeoEvent;
  initialAddress?: string;
  initialStatus: Status;
  initialPrescribed: boolean;
  initialLatitude?: number;
  initialLongitude?: number;
  initialAlertCameraId?: string;
};

export const convertGeoEventValuesToFormValues = (
  props: UseInitialValuesProps,
): GeoEventFormValues => {
  const {
    geoEvent,
    initialAddress,
    initialStatus,
    initialPrescribed,
    initialLatitude,
    initialLongitude,
    initialAlertCameraId,
  } = props;

  const customEvacDescriptionFlags = getCustomEvacDescriptionFlags(geoEvent);

  return {
    name: geoEvent?.name || '',
    address: geoEvent?.data?.isComplexParent
      ? ''
      : geoEvent?.address || initialAddress || '',
    regions: geoEvent?.data?.isComplexParent ? [] : geoEvent?.regions || [],
    status: initialStatus,
    notificationType: geoEvent?.notificationType || NotificationType.Silent,
    acreage: geoEvent?.data.acreage ?? null,
    containment: geoEvent?.data.containment ?? null,
    isComplexParent: !!geoEvent?.data.isComplexParent,
    isFps: !!geoEvent?.data.isFps,
    isPrescribed: initialPrescribed,
    temporarilyDisplayEvacZones: !!geoEvent?.data.temporarilyDisplayEvacZones,
    evacuationOrders: geoEvent?.data.evacuationOrders || '',
    evacuationWarnings: geoEvent?.data.evacuationWarnings || '',
    evacuationNotes: geoEvent?.data.evacuationNotes || '',
    evacuationAdvisories: geoEvent?.data.evacuationAdvisories || '',
    reportNotificationType: NotificationType.Normal,
    reportMessage: '',
    lat: geoEvent?.lat || initialLatitude || 0,
    lng: geoEvent?.lng || initialLongitude || 0,
    prescribedDateStartLocal: geoEvent?.data.prescribedDateStartLocal
      ? new Date(geoEvent.data.prescribedDateStartLocal)
      : null,
    activeEvacuations:
      !!(
        geoEvent?.data.evacuationOrders ||
        geoEvent?.data.evacuationWarnings ||
        geoEvent?.data.evacuationNotes ||
        geoEvent?.data.evacuationAdvisories
      ) || (geoEvent?.evacZoneStatuses?.length ?? 0) > 0,
    evacZoneStatuses: geoEvent?.evacZoneStatuses ?? [],
    customOrders: customEvacDescriptionFlags.customOrders,
    customWarnings: customEvacDescriptionFlags.customWarnings,
    customAdvisories: customEvacDescriptionFlags.customAdvisories,
    asset: initialAlertCameraId ? 'alertCamera' : null,
    media: null,
    fileType: '',
    embedUrl: '',
    mediaLat: '',
    mediaLng: '',
    mediaAz: 0,
    links: geoEvent?.data.links ?? [],
    reporterOnlyNotes: geoEvent?.data.reporterOnlyNotes || '',
    childLocations: (geoEvent?.childGeoEvents?.filter(
      (ge) => ge.geoEventType === 'location',
    ) || []) as ChildLocation[],
    childWildfires: (geoEvent?.childGeoEvents?.filter(
      (ge) => ge.geoEventType === 'wildfire',
    ) || []) as RelatedGeoEvent[],
  };
};

const useInitialValues = (props: UseInitialValuesProps): GeoEventFormValues => {
  const {
    geoEvent,
    initialAddress,
    initialStatus,
    initialPrescribed,
    initialLatitude,
    initialLongitude,
    initialAlertCameraId,
  } = props;

  return useMemo(
    () =>
      convertGeoEventValuesToFormValues({
        geoEvent,
        initialAddress,
        initialStatus,
        initialPrescribed,
        initialLatitude,
        initialLongitude,
        initialAlertCameraId,
      }),
    [
      geoEvent,
      initialAddress,
      initialStatus,
      initialPrescribed,
      initialLatitude,
      initialLongitude,
      initialAlertCameraId,
    ],
  );
};

export default useInitialValues;
