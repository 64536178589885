/**
 * These regexes were taken from react-player: https://github.com/cookpete/react-player/blob/master/src/patterns.js
 * We are not currently using react-player due to JS issues with some embeds (see: https://github.com/sherwood-forestry-service/watchduty-frontend/pull/91#issuecomment-1186004803).
 * Instead, we're using these to embed via <iframe>s ourselves.
 */

export const MATCH_URL_TWITTER =
  /^https?:\/\/(?:twitter|x)\.com\/.+\/status\/([0-9]+)/;

export const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;

export const MATCH_URL_FACEBOOK =
  /^https?:\/\/(www\.)?facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;

export const MATCH_URL_FACEBOOK_WATCH = /^https?:\/\/fb\.watch\/.+$/;

// List of supported valid URLs supported by react-player
// https://github.com/cookpete/react-player/blob/master/src/patterns.js
export const isSupportedEmbedUrl = (value: string): boolean =>
  [
    MATCH_URL_YOUTUBE,
    MATCH_URL_FACEBOOK,
    MATCH_URL_FACEBOOK_WATCH,
    MATCH_URL_TWITTER,
  ].some((pattern) => pattern.test(value));
