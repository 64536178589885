import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyNotifications from 'components/EmptyNotifications';
import useLocationSearchParams from 'hooks/useLocationSearchParams';
import { useNotificationsSettings } from 'hooks/useNotificationsSettings';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import useOnboardingState from 'state/useOnboardingState';
import OnboardingLayout from 'components/OnboardingLayout';
import CountyNotifications from '../CountyNotifications';

const OnboardingNotificationsContent = (): JSX.Element => {
  const query = useLocationSearchParams();
  const {
    isPushEnabled,
    pushToken,
    subscribedNotificationSettings,
    allNotificationSettings,
  } = useNotificationsSettings({
    notificationsEnabled: query.get('enabled') === 'true',
  });
  const history = useHistory();
  const { setCompleted } = useOnboardingState();
  const { t } = useTranslation();

  const handleDone = (): void => {
    setCompleted(true);
    history.push('/');
  };

  const title = t('onboardingNotifications.title');
  const buttonTitle = t('onboardingNotifications.button');

  const { data, isLoading, isError } = allNotificationSettings;
  if (!isPushEnabled) {
    return (
      <OnboardingLayout
        title={title}
        buttonTitle={buttonTitle}
        onClick={handleDone}
      >
        <EmptyNotifications disabled onboarding />
      </OnboardingLayout>
    );
  }
  if (!pushToken || isLoading) {
    return (
      <OnboardingLayout title={title}>
        <LoadingAndErrors isLoading />
      </OnboardingLayout>
    );
  }

  if (isError || !data) {
    return (
      <OnboardingLayout
        title={title}
        buttonTitle={buttonTitle}
        onClick={handleDone}
      >
        <LoadingAndErrors />
      </OnboardingLayout>
    );
  }

  const regionSettings = allNotificationSettings.data;
  const isAnyCountySelected =
    !!subscribedNotificationSettings.data?.data.length;

  if (!isAnyCountySelected) {
    return (
      <OnboardingLayout
        title={title}
        buttonTitle={buttonTitle}
        onClick={handleDone}
      >
        <EmptyNotifications onboarding />
      </OnboardingLayout>
    );
  }

  return (
    <OnboardingLayout
      title={title}
      buttonTitle={buttonTitle}
      onClick={handleDone}
    >
      <CountyNotifications regionSettings={regionSettings} />
    </OnboardingLayout>
  );
};

export default OnboardingNotificationsContent;
