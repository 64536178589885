import { Capacitor } from '@capacitor/core';
import { useCallback, useEffect, useRef } from 'react';
import useIapState, { IAP_PRODUCT_IDS } from 'state/useIapState';
import { MEMBERSHIP_PLANS } from '../constants';

export const useIapInitializer = (): void => {
  const { setProduct, setLoading } = useIapState();
  const storeInitializedRef = useRef(false);

  const onProductUpdate = useCallback(
    (product: CdvPurchase.Product) => {
      const price = product?.offers[0]?.pricingPhases[0]?.price;
      if (price) {
        setProduct(product.id, price);
      }
    },
    [setProduct],
  );

  const onDeviceReady = useCallback(() => {
    if (storeInitializedRef.current) return;

    setLoading(true);
    const purchasePlatform = CdvPurchase.Platform.APPLE_APPSTORE;
    const recurringProductType = CdvPurchase.ProductType.PAID_SUBSCRIPTION;

    CdvPurchase.store.ready(() => {
      setLoading(false);
      storeInitializedRef.current = true;
    });

    CdvPurchase.store.register([
      {
        type: recurringProductType,
        id: IAP_PRODUCT_IDS[MEMBERSHIP_PLANS.yearly],
        platform: purchasePlatform,
      },
      {
        type: recurringProductType,
        id: IAP_PRODUCT_IDS[MEMBERSHIP_PLANS.proYearly],
        platform: purchasePlatform,
      },
      {
        type: recurringProductType,
        id: IAP_PRODUCT_IDS[MEMBERSHIP_PLANS.proMonthly],
        platform: purchasePlatform,
      },
    ]);

    CdvPurchase.store.when().productUpdated(onProductUpdate);

    if (import.meta.env.VITE_ENV !== 'production') {
      CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
    }

    CdvPurchase.store.initialize([purchasePlatform]);
  }, [onProductUpdate, setLoading]);

  useEffect(() => {
    const isIos = Capacitor.getPlatform() === 'ios';

    if (isIos) {
      document.addEventListener('deviceready', onDeviceReady);
    }

    return () => {
      if (isIos) {
        document.removeEventListener('deviceready', onDeviceReady);
      }
    };
  }, [onDeviceReady]);
};
