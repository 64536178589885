import { useCallback } from 'react';
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { LinkType } from 'shared/types';
import { formatPhoneNumber } from 'shared/utils';
import GrayButton from 'components/GrayButton';
import { LinkTypes } from '../../constants';

const typeOptions: { label: string; value: LinkType }[] = [
  { label: 'Phone', value: LinkTypes.phone },
  { label: 'Website', value: LinkTypes.website },
  { label: 'Email', value: LinkTypes.email },
];

const useStyles = makeStyles()((theme) => ({
  button: {
    minHeight: 48,
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius * 1.34,
  },
  item: {
    display: 'grid',
    gridTemplateColumns: '24px 1.5fr 3fr 2fr 42px',
    gap: 16,
  },
  capitalizeLabel: {
    textTransform: 'capitalize',
  },
  deleteButton: {
    width: 42,
    height: 42,
    minWidth: 42,
    backgroundColor: theme.palette.common.black,
    borderRadius: theme.shape.borderRadius * 2,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const FormLinks = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { control, formState, getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'links' });

  const handleOnChangeValue = (value: string, index: number): void => {
    const linkType = getValues(`links[${index}].linkType`);

    if (linkType !== LinkTypes.phone) {
      setValue(`links[${index}].value`, value, { shouldValidate: true });
      return;
    }

    const nextValue = formatPhoneNumber(value);
    setValue(`links[${index}].value`, nextValue, { shouldValidate: true });
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const links = Array.from(getValues('links'));
      const [reorderedItem] = links.splice(result.source.index, 1);
      links.splice(result.destination.index, 0, reorderedItem);
      setValue('links', links);
    },
    [getValues, setValue],
  );

  return (
    <Grid container spacing={2} direction="column">
      {fields.length > 0 && (
        <Grid item>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="links">
              {(provided) => (
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {fields.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(prov) => (
                        <Grid
                          item
                          {...prov.draggableProps}
                          {...prov.dragHandleProps}
                          ref={prov.innerRef}
                        >
                          <Grid item>
                            <div className={classes.item}>
                              <Box className={classes.iconContainer}>
                                <MenuIcon color="secondary" />
                              </Box>

                              <Controller
                                name={`links[${index}].linkType`}
                                control={control}
                                render={({ field, fieldState }) => {
                                  const {
                                    ref,
                                    value: val,
                                    ...muiFieldProps
                                  } = field;
                                  return (
                                    <TextField
                                      select
                                      id={`links[${index}].linkType`}
                                      label={t(
                                        'formLinks.inputs.linkType.label',
                                      )}
                                      fullWidth
                                      {...muiFieldProps}
                                      value={val || ''}
                                      inputRef={ref}
                                      error={!!fieldState.error}
                                      helperText={fieldState.error?.message}
                                      required
                                      disabled={formState.isSubmitting}
                                    >
                                      {typeOptions.map(({ label, value }) => {
                                        return (
                                          <MenuItem
                                            key={label}
                                            value={value}
                                            className={classes.capitalizeLabel}
                                          >
                                            {label}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextField>
                                  );
                                }}
                              />

                              <Grid item>
                                <Controller
                                  name={`links[${index}].label`}
                                  control={control}
                                  render={({ field, fieldState }) => {
                                    const { ref, ...muiFieldProps } = field;
                                    return (
                                      <TextField
                                        id={`links[${index}].label`}
                                        label={t(
                                          'formLinks.inputs.linkLabel.label',
                                        )}
                                        fullWidth
                                        {...muiFieldProps}
                                        inputRef={ref}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        required
                                        disabled={formState.isSubmitting}
                                      />
                                    );
                                  }}
                                />
                              </Grid>

                              <Grid item>
                                <Controller
                                  name={`links[${index}].value`}
                                  control={control}
                                  render={({ field, fieldState }) => {
                                    const { ref, onChange, ...muiFieldProps } =
                                      field;
                                    return (
                                      <TextField
                                        id={`links[${index}].value`}
                                        label={t(
                                          'formLinks.inputs.linkValue.label',
                                        )}
                                        fullWidth
                                        {...muiFieldProps}
                                        onChange={(e) =>
                                          handleOnChangeValue(
                                            e.target.value,
                                            index,
                                          )
                                        }
                                        inputRef={ref}
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                        required
                                        disabled={formState.isSubmitting}
                                      />
                                    );
                                  }}
                                />
                              </Grid>

                              <Box className={classes.iconContainer}>
                                <Button
                                  className={classes.deleteButton}
                                  onClick={() => remove(index)}
                                >
                                  <CloseIcon color="primary" />
                                </Button>
                              </Box>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      )}

      <Grid item>
        <GrayButton
          className={classes.button}
          onClick={() => append({ linkType: null, label: '', value: '' })}
        >
          {t('formLinks.button')}
        </GrayButton>
      </Grid>
    </Grid>
  );
};

export default FormLinks;
