import { Capacitor } from '@capacitor/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { useTranslation } from 'react-i18next';

type LocationPermissionDialogProps = {
  open: boolean;
  onClose: () => void;
};

const LocationPermissionDialog = (
  props: LocationPermissionDialogProps,
): JSX.Element => {
  const { open, onClose } = props;
  const { t } = useTranslation();

  const isPlatformWeb = Capacitor.getPlatform() === 'web';

  const handleClickSettings = (): void => {
    onClose();

    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('map.locationPermission.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('map.locationPermission.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {t('common.cancel')}
        </Button>
        {!isPlatformWeb && (
          <Button onClick={handleClickSettings} color="primary" autoFocus>
            {t('common.settings')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LocationPermissionDialog;
