import { grey, red } from '@mui/material/colors';
import { CardElement } from '@recurly/react-recurly';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

type RecurlyCardElementProps = {
  error: boolean;
};

const cardElementStyles = {
  fontFamily: 'Roboto, sans-serif',
  placeholder: {
    color: grey[600],
  },
  fontColor: grey[800],
};

const cardInvalidStyles = {
  placeholder: {
    color: red[500],
  },
};

const useStyles = makeStyles()((theme) => ({
  cardElement: {
    '& .recurly-element.recurly-element-card': {
      height: 56,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'inherit',
      borderColor: theme.palette.grey[400],
      '&.recurly-element-invalid': {
        borderColor: theme.palette.error.main,
      },
      '&:hover': {
        borderColor: theme.palette.grey[800],
      },
    },
  },
  cardElementInvalid: {
    '& .recurly-element.recurly-element-card': {
      borderColor: theme.palette.error.main,
    },
  },
}));

const RecurlyCardElement = (props: RecurlyCardElementProps): JSX.Element => {
  const { error } = props;
  const { classes, cx } = useStyles();

  const cardStyles = useMemo(() => {
    if (error) {
      return { ...cardElementStyles, ...cardInvalidStyles };
    }
    return cardElementStyles;
  }, [error]);

  return (
    <CardElement
      className={cx(classes.cardElement, error && classes.cardElementInvalid)}
      style={cardStyles}
    />
  );
};

export default RecurlyCardElement;
