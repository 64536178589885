import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import PhotoSubmissionContent from './PhotoSubmissionContent';

type ContentProps = {
  geoEventId?: number;
};

const Content = (props: ContentProps): JSX.Element => {
  const { geoEventId } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('photoSubmission.title')}</title>
      </Helmet>
      <PhotoSubmissionContent geoEventId={geoEventId} />
    </>
  );
};

const PhotoSubmission = (
  props: RouteComponentProps<{ geoEventId?: string }>,
): JSX.Element => {
  const {
    match: {
      params: { geoEventId },
    },
  } = props;
  const { t } = useTranslation();

  return (
    <PageLayout data-cname="PhotoSubmissionPage">
      {{
        content: (
          <Content
            geoEventId={geoEventId ? parseInt(geoEventId, 10) : undefined}
          />
        ),
        headerBar: (
          <HeaderBar
            action={geoEventId ? `/i/${geoEventId}` : '/'}
            hideAction
            title={t('photoSubmission.title')}
          />
        ),
      }}
    </PageLayout>
  );
};

export default PhotoSubmission;
