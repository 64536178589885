import { useCallback, useMemo } from 'react';
import { useMap, Layer, MapGeoJSONFeature } from 'shared/map-exports';
import {
  EvacZone,
  EvacZonePoi,
  GeoEvent,
  GeoEventEvacZoneStatus,
  LayerEvacZone,
  Location,
  Poi,
} from 'shared/types';
import { usePoisState } from 'state/usePoisState';
import { EvacZoneDialogContent } from 'components/Map/EvacZoneDialogContent';
import useMapLayersState from 'state/useMapLayersState';
import VisibilityToggledSource from '../VisibilityToggledSource';
import {
  INACTIVE_ZONES_LAYER_MIN_ZOOM,
  ACTIVE_ZONES_LAYER_MIN_ZOOM,
  VIEW_SOURCE_ID,
  FEATURE_UID_V2_FIELD_NAME,
  FILL_ID_NO_STATUS_POSTFIX,
  FILL_ID_NO_STATUS,
} from './constants';
import {
  getCountyNameFromSlug,
  createGroupings,
  getTilesUrl,
  getSelectedEvacZoneUId,
  createColorGroupings,
  getFillLayerId,
  createPatternGroupings,
} from './utils';
import {
  getLabelLayerStyle,
  getSelectedZonePatternStyle,
  getSelectedZoneStrokeStyle,
  getUidFillStyle,
  getUidPatternStyle,
  getUidStrokeStyle,
} from './layerStyles';
import { EvacZoneStyles } from '../../../../constants';
import { BEFORE_LAYER_ID } from '../../styles/constants';
import useActiveEvacZonesQuery from '../../../../hooks/useActiveEvacZonesQuery';
import { useMapLayerEvents } from '../useMapLayerEvents';
import { useGeoEventEvacZones } from '../../../../hooks/useGeoEventEvacZones';
import useGeoEventQuery from '../../../../hooks/useGeoEventQuery';
import { useCacheState } from '../../../../state';

const handleSingleClickFeature = (
  feature: MapGeoJSONFeature,
  evacZones: LayerEvacZone[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map: any,
  setSelectedPoi: (poi: Poi) => void,
  activeEvacZones: GeoEventEvacZoneStatus[],
): void => {
  if (!feature || !feature.properties) return;

  const evacZone = evacZones.find((zone) => zone.uidV2 === feature.id);
  // we don't render the fills/strokes via opacity above a certain zoom level for inactive zones
  if (
    map &&
    map.getZoom() < INACTIVE_ZONES_LAYER_MIN_ZOOM &&
    !evacZone?.status
  ) {
    return;
  }

  const activeEvacZone = activeEvacZones.find(
    (eZS) => eZS.evacZone.uidV2 === feature.id,
  );

  const uid: string = (
    activeEvacZone?.evacZone.uidV2 ??
    feature.id ??
    ''
  ).toString();
  const poiDialogData: EvacZonePoi = {
    id: activeEvacZone?.evacZone.id,
    uid,
    displayName:
      activeEvacZone?.evacZone.displayName ?? feature.properties.zone_name,
    status: activeEvacZone?.status,
    county: getCountyNameFromSlug(feature.properties.county_slug),
    dateCreated: activeEvacZone?.dateCreated,
    dateModified: activeEvacZone?.dateModified,
    source:
      activeEvacZone?.evacZone.sourceType ?? feature.properties.source_type,
    style:
      activeEvacZone?.evacZone.regionEvacZoneStyle ?? EvacZoneStyles.default,
  };

  setSelectedPoi({
    type: 'activeEvacZone',
    id: poiDialogData.uid,
    PoiDialogContent: () => <EvacZoneDialogContent evacZone={poiDialogData} />,
  });
};

const handleClickEvent = (
  features: MapGeoJSONFeature[],
  evacZones: LayerEvacZone[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map: any,
  setSelectedPoi: (poi: Poi) => void,
  activeEvacZones: GeoEventEvacZoneStatus[],
): void => {
  for (const feature of features) {
    handleSingleClickFeature(
      feature,
      evacZones,
      map,
      setSelectedPoi,
      activeEvacZones,
    );
  }
};

/*
 * This combines the global active evac zones with those associated with the optionally selected geo event
 *   that may or may not have a status for them
 * If the selected geo event has a single active zone, we display all of inactive zones for its region(s) and
 * all global active zones
 */
export const getZonesForDisplay = (
  geoEventEvacZones: EvacZone[],
  allActiveEvacZones: GeoEventEvacZoneStatus[],
  selectedGeoEvent: GeoEvent | Location | undefined,
): LayerEvacZone[] => {
  // This should be updated when we modify the uid -> uuid.
  // https://trello.com/c/K6pRc5W5/1328-bug-evac-zones-status-and-visibility-on-front-end-are-keyed-off-zoneslug-not-zoneuid
  const { activeZones, inactiveZones } = geoEventEvacZones.reduce(
    (geoEventZones, evacZone) => {
      const isZoneActive = !!allActiveEvacZones.find(
        (aZ) =>
          aZ.geoEventId === selectedGeoEvent?.id &&
          aZ.evacZone.id === evacZone.id,
      );

      if (isZoneActive) {
        geoEventZones.activeZones.push(evacZone);
      } else {
        geoEventZones.inactiveZones.push(evacZone);
      }

      return geoEventZones;
    },
    { activeZones: [] as EvacZone[], inactiveZones: [] as EvacZone[] },
  );

  if (
    activeZones.length > 0 ||
    (selectedGeoEvent?.geoEventType === 'wildfire' &&
      selectedGeoEvent?.data.temporarilyDisplayEvacZones)
  ) {
    return [
      ...inactiveZones.map((zone) => ({
        uidV2: zone.uidV2,
        style: zone.region.evacZoneStyle,
      })),
      ...allActiveEvacZones.map((zone) => ({
        uidV2: zone.evacZone.uidV2,
        status: zone.status,
        style: zone.evacZone.regionEvacZoneStyle,
      })),
    ];
  }

  return allActiveEvacZones.map((zone) => ({
    uidV2: zone.evacZone.uidV2,
    status: zone.status,
    style: zone.evacZone.regionEvacZoneStyle,
  }));
};
/*
 * This layer is designed to show the zones with statuses globally on the map and/or
 * the active/inactive zones associated with a selected geo event's region(s)
 *
 * It handles the styling by grouping like colors/patterns within the possible groupings of style + status
 * and then filtering on the combinations of UIDs within those color/pattern groupings.
 */
const StructuredEvacuationsViewLayer = (): JSX.Element => {
  const { cacheBusterTs } = useCacheState();
  const { current: map } = useMap();
  const { selectedPoi, setSelectedPoi } = usePoisState();
  const { isDarkBaseLayer } = useMapLayersState();
  const activeEvacZones = useActiveEvacZonesQuery();
  const selectedEvacZoneUId = getSelectedEvacZoneUId(selectedPoi, true);

  const { geoEvent: selectedGeoEvent } = useGeoEventQuery<
    GeoEvent | Location
  >();
  const { geoEventEvacZones } = useGeoEventEvacZones(
    selectedGeoEvent as GeoEvent,
  );

  const evacZones: LayerEvacZone[] = useMemo(() => {
    return getZonesForDisplay(
      geoEventEvacZones,
      activeEvacZones,
      selectedGeoEvent,
    );
  }, [activeEvacZones, geoEventEvacZones, selectedGeoEvent]);

  const zonesWithStatus = evacZones.filter((evacZone) => !!evacZone.status);
  const zonesWithOutStatus = evacZones.filter((evacZone) => !evacZone.status);
  const zoneIdsWithStatus = zonesWithStatus.map((evacZone) => evacZone.uidV2);
  const zoneIdsWithOutStatus = zonesWithOutStatus.map(
    (evacZone) => evacZone.uidV2,
  );
  const allZoneIds = evacZones.map((evacZone) => evacZone.uidV2);
  const groups = createGroupings(zonesWithStatus);
  const colorGroups = createColorGroupings(groups);

  const clickCallback = useCallback(
    (features: MapGeoJSONFeature[]) =>
      handleClickEvent(
        features,
        evacZones,
        map,
        setSelectedPoi,
        activeEvacZones,
      ),
    [evacZones, map, setSelectedPoi, activeEvacZones],
  );

  const colorKeys = Object.keys(colorGroups).concat(FILL_ID_NO_STATUS_POSTFIX);
  colorKeys.forEach((color) => {
    const layerId = getFillLayerId(color);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMapLayerEvents({ layerId, onClick: clickCallback });
  });

  const visible = evacZones.length > 0;
  const tileCacheBuster =
    evacZones.length > 0 && cacheBusterTs ? cacheBusterTs : undefined;
  return (
    <VisibilityToggledSource
      url={getTilesUrl(tileCacheBuster)}
      visible={visible}
      promoteId={FEATURE_UID_V2_FIELD_NAME}
      // Do not unset `volatile` ever for this layer.
      volatile
      id={VIEW_SOURCE_ID}
    >
      <Layer
        id={FILL_ID_NO_STATUS}
        {...getUidFillStyle('white', zoneIdsWithOutStatus, 0)}
      />
      {Object.entries(colorGroups).map(([color, uids]) => (
        <Layer
          key={color}
          id={getFillLayerId(color)}
          {...getUidFillStyle(color, uids)}
        />
      ))}
      {Object.entries(createPatternGroupings(groups)).map(([pattern, uids]) => (
        <Layer key={pattern} {...getUidPatternStyle(pattern, uids)} />
      ))}
      <Layer
        id="layer_stroke_inactive"
        {...getUidStrokeStyle(
          isDarkBaseLayer,
          zoneIdsWithOutStatus,
          INACTIVE_ZONES_LAYER_MIN_ZOOM,
        )}
      />
      <Layer
        id="layer_stroke_active"
        {...getUidStrokeStyle(
          isDarkBaseLayer,
          zoneIdsWithStatus,
          ACTIVE_ZONES_LAYER_MIN_ZOOM,
        )}
      />
      <Layer
        beforeId={BEFORE_LAYER_ID}
        {...getSelectedZonePatternStyle(selectedEvacZoneUId)}
      />
      <Layer {...getSelectedZoneStrokeStyle(selectedEvacZoneUId)} />
      <Layer {...getLabelLayerStyle(allZoneIds)} />
    </VisibilityToggledSource>
  );
};

export default StructuredEvacuationsViewLayer;
