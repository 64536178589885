import { Theme, Button } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const WhiteButton = withStyles(Button, (theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
})) as typeof Button;

export default WhiteButton;
