import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSelectedWildfireGeoEventId } from 'hooks/useSelectedWildfireGeoEventId';
import { useAuthState } from 'state';
import { SupportUsBanner } from 'components/SupportUsBanner';
import { MAP_DOM_ELEMENT_ID } from '../../../constants';
import { getMapDialogCommonStyles } from '../utils';
import { useSupportUsDialog } from './useSupportUsDialog';

const useStyles = makeStyles<{
  isGeoEventSelected: boolean;
  interactive: boolean;
}>()((theme, { isGeoEventSelected, interactive }) =>
  getMapDialogCommonStyles(theme, {
    isGeoEventSelected,
    interactive,
    minWidth: 343,
  }),
);

export const SupportUsDialog = (): JSX.Element => {
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const {
    permissions: { canReport },
  } = useAuthState();
  const { classes } = useStyles({
    isGeoEventSelected: Boolean(selectedGeoEventId),
    interactive: canReport,
  });
  const { open, handleClose } = useSupportUsDialog();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        root: classes.root,
        container: classes.container,
        paper: classes.paper,
      }}
      hideBackdrop
      container={(): HTMLElement | null =>
        document.getElementById(MAP_DOM_ELEMENT_ID)
      }
    >
      <SupportUsBanner onClose={handleClose} />
    </Dialog>
  );
};
