import {
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Button,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { NotificationOption, NotificationSetting } from 'shared/types';
import { NotificationSettingTypes } from '../../constants';

type RegionEditorListItemProps = {
  regionSetting: NotificationSetting;
  onEdit: () => void;
};

type SecondaryTextProps = {
  setting: NotificationOption;
};

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 1.34,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  itemText: {
    margin: 0,
    lineHeight: 1.5,
  },
  fontMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: 'fit-content',
    textTransform: 'none',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
  },
}));

const SecondaryText = (props: SecondaryTextProps): JSX.Element | null => {
  const { setting } = props;
  const { t } = useTranslation();

  if (setting === NotificationSettingTypes.all) {
    return null;
  }

  const el = [];

  if (
    setting === NotificationSettingTypes.allWithSilent ||
    setting === NotificationSettingTypes.onlyNewWithSilent
  ) {
    el.push(
      <Typography key="all" variant="body1" color="textSecondary">
        {t('notifications.secondary.includeSilent')}
      </Typography>,
    );
  }

  if (
    setting === NotificationSettingTypes.onlyNew ||
    setting === NotificationSettingTypes.onlyNewWithSilent
  ) {
    el.push(
      <Typography key="only-new" variant="body1" color="textSecondary">
        {t('notifications.secondary.firstOnly')}
      </Typography>,
    );
  }

  return <>{el}</>;
};

const RegionEditorListItem = (
  props: RegionEditorListItemProps,
): JSX.Element => {
  const { regionSetting, onEdit } = props;
  const { classes } = useStyles();
  const { displayName } = regionSetting.region;
  const { t } = useTranslation();

  return (
    <ListItem
      alignItems="flex-start"
      disableGutters
      className={classes.root}
      aria-label={displayName}
    >
      <ListItemText
        className={classes.itemText}
        primary={displayName}
        primaryTypographyProps={{ className: classes.fontMedium }}
        secondary={<SecondaryText setting={regionSetting.setting} />}
      />
      <ListItemSecondaryAction>
        <Button
          variant="text"
          size="small"
          color="inherit"
          className={classes.button}
          onClick={onEdit}
          data-testid={`edit-${displayName}`}
        >
          {t('common.edit')}
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default RegionEditorListItem;
