import { useEffect } from 'react';
import { ControlPosition, useControl, Map } from 'shared/map-exports';
import { ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import MapControlButton from './MapControlButton';
import BaseMapboxGLController from './BaseMapboxGLController';

type NavigationControlProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  position?: ControlPosition;
};

const useStyles = makeStyles()((theme) => ({
  zoomInBtn: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
  },
  zoomOutBtn: {
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

const NavigationControlContent = (
  props: NavigationControlProps & { map?: Map },
): JSX.Element => {
  const { map } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <ButtonGroup
      orientation="vertical"
      aria-label={t('map.accessibility.navControl')}
    >
      <MapControlButton
        ariaLabel={t('map.accessibility.zoomIn')}
        onClick={() => map?.zoomIn()}
        Icon={AddIcon}
        className={classes.zoomInBtn}
      />
      <MapControlButton
        ariaLabel={t('map.accessibility.zoomOut')}
        onClick={() => map?.zoomOut()}
        Icon={RemoveIcon}
        className={classes.zoomOutBtn}
      />
    </ButtonGroup>
  );
};

class MapboxGLNavigationController extends BaseMapboxGLController<
  NavigationControlProps & { map?: Map }
> {
  content = (): JSX.Element => (
    <NavigationControlContent map={this.map} {...this.props} />
  );
}

const NavigationControl = (props: NavigationControlProps): null => {
  const { position } = props;
  // @ts-ignore - https://github.com/visgl/deck.gl/issues/9211
  const controller = useControl(() => new MapboxGLNavigationController(props), {
    position,
  }) as MapboxGLNavigationController;

  controller.update(props);
  useEffect(() => controller.render(), [controller, props]);
  return null;
};

export default NavigationControl;
