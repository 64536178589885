import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import PageLayout from 'components/PageLayout';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import HeaderBar from 'components/HeaderBar';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { GeoEvent, Report } from 'shared/types';
import EditReportForm from './EditReportForm';

type ContentProps = {
  geoEvent: GeoEvent;
  report: Report;
};

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchGeoEvent = (geoEventId: string) =>
  API.get(`geo_events/${geoEventId}`);

// todo: react-query function type returns can be addressed after react-query 5 upgrade
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fetchReport = (reportId: string) => API.get(`reports/${reportId}`);

const Content = (props: ContentProps): JSX.Element => {
  const { geoEvent, report } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('editReport.title')}</title>
      </Helmet>
      <EditReportForm geoEvent={geoEvent} report={report} />
    </>
  );
};

const EditReport = (
  props: RouteComponentProps<{ geoEventId: string; reportId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { geoEventId, reportId },
    },
  } = props;
  const { t } = useTranslation();

  const geoEventQuery = useQuery({
    queryKey: ['geoEvent', geoEventId],
    queryFn: () => fetchGeoEvent(geoEventId),
  });

  const reportQuery = useQuery({
    queryKey: ['report', reportId],
    queryFn: () => fetchReport(reportId),
  });

  const isLoading = geoEventQuery.isLoading || reportQuery.isLoading;
  const isError = geoEventQuery.isError || reportQuery.isError;

  if (isLoading) {
    return (
      <PageLayout data-cname="EditReportPage">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title={t('common.loading')} action="back" />,
        }}
      </PageLayout>
    );
  }

  if (isError || !geoEventQuery.data || !reportQuery.data) {
    return (
      <PageLayout data-cname="EditReportPage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title={t('common.error')} action="back" />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="AddIncidentReportPage">
      {{
        content: (
          <Content
            geoEvent={geoEventQuery.data.data}
            report={reportQuery.data.data}
          />
        ),
        headerBar: (
          <HeaderBar action="back" title={t('editReport.title')} hideAction />
        ),
      }}
    </PageLayout>
  );
};

export default EditReport;
