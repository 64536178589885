import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { User } from 'shared/types';
import { makeStyles } from 'tss-react/mui';
import UserStatusChip from 'components/Map/UserStatusChip';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type UserCardProps = {
  user: User | null;
  toggleOpen: () => void;
  isMember: boolean;
};

const useStyles = makeStyles()((theme) => {
  return {
    userItemBtn: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: 'auto',
    },
  };
});

const UserCard = (props: UserCardProps): JSX.Element => {
  const { isMember, user, toggleOpen } = props;
  const { classes } = useStyles();
  const displayName = [user?.firstName, user?.lastName].join(' ').trim();
  const { t } = useTranslation();

  return (
    <Link
      key="/account"
      to="/account"
      onClick={toggleOpen}
      aria-label={t('drawerNav.account')}
    >
      <ListItemButton className={classes.userItemBtn} key="account">
        <ListItemText
          primary={
            <Stack direction="row">
              {displayName && (
                <Typography
                  fontWeight={600}
                  variant="body2"
                  noWrap
                  sx={{ marginRight: 1 }}
                >
                  {displayName}
                </Typography>
              )}
              {isMember && (
                <UserStatusChip
                  type={
                    user?.supporterStatus?.planType === 'pro'
                      ? 'pro'
                      : 'members'
                  }
                />
              )}
            </Stack>
          }
          secondary={
            <Typography
              variant="body2"
              fontWeight={displayName ? 400 : 600}
              color={displayName ? 'textSecondary' : 'textPrimary'}
              noWrap
            >
              {user?.email}
            </Typography>
          }
        />
        <ListItemIcon sx={{ justifyContent: 'right', minWidth: 24 }}>
          <ArrowForwardIosIcon fontSize="small" />
        </ListItemIcon>
      </ListItemButton>
    </Link>
  );
};

export default UserCard;
