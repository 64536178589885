import { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { useHistory } from 'react-router-dom';
import { useAuthState, useSnackbarState } from 'state';
import { GeoEvent, Link } from 'shared/types';
import LinkItem from 'components/LinkItem';
import Tooltip from 'components/Tooltip';
import { parseCoordinate } from 'shared/utils';
import UserStatusChip from 'components/Map/UserStatusChip';
import { ABORT_SHARE_ERROR_STRING, MEMBERSHIP_PLANS } from '../../../constants';

type GeoEventStructuredInfoProps = {
  geoEvent: GeoEvent;
};

type LocationCoordsProps = {
  geoEvent: GeoEvent;
};

type LinksListProps = {
  links: Link[];
};

const useStyles = makeStyles()((theme) => ({
  listSubheader: {
    padding: theme.spacing(3, 2, 2),
    lineHeight: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  locationIcon: {
    color: theme.palette.text.primary,
  },
  blurredLocationImg: {
    height: 20,
    marginLeft: -4,
  },
}));

const LocationCoords = (props: LocationCoordsProps): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const { showMembershipFeatures } = useAuthState();
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();

  const initialLocationCoords = `${parseCoordinate(
    geoEvent.lat,
  )}, ${parseCoordinate(geoEvent.lng)}`;

  const handleLocationClick = async (): Promise<void> => {
    if (!showMembershipFeatures) {
      history.push(`/support_us/${MEMBERSHIP_PLANS.yearly}`);
      return;
    }

    if (!Capacitor.isNativePlatform()) {
      navigator.clipboard.writeText(initialLocationCoords);
      setCopied(true);
      return;
    }

    try {
      await Share.share({
        title: geoEvent.name,
        text: initialLocationCoords,
        dialogTitle: t('geoEvent.structuredInfo.share'),
      });
    } catch (error) {
      // iOS will throw an error if aborted
      if ((error as Error)?.message === ABORT_SHARE_ERROR_STRING) {
        return;
      }

      setSnackbar(t('geoEvent.errorMessage'), 'error');
    }
  };

  if (!showMembershipFeatures) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <Box
        role="button"
        tabIndex={0}
        onClick={handleLocationClick}
        sx={{ cursor: 'pointer' }}
      >
        <img
          src="/images/location-coords-blur.png"
          alt="blurred location"
          className={classes.blurredLocationImg}
        />
      </Box>
    );
  }

  if (Capacitor.isNativePlatform()) {
    return (
      <Box
        role="button"
        tabIndex={0}
        onClick={handleLocationClick}
        sx={{ cursor: 'pointer' }}
      >
        {initialLocationCoords}
      </Box>
    );
  }

  return (
    <Tooltip
      title={t('common.copied')}
      open={copied}
      placement="top"
      onClose={() => setCopied(false)}
      leaveDelay={200}
    >
      <Box
        role="button"
        tabIndex={0}
        onClick={handleLocationClick}
        sx={{ cursor: 'pointer' }}
      >
        {initialLocationCoords}
      </Box>
    </Tooltip>
  );
};

const LinksList = (props: LinksListProps): JSX.Element => {
  const { links } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <List
      subheader={
        <ListSubheader
          color="inherit"
          className={classes.listSubheader}
          disableSticky
        >
          <Typography variant="h3">
            {t('geoEvent.structuredInfo.links')}
          </Typography>
        </ListSubheader>
      }
    >
      {links.map((link) => (
        <LinkItem key={`${link.label}-${link.value}`} link={link} />
      ))}
    </List>
  );
};

const GeoEventStructuredInfo = (
  props: GeoEventStructuredInfoProps,
): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const links = geoEvent.data?.links ?? [];

  return (
    <List
      subheader={
        <ListSubheader
          color="inherit"
          className={classes.listSubheader}
          disableSticky
        >
          <Typography variant="h3">
            {t('geoEvent.structuredInfo.information')}
          </Typography>
        </ListSubheader>
      }
    >
      <ListItem divider>
        <ListItemText
          primary={
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              component="span"
            >
              <Typography
                color="secondary"
                fontWeight="medium"
                component="span"
                sx={{ fontVariant: 'all-small-caps' }}
              >
                {t('geoEvent.structuredInfo.initialLocation')}
              </Typography>
              <UserStatusChip type="members" sx={{ marginLeft: 1 }} />
            </Box>
          }
          secondary={<LocationCoords geoEvent={geoEvent} />}
          secondaryTypographyProps={{
            variant: 'body1',
            color: 'textPrimary',
            fontWeight: 'medium',
          }}
        />
        <LocationOnIcon className={classes.locationIcon} />
      </ListItem>

      {links.length > 0 && <LinksList links={links} />}
    </List>
  );
};

export default GeoEventStructuredInfo;
