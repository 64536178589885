import {
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DialogContentListType } from './types';

type ElectricLineDialogContentProps = {
  title: string;
  list: DialogContentListType[];
};

const useStyles = makeStyles()((theme) => ({
  cell: {
    border: 0,
    padding: `0 16px 4px 0`,
  },
}));

const ElectricLineDialogContent = (
  props: ElectricLineDialogContentProps,
): JSX.Element => {
  const { list, title } = props;
  const { classes } = useStyles();

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: '4px' }}>
        <Typography component="span" variant="body1" fontWeight="bold">
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Table>
          <TableBody>
            {list.map(({ name, value }) => (
              <TableRow key={name}>
                <TableCell component="th" scope="row" className={classes.cell}>
                  <Typography
                    variant="body2"
                    color="secondary"
                    textTransform="uppercase"
                  >
                    {name}
                  </Typography>
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography variant="body2" textTransform="capitalize">
                    {value}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </>
  );
};

export default ElectricLineDialogContent;
