import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useTranslation } from 'react-i18next';
import { MapBaseLayerNames } from './constants';
import BasemapStyleSelection from './BasemapStyleSelection';
import { MapBaseLayer } from './types';

type MapBaseLayerSelectorProps = {
  value: MapBaseLayer;
  onChange: (layer: MapBaseLayer) => void;
};

const useStyles = makeStyles()((theme) => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 4,
    marginTop: -8,
  },
  radioButton: {
    flexDirection: 'column',
    marginLeft: 0,
    marginRight: 0,
  },
  fontBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const IMG_URLS = {
  [MapBaseLayerNames.STREET]: '/images/street.png',
  [MapBaseLayerNames.SATELLITE]: '/images/satellite.png',
  [MapBaseLayerNames.TOPOGRAPHIC]: '/images/terrain.png',
};

const TRANSLATIONS = {
  [MapBaseLayerNames.STREET]: 'map.layers.basemaps.street',
  [MapBaseLayerNames.SATELLITE]: 'map.layers.basemaps.satellite',
  [MapBaseLayerNames.TOPOGRAPHIC]: 'map.layers.basemaps.topographic',
} as const;

const MapBaseLayerSelector = (
  props: MapBaseLayerSelectorProps,
): JSX.Element => {
  const { value, onChange } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      value={value}
      name="radio-buttons-group"
      className={classes.radioGroup}
    >
      {[
        MapBaseLayerNames.STREET,
        MapBaseLayerNames.SATELLITE,
        MapBaseLayerNames.TOPOGRAPHIC,
      ].map((name) => (
        <FormControlLabel
          key={`base-${name}`}
          value={name}
          className={classes.radioButton}
          control={
            <Radio
              color="primary"
              icon={<BasemapStyleSelection url={IMG_URLS[name]} />}
              checkedIcon={
                <BasemapStyleSelection url={IMG_URLS[name]} checked />
              }
            />
          }
          label={
            <Typography
              variant="subtitle1"
              className={value === name ? classes.fontBold : undefined}
            >
              {t(TRANSLATIONS[name])}
            </Typography>
          }
          onClick={() => onChange(name)}
        />
      ))}
    </RadioGroup>
  );
};

export default MapBaseLayerSelector;
