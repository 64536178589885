import { Backdrop, Box } from '@mui/material';
import CameraPlayer from 'components/CameraDetails/CameraPlayer';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { useAlertCamera } from 'hooks/useAlertCameras';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.common.black,
    paddingLeft: 'env(safe-area-inset-left)',
    paddingRight: 'env(safe-area-inset-right)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const MobileFullscreenCamera = (): JSX.Element | null => {
  const [showControls, setShowControls] = useState(true);
  const { classes } = useStyles();
  const { cameraId } = useParams<{ cameraId: string }>();

  const { alertCamera, isLoading, error } = useAlertCamera({
    id: cameraId,
    refetchInterval: 1000 * 15,
  });

  if (isLoading || error) {
    return <LoadingAndErrors isLoading={isLoading} progressColor="primary" />;
  }

  return (
    <div className={classes.container}>
      <Backdrop
        open
        onMouseDown={() => setShowControls((prev) => !prev)}
        sx={{
          zIndex: 1,
          backgroundColor: showControls ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        }}
      />

      <Box width="100%" height="100%">
        {alertCamera && (
          <CameraPlayer
            camera={alertCamera}
            fullScreen
            showControls={showControls}
          />
        )}
      </Box>
    </div>
  );
};
