import { useCallback, useEffect, useRef, useState } from 'react';
import { useMap, Marker, MarkerComponent } from 'shared/map-exports';
import distance from '@turf/distance';
import { useMeasureDistanceToolState } from 'state/useMeasureDistanceToolState';
import { Typography } from '@mui/material';
import {
  getOutsideLabelOffset,
  toLocaleStringWithAdaptiveFraction,
} from './utils';
import { getMajorTickStop } from './tickStops';
import { Circle } from './Circle';
import { Z_INDEX_LABELS } from './constants';
import { Measurements, UpdateRef } from './types';
import { Ticks } from './Ticks';
import { PointMarker } from './PointMarker';

type LabelMeasurements = {
  distanceMiles: number;
  angleRad: number;
};

export const MeasureDistanceToolLayer = (): JSX.Element | null => {
  const {
    points,
    isToolActive,
    onUpdateStartPointEnd,
    onUpdateStartPointStart,
    setStartPoint,
    setEndPoint,
  } = useMeasureDistanceToolState();
  const startRef = useRef<Marker | null>();
  const endRef = useRef<Marker | null>();
  const circleRef = useRef<UpdateRef>(null);
  const ticksRef = useRef<UpdateRef>(null);
  const [labelMeasurements, setLabelMeasurements] = useState<LabelMeasurements>(
    {
      distanceMiles: 0,
      angleRad: 0,
    },
  );
  const { current: mapRef } = useMap();

  // This method computes display properties for child components based on the
  // points from the distance tool state and the map's viewport. It stores state
  // locally for the display of the distance label (`setLabelMeasurements`), and
  // calls into update methods on the child components to update their display
  // CSS properties directly.
  //
  // This is a much more performant way to update the display than to rely on React's
  // render cycle to update the same CSS properties.
  const update = useCallback(() => {
    if (!mapRef || !startRef.current || !endRef.current) return;
    const map = mapRef.getMap();
    const start = startRef.current.getLngLat();
    const end = endRef.current.getLngLat();

    const startPointPixels = map.project(start);
    const endPointPixels = map.project(end);
    const x = startPointPixels.x - endPointPixels.x;
    const y = startPointPixels.y - endPointPixels.y;

    // TODO:gabe scale the zoom by the cosine of the latitude.
    const zoom = mapRef?.getMap().getZoom() || 0;
    const tickStop = getMajorTickStop(zoom, start.lat);

    const radiusPixels = Math.sqrt(x * x + y * y);
    const angleRad = Math.atan2(y, x) + Math.PI;

    const radiusMiles = distance([start.lng, start.lat], [end.lng, end.lat], {
      units: 'miles',
    });

    const measurements: Measurements = {
      radiusPixels,
      angleRad,
      radiusMiles,
      tickStop,
    };

    circleRef.current?.update(measurements);
    ticksRef.current?.update(measurements);
    setLabelMeasurements({
      distanceMiles: radiusMiles,
      angleRad,
    });
  }, [mapRef]);

  useEffect(() => {
    if (!isToolActive) return () => {};
    mapRef?.on('move', update);
    update();
    return () => {
      mapRef?.off('move', update);
    };
  }, [isToolActive, update, mapRef]);

  useEffect(() => {
    if (!isToolActive) return;
    update();
  }, [update, isToolActive, points]);

  if (!isToolActive || !points) {
    return null;
  }

  const distanceString = toLocaleStringWithAdaptiveFraction(
    labelMeasurements.distanceMiles,
    'mi',
  );
  const labelOffset = getOutsideLabelOffset(labelMeasurements.angleRad);
  return (
    <>
      <PointMarker
        ref={(r) => {
          startRef.current = r;
        }}
        latLng={points.start}
        onDragStart={onUpdateStartPointStart}
        onDragEnd={onUpdateStartPointEnd}
        onDrag={setStartPoint}
      />
      <PointMarker
        ref={(r) => {
          endRef.current = r;
        }}
        latLng={points.end}
        onDrag={setEndPoint}
      />
      <Circle ref={circleRef} center={points.start}>
        <Ticks ref={ticksRef} />
      </Circle>
      <MarkerComponent
        latitude={points.end.lat}
        longitude={points.end.lng}
        offset={labelOffset}
        style={{ zIndex: Z_INDEX_LABELS }}
      >
        <Typography
          variant="body1"
          style={{
            color: 'black',
            fontWeight: 900,
            textShadow:
              '-1.5px -1.5px 0 white, 1.5px -1.5px 0 white, -1.5px 1.5px 0 white, 1.5px 1.5px 0 white',
          }}
        >
          {distanceString}
        </Typography>
      </MarkerComponent>
    </>
  );
};
