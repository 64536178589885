import { useMemo } from 'react';
import { FieldErrors, FieldValues, Path, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import { getEmailInputSxProp, getEmailProviderFuzzyMatch } from './utils';

export const useEmailValidation = <T extends FieldValues>(input: {
  fieldKey: Path<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
}): { emailWarningText: string; emailFieldSxProps: SxProps } => {
  const { fieldKey, errors, watch } = input;
  const { t } = useTranslation();

  const emailFieldError = errors[fieldKey];
  const hasEmailError = Boolean(emailFieldError);
  const emailValue = watch(fieldKey);

  const emailFieldWarningText = useMemo((): string => {
    if (hasEmailError) return '';
    return getEmailProviderFuzzyMatch(t, emailValue);
  }, [hasEmailError, emailValue, t]);

  const sxProps = useMemo(
    (): SxProps =>
      getEmailInputSxProp({
        registered: emailFieldError?.type === 'registered',
      }),
    [emailFieldError],
  );

  return {
    emailWarningText: emailFieldWarningText,
    emailFieldSxProps: sxProps,
  };
};
