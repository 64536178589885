import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AlertCamera } from 'hooks/useAlertCameras';
import { UseAlertCameraTimelapseFramesReturn } from './useAlertCameraPlayerState.types';
import { fetchCameraTimeLapse } from './useAlertCameraPlayerState.utils';

export const useAlertCameraTimelapseFrames = (
  replayTime: number,
  camera?: AlertCamera,
  enabled = false,
): UseAlertCameraTimelapseFramesReturn => {
  const { isFetching, data, refetch } = useQuery({
    queryKey: ['camera-timelapse', camera?.id, replayTime],
    queryFn: async () => {
      const frames = await fetchCameraTimeLapse(
        camera!.id,
        replayTime.toString(),
      );
      return frames;
    },
    staleTime: 0,
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!camera?.id && enabled,
  });

  const timelapseFrames = useMemo(() => data || [], [data]);
  return {
    isFetchingTimelapseFrames: isFetching,
    timelapseFrames,
    refetchTimelapseFrames: refetch,
  };
};
