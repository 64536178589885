import { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NotificationSetting } from 'shared/types';
import { getSortedRegionsSections } from 'shared/utils';
import BlackButton from 'components/BlackButton';
import RegionCard from './RegionCard';
import { NotificationTypes } from '../../../constants';

type CountyNotificationsProps = {
  regionSettings: NotificationSetting[];
};

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  marginTop16: {
    marginTop: theme.spacing(2),
  },
}));

export const getActive = (
  regionSettings: NotificationSetting[],
): NotificationSetting[] =>
  regionSettings.filter(
    (regionSetting) => regionSetting.setting !== NotificationTypes.OFF.key,
  );

const CountyNotifications = (props: CountyNotificationsProps): JSX.Element => {
  const { regionSettings } = props;
  const { classes } = useStyles();
  const activeSettings = getActive(regionSettings);
  const { t } = useTranslation();
  const history = useHistory();

  const handleAddCounty = (): void => {
    history.push('/notifications/add_counties');
  };

  const regionSettingsSections = useMemo(
    () => getSortedRegionsSections(activeSettings, null),
    [activeSettings],
  );

  return (
    <Grid item container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <BlackButton fullWidth onClick={handleAddCounty}>
          {t('notifications.addCounty')}
        </BlackButton>
      </Grid>

      <Grid item xs={12} className={classes.marginTop16}>
        <Typography variant="h2">{t('notifications.notifications')}</Typography>
      </Grid>

      {regionSettingsSections.map((regionSection) => (
        <Grid item xs={12} key={regionSection.title}>
          <RegionCard
            title={regionSection.title}
            regionSettings={regionSection.regionSettings}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CountyNotifications;
