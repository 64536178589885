import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMap } from 'shared/map-exports';
import { parseCoordinate } from 'shared/utils';
import Tooltip from 'components/Tooltip';
import { useAuthState } from 'state';
import { API } from 'api';
import useClipboard from 'hooks/useClipboard';
import { usePoisState } from 'state/usePoisState';
import { LatLng } from 'shared/types';
import { Capacitor } from '@capacitor/core';

type LatLngMetadata = {
  region: {
    id: number;
    displayName: string;
    state: string;
    name: string; // AKA "slug"
  } | null;
  slackChannel: string;
  slackChannelId: string | null; // Can be null for archived channels
};

const SLACK_TEAM_ID = 'T025E476A3A';

const fetchMetadata = (
  coordinates: LatLng,
): Promise<AxiosResponse<LatLngMetadata>> =>
  API.get<LatLngMetadata>(`/lat_lng/metadata/`, {
    params: { lat: coordinates.lat, lng: coordinates.lng },
  });

// Reference:
// - Web: https://api.slack.com/reference/deep-linking#app_channel
// - Native: https://api.slack.com/reference/deep-linking#open_a_channel
const getSlackChannelDeepLink = (channelId: string): string =>
  Capacitor.isNativePlatform()
    ? `slack://channel?team=${SLACK_TEAM_ID}&id=${channelId}`
    : `https://slack.com/app_redirect?team=${SLACK_TEAM_ID}&channel=${channelId}`;

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(2, 6, 0.5, 2),
    lineHeight: '1.2 !important',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  actions: {
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    padding: '2px 0px',
    textDecoration: 'underline',
    pointerEvents: 'auto',
  },
  button: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.accent.main,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
    pointerEvents: 'auto',
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const MapPinDialogContent = (): JSX.Element | null => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { current: map } = useMap();
  const {
    permissions: { isInternalUser, canReport },
    showMembershipProFeatures,
  } = useAuthState();
  const { selectedPoi } = usePoisState();
  const { copied, copyText, reset } = useClipboard();

  const coordinates =
    selectedPoi?.type === 'mapPin' ? selectedPoi.coordinates : null;

  const metaDataQuery = useQuery({
    queryKey: ['metadata', coordinates?.lat, coordinates?.lng],
    queryFn: () => coordinates && fetchMetadata(coordinates),
    enabled: showMembershipProFeatures && !!coordinates,
  });

  if (!coordinates) {
    // It is not possible for the dialog to be shown without a mapPin POI type.
    // This early return is to make the linter happy and do so without any undesirable
    // side effects.
    return null;
  }

  const { isLoading, data } = metaDataQuery;

  const countyName = data?.data.region?.displayName ?? '...';
  const slackChannel = data?.data.slackChannel;
  const slackChannelId = data?.data.slackChannelId;

  const createRoutePathParams = new URLSearchParams({
    lat: coordinates.lat.toString(),
    lng: coordinates.lng.toString(),
    zoom: map?.getZoom().toString() ?? '',
  });

  const coordinatesString =
    coordinates &&
    `${parseCoordinate(coordinates.lat)}, ${parseCoordinate(coordinates.lng)}`;

  return (
    <Box sx={{ position: 'relative', minHeight: 96 }}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography component="span" variant="body2">
          <b>{countyName}</b>
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Tooltip
          title={t('common.copied')}
          open={copied}
          placement="top"
          onClose={reset}
          leaveDelay={200}
          sx={{ '.MuiTooltip-tooltip': { marginBottom: '0px !important' } }}
        >
          <MuiLink
            component="button"
            variant="body2"
            onClick={() => copyText(coordinatesString)}
            className={classes.link}
          >
            {coordinatesString}
          </MuiLink>
        </Tooltip>

        {isInternalUser && slackChannel && slackChannelId && (
          <MuiLink
            href={getSlackChannelDeepLink(slackChannelId)}
            target="_blank"
            variant="body2"
            className={classes.link}
          >
            {`#${slackChannel}`}
          </MuiLink>
        )}
      </DialogContent>

      {(isInternalUser || canReport) && (
        <DialogActions className={classes.actions}>
          <MuiLink
            to={`/incident/create?${createRoutePathParams.toString()}`}
            component={Link}
            variant="subtitle1"
            className={classes.button}
            underline="none"
          >
            {t('map.poi.mapPin.button')}
          </MuiLink>
        </DialogActions>
      )}

      {isLoading && (
        <Box className={classes.loaderContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
