import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  SHORT_MONTH_DAY_12HOUR_FORMAT,
  formatDate,
  formatDateDistanceToNow,
} from 'shared/dates';

export type PowerOutageDialogContentProps = {
  dateStart?: Date;
  expectedDateResolved?: Date;
  utilityCompany: string;
  impactedCustomerCount?: number;
  notes?: string;
};

export const PowerOutageDialogContent = (
  props: PowerOutageDialogContentProps,
): JSX.Element => {
  const { t } = useTranslation();

  const {
    dateStart,
    expectedDateResolved,
    utilityCompany,
    impactedCustomerCount,
    notes,
  } = props;

  const dateStartRelative =
    dateStart && formatDateDistanceToNow(dateStart, { addSuffix: true });
  const dateStartFormatted =
    dateStart && formatDate(dateStart, SHORT_MONTH_DAY_12HOUR_FORMAT);

  const dateResolvedFormatted = expectedDateResolved
    ? formatDate(expectedDateResolved, SHORT_MONTH_DAY_12HOUR_FORMAT)
    : '-';

  const attributeNameSx = { fontVariant: 'all-small-caps' };

  return (
    <>
      <DialogTitle
        sx={{
          padding: 2,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Typography component="span" variant="body1">
          <b>{t('map.poi.powerOutage.powerOutage')}</b>
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Typography variant="body2">
          <b>{utilityCompany}</b>
          <Typography variant="body2" component="span" color="grayText">
            {' ･ '}
          </Typography>
          <b>{dateStartRelative}</b>
          {dateStart && (
            <Typography variant="body2" component="span" color="grayText">
              {` ･ ${dateStartFormatted}`}
            </Typography>
          )}
        </Typography>
        <Grid container rowGap={0.5} sx={{ marginTop: 1 }}>
          <Grid item xs={6}>
            <Typography variant="body2" color="grayText" sx={attributeNameSx}>
              {t('map.poi.powerOutage.estimatedRestoration')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{dateResolvedFormatted}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="grayText" sx={attributeNameSx}>
              {t('map.poi.powerOutage.customersAffected')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{impactedCustomerCount}</Typography>
          </Grid>
          {Boolean(notes) && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="grayText"
                  sx={attributeNameSx}
                >
                  {t('map.poi.powerOutage.notes')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color={notes ? undefined : 'grayText'}
                >
                  {notes}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </>
  );
};
