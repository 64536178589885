import {
  Button,
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  Divider,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VectorLogo from 'components/VectorLogo';
import { useAuthState, useSnackbarState } from 'state';
import { API } from 'api';
import { registerDeviceWithWatchDuty } from 'shared/device';
import GrayButton from 'components/GrayButton';

type FormValues = {
  username: string;
  password: string;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    overflow: 'auto',
    width: '100%',
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
  },
}));

const initialValues: FormValues = {
  username: '',
  password: '',
};

const LoginForm = (): JSX.Element => {
  const history = useHistory();
  const { loginSuccess } = useAuthState();
  const { setSnackbar } = useSnackbarState();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t('login.inputs.password.required'))
      .min(3, t('login.inputs.password.error')),
    username: Yup.string().required(t('login.inputs.username.required')),
  });

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const { isPending, mutate } = useMutation({
    mutationFn: (newLogin: FormValues) => API.post('auth/login/', newLogin),
  });

  const handleLogin: SubmitHandler<FormValues> = (values) => {
    mutate(values, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess: (data: any) => {
        const key = data?.data?.key || null;
        const userData = data?.data?.user || null;
        const params = {
          user: userData,
        };
        registerDeviceWithWatchDuty(params);
        loginSuccess(key, userData);
        history.push('/');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.status !== 400) {
          setSnackbar(t('common.unknownErrorTryAgain'), 'error');
          return;
        }

        const {
          response: {
            data: { nonFieldErrors },
          },
        } = error;

        if (nonFieldErrors && nonFieldErrors.length === 1) {
          setSnackbar(nonFieldErrors[0], 'error');
          return;
        }

        // Generic error message
        setSnackbar(t('common.unknownError'), 'error');
      },
    });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(handleLogin)}
      className={classes.root}
      noValidate
    >
      <Container maxWidth="sm">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box sx={{ textAlign: 'center' }}>
              <VectorLogo size={74} sx={{ paddingTop: 3 }} />
            </Box>

            <Typography align="center" component="h2">
              <b>{t('login.welcome')}</b>
            </Typography>
          </Grid>

          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Controller
                name="username"
                control={control}
                render={({ field, fieldState }): JSX.Element => {
                  const { ref, ...muiFieldProps } = field;
                  return (
                    <TextField
                      id="field-control-username"
                      label={t('login.inputs.username.label')}
                      fullWidth
                      type="text"
                      {...muiFieldProps}
                      inputRef={ref}
                      inputProps={{ autoCapitalize: 'none' }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      required
                    />
                  );
                }}
              />
            </Grid>

            <Grid item>
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }): JSX.Element => {
                  const { ref, ...muiFieldProps } = field;
                  return (
                    <TextField
                      id="field-control-password"
                      label={t('login.inputs.password.label')}
                      fullWidth
                      type="password"
                      {...muiFieldProps}
                      inputRef={ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      required
                    />
                  );
                }}
              />
            </Grid>

            <Grid item>
              <Button
                disabled={isPending}
                type="submit"
                fullWidth
                className={classes.button}
              >
                {isPending ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  t('login.buttons.login')
                )}
              </Button>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                className={classes.button}
                component={Link}
                to="/reset_password"
                variant="outlined"
                color="inherit"
              >
                {t('login.forgotPassword')}
              </Button>
            </Grid>

            <Grid item>
              <Box py={1}>
                <Divider />
              </Box>
            </Grid>

            <Grid item>
              <Typography align="center" variant="subtitle1">
                {t('login.notAMember')}
              </Typography>
            </Grid>

            <Grid item>
              <GrayButton
                fullWidth
                className={classes.button}
                to="/support_us"
                component={Link}
              >
                {t('login.buttons.learnMore')}
              </GrayButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

export default LoginForm;
