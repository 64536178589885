import { useCallback, useMemo } from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { API } from 'api';
import { AxiosResponse } from 'axios';
import { getPushTokenFromLocalStorage } from 'state/localStorage';
import {
  NotificationOption,
  NotificationSettingForPut,
  NotificationSettingAPI,
  NotificationSetting,
  Region as NotificationRegion,
  QueryApiResult,
} from 'shared/types';
import { getAllNotificationRegions } from 'shared/utils';
import {
  NOTIFICATIONS_CACHE_TIME,
  NOTIFICATIONS_SETTINGS_QUERY_KEY,
  NotificationSettingTypes,
  NotificationSettingTypesCoverageOrder,
} from '../constants';
import { persistedQueryClient } from '../components/CacheableQueryClientProvider';

type UseNotificationsHookReturn = {
  putNotificationSettings: UseMutationResult<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    AxiosResponse<any>,
    unknown,
    NotificationSettingForPut[],
    unknown
  >;
  isSubscribedToAllForRegion: (regions: Region[]) => boolean;
  subscribedNotificationSettings: UseQueryResult<
    NotificationSettingAPI,
    unknown
  >;
  getMostCoverageSetting: (regions: Region[]) => NotificationOption;
  allNotificationSettings: QueryApiResult<NotificationSetting[]>;
};

// this partially types the region response - different API endpoints have more or less verbose concepts of region data
type Region = {
  id: number;
};

type UseNotificationsProps = {
  retryQuery?: boolean;
  queryEnabled?: boolean;
};

// This is used for the region subscription settings
export const useNotifications = (
  props: UseNotificationsProps = {},
): UseNotificationsHookReturn => {
  const { retryQuery, queryEnabled = true } = props;

  // If you import this, it will run the queries, so please be careful about the context (push token must be enabled)

  const pushToken = getPushTokenFromLocalStorage();

  const subscribedNotificationSettings = useQuery<NotificationSettingAPI>(
    {
      /**
       * By including the push token in the query key we make sure
       * query is retried when push token changes - helpful for when
       * a user toggles notifications permissions on the app
       */
      queryKey: [NOTIFICATIONS_SETTINGS_QUERY_KEY, pushToken],
      // Query is disabled if there's no push token
      queryFn: () =>
        API.get<NotificationSetting[]>('notifications/subscriptions/', {
          params: { push_token: pushToken, only_subscribed: true },
        }),
      staleTime: NOTIFICATIONS_CACHE_TIME,
      gcTime: NOTIFICATIONS_CACHE_TIME,
      retry: retryQuery,
      enabled: queryEnabled && !!pushToken,
    },
    persistedQueryClient,
  );

  const notificationRegionsQuery = useQuery(
    {
      queryKey: ['notification-regions'],
      queryFn: () =>
        API.get<NotificationRegion[]>('regions/', {
          params: { no_filter: true },
        }),
      staleTime: NOTIFICATIONS_CACHE_TIME,
      gcTime: NOTIFICATIONS_CACHE_TIME,
      retry: retryQuery,
      enabled: queryEnabled && !!pushToken,
    },
    persistedQueryClient,
  );

  const allNotificationSettings = useMemo(() => {
    const data = getAllNotificationRegions(
      notificationRegionsQuery.data?.data ?? [],
      subscribedNotificationSettings.data?.data ?? [],
    );
    return {
      data,
      isLoading:
        subscribedNotificationSettings.isLoading ||
        notificationRegionsQuery.isLoading,
      isError:
        subscribedNotificationSettings.isError ||
        notificationRegionsQuery.isError,
      isSuccess:
        subscribedNotificationSettings.isSuccess &&
        notificationRegionsQuery.isSuccess,
    };
  }, [
    notificationRegionsQuery.data?.data,
    notificationRegionsQuery.isError,
    notificationRegionsQuery.isLoading,
    notificationRegionsQuery.isSuccess,
    subscribedNotificationSettings.data?.data,
    subscribedNotificationSettings.isError,
    subscribedNotificationSettings.isLoading,
    subscribedNotificationSettings.isSuccess,
  ]);

  const putNotificationSettings = useMutation({
    mutationFn: (payload: NotificationSettingForPut[]) => {
      return API.put(
        `notifications/subscriptions/?push_token=${pushToken}`,
        payload,
      );
    },
    onSuccess: async () => {
      /**
       * We need to invalidate our persistent cache or the main page will not update after these individual PUT changes
       */
      persistedQueryClient.invalidateQueries({
        queryKey: [NOTIFICATIONS_SETTINGS_QUERY_KEY, pushToken],
      });
    },
  });

  // If you are subscribed to all for any matching region
  const isSubscribedToAllForRegion = useCallback(
    (regions: Region[]): boolean => {
      const settingsData = subscribedNotificationSettings.data?.data;
      if (!settingsData) {
        return false;
      }
      const regionIds = regions.map((region) => region.id);
      const matchingSettings = settingsData.filter(
        (setting) =>
          regionIds.includes(setting.region.id) &&
          (setting.setting === NotificationSettingTypes.all ||
            setting.setting === NotificationSettingTypes.allWithSilent),
      );
      return matchingSettings.length > 0;
    },
    [subscribedNotificationSettings],
  );

  const getMostCoverageSetting = useCallback(
    (regions: Region[]): NotificationOption => {
      // this may include multiple counties, but we want the region setting with the most coverage
      const settingsData = allNotificationSettings.data;
      if (!settingsData) {
        return NotificationSettingTypes.off;
      }
      const regionIds = regions.map((region) => region.id);
      const matchingOptions = settingsData
        .filter((setting) => regionIds.includes(setting.region.id))
        .map((setting) => setting.setting);
      if (!matchingOptions.length) {
        return NotificationSettingTypes.off;
      }
      const rankedOptions = matchingOptions.sort((a, b) => {
        const rankA = NotificationSettingTypesCoverageOrder.indexOf(a);
        const rankB = NotificationSettingTypesCoverageOrder.indexOf(b);
        return rankA - rankB;
      });
      return rankedOptions[0];
    },
    [allNotificationSettings],
  );

  return {
    isSubscribedToAllForRegion,
    putNotificationSettings,
    subscribedNotificationSettings,
    getMostCoverageSetting,
    allNotificationSettings,
  };
};
