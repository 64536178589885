import { MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import CameraPlayer from 'components/CameraDetails/CameraPlayer';
import {
  AlertCamera,
  useAlertCamera,
  useAlertCameras,
} from 'hooks/useAlertCameras';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import Map from 'components/Map';
import { AllMapLayers } from 'components/Map/constants';
import { AlertCamerasLayer } from 'components/Map/layers/AlertCamerasLayer';
import { useGeoEvents } from 'hooks/useGeoEvents';
import GeoEventsLayer from 'components/Map/layers/GeoEventsLayer';
import {
  getCameraAttributionImage,
  getRelatedCameras,
  isCameraOffline,
  isTimelapseSupported,
  getCameraAttributionLabelTransKey,
} from 'components/CameraDetails/CameraDetails.utils';
import { OfflineMessage } from 'components/CameraDetails/OfflineMessage';
import { CameraPreview } from 'components/CameraDetails/CameraPreview';

type CameraProps = {
  camera: AlertCamera;
};

const attributionToLogoUrl = {
  UCSD: '/images/camera-providers/alertca-white.png',
  ALERTWEST: '/images/camera-providers/alertwest-white.png',
  ALERTWILDFIRE: '/images/camera-providers/alertwildfire-white.png',
  UO: '/images/camera-providers/alertwestuo-white.png',
  UNR: '/images/camera-providers/alertwestunr-white.png',
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    overflowX: 'hidden',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(50vmin, 125vmin) 1fr',
    gap: '16px',
    '@media (max-width: 900px)': {
      gridTemplateColumns: '1fr',
    },
    '@media (max-height: 576px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (min-width: 2000px) and (max-height: 1000px)': {
      // For larger screens, should we even bother?
      gridTemplateColumns: '1fr 1fr',
    },
  },
  map: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    width: '100%',
    height: 282,
  },
}));

const Camera = (props: CameraProps): JSX.Element => {
  const { camera } = props;

  const cameraOffline = isCameraOffline(camera.imageTimestamp);

  if (cameraOffline) {
    return <OfflineMessage camera={camera} />;
  }

  if (isTimelapseSupported(camera)) {
    return (
      <CameraPlayer
        // key prop needed to force component to remount when cameraId
        // changes so the component's local state is reset; useful when
        // switching between related cameras
        key={camera.id}
        camera={camera}
        fullScreen
      />
    );
  }

  return <CameraPreview url={camera.imageUrl} />;
};

export const CameraFullscreenDetails = (): JSX.Element => {
  const { cameraId } = useParams<{ cameraId: string }>();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { alertCamera, isLoading, error } = useAlertCamera({
    id: cameraId,
    refetchInterval: 1000 * 15,
  });
  const {
    alertCameras: allCameras,
    isLoading: isLoadingAll,
    error: allErrors,
  } = useAlertCameras({ enabled: true });
  const { wildfireEvents } = useGeoEvents();

  const relatedCameras =
    alertCamera && allCameras?.length
      ? getRelatedCameras(alertCamera, allCameras, t)
      : [];

  const attributionImage = alertCamera
    ? getCameraAttributionImage(alertCamera, attributionToLogoUrl)
    : '';

  const handleClickAttribution = async (
    event: MouseEvent<HTMLAnchorElement>,
  ): Promise<void> => {
    event.preventDefault();

    if (!alertCamera) return;

    await Browser.open({
      url: alertCamera.cameraUrl,
      presentationStyle: 'popover',
    });
  };

  return (
    <div className={classes.root}>
      <Box className={classes.container}>
        {alertCamera && <Camera camera={alertCamera} />}

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h3" fontWeight="bold">
                  {alertCamera?.name}
                </Typography>
              </Grid>

              {alertCamera && (
                <Grid item xs={12}>
                  <div className={classes.map}>
                    <Map
                      center={alertCamera.latlng}
                      zoom={10}
                      noControls
                      disableMapLayers={AllMapLayers}
                    >
                      <AlertCamerasLayer visible interactive={false} />
                      <GeoEventsLayer
                        geoEvents={wildfireEvents}
                        isFadable
                        interactive={false}
                      />
                    </Map>
                  </div>
                </Grid>
              )}

              {alertCamera && relatedCameras.length > 1 && (
                <>
                  <Grid item xs={12}>
                    <Typography
                      id="additional-cameras-header"
                      variant="body1"
                      component="h4"
                      fontWeight="bold"
                    >
                      {t('alertCameras.additionalCameras')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      value={alertCamera.id}
                      variant="watchduty-gap"
                      aria-labelledby="additional-cameras-header"
                    >
                      {relatedCameras?.map((camera: AlertCamera) => (
                        <ToggleButton
                          color="primary"
                          key={camera.id}
                          value={camera.id}
                          size="large"
                          component={Link}
                          to={`/camera/${camera.id}/fullscreen`}
                          sx={{ minWidth: 48 }}
                        >
                          {camera.name}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {alertCamera && attributionImage && (
            <Grid item xs={12} alignSelf="flex-end">
              <Stack spacing={2}>
                <Typography
                  variant="subtitle1"
                  color="white"
                  textAlign="center"
                  textTransform="uppercase"
                  fontWeight="bold"
                >
                  {alertCamera &&
                    t(getCameraAttributionLabelTransKey(alertCamera))}
                </Typography>
                <a
                  href={alertCamera.cameraUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textAlign: 'center' }}
                  onClick={handleClickAttribution}
                >
                  <img
                    src={attributionImage}
                    alt={alertCamera.attribution}
                    style={{ maxWidth: '70%' }}
                  />
                </a>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>

      {(isLoading || isLoadingAll || error || allErrors) && (
        <LoadingAndErrors
          isLoading={isLoading || isLoadingAll}
          progressColor="primary"
        />
      )}
    </div>
  );
};
