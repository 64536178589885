import { capitalize } from 'lodash-es';
import { LayerEvacZone, EvacZoneStatus, Poi } from 'shared/types';
import {
  ADVISORIES_DEFAULT_COLOR,
  ADVISORIES_DEFAULT_PATTERN,
  ADVISORIES_GREEN_PATTERN,
  ADVISORIES_REVERSE_PATTERN,
  ADVISORIES_RSG_COLOR,
  EvacZoneStatuses,
  EvacZoneStyles,
  ORDERS_COLOR,
  ORDERS_PATTERN,
  ORDERS_REVERSE_PATTERN,
  WARNINGS_COLOR,
  WARNINGS_PATTERN,
} from '../../../../constants';
import { StyleStatusGrouping } from './types';
import getWatchdutyTileUrl from '../getWatchdutyTileUrl';
import { SOURCE_ID } from './constants';

/*
 *  Please note that the order from advisory -> warning -> orders ensures
 *  that the highest priority color on the stroke perimeters is on top where they touch
 */
export const createColorGroupings = (
  groupings: StyleStatusGrouping,
): Record<string, string[]> => ({
  [ADVISORIES_DEFAULT_COLOR]: [
    ...groupings[EvacZoneStyles.default][EvacZoneStatuses.advisories],
  ],
  [ADVISORIES_RSG_COLOR]: [
    ...groupings[EvacZoneStyles.readySetGo][EvacZoneStatuses.advisories],
    ...groupings[EvacZoneStyles.withLevels][EvacZoneStatuses.advisories],
    ...groupings[EvacZoneStyles.reverseColors][EvacZoneStatuses.orders],
  ],
  [WARNINGS_COLOR]: [
    ...groupings[EvacZoneStyles.default][EvacZoneStatuses.warnings],
    ...groupings[EvacZoneStyles.readySetGo][EvacZoneStatuses.warnings],
    ...groupings[EvacZoneStyles.withLevels][EvacZoneStatuses.warnings],
    ...groupings[EvacZoneStyles.reverseColors][EvacZoneStatuses.warnings],
  ],
  [ORDERS_COLOR]: [
    ...groupings[EvacZoneStyles.default][EvacZoneStatuses.orders],
    ...groupings[EvacZoneStyles.readySetGo][EvacZoneStatuses.orders],
    ...groupings[EvacZoneStyles.withLevels][EvacZoneStatuses.orders],
    ...groupings[EvacZoneStyles.reverseColors][EvacZoneStatuses.advisories],
  ],
});

export const createPatternGroupings = (
  groupings: StyleStatusGrouping,
): Record<string, string[]> => ({
  [ADVISORIES_DEFAULT_PATTERN]: [
    ...groupings[EvacZoneStyles.default][EvacZoneStatuses.advisories],
  ],
  [ADVISORIES_GREEN_PATTERN]: [
    ...groupings[EvacZoneStyles.readySetGo][EvacZoneStatuses.advisories],
    ...groupings[EvacZoneStyles.withLevels][EvacZoneStatuses.advisories],
  ],
  [ADVISORIES_REVERSE_PATTERN]: [
    ...groupings[EvacZoneStyles.reverseColors][EvacZoneStatuses.advisories],
  ],
  [WARNINGS_PATTERN]: [
    ...groupings[EvacZoneStyles.default][EvacZoneStatuses.warnings],
    ...groupings[EvacZoneStyles.readySetGo][EvacZoneStatuses.warnings],
    ...groupings[EvacZoneStyles.withLevels][EvacZoneStatuses.warnings],
    ...groupings[EvacZoneStyles.reverseColors][EvacZoneStatuses.warnings],
  ],
  [ORDERS_PATTERN]: [
    ...groupings[EvacZoneStyles.default][EvacZoneStatuses.orders],
    ...groupings[EvacZoneStyles.readySetGo][EvacZoneStatuses.orders],
    ...groupings[EvacZoneStyles.withLevels][EvacZoneStatuses.orders],
  ],
  [ORDERS_REVERSE_PATTERN]: [
    ...groupings[EvacZoneStyles.reverseColors][EvacZoneStatuses.orders],
  ],
});

export const getFillLayerId = (color: string): string => {
  return `layer_color_${color}`;
};

export const getActiveZonesLayerIds = (): string[] => {
  return [
    ADVISORIES_DEFAULT_COLOR,
    ADVISORIES_RSG_COLOR,
    WARNINGS_COLOR,
    ORDERS_COLOR,
  ].map(getFillLayerId);
};

export const getCountyNameFromSlug = (countySlug: string): string => {
  const [name] = countySlug.split('-');
  let countyName = '';
  name.split('_').forEach((nameItem) => {
    countyName += `${capitalize(nameItem)} `;
  });
  return countyName.trim();
};

export const switchZoneStatus = (
  currentStatus?: EvacZoneStatus,
): EvacZoneStatus | undefined => {
  const statuses: EvacZoneStatus[] = [
    EvacZoneStatuses.advisories,
    EvacZoneStatuses.warnings,
    EvacZoneStatuses.orders,
  ];
  const currentIndex = currentStatus ? statuses.indexOf(currentStatus) : -1;
  const nextIndex = currentIndex + 1 > statuses.length ? 0 : currentIndex + 1;
  return statuses[nextIndex];
};

export const createGroupings = (
  evacZones: LayerEvacZone[],
): StyleStatusGrouping => {
  const groups: StyleStatusGrouping = {
    [EvacZoneStyles.default]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: [],
    },
    [EvacZoneStyles.readySetGo]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: [],
    },
    [EvacZoneStyles.reverseColors]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: [],
    },
    [EvacZoneStyles.withLevels]: {
      [EvacZoneStatuses.warnings]: [],
      [EvacZoneStatuses.advisories]: [],
      [EvacZoneStatuses.orders]: [],
    },
  };

  evacZones.forEach((zone) => {
    if (!zone.status) {
      return;
    }
    const style = zone.style || EvacZoneStyles.default;
    groups[style][zone.status].push(zone.uidV2);
  });
  return groups;
};

export const getTilesUrl = (cacheBuster?: number | undefined): string => {
  return `${getWatchdutyTileUrl(SOURCE_ID)}${
    cacheBuster ? `?ts=${cacheBuster}` : ''
  }`;
};

export const getSelectedEvacZoneUId = (
  selectedPoi?: Poi,
  isActiveEvacZone: boolean = false,
): string => {
  const expectedType = isActiveEvacZone ? 'activeEvacZone' : 'evacZone';
  return selectedPoi?.id && selectedPoi.type === expectedType
    ? selectedPoi.id.toString()
    : '';
};
