import { useEffect, useState } from 'react';
import { Camera } from '@watchduty/camera';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import * as Sentry from '@sentry/capacitor';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { hasGeolocationPermissions } from 'hooks/usePosition';
import useForegroundState from 'hooks/useForegroundState';
import PhotoSubmissionForm from './PhotoSubmissionForm';
import PhotoSubmissionSuccess from './PhotoSubmissionSuccess';
import NoPermissionAccess from './NoPermissionAccess';

type PhotoSubmissionContentProps = {
  geoEventId?: number;
};

const PhotoSubmissionContent = (
  props: PhotoSubmissionContentProps,
): JSX.Element => {
  const { geoEventId } = props;
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasLocationAccess, setHasLocationAccess] = useState(true);
  const [hasCameraAccess, setHasCameraAccess] = useState(true);
  const { t } = useTranslation();
  const appState = useForegroundState();

  useEffect(() => {
    const checkPermissions = async (): Promise<void> => {
      try {
        const hasGeoLocationPermissions = await hasGeolocationPermissions();

        setHasLocationAccess(hasGeoLocationPermissions);
        if (!hasGeoLocationPermissions) {
          return;
        }

        let permissionStatus = await Camera.checkPermissions();
        if (permissionStatus.camera !== 'granted') {
          permissionStatus = await Camera.requestPermissions({
            permissions: ['camera'],
          });
          setHasCameraAccess(permissionStatus.camera === 'granted');
        }
      } catch (err) {
        Sentry.captureException(err);
        setError(true);
      }
    };

    if (appState.isActive) {
      checkPermissions();
    }
  }, [appState.isActive]);

  if (error) {
    return <LoadingAndErrors />;
  }

  if (!hasLocationAccess) {
    return (
      <NoPermissionAccess
        title={t('photoSubmission.noLocation.title')}
        description={
          <>
            <Typography>
              {t('photoSubmission.noLocation.description1')}
            </Typography>
            <Typography>
              {t('photoSubmission.noLocation.description2')}
            </Typography>
          </>
        }
        buttonTitle={t('photoSubmission.noLocation.button')}
      />
    );
  }

  if (!hasCameraAccess) {
    return (
      <NoPermissionAccess
        title={t('photoSubmission.noCamera.title')}
        description={t('photoSubmission.noCamera.description')}
        buttonTitle={t('photoSubmission.noCamera.button')}
      />
    );
  }

  if (success) {
    return <PhotoSubmissionSuccess />;
  }

  return (
    <PhotoSubmissionForm geoEventId={geoEventId} setSuccess={setSuccess} />
  );
};

export default PhotoSubmissionContent;
