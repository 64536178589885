import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, Link } from 'react-router-dom';
import { getResponsiveFontSize } from 'shared/utils';
import { useAuthState } from 'state';
import useDrawerNavState from 'state/useDrawerNavState';
import useGeoEventQuery from 'hooks/useGeoEventQuery';
import { useEffect, useMemo } from 'react';
import HeartIcon from 'assets/header-heart.svg?react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, useTheme } from '@mui/material';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import TextLogo from '../TextLogo';
import ButtonCamera from './ButtonCamera';
import MenuBadge from './MenuBadge';
import DrawerNav from './DrawerNav';
import { AdminActions } from './AdminActions';
import { GeoEvent, Location } from '../../shared/types';

type HeaderBarProps = {
  title?: string;
  action?: string;
  hideAction?: boolean;
  showSupportUs?: boolean;
  dark?: boolean;
  bgColor?: string;
  elevation?: number;
};

const getAppBgColor = (
  theme: Theme,
  dark: boolean,
  bgColor?: string,
): string => {
  const { palette } = theme;
  // Show our reporters, etc that they are on staging
  let appBgColor =
    import.meta.env.VITE_ENV === 'staging'
      ? '#008000' /* green */
      : palette.primary.main;
  if (dark) appBgColor = palette.common.black;
  if (bgColor) appBgColor = bgColor;
  return appBgColor;
};

const useStyles = makeStyles<{ dark: boolean; bgColor?: string }>()((
  theme,
  { dark, bgColor },
) => {
  const { palette, spacing, typography } = theme;
  const appBgColor = getAppBgColor(theme, dark, bgColor);

  return {
    menuButton: {
      marginRight: spacing(1),
      // hard-coding to prevent iOS Dynamic Type from resizing this
      width: 48,
      height: 48,
    },
    menuIcon: {
      width: 24,
      height: 24,
      color: dark ? palette.common.white : palette.text.primary,
    },
    appBarMiddle: {
      flexGrow: 1,
      minWidth: 0,
    },
    title: {
      fontSize: getResponsiveFontSize(typography.pxToRem(20)),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    textLogo: {
      // hard-coding to prevent iOS Dynamic Type from resizing this
      width: 144, // 9rem
      fill: dark ? palette.common.white : palette.text.primary,
    },
    appBar: {
      flexGrow: 0,
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
      paddingTop: `env(safe-area-inset-top)`,
      position: 'sticky',
      backgroundColor: appBgColor,
      top: 0,
      zIndex: theme.zIndex.drawer + 3,
    },
  };
});

const HeaderBar = (props: HeaderBarProps): JSX.Element => {
  const {
    title,
    action,
    hideAction,
    showSupportUs,
    dark = false,
    bgColor,
    elevation = 4,
  } = props;
  const history = useHistory();
  const {
    permissions: { canReport, isRxOnlyReporter },
    hasActiveMembership,
  } = useAuthState();
  const { classes } = useStyles({ dark, bgColor });
  const { open, toggleOpen } = useDrawerNavState();
  const { t } = useTranslation();

  const { geoEvent } = useGeoEventQuery<GeoEvent | Location>();

  const geoEventId =
    geoEvent?.data.geoEventType === 'wildfire' ? geoEvent.id : undefined;
  const locationId =
    geoEvent?.data.geoEventType === 'location' ? geoEvent.id : undefined;

  const editPermissions = useMemo(() => {
    if (!geoEvent) return false;
    if (geoEvent.geoEventType !== 'wildfire') {
      return canReport && !isRxOnlyReporter;
    }
    if (!isRxOnlyReporter) return true;
    return geoEvent.data.isPrescribed;
  }, [geoEvent, isRxOnlyReporter, canReport]);
  const automatedGeoEvent = !geoEvent?.reporterManaged;
  const showAdmin = canReport && (!geoEventId || editPermissions);
  // we want to see where people go with no pre-selection if they are not already members
  const donateLink = hasActiveMembership
    ? '/support_us/donate'
    : '/support_us/';

  const getButtonAppBar = (): JSX.Element => {
    if (action) {
      if (action === 'noControls') {
        return <></>;
      }
      return (
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => {
            // We can't always allow action="back" because that could take the user out of Watch Duty. For now, we're opting into this with a special URL #hash
            // @see https://trello.com/c/Ywn5UB9H/279-refactor-page-history-mechanism
            if (action === 'back' || window.location.hash === '#allow-back') {
              // history does indeed have a goBack function
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              history.goBack();
            } else {
              history.push(action);
            }
          }}
          size="large"
        >
          <ArrowBackIcon className={classes.menuIcon} />
        </IconButton>
      );
    }
    return (
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleOpen}
        size="large"
      >
        <MenuBadge />
      </IconButton>
    );
  };

  const ActionComponent = showAdmin ? (
    <AdminActions
      automatedGeoEvent={automatedGeoEvent}
      geoEventId={geoEventId}
      locationId={locationId}
    />
  ) : (
    <ButtonCamera geoEventId={geoEventId} />
  );

  const theme = useTheme();
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      const appBgColor = getAppBgColor(theme, dark, bgColor);
      StatusBar.setBackgroundColor({ color: appBgColor });
      StatusBar.setStyle({ style: dark ? Style.Dark : Style.Light });
    }
  }, [dark, theme, bgColor]);

  return (
    <AppBar className={classes.appBar} elevation={elevation}>
      <Toolbar>
        {getButtonAppBar()}

        <Box className={classes.appBarMiddle}>
          {title ? (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          ) : (
            <TextLogo className={classes.textLogo} />
          )}
        </Box>

        {showSupportUs && (
          <IconButton
            sx={{ marginRight: hideAction ? 0 : 2 }}
            to={donateLink}
            component={Link}
            aria-label={t('common.supportUs')}
          >
            <HeartIcon />
          </IconButton>
        )}

        {!hideAction && ActionComponent}
      </Toolbar>

      <DrawerNav open={open} toggleOpen={toggleOpen} />
    </AppBar>
  );
};

export default HeaderBar;
