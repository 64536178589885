import { AgenciesObject } from './types';

export const FEDERAL_LANDS_SOURCE_ID = 'federal-lands-ownership-source';
export const FEDERAL_LANDS_SOURCE_LAYER = 'federal';

export const FEDERAL_LANDS_AGENCIES = {
  landManagment: 'Bureau of Land Management',
  reclamation: 'Bureau of Reclamation',
  defense: 'Department of Defense',
  fishWild: 'Fish and Wildlife Service',
  forest: 'Forest Service',
  nationalPark: 'National Park Service',
} as AgenciesObject;

export const COLOR_BUREAU_LAND_MANAGMENT = '#FFEC8B';
export const COLOR_BUREAU_RECLAMATION = '#FFFF93';
export const COLOR_DEPARTMENT_DEFENSE = '#F7A2C3';
export const COLOR_FISH_WILD = '#90DEB0';
export const COLOR_FOREST_SERVICE = '#BBF3A6';
export const COLOR_NATIONAL_PARK = '#C3AAEA';

export const LAYER_FILL_OPACITY = 0.5;
export const LAYER_BORDER_OPACITY = 1;
export const LAYER_TEXT_OPACITY = 1;

export const MIN_ZOOM_VISIBILITY = 10;
