import { Layer } from 'shared/map-exports';
import { useCallback } from 'react';
import { usePoisState } from 'state/usePoisState';
import { MapLayerProps } from 'components/Map/types';
import { useTranslation } from 'react-i18next';
import addVisible from 'shared/addVisible';
import ElectricLineDialogContent from './DialogContent';
import { ElectricLineType } from './types';
import {
  ELECTRICAL_MAPTILES_DIRECTORY,
  ELECTRICAL_MAP_INTERACTIVE_LAYER_ID,
  ELECTRICAL_MAP_LAYER_ID,
  ELECTRICAL_SOURCE_DATA_LAYER,
} from './constants';
import {
  getSelectedStrokeStyle,
  electricalLayerStyle,
  layerStyleLineCasingElectrical,
  getInteractiveLayerStyle,
} from './layerStyles';
import { MapboxFeature, useMapLayerEvents } from '../useMapLayerEvents';
import VisibilityToggledSource from '../VisibilityToggledSource';
import getWatchdutyTileUrl from '../getWatchdutyTileUrl';

const ElectricLinesLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const { selectedPoi, setSelectedPoi } = usePoisState();
  const { t } = useTranslation();

  const selectedLineUId =
    (selectedPoi?.type === 'electricalLine' && selectedPoi.id) || '';

  const selectedStrokeStyle = getSelectedStrokeStyle({
    selectedId: selectedLineUId,
    sourceLayer: ELECTRICAL_SOURCE_DATA_LAYER,
  });

  useMapLayerEvents({
    layerId: ELECTRICAL_MAP_INTERACTIVE_LAYER_ID,
    onClick: useCallback(
      (features: MapboxFeature<ElectricLineType>[]) => {
        for (const feature of features) {
          const electricLine: ElectricLineType = feature.properties;
          setSelectedPoi({
            type: 'electricalLine',
            id: electricLine.ID,
            PoiDialogContent: () => (
              <ElectricLineDialogContent
                title={t('map.poi.electricalLines.title')}
                list={[
                  {
                    name: t('map.poi.electricalLines.status'),
                    value: electricLine.STATUS,
                  },
                  {
                    name: t('map.poi.electricalLines.type'),
                    value: electricLine.TYPE,
                  },
                  {
                    name: t('map.poi.electricalLines.voltClass'),
                    value: `${electricLine.VOLT_CLASS} kV`,
                  },
                  {
                    name: t('map.poi.electricalLines.voltage'),
                    value: `${electricLine.VOLTAGE} kV`,
                  },
                  {
                    name: t('map.poi.electricalLines.owner'),
                    value: electricLine.OWNER,
                  },
                ]}
              />
            ),
          });
        }
      },
      [setSelectedPoi, t],
    ),
  });

  return (
    <VisibilityToggledSource
      visible={visible}
      url={getWatchdutyTileUrl(ELECTRICAL_MAPTILES_DIRECTORY)}
    >
      <Layer {...addVisible(layerStyleLineCasingElectrical, visible)} />
      <Layer
        id={ELECTRICAL_MAP_LAYER_ID}
        {...addVisible(electricalLayerStyle, visible)}
      />
      <Layer
        id={ELECTRICAL_MAP_INTERACTIVE_LAYER_ID}
        {...addVisible(
          getInteractiveLayerStyle(ELECTRICAL_SOURCE_DATA_LAYER),
          visible,
        )}
      />
      <Layer {...addVisible(selectedStrokeStyle, visible)} />
    </VisibilityToggledSource>
  );
};

export default ElectricLinesLayer;
