import {
  useMutation,
  UseMutationResult,
  useQuery,
} from '@tanstack/react-query';
import { API } from 'api';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { getPushTokenFromLocalStorage } from '../state/localStorage';
import { persistedQueryClient } from '../components/CacheableQueryClientProvider';
import { NOTIFICATIONS_CACHE_TIME } from '../constants';

type TopicSubscription = {
  id: number;
  topic: number;
  user: number;
  device: string;
  isActive: boolean;
  dateCreated: string;
  dateModified: string;
};

type TopicSubscriptionListAPI = {
  data: TopicSubscription[];
};

type UseTopicSubscriptionsHookReturn = {
  getSubscriptionToTopic: (topicId: number) => TopicSubscription | null;
  subscribeToTopic: UseMutationResult<
    AxiosResponse<unknown>,
    unknown,
    number,
    unknown
  >;
  unsubscribe: UseMutationResult<
    AxiosResponse<unknown>,
    unknown,
    number,
    unknown
  >;
};

export const useTopicSubscriptions = (): UseTopicSubscriptionsHookReturn => {
  const pushToken = getPushTokenFromLocalStorage();
  const subscribedTopicsQueryKey = 'subscribedTopics';

  const clearTopicsCache = (): void => {
    persistedQueryClient.invalidateQueries({
      queryKey: [subscribedTopicsQueryKey],
    });
  };

  // This is only used for subscriptions to individual incident topics
  const topicSubscriptions = useQuery<TopicSubscriptionListAPI>(
    {
      queryKey: [subscribedTopicsQueryKey],
      queryFn: () =>
        API.get<TopicSubscription[]>(`notifications/topics/subscription/`, {
          params: { push_token: pushToken },
        }),
      staleTime: NOTIFICATIONS_CACHE_TIME,
      gcTime: NOTIFICATIONS_CACHE_TIME,
      /**
       * We need to disable this query if there's no push notification.
       * Critical for use case when user denied permissions but enabled them
       * by clicking on the switch notifications button inside any incident.
       */
      enabled: !!pushToken,
    },
    persistedQueryClient,
  );

  const getSubscriptionToTopic = useCallback(
    (topicId: number): TopicSubscription | null => {
      // This is only used for subscriptions to individual incident topics
      const { data } = topicSubscriptions;
      if (!data) return null;

      const topicSubscription = data.data.find(
        (subscription: TopicSubscription) => subscription.topic === topicId,
      );
      if (!topicSubscription) {
        return null;
      }

      return topicSubscription;
    },
    [topicSubscriptions],
  );

  // This is only used for subscriptions to individual incident topics
  const subscribeToTopic = useMutation({
    mutationFn: (topicId: number) => {
      const params = {
        device: { push_token: pushToken },
        is_active: true,
        topic: topicId,
      };
      return API.post(`notifications/topics/subscription/?`, params);
    },
    onSuccess: clearTopicsCache,
  });

  // This is only used for subscriptions to individual incident topics
  const unsubscribe = useMutation({
    mutationFn: (topicSubscriptionId: number) =>
      API.delete(`notifications/topics/subscription/${topicSubscriptionId}/`),
    onSuccess: clearTopicsCache,
  });

  return {
    getSubscriptionToTopic,
    subscribeToTopic,
    unsubscribe,
  };
};
