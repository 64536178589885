import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMap, LngLatBounds } from 'shared/map-exports';
import { QueryKey, useQueries } from '@tanstack/react-query';
import { FeatureCollection } from 'geojson';
import { TileSetType } from 'shared/types';

export type Geometry = {
  xmin: number;
  xmax: number;
  ymin: number;
  ymax: number;
};

type UseTiledQueriesProps = {
  enabled: boolean;
  // Size of one edge of tile (in degrees lat/lon)
  tileSize: number;
  staleTime: number;
  queryKey: QueryKey;
  queryFn: (tileGeometry: Geometry) => Promise<FeatureCollection>;
  mergeFn: (data: FeatureCollection[]) => FeatureCollection;
  refetchInterval?: number;
  replaceTiles?: boolean;
};

const getTileKey = (x: number, y: number): string => `${x},${y}`;

const floorToClosest = (num: number, frac: number): number =>
  frac * Math.floor((1 / frac) * num);

const ceilToClosest = (num: number, frac: number): number =>
  frac * Math.ceil((1 / frac) * num);

const useTileSet = (tileSize: number, replaceTiles: boolean): TileSet => {
  const { current: map } = useMap();
  const tileSet = useMemo(
    () => new TileSet(tileSize, map?.getBounds() || undefined),
    [map, tileSize],
  );
  const [, setTilesMemo] = useState<string>();

  // Watch for changes in the map viewport and expand TileSet
  // as needed.
  const handleViewportChange = useCallback(() => {
    tileSet.expandToBounds(map?.getBounds() || undefined, replaceTiles);
    // Update string representation of all the tiles in the TileSet.
    // When it changes, react will re-render and update the map.
    setTilesMemo(tileSet.tileKeys.join('/'));
  }, [map, tileSet, replaceTiles]);

  useEffect(() => {
    map?.on('moveend', handleViewportChange);
    return () => {
      map?.off('moveend', handleViewportChange);
    };
  }, [handleViewportChange, map]);

  return tileSet;
};

/**
 * If "replaceTiles" is set to true, tiles will be reset every time an event on
 * the map (pan, zoom, etc.) is fired, and "tileSet" will only be calculated based
 * on the current map bounding box. Otherwise, tile sets will be pushed into the
 * existing tile set, and queries will be accumulated.
 *
 * Note: if enabled:false after data has already been fetched, the old data will still be served, so
 *   a visibility style is also needed to not show it in this case.
 *
 */
const useTiledQueries = ({
  enabled,
  tileSize,
  staleTime,
  queryKey,
  queryFn,
  mergeFn,
  refetchInterval,
  replaceTiles = false,
}: UseTiledQueriesProps): FeatureCollection => {
  const tileSet = useTileSet(tileSize, replaceTiles);
  const queries = Object.entries(tileSet.tiles).map(
    ([tileKey, tileGeometry]) => ({
      queryKey: [...queryKey, tileKey],
      queryFn: () => queryFn(tileGeometry),
      enabled,
      staleTime,
      refetchInterval,
    }),
  );
  const results = useQueries({ queries });
  // Create an array of results that are ready and preserve the original index of that result
  // within the `queries` array. The indexes of items in the `queries` array matches those in
  // `tileSet.tiles`.
  const readyResults = results
    .map((result, i) => ({ result, i }))
    .filter((e) => e.result.isSuccess);
  const readyKeys = readyResults.map(
    ({ result, i }) => `${tileSet.tileKeys[i]}/${result.dataUpdatedAt}`,
  );
  const readyData = readyResults
    .map(({ result }) => result.data)
    .filter(Boolean) as FeatureCollection[];

  return useMemo(
    () => mergeFn(readyData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readyKeys.join(',')],
  );
};

class TileSet {
  tileSize: number;

  tileKeys: string[];

  tiles: Record<string, TileSetType>;

  constructor(tileSize: number, bounds?: LngLatBounds) {
    this.tileSize = tileSize;
    this.tileKeys = [];
    this.tiles = {};
    this.expandToBounds(bounds);
  }

  expandToBounds = (bounds?: LngLatBounds, replaceTiles?: boolean): void => {
    if (!bounds) {
      return;
    }

    if (replaceTiles) {
      this.tileKeys = [];
      this.tiles = {};
    }
    // Ensure that every tile in the extent that includes `bounds`
    // is present in `this.tiles`.
    const ymin = floorToClosest(bounds.getSouth(), this.tileSize);
    const ymax = ceilToClosest(bounds.getNorth(), this.tileSize);
    const xmin = floorToClosest(bounds.getWest(), this.tileSize);
    const xmax = ceilToClosest(bounds.getEast(), this.tileSize);
    for (let y = ymin; y < ymax; y += this.tileSize) {
      for (let x = xmin; x < xmax; x += this.tileSize) {
        const key = getTileKey(x, y);
        this.tileKeys.push(key);
        this.tiles[key] = {
          xmin: x,
          ymin: y,
          xmax: x + this.tileSize,
          ymax: y + this.tileSize,
        };
      }
    }
  };
}

export default useTiledQueries;
