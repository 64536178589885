import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { PlaceLocation } from 'shared/types';
import { replaceAtIndex } from 'shared/utils';
import { localStorageEffect, LOCAL_STORAGE_KEY } from './localStorage';

type MapPlacesState = {
  places: PlaceLocation[];
  updatedAt: number;
};

type UseMapPlacesState = {
  setMapPlaces: (places: PlaceLocation[], updatedAt?: number) => void;
  addMapPlace: (placeLocation: PlaceLocation, updatedAt?: number) => void;
  deleteMapPlace: (placeId: number, updatedAt?: number) => void;
  updateMapPlace: (placeLocation: PlaceLocation, updatedAt?: number) => void;
  places: PlaceLocation[];
  updatedAt: number;
};

const DEFAULT_MAP_PLACES_STATE: MapPlacesState = {
  places: [],
  updatedAt: 0,
};

export const mapPlacesStateAtom = atom({
  key: LOCAL_STORAGE_KEY.MAP_PLACES,
  default: DEFAULT_MAP_PLACES_STATE,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.MAP_PLACES)],
});

const useMapPlacesState = (): UseMapPlacesState => {
  const [mapPlacesState, setMapPlacesState] =
    useRecoilState(mapPlacesStateAtom);

  const setMapPlaces = useCallback(
    (places: PlaceLocation[], updatedAt?: number) => {
      setMapPlacesState({ places, updatedAt: updatedAt || Date.now() });
    },
    [setMapPlacesState],
  );

  const addMapPlace = useCallback(
    (placeLocation: PlaceLocation, updatedAt?: number) => {
      setMapPlacesState((prev) => ({
        places: [placeLocation, ...prev.places],
        updatedAt: updatedAt || Date.now(),
      }));
    },
    [setMapPlacesState],
  );

  const deleteMapPlace = useCallback(
    (placeId: number, updatedAt?: number) => {
      setMapPlacesState((prev) => ({
        places: prev.places.filter((place) => place.id !== placeId),
        updatedAt: updatedAt || Date.now(),
      }));
    },
    [setMapPlacesState],
  );

  const updateMapPlace = useCallback(
    (placeLocation: PlaceLocation, updatedAt?: number) => {
      const placeIdx = mapPlacesState.places.findIndex(
        (place) => place.id === placeLocation.id,
      );

      if (placeIdx < 0) return;

      const mapPlaces = replaceAtIndex<PlaceLocation>(
        mapPlacesState.places,
        placeIdx,
        placeLocation,
      );

      setMapPlacesState({
        places: mapPlaces,
        updatedAt: updatedAt || Date.now(),
      });
    },
    [mapPlacesState.places, setMapPlacesState],
  );

  return {
    ...mapPlacesState,
    setMapPlaces,
    addMapPlace,
    deleteMapPlace,
    updateMapPlace,
  };
};

export default useMapPlacesState;
