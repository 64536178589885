import { LONG_PRESS_TIME } from 'components/IncidentsMap/constants';
import { useCallback, useRef } from 'react';
import { atom, useRecoilCallback } from 'recoil';
import { LatLng } from 'shared/types';
import { MapLayerTouchEvent } from 'shared/map-exports';

import { useAuthState } from 'state';

type UseMapLongPressType = {
  onTouchStart: (e: MapLayerTouchEvent) => void;
  clearMobileTimeout: () => void;
};

type MapLongPressPropsType = {
  onLongPress: (coordinates: LatLng) => void;
};

const allowLongPressAtom = atom({
  key: 'allowLongPress',
  default: true,
});

const useMapLongPress = (props: MapLongPressPropsType): UseMapLongPressType => {
  const { onLongPress } = props;
  const { showMembershipProFeatures } = useAuthState();

  const longPressTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearMobileTimeout = useCallback((): void => {
    if (!showMembershipProFeatures) {
      return;
    }
    clearTimeout(longPressTimeout.current!);
  }, [longPressTimeout, showMembershipProFeatures]);

  // Use a Recoil callback to read the atom value instead of using `useRecoilState`.
  // The latter results in a re-render of the component that calls this hook, which
  // results in React state management issues.
  //
  // `useRecoilCallback` allows for modifying the state without triggering re-renders.
  const onTouchStart = useRecoilCallback(({ snapshot }) => {
    return async (e: MapLayerTouchEvent): Promise<void> => {
      if (e.originalEvent.touches.length > 1) {
        return;
      }
      const allowLongPress = await snapshot.getPromise(allowLongPressAtom);
      longPressTimeout.current = setTimeout(async () => {
        if (!allowLongPress) {
          return;
        }
        onLongPress(e.lngLat);
      }, LONG_PRESS_TIME);
    };
  }, []);

  return {
    onTouchStart,
    clearMobileTimeout,
  };
};

/**
 * This hook can be used to short-circuit calls to `onTouchStart()` on a temporary
 * basis, ie, to prevent the long press from being triggered when the user is dragging
 * a map marker around.
 */
export const useAllowMapLongPress = (): ((allow: boolean) => void) => {
  // Use a Recoil callback to set the atom value instead of using `useRecoilState`.
  // The latter results in a re-render of the component that calls this hook, which
  // results in React state management issues.
  //
  // `useRecoilCallback` allows for modifying the state without triggering re-renders.
  const setAllowLongPress = useRecoilCallback(({ set }) => {
    return (allow: boolean): void => {
      set(allowLongPressAtom, allow);
    };
  });

  return setAllowLongPress;
};

export default useMapLongPress;
