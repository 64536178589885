import { Menu, MenuItem, Typography } from '@mui/material';
import { AlertCamera } from 'hooks/useAlertCameras';
import { PopupState, bindMenu } from 'material-ui-popup-state/hooks';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { parseReplayTime } from './CameraDetails.utils';

type CameraReplayTimeMenuProps = {
  camera: AlertCamera;
  isLoading: boolean;
  fullScreen: boolean;
  popupState: PopupState;
  setTimelapseReplayTime: (replayTime: number) => void;
};

const REPLAY_TIME_OPTIONS_SECONDS = [
  60 * 5, // 5 minutes
  60 * 10, // 10 minutes
  60 * 15, // 15 minutes
  60 * 30, // 30 minutes
  60 * 60, // 1 hour
  60 * 60 * 2, // 2 hours
  60 * 60 * 3, // 3 hours
  60 * 60 * 6, // 6 hours
];

const isReplayTimeEnabledForCamera = (
  camera: AlertCamera,
  replayTime: number,
): boolean => camera.hasPtz || replayTime / 60 >= 30;

const useStyles = makeStyles<{ fullScreen: boolean }>()(
  (theme, { fullScreen }) => ({
    menuPaper: {
      backgroundColor: fullScreen
        ? theme.palette.background.paper
        : theme.palette.grey[900],
      color: fullScreen
        ? theme.palette.text.primary
        : theme.palette.background.paper,
    },
  }),
);

export const CameraReplayTimeMenu = (
  props: CameraReplayTimeMenuProps,
): JSX.Element => {
  const { camera, isLoading, fullScreen, popupState, setTimelapseReplayTime } =
    props;
  const { classes } = useStyles({ fullScreen });
  const { t } = useTranslation();

  const handleSetReplayTime = (time: number): void => {
    setTimelapseReplayTime(time);
    popupState.close();
  };

  return (
    <Menu
      {...bindMenu(popupState)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      classes={{ paper: classes.menuPaper }}
    >
      {REPLAY_TIME_OPTIONS_SECONDS.map((time) => (
        <MenuItem
          key={time}
          sx={{ padding: '4px 16px' }}
          onClick={() => handleSetReplayTime(time)}
          disabled={isLoading || !isReplayTimeEnabledForCamera(camera, time)}
        >
          <Typography key={time} variant="subtitle1" component="p">
            {parseReplayTime(time, t)}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
