import { MenuItem, TextField } from '@mui/material';
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReportAsset, Report, FormFieldProps } from 'shared/types';
import { makeStyles } from 'tss-react/mui';
import { ASSET_OPTIONS } from './MediaForm.constants';

type AssetFieldProps<TName extends FieldPath<FieldValues>> =
  FormFieldProps<TName> & {
    latFieldName: string;
    lngFieldName: string;
    azFieldName: string;
    report?: Report;
  };

const useStyles = makeStyles()((theme) => ({
  notificationLabel: {
    textTransform: 'capitalize',
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    '& #field-control-asset': {
      color: theme.palette.text.secondary,
    },
  },
}));

const AssetTypeField = <TName extends FieldPath<FieldValues>>(
  props: AssetFieldProps<TName>,
): JSX.Element => {
  const { field, fieldState, latFieldName, lngFieldName, azFieldName, report } =
    props;
  const { ref, onChange, ...muiFieldProps } = field;
  const { cx, classes } = useStyles();
  const { t } = useTranslation();
  const { setValue, formState } = useFormContext();

  const handleChangeAssetType = (input: {
    currentValue: ReportAsset | 'placeholder';
    newValue: ReportAsset | 'placeholder';
  }): void => {
    const { currentValue, newValue } = input;

    if (currentValue === newValue) {
      return;
    }

    if (currentValue === 'image') {
      setValue('media', null);
      setValue('fileType', '');
      setValue(latFieldName, '');
      setValue(lngFieldName, '');
      setValue(azFieldName, 0);
    } else if (currentValue === 'alertCamera') {
      setValue('media', null);
    } else if (currentValue === 'socialEmbed') {
      setValue('embedUrl', '');
    }

    setValue('asset', newValue);
  };

  return (
    <TextField
      select
      id="field-control-asset"
      label={t('addIncidentReport.inputs.asset.label')}
      fullWidth
      {...muiFieldProps}
      value={field.value ?? 'placeholder'}
      onChange={(e) =>
        handleChangeAssetType({
          currentValue: field.value,
          newValue: (e.target.value === 'placeholder'
            ? null
            : e.target.value) as ReportAsset | 'placeholder',
        })
      }
      inputRef={ref}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      className={cx(
        classes.notificationLabel,
        !field.value && classes.textSecondary,
      )}
      disabled={formState.isSubmitting || Boolean(report)}
    >
      <MenuItem
        value="placeholder"
        className={cx(classes.notificationLabel, classes.textSecondary)}
      >
        {t('addIncidentReport.inputs.asset.options.type')}
      </MenuItem>
      {ASSET_OPTIONS.map(({ transLabelKey, value }) => {
        return (
          <MenuItem
            key={transLabelKey}
            value={value as string}
            className={classes.notificationLabel}
          >
            {t(transLabelKey)}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default AssetTypeField;
