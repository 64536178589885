import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useSetMapPinPoi } from 'components/IncidentsMap/useMapPinPoi';
import { MapToolsMenuItemProps } from './types';

const DropPinMenuItem = (props: MapToolsMenuItemProps): JSX.Element => {
  const { position, onClick, padding } = props;
  const { t } = useTranslation();
  const { setSelectedMapPinPoi } = useSetMapPinPoi();

  const handleDropPin = useCallback(
    (lngLatLan: number, lngLatLng: number): void => {
      setSelectedMapPinPoi({ lat: lngLatLan, lng: lngLatLng });
    },
    [setSelectedMapPinPoi],
  );

  return (
    <MenuItem
      onClick={() => {
        handleDropPin(position.lat, position.lng);
        onClick();
      }}
      sx={{ padding }}
    >
      {t('map.contextMenu.dropPinBtnTitle')}
    </MenuItem>
  );
};

export default DropPinMenuItem;
