import { FormHelperText, List, ListItem, SxProps } from '@mui/material';

type FieldHelperMessagesProps = {
  messages: string[];
  error?: boolean;
  sx?: SxProps;
  ['data-testid']?: string;
};

export const FieldHelperMessages = (
  props: FieldHelperMessagesProps,
): JSX.Element | null => {
  const { messages, error = false, sx = {}, 'data-testid': dataTestId } = props;

  const parsedMessages = messages.filter(Boolean);

  if (!parsedMessages.length) {
    return null;
  }

  if (parsedMessages.length === 1) {
    const msg = parsedMessages[0];
    return (
      <FormHelperText
        error={error}
        sx={{ margin: 0, color: 'text.secondary', ...sx }}
        data-testid={dataTestId}
      >
        {msg}
      </FormHelperText>
    );
  }

  return (
    <List
      disablePadding
      dense
      sx={{
        marginTop: 0.5,
        paddingLeft: '15px',
        color: 'text.secondary',
        ...sx,
      }}
      data-testid={dataTestId}
    >
      {parsedMessages.map((msg) => (
        <ListItem
          key={msg}
          dense
          disablePadding
          sx={{
            '&::before': {
              content: '"•"',
              color: 'inherit',
              fontSize: 'inherit',
              position: 'absolute',
              left: -15,
              top: -3,
            },
          }}
        >
          <FormHelperText
            error={error}
            sx={{ margin: 0, lineHeight: 1.35, color: 'inherit' }}
          >
            {msg}
          </FormHelperText>
        </ListItem>
      ))}
    </List>
  );
};
