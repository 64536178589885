import { API } from 'api';
import { GeoEvent } from '../../shared/types';

export const fetchGeoEventsForAutocomplete = async (): Promise<GeoEvent[]> => {
  const response = await API.get('geo_events/search/', {
    params: {
      limit: 500,
      geoEventTypes: 'wildfire',
      reporterManagedOnly: false,
      isActive: true,
    },
  });
  return response.data.results;
};
