import { Capacitor } from '@capacitor/core';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import {
  localStorageEffect,
  LOCAL_STORAGE_KEY,
  getLastPushNotificationTimestamp,
} from './localStorage';

type OnboardingState = {
  firstAppOpen: boolean;
  onboardUser: boolean;
  onboardCompleted: boolean;
  notificationsEnabled: boolean;
  showTutorialSteps: boolean;
  onboardedAt?: string;
};

type UseOnboardingState = {
  showTutorialSteps: boolean;
  setFirstAppOpen: () => void;
  setOnboardUser: (onboardUser: boolean) => void;
  setCompleted: (onboardCompleted: boolean) => void;
  setNotificationsEnabled: (notificationsEnabled: boolean) => void;
  onboardCompleted: boolean;
  onboardUser: boolean;
  notificationsEnabled: boolean;
  onboardedAt?: string | undefined;
  setShowTutorialSteps: (showTutorialSteps: boolean) => void;
};

const FIVE_MINUTES_MS = 1000 * 60 * 5; // 5 minutes

export const initialState: OnboardingState = {
  firstAppOpen: true,
  onboardUser: true,
  onboardCompleted: false,
  notificationsEnabled: false,
  showTutorialSteps: true,
};

// Merges initial state and saved state object values.
// Useful when adding new properties that might not
// exist in the saved state object.
const mergeValue = (savedValue: OnboardingState): OnboardingState => {
  return { ...initialState, ...savedValue };
};

export const onboardingStateAtom = atom({
  key: LOCAL_STORAGE_KEY.ONBOARDING_STATE,
  default: { ...initialState },
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.ONBOARDING_STATE, mergeValue)],
});

const useOnboardingState = (): UseOnboardingState => {
  const [onboardingState, setOnboardingState] =
    useRecoilState(onboardingStateAtom);

  const setFirstAppOpen = useCallback(() => {
    setOnboardingState((currVal) => ({ ...currVal, firstAppOpen: false }));
  }, [setOnboardingState]);

  const setOnboardUser = useCallback(
    (onboardUser: boolean) => {
      setOnboardingState((currVal) => {
        // eslint-disable-next-line prefer-destructuring
        let onboardCompleted = currVal.onboardCompleted;

        if (!currVal.onboardCompleted && !onboardUser) {
          onboardCompleted = true;
        }

        const onboardedAt = onboardCompleted
          ? new Date().toISOString()
          : undefined;

        return {
          ...currVal,
          onboardUser,
          onboardCompleted,
          onboardedAt,
        };
      });
    },
    [setOnboardingState],
  );

  const setCompleted = useCallback(
    (onboardCompleted: boolean) => {
      setOnboardingState((currVal) => ({
        ...currVal,
        onboardCompleted,
        onboardUser: false,
        onboardedAt: new Date().toISOString(),
      }));
    },
    [setOnboardingState],
  );

  const setNotificationsEnabled = useCallback(
    (notificationsEnabled: boolean) => {
      setOnboardingState((currVal) => ({ ...currVal, notificationsEnabled }));
    },
    [setOnboardingState],
  );

  const setShowTutorialSteps = useCallback(
    (showTutorialSteps: boolean) => {
      setOnboardingState((currVal) => ({ ...currVal, showTutorialSteps }));
    },
    [setOnboardingState],
  );

  const lastPushNotificationTimestamp = getLastPushNotificationTimestamp();

  const showTutorialSteps =
    !onboardingState.onboardUser &&
    onboardingState.showTutorialSteps &&
    Capacitor.isNativePlatform() &&
    Date.now() - lastPushNotificationTimestamp > FIVE_MINUTES_MS;

  return {
    ...onboardingState,
    showTutorialSteps,
    setFirstAppOpen,
    setOnboardUser,
    setCompleted,
    setNotificationsEnabled,
    setShowTutorialSteps,
  };
};

export default useOnboardingState;
