import { SetterOrUpdater, atom, useRecoilState } from 'recoil';
import { LOCAL_STORAGE_KEY } from './localStorage';

export type WebGL2State = {
  enabled: boolean | undefined;
};

type UseWebGL2State = {
  hasWebGL2: boolean | undefined;
  setEnabledData: SetterOrUpdater<WebGL2State>;
};

const DEFAULT: WebGL2State = {
  enabled: undefined,
};

export const enabledAtom = atom({
  key: LOCAL_STORAGE_KEY.HAS_WEB_GL_2,
  default: DEFAULT,
});

const useWebGL2State = (): UseWebGL2State => {
  const [enabledData, setEnabledData] = useRecoilState(enabledAtom);

  return {
    hasWebGL2: enabledData.enabled,
    setEnabledData,
  };
};

export default useWebGL2State;
