import { Helmet } from 'react-helmet';
import {
  Box,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/capacitor';
import PageLayout from 'components/PageLayout';
import watchdutyTheme from 'theme';
import BlackButton from 'components/BlackButton';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: `url("/images/app-background.png")`,
    backgroundSize: '100% 100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
  },
}));

const Content = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleAppReload = (): void => {
    Sentry.captureMessage('user-reloading-from-fatal-error-page', {
      level: 'fatal',
    });
    window.location.href = '/';
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={watchdutyTheme}>
        <Helmet>
          <title>{t('errorFallback.title')}</title>
        </Helmet>

        <Box className={classes.root}>
          <Typography variant="h2" align="center">
            {t('errorFallback.title')}
          </Typography>

          <Typography align="center">
            {t('errorFallback.description')}
          </Typography>

          <BlackButton className={classes.button} onClick={handleAppReload}>
            {t('errorFallback.reload')}
          </BlackButton>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const ErrorFallback = (): JSX.Element => {
  return (
    <PageLayout data-cname="ErrorFallback" showBanner={false}>
      {{
        content: <Content />,
        headerBar: <></>,
      }}
    </PageLayout>
  );
};

export default ErrorFallback;
