import { useCallback, useEffect } from 'react';
import { Resetter, atom, useRecoilState, useResetRecoilState } from 'recoil';
import { MembershipPlan } from 'shared/types';
import { MEMBERSHIP_PLANS } from '../constants';
import { LOCAL_STORAGE_KEY, localStorageEffect } from './localStorage';

export type MembershipState = {
  clientToken: string;
  fromMobile: boolean;
  email: string;
  firstName: string;
  lastName: string;
  optInToUpdates: boolean;
  existingUser: boolean;
  updatePaymentMethod: boolean;
  verificationToken?: string;
  userId?: number;
  plan: MembershipPlan;
};

type UseMembershipStateProps = {
  stateUpdateData?: Partial<MembershipState>;
};

type UseMembershipState = MembershipState & {
  updateMembershipState: (newState: Partial<MembershipState>) => void;
  resetMembershipState: Resetter;
};

export const MEMBERSHIP_TITLE_TRANS_KEYS = {
  [MEMBERSHIP_PLANS.yearly]:
    'recurlyAccountInformation.membershipTitle.regular',
  [MEMBERSHIP_PLANS.proYearly]: 'recurlyAccountInformation.membershipTitle.pro',
  [MEMBERSHIP_PLANS.proMonthly]:
    'recurlyAccountInformation.membershipTitle.pro',
};

export const MEMBERSHIP_DESCRIPTION_TRANS_KEYS = {
  [MEMBERSHIP_PLANS.yearly]:
    'recurlyAccountInformation.membershipDescription.yearly',
  [MEMBERSHIP_PLANS.proYearly]:
    'recurlyAccountInformation.membershipDescription.yearly',
  [MEMBERSHIP_PLANS.proMonthly]:
    'recurlyAccountInformation.membershipDescription.monthly',
};

export const MEMBERSHIP_CODES = {
  [MEMBERSHIP_PLANS.yearly]: 'wd_annual_recurring',
  [MEMBERSHIP_PLANS.proYearly]: 'wd_pro_yearly',
  [MEMBERSHIP_PLANS.proMonthly]: 'wd_pro_monthly',
};

const DEFAULT_MEMBERSHIP_STATE: MembershipState = {
  clientToken: '',
  fromMobile: false,
  email: '',
  firstName: '',
  lastName: '',
  optInToUpdates: false,
  existingUser: false,
  updatePaymentMethod: false,
  plan: MEMBERSHIP_PLANS.yearly,
};

const membershipStateAtom = atom({
  key: LOCAL_STORAGE_KEY.MEMBERSHIP_STATE,
  default: DEFAULT_MEMBERSHIP_STATE,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.MEMBERSHIP_STATE)],
});

const useMembershipState = (
  props: UseMembershipStateProps = {},
): UseMembershipState => {
  const { stateUpdateData } = props;
  const resetMembershipState = useResetRecoilState(membershipStateAtom);
  const [membershipState, setMembershipState] =
    useRecoilState(membershipStateAtom);

  const updateMembershipState = useCallback(
    (newState: Partial<MembershipState>) => {
      setMembershipState((prev) => ({ ...prev, ...newState }));
    },
    [setMembershipState],
  );

  useEffect(() => {
    if (stateUpdateData) {
      updateMembershipState(stateUpdateData);
    }
  }, [stateUpdateData, updateMembershipState]);

  return {
    ...membershipState,
    updateMembershipState,
    resetMembershipState,
  };
};

export default useMembershipState;
