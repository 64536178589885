import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import HeaderBar from 'components/HeaderBar';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import PageLayout from 'components/PageLayout';
import { useInboxMessages } from 'hooks/useInboxMessages';
import MessagesContent from './MessagesContent';

const Content = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('messagesInbox.title')}</title>
      </Helmet>
      <MessagesContent />
    </>
  );
};

const MessagesInbox = (): JSX.Element => {
  const { t } = useTranslation();
  const { loading, error } = useInboxMessages({ refetchOnMount: true });

  if (loading) {
    return (
      <PageLayout data-cname="InboxMessagesPage">
        {{
          content: <LoadingAndErrors isLoading />,
          headerBar: <HeaderBar title={t('common.loading')} action="back" />,
        }}
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout data-cname="InboxMessagesPage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title={t('common.error')} action="back" />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout isLoading={loading} data-cname="InboxMessagesPage">
      {{
        content: <Content />,
        headerBar: (
          <HeaderBar title={t('messagesInbox.title')} action="/" hideAction />
        ),
      }}
    </PageLayout>
  );
};

export default MessagesInbox;
