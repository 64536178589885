import { useCallback, useMemo } from 'react';
import { SetterOrUpdater, atom, useRecoilState } from 'recoil';
import { GeneralLayerGroup, MapBaseLayer } from 'components/Map/types';
import { localStorageEffect, LOCAL_STORAGE_KEY } from './localStorage';
import {
  MapBaseLayerNames,
  MapLayers,
  MapLayer,
} from '../components/Map/constants';

export type MapDrawerContentType = 'layers' | 'legends';

export type MapDrawerState = {
  open: boolean;
  content: MapDrawerContentType;
  disabledLayers: string[];
};

export type GroupDrawerState = {
  open: boolean;
  content: GeneralLayerGroup;
};

export const initialMapLayersState: Record<MapLayer, boolean> = {
  [MapLayers.ACTIVE_FIRE_PERIMETERS]: true, // active by default
  [MapLayers.HISTORICAL_FIRE_PERIMETERS]: false,
  [MapLayers.MODIS]: false,
  [MapLayers.VIIRS]: false,
  [MapLayers.SMOKE_COVERAGE]: false,
  [MapLayers.PURPLE_AIR]: false,
  [MapLayers.SURFACE_WIND]: false,
  [MapLayers.FLIGHT_TRACKER]: false,
  [MapLayers.RADIO_TOWERS]: false,
  [MapLayers.RED_FLAG_WARNINGS]: true, // active by default
  [MapLayers.POWER_OUTAGES]: true,
  [MapLayers.CAMERAS]: false,
  [MapLayers.EVACUATION_ZONES]: false,
  [MapLayers.ELECTRICAL_LINES]: false,
  [MapLayers.GAS_PIPELINES]: false,
  [MapLayers.EXTERNAL_GEO_EVENTS]: false,
  [MapLayers.FEDERAL_STATE_LOCAL]: false,
  [MapLayers.RESPONSIBILITY_AREAS]: false,
  [MapLayers.ELECTRIC_RETAIL_SERVICE]: false,
  [MapLayers.PRIVATE_LAND_OWNERSHIP]: false,
};

type MergeMapLayersValueType = {
  ActiveFirePerimetersLayer: boolean;
  HistoricalFirePerimetersLayer: boolean;
  ModisLayer: boolean;
  ViirsLayer: boolean;
  SmokeCoverageLayer: boolean;
  PurpleAir: boolean;
  'Surface Wind': boolean;
  FlightTracker: boolean;
  RadioTowers: boolean;
  RedFlagWarnings: boolean;
  EvacuationZones: boolean;
};

export type BaseMapLayer = 'Street Map' | 'Satellite Map' | 'Topographic Map';

type UseMapLayerState = {
  mapBaseLayer: BaseMapLayer;
  setMapBaseLayer: SetterOrUpdater<BaseMapLayer>;
  mapLayers: MapLayer[];
  isDarkBaseLayer: boolean;
  isLightBaseLayer: boolean;
  setDrawerOpen: (open: boolean, content?: MapDrawerContentType) => void;
  setMapLayerVisibility: (name: string, visibility: boolean) => void;
  disabledLayers: string[];
  setDisabledLayers: (disabledLayers?: string[]) => void;
  drawerOpen: boolean;
  drawerContent: MapDrawerContentType;
  groupDrawerOpen: boolean;
  groupDrawerContent: GeneralLayerGroup;
  setGroupDrawerOpen: (open: boolean, content?: GeneralLayerGroup) => void;
};

// Update map layers state from string[] to Record<string, boolean>
export const mergeMapLayersValue = (
  savedValue: string[] | Record<string, boolean>,
): MergeMapLayersValueType => {
  let parsedSavedValue: Record<string, boolean> = {};
  if (Array.isArray(savedValue)) {
    savedValue.forEach((layer) => {
      parsedSavedValue[layer] = true;
    });
  } else {
    parsedSavedValue = savedValue;
  }
  return { ...initialMapLayersState, ...parsedSavedValue };
};

const initialDrawerState: MapDrawerState = {
  open: false,
  content: 'layers',
  disabledLayers: [],
};

const initialGroupDrawerState: GroupDrawerState = {
  open: false,
  content: 'firePerimeters',
};

const mapBaseLayerAtom = atom({
  key: LOCAL_STORAGE_KEY.MAP_BASE_LAYER,
  default: MapBaseLayerNames.STREET as MapBaseLayer,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.MAP_BASE_LAYER)],
});

export const mapLayersAtom = atom({
  key: LOCAL_STORAGE_KEY.MAP_LAYERS,
  default: initialMapLayersState,
  effects: [
    localStorageEffect(LOCAL_STORAGE_KEY.MAP_LAYERS, mergeMapLayersValue),
  ],
});

export const mapLayersDrawerAtom = atom({
  key: LOCAL_STORAGE_KEY.MAP_LAYERS_DRAWER,
  default: { ...initialDrawerState },
});

export const mapLayerGroupsDrawerAtom = atom({
  key: 'MAP_LAYER_GROUPS_DRAWER',
  default: { ...initialGroupDrawerState },
});

const useMapLayersState = (): UseMapLayerState => {
  const [mapBaseLayer, setMapBaseLayer] = useRecoilState(mapBaseLayerAtom);
  const [mapLayers, setMapLayers] = useRecoilState(mapLayersAtom);
  const [mapLayersDrawer, setMapLayersDrawer] =
    useRecoilState(mapLayersDrawerAtom);
  const [mapLayerGroupsDrawer, setMapLayerGroupsDrawer] = useRecoilState(
    mapLayerGroupsDrawerAtom,
  );

  const setDisabledLayers = useCallback(
    (disabledLayers: string[] = []) => {
      setMapLayersDrawer((currVal) => ({ ...currVal, disabledLayers }));
    },
    [setMapLayersDrawer],
  );

  const setDrawerOpen = useCallback(
    (open: boolean, content?: MapDrawerContentType) => {
      setMapLayersDrawer((currVal) => ({
        ...currVal,
        open,
        content: content ?? currVal.content,
      }));
    },
    [setMapLayersDrawer],
  );

  const setGroupDrawerOpen = useCallback(
    (open: boolean, content?: GeneralLayerGroup) => {
      setMapLayerGroupsDrawer((currVal) => ({
        ...currVal,
        open,
        content: content ?? currVal.content,
      }));
    },
    [setMapLayerGroupsDrawer],
  );

  const setMapLayerVisibility = useCallback(
    (name: string, visibility: boolean) => {
      setMapLayers((currVal) => ({ ...currVal, [name]: visibility }));
    },
    [setMapLayers],
  );

  const activeMapLayers = useMemo(() => {
    return (Object.keys(mapLayers) as MapLayer[]).reduce(
      (activeLayers, layer): MapLayer[] => {
        if (mapLayers[layer]) activeLayers.push(layer);
        return activeLayers;
      },
      [] as MapLayer[],
    );
  }, [mapLayers]);

  return {
    mapBaseLayer,
    setMapBaseLayer,
    mapLayers: activeMapLayers,
    isDarkBaseLayer: mapBaseLayer === MapBaseLayerNames.SATELLITE,
    isLightBaseLayer: mapBaseLayer !== MapBaseLayerNames.SATELLITE,
    setMapLayerVisibility,
    disabledLayers: mapLayersDrawer.disabledLayers,
    setDisabledLayers,
    drawerOpen: mapLayersDrawer.open,
    drawerContent: mapLayersDrawer.content,
    setDrawerOpen,
    groupDrawerOpen: mapLayerGroupsDrawer.open,
    groupDrawerContent: mapLayerGroupsDrawer.content,
    setGroupDrawerOpen,
  };
};

export default useMapLayersState;
