import { useEffect, useRef } from 'react';
import {
  Paper,
  Typography,
  Container,
  Stack,
  FormControl,
  useTheme,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import { useAuthState } from 'state';
import { MembershipPlan } from 'shared/types';
import { ANALYTICS_EVENTS, logEvent } from 'shared/analytics';
import RadioCard from './RadioCard';
import { MEMBERSHIP_PLANS, MEMBERSHIP_PLANS_AMOUNTS } from '../../../constants';
import { useMembershipPlan } from '../MembershipPlanProvider';
import { ExpandedContent } from './ExpandedContent';
import { CardContent } from './CardContent';
import { ExpiryInfo, ManageMembershipButton } from './ManageMembership';

const useStyles = makeStyles()((theme) => ({
  paper: {
    paddingTop: 20,
    paddingBottom: 20,
    overflowY: 'auto',
    width: '100%',
    backgroundColor: theme.palette.background.membership,
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 48,
    fontSize: 16,
  },
}));

const SupportUsContent = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { plan } = useParams<{ plan?: MembershipPlan | 'donate' }>();
  const { verifyingToken, selectedPlan, updatePlan } = useMembershipPlan();
  const { isAuthenticated, hasActiveMembership, isProMember } = useAuthState();
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const planUpdatedRef = useRef(false);
  const theme = useTheme();

  useEffect(() => {
    /**
     * Plan from param and selected plan might be out of sync. For example,
     * if the user is on the /support_us/pro_yearly page, but the plan is
     * actually yearly, we should update the plan to yearly.
     */
    if (!plan || plan === 'donate' || planUpdatedRef.current) return;

    const shouldUpdateToYearly =
      plan === MEMBERSHIP_PLANS.yearly &&
      selectedPlan !== MEMBERSHIP_PLANS.yearly;

    const shouldUpdateToProYearly =
      plan === MEMBERSHIP_PLANS.proYearly &&
      selectedPlan === MEMBERSHIP_PLANS.yearly;

    if (shouldUpdateToYearly || shouldUpdateToProYearly) {
      updatePlan(plan);
      planUpdatedRef.current = true;
    }
  }, [plan, selectedPlan, updatePlan]);

  useEffect(() => {
    if (plan !== MEMBERSHIP_PLANS.proYearly) {
      return;
    }

    const centerProCard = (): void => {
      setTimeout(() => {
        if (!containerRef.current) return;
        containerRef.current.scrollTo({
          top: (containerRef.current.scrollHeight - window.innerHeight) * 0.75,
          behavior: 'smooth',
        });
      }, theme.transitions.duration.standard);
    };

    centerProCard();
  }, [isProMember, plan, theme.transitions.duration.standard]);

  if (verifyingToken) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <Paper elevation={0} className={classes.paper} ref={containerRef}>
      <Container maxWidth="sm">
        <Stack alignItems="center" spacing={3}>
          <Typography align="center" fontSize={16}>
            <Trans i18nKey="supportUs.description">
              As a non-profit, all critical safety features will be
              <em>free</em>, <b>forever</b>. Your financial contribution helps
              ensure free access for all.
            </Trans>
          </Typography>

          <FormControl fullWidth>
            <Stack spacing={3}>
              {!isProMember && (
                <RadioCard
                  title={t('supportUs.membership.title')}
                  value={MEMBERSHIP_PLANS.yearly}
                  onChange={(value) => {
                    updatePlan(MEMBERSHIP_PLANS.yearly);
                    history.replace(`/support_us/${value}`);
                  }}
                  checked={plan === MEMBERSHIP_PLANS.yearly}
                  active={hasActiveMembership}
                >
                  <RadioCard.Content>
                    {hasActiveMembership ? (
                      <ExpiryInfo />
                    ) : (
                      <CardContent
                        plan={MEMBERSHIP_PLANS.yearly}
                        description={t('supportUs.membership.description')}
                        amount={
                          MEMBERSHIP_PLANS_AMOUNTS[MEMBERSHIP_PLANS.yearly]
                        }
                      />
                    )}
                  </RadioCard.Content>
                  <RadioCard.CollapseContent>
                    {hasActiveMembership ? (
                      <ManageMembershipButton />
                    ) : (
                      <ExpandedContent plan={MEMBERSHIP_PLANS.yearly} />
                    )}
                  </RadioCard.CollapseContent>
                </RadioCard>
              )}

              <RadioCard
                title={t('supportUs.proMembership.title')}
                value={MEMBERSHIP_PLANS.proYearly}
                onChange={(value) => {
                  updatePlan(MEMBERSHIP_PLANS.proYearly);
                  history.replace(`/support_us/${value}`);
                }}
                checked={
                  plan === MEMBERSHIP_PLANS.proYearly ||
                  plan === MEMBERSHIP_PLANS.proMonthly
                }
                active={isProMember}
              >
                <RadioCard.Content>
                  {isProMember ? (
                    <ExpiryInfo />
                  ) : (
                    <CardContent
                      plan={MEMBERSHIP_PLANS.proYearly}
                      description={t('supportUs.proMembership.description')}
                      amount={
                        MEMBERSHIP_PLANS_AMOUNTS[MEMBERSHIP_PLANS.proYearly]
                      }
                    />
                  )}
                </RadioCard.Content>
                <RadioCard.CollapseContent>
                  {isProMember ? (
                    <ManageMembershipButton />
                  ) : (
                    <ExpandedContent
                      plan={MEMBERSHIP_PLANS.proYearly}
                      upgrade={hasActiveMembership}
                    />
                  )}
                </RadioCard.CollapseContent>
              </RadioCard>

              <RadioCard
                title={t('supportUs.donate.title')}
                value="donate"
                onChange={(value) => {
                  logEvent({ name: ANALYTICS_EVENTS.CLICKED.DONATION });
                  history.replace(`/support_us/${value}`);
                }}
                checked={plan === 'donate'}
              >
                <RadioCard.Content>
                  <CardContent
                    description={t('supportUs.donate.description')}
                  />
                </RadioCard.Content>
                <RadioCard.CollapseContent>
                  <ExpandedContent />
                </RadioCard.CollapseContent>
              </RadioCard>
            </Stack>
          </FormControl>

          {!isAuthenticated && (
            <Button
              fullWidth
              className={classes.button}
              component={Link}
              to="/login"
              variant="outlined"
              color="inherit"
            >
              {t('supportUs.alreadyAMember')}
            </Button>
          )}
        </Stack>
      </Container>
    </Paper>
  );
};

export default SupportUsContent;
