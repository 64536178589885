import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import OnboardingNotificationsContent from './OnboardingNotificationsContent';

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('onboardingNotifications.title')}</title>
      </Helmet>

      <OnboardingNotificationsContent />
    </>
  );
};

const OnboardingNotifications = (): JSX.Element => {
  return (
    <PageLayout
      data-cname="OnboardingNotificationsPage"
      data-testid="onboardingNotificationsPage"
    >
      {{
        content: <Content />,
        headerBar: <></>,
      }}
    </PageLayout>
  );
};

export default OnboardingNotifications;
