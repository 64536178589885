import { Typography, Link as MuiLink } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMapSearchState } from 'state/useMapSearchState';
import {
  mapLocationToPlaceAddress,
  mapSearchResultToMapLocation,
} from 'shared/utils';
import useMapPlaces from 'hooks/useMapPlaces';
import { MouseEventHandler } from 'react';
import { useAuthState } from 'state';
import useSelectedLocationState from 'state/useSelectedLocationState';
import { MEMBERSHIP_PLANS } from '../../constants';

type LocationMarkerLabelProps = {
  label: string;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '100%',
    minWidth: 100,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  link: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const LocationMarkerLabel = (props: LocationMarkerLabelProps): JSX.Element => {
  const { label } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { value } = useMapSearchState();
  const { places } = useMapPlaces();
  const { showMembershipFeatures } = useAuthState();
  const { setSelectedLocation } = useSelectedLocationState();
  const history = useHistory();

  const handleAddPlace: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (places.length >= 1 && !showMembershipFeatures) {
      event.preventDefault();
      history.push(`/support_us/${MEMBERSHIP_PLANS.yearly}`);
      return;
    }
    setSelectedLocation(null);
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      {!!value && (
        <MuiLink
          to={{
            pathname: '/add_place',
            state: mapLocationToPlaceAddress(
              mapSearchResultToMapLocation(value),
            ),
          }}
          component={Link}
          underline="none"
          color="textPrimary"
          className={classes.link}
          onClick={handleAddPlace}
        >
          {t('common.save')}
        </MuiLink>
      )}
    </div>
  );
};

export default LocationMarkerLabel;
