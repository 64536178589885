import {
  Tooltip as MuiTooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.body1.fontSize,
  },
}));

export default Tooltip;
