import { IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthState, useMapState } from 'state';
import { useAlertCameraPlayerState } from 'hooks/useAlertCameraPlayer';
import { useSelectedCameraId } from 'hooks/useSelectedCameraId';

type AdminActionsProps = {
  automatedGeoEvent?: boolean;
  geoEventId?: number;
  locationId?: number;
};

const useStyles = makeStyles()(() => ({
  menuButton: {
    width: 48,
    height: 48,
  },
  menuIcon: {
    width: 24,
    height: 24,
  },
  menuList: {
    padding: 0,
    minWidth: 175,
  },
  menuItem: {
    padding: '12px 16px',
  },
}));

export const AdminActions = (props: AdminActionsProps): JSX.Element => {
  const { automatedGeoEvent, geoEventId, locationId } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    permissions: { canCreateLocation },
  } = useAuthState();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { incidentMapState, geoEventMapState } = useMapState();
  const cameraId = useSelectedCameraId();
  const { timelapseFrame } = useAlertCameraPlayerState();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getIncidentPageMenuItems = (): JSX.Element[] => {
    const routePrefix = automatedGeoEvent ? 'i' : 'incident';
    const editLink = `/${routePrefix}/${geoEventId}/edit`;

    if (automatedGeoEvent) {
      return [
        <MenuItem
          component={Link}
          to={editLink}
          key="editIncident"
          onClick={handleClose}
          className={classes.menuItem}
        >
          {t('header.actions.editIncident')}
        </MenuItem>,
      ];
    }

    let createLocationLink = `/location/create?parentGeoEventId=${geoEventId}`;
    if (geoEventMapState?.lat && geoEventMapState?.lng) {
      createLocationLink += `&lat=${geoEventMapState.lat}&lng=${geoEventMapState.lng}`;
    }

    const items = [
      <MenuItem
        component={Link}
        to={`/add_report/${geoEventId}`}
        key="addUpdate"
        onClick={handleClose}
        className={classes.menuItem}
        divider
      >
        {t('header.actions.addUpdate')}
      </MenuItem>,
      <MenuItem
        component={Link}
        to={editLink}
        key="editIncident"
        onClick={handleClose}
        className={classes.menuItem}
        divider
      >
        {t('header.actions.editIncident')}
      </MenuItem>,
    ];
    if (canCreateLocation) {
      items.push(
        <MenuItem
          component={Link}
          to={createLocationLink}
          key="createLocation"
          onClick={handleClose}
          className={classes.menuItem}
        >
          {t('header.actions.addLocation')}
        </MenuItem>,
      );
    }
    return items;
  };

  const getLocationPageMenuItems = (): JSX.Element[] => {
    return [
      <MenuItem
        component={Link}
        to={`/location/${locationId}/edit`}
        key="editLocation"
        onClick={handleClose}
        className={classes.menuItem}
      >
        {t('header.actions.editLocation')}
      </MenuItem>,
    ];
  };

  const getDefaultMenuItems = (): JSX.Element[] => {
    let createIncident = '/incident/create';
    let createLocation = '/location/create';

    if (incidentMapState.center.lat && incidentMapState.center.lng) {
      const params = new URLSearchParams({
        lat: incidentMapState.center.lat.toString(),
        lng: incidentMapState.center.lng.toString(),
      });

      createLocation += `?${params.toString()}`;
      if (incidentMapState.zoom) {
        params.append('zoom', incidentMapState.zoom.toString());
      }
      if (cameraId) {
        params.append('alertCameraId', cameraId);
        if (timelapseFrame?.imageTimestamp) {
          params.append('alertCameraTs', timelapseFrame.imageTimestamp);
        }
      }
      createIncident += `?${params.toString()}`;
    }

    const items = [
      <MenuItem
        component={Link}
        to={createIncident}
        key="createIncident"
        onClick={handleClose}
        className={classes.menuItem}
        divider
      >
        {t('header.actions.addIncident')}
      </MenuItem>,
    ];
    if (canCreateLocation) {
      items.push(
        <MenuItem
          component={Link}
          to={createLocation}
          key="createLocation"
          onClick={handleClose}
          className={classes.menuItem}
        >
          {t('header.actions.addLocation')}
        </MenuItem>,
      );
    }
    return items;
  };

  // eslint-disable-next-line no-nested-ternary
  const menuItems = geoEventId
    ? getIncidentPageMenuItems()
    : locationId
      ? getLocationPageMenuItems()
      : getDefaultMenuItems();

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="medium"
        className={classes.menuButton}
        aria-label={t('header.moreButtonLabel')}
      >
        <MoreVert className={classes.menuIcon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ list: classes.menuList }}
      >
        {menuItems}
      </Menu>
    </>
  );
};
