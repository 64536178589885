import { ButtonBase, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FunctionComponent, SVGProps } from 'react';
import { makeStyles } from 'tss-react/mui';

type MapControlButtonProps =
  | {
      Icon:
        | FunctionComponent<SVGProps<SVGSVGElement>>
        | OverridableComponent<SvgIconTypeMap>;
      text: string;
      onClick: () => void;
      disabled?: boolean;
      className?: string;
    }
  | {
      Icon:
        | FunctionComponent<SVGProps<SVGSVGElement>>
        | OverridableComponent<SvgIconTypeMap>;
      ariaLabel: string;
      onClick: () => void;
      disabled?: boolean;
      className?: string;
    };

const useStyles = makeStyles()((theme) => {
  const { spacing, palette } = theme;

  return {
    controlButton: {
      width: `${spacing(7)} !important`,
      height: `${spacing(7)} !important`,
      display: 'flex !important',
      flexDirection: 'column',
      backgroundColor: palette.common.white,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      marginTop: '16px',
      marginRight: '16px',
      pointerEvents: 'auto',
    },
    controlIcon: {
      width: 24,
      height: 24,
      color: palette.text.primary,
    },
    controlLabel: {
      fontVariant: 'all-small-caps',
      // hard-coding to prevent iOS Dynamic Type from resizing this
      fontSize: '8pt', // 11px
      lineHeight: '12pt', // 16px
    },
  };
});

const MapControlButton = (props: MapControlButtonProps): JSX.Element => {
  const { Icon, onClick, disabled, className = '' } = props;
  const { classes } = useStyles();

  if ('text' in props) {
    const { text } = props;
    return (
      <ButtonBase
        className={`${classes.controlButton} ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon className={classes.controlIcon} />
        <Typography variant="subtitle1" className={classes.controlLabel}>
          {text}
        </Typography>
      </ButtonBase>
    );
  }

  const { ariaLabel } = props;

  return (
    <ButtonBase
      className={`${classes.controlButton} ${className}`}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
    >
      <Icon className={classes.controlIcon} />
    </ButtonBase>
  );
};

export default MapControlButton;
