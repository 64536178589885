import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { LOCAL_STORAGE_KEY, localStorageEffect } from './localStorage';

type SupportUsBannerAdState = {
  popupLastShownTimestamp?: number;
  geoEventBannerLastShownTimestamp: Record<number, number>;
};

type UseSupportUsBannerAdState = {
  popupLastShownTimestamp?: number;
  getGeoEventBannerLastShownTimestamp: (
    geoEventId: number,
  ) => number | undefined;
  updatePopupLastShownTimestamp: () => void;
  updateGeoEventBannerLastShownTimestamp: (geoEventId: number) => void;
};

const DEFAULT_SUPPORT_US_BANNER_AD_STATE: SupportUsBannerAdState = {
  geoEventBannerLastShownTimestamp: {},
};

export const supportUsBannerAddAtom = atom({
  key: LOCAL_STORAGE_KEY.SUPPORT_US_BANNER_AD,
  default: DEFAULT_SUPPORT_US_BANNER_AD_STATE,
  effects: [localStorageEffect(LOCAL_STORAGE_KEY.SUPPORT_US_BANNER_AD)],
});

export const useSupportUsBannerAdState = (): UseSupportUsBannerAdState => {
  const [supportUsBannerAdState, setSupportUsBannerAdState] = useRecoilState(
    supportUsBannerAddAtom,
  );

  const getGeoEventBannerLastShownTimestamp = useCallback(
    (geoEventId: number): number | undefined => {
      return supportUsBannerAdState.geoEventBannerLastShownTimestamp[
        geoEventId
      ];
    },
    [supportUsBannerAdState.geoEventBannerLastShownTimestamp],
  );

  const updatePopupLastShownTimestamp = useCallback(() => {
    setSupportUsBannerAdState((prevState) => ({
      ...prevState,
      popupLastShownTimestamp: Date.now(),
    }));
  }, [setSupportUsBannerAdState]);

  const updateGeoEventBannerLastShownTimestamp = useCallback(
    (geoEventId: number) => {
      setSupportUsBannerAdState((prevState) => ({
        ...prevState,
        geoEventBannerLastShownTimestamp: {
          ...prevState.geoEventBannerLastShownTimestamp,
          [geoEventId]: Date.now(),
        },
      }));
    },
    [setSupportUsBannerAdState],
  );

  return {
    popupLastShownTimestamp: supportUsBannerAdState.popupLastShownTimestamp,
    getGeoEventBannerLastShownTimestamp,
    updatePopupLastShownTimestamp,
    updateGeoEventBannerLastShownTimestamp,
  };
};
