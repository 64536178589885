import { useCallback, useEffect } from 'react';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CameraTimeLapseFrameDetails } from 'hooks/useAlertCameraPlayer';
import { useQueryParams } from 'hooks/useQueryParms';
import { useAlertCameraOptions } from './useAlertCameraOptions';
import { CameraTimelapse } from './CameraTimelapse';

export const MediaAlertCameraForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { control, formState, watch, setValue } = useFormContext();
  const { cameraOptions, isLoading } = useAlertCameraOptions();
  const [{ alertCameraId, alertCameraTs }] = useQueryParams<{
    alertCameraId?: string;
    alertCameraTs?: string;
  }>();

  const selectedCamera = watch('media');
  const isCameraInOptions = cameraOptions.some(
    (camera) => camera.id === selectedCamera?.id,
  );

  useEffect(() => {
    if (alertCameraId) {
      const initialSelectedCamera = cameraOptions.find(
        (camera) => camera.id === alertCameraId,
      );
      if (initialSelectedCamera) {
        setValue('media', initialSelectedCamera);
      }
    }
  }, [setValue, cameraOptions, alertCameraId]);

  useEffect(() => {
    if (selectedCamera?.id && !isCameraInOptions) {
      setValue('media', null);
    }
  }, [isCameraInOptions, selectedCamera?.id, setValue]);

  const handleFrameSelect = useCallback(
    (frame?: CameraTimeLapseFrameDetails): void => {
      if (!frame) return;
      setValue('media', {
        ...selectedCamera,
        ...frame,
        imageTimestamp: new Date(frame.imageTimestamp),
      });
    },
    [selectedCamera, setValue],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <Controller
          name="media"
          control={control}
          render={({ field, fieldState }): JSX.Element => {
            return (
              <FormControl fullWidth>
                <Autocomplete
                  id="media"
                  options={cameraOptions}
                  getOptionLabel={(option): string => option.name || ''}
                  isOptionEqualToValue={(option, value): boolean =>
                    option.id === value?.id
                  }
                  value={field.value}
                  onChange={(_, value): void => field.onChange(value)}
                  onBlur={field.onBlur}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      name={field.name}
                      inputRef={field.ref}
                      variant="outlined"
                      label={t('addIncidentReport.inputs.alertCamera.label')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={formState.isSubmitting || isLoading}
                    />
                  )}
                />
              </FormControl>
            );
          }}
        />
      </Grid>

      {selectedCamera && (
        <Grid item xs={12} md={6}>
          <CameraTimelapse
            camera={selectedCamera}
            onFrameSelect={handleFrameSelect}
            initialFrameTs={alertCameraTs}
          />
        </Grid>
      )}
    </Grid>
  );
};
