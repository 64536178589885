import chroma from 'chroma-js';
import { Layer, LayerProps } from 'shared/map-exports';
import { FeatureCollection } from 'geojson';
import { differenceInHours } from 'date-fns';
import ArcGisLayer from './ArcGisLayer';
import get24hDatetimeRange from '../get24hDatetimeRange';
import addVisible from '../../../shared/addVisible';
import { MapLayerProps } from '../types';

const fields = ['ACQ_DATE'];

const colorScale = chroma
  .scale(['red', 'orange'])
  .domain([0, 24])
  .mode('lab')
  .correctLightness();

const modisLayerStyle: LayerProps = {
  id: 'modis',
  type: 'circle',
  paint: {
    'circle-opacity': 0.5,
    'circle-blur': 0.5,
    'circle-radius': [
      'interpolate',
      ['exponential', 2],
      ['zoom'],
      0,
      2,
      22,
      60000,
    ],
    'circle-color': [
      'interpolate',
      ['linear'],
      ['get', 'age'],
      ...Array.from(colorScale.colors(24).entries()).flat(),
    ],
  },
};

const processData = (
  featureCollection: FeatureCollection,
): FeatureCollection => {
  const { features } = featureCollection;
  const currentDate = new Date();

  return {
    type: 'FeatureCollection',
    features: features.map((feature) => {
      const acquiredTime = new Date(feature.properties?.ACQ_DATE);
      const age = differenceInHours(currentDate, acquiredTime, {
        roundingMethod: 'floor',
      });
      const properties = {
        ...feature.properties,
        age,
      };
      return { ...feature, properties };
    }),
  };
};

const ModisLayer = (props: MapLayerProps): JSX.Element => {
  const { visible } = props;
  const [rangeLow, rangeHigh] = get24hDatetimeRange();

  return (
    <ArcGisLayer
      visible={visible}
      src="https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/MODIS_Thermal_v1/FeatureServer/0"
      where={`ACQ_DATE BETWEEN ${rangeLow} AND ${rangeHigh} AND CONFIDENCE >= 70`}
      orderBy="ACQ_DATE DESC"
      fields={fields}
      precision={4}
      onData={processData}
      staleTime={60 * 60 * 1000} // 60 minutes
    >
      <Layer {...addVisible(modisLayerStyle, visible)} />
    </ArcGisLayer>
  );
};

export default ModisLayer;
