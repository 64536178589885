import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Visa from 'assets/visa.svg?react';
import MasterCard from 'assets/mastercard.svg?react';
import Discover from 'assets/discover.svg?react';
import Amex from 'assets/amex.svg?react';
import PayPal from 'assets/paypal.svg?react';
import ApplePay from 'assets/applepay.svg?react';
import { getResponsiveFontSize } from 'shared/utils';
import { CardType } from 'shared/types';

type PaymentMethodProps = {
  type: CardType;
};

const useStyles = makeStyles()((theme) => ({
  defaultCard: {
    fontSize: getResponsiveFontSize('3.5rem'),
    marginTop: theme.spacing(-1),
  },
}));

const creditCardComponent = {
  Visa: <Visa />,
  MasterCard: <MasterCard />,
  Discover: <Discover />,
  Amex: <Amex />,
  PayPal: <PayPal />,
  ApplePay: <ApplePay />,
};

const PaymentMethod = (props: PaymentMethodProps): JSX.Element => {
  const { type } = props;
  const { classes } = useStyles();

  const card = creditCardComponent[type];

  return card ? (
    <Box>{card}</Box>
  ) : (
    <CreditCardIcon className={classes.defaultCard} />
  );
};

export default PaymentMethod;
