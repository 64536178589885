import { useEffect } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import useAppInfoState from 'state/useAppInfoState';
import { WDAppInfo } from 'shared/types';
import { getWebAppVersion, isMobile } from 'shared/utils';

const useAppInfo = (update = false): WDAppInfo => {
  const {
    appId,
    appName,
    appBuild,
    appVersion: nativeAppVersion,
    setAppInfoState,
  } = useAppInfoState();

  const appVersion = isMobile() ? nativeAppVersion : getWebAppVersion();

  useEffect(() => {
    const getInfoInternal = async (): Promise<void> => {
      if (!update || !isMobile()) return;
      const result = await CapacitorApp.getInfo();
      setAppInfoState(result);
    };

    getInfoInternal();
  }, [setAppInfoState, update]);

  return {
    appId,
    appName,
    appBuild,
    appVersion,
  };
};

export default useAppInfo;
