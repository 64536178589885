import { range } from 'lodash-es';
import { PointLike } from 'shared/map-exports';

export const getFractionDigits = (value: number): number => {
  if (value < 1) {
    return 2;
  }
  if (value < 100) {
    return 1;
  }
  return 0;
};

export const toLocaleStringWithAdaptiveFraction = (
  distanceMiles: number,
  units?: string,
): string => {
  const distanceMilesNumberString = distanceMiles.toLocaleString(undefined, {
    maximumFractionDigits: getFractionDigits(distanceMiles),
  });
  const unitString = units ? ` ${units}` : '';
  return `${distanceMilesNumberString} ${unitString}`;
};

export const isDisplayInvertedForAngle = (angleRad: number): boolean =>
  angleRad > Math.PI / 2 && angleRad < (3 * Math.PI) / 2;

const DISTANCE_LABEL_OFFSET_X_PIXELS = 40;
const DISTANCE_LABEL_OFFSET_Y_PIXELS = 25;
const DISTANCE_LABEL_OFFSET_TABLE_COUNT = 50;

// Pre-compute an offset lookup table for the distance label for
// multiple points around a circle.
const OFFSET_LOOKUP_TABLE: PointLike[] = range(
  0,
  DISTANCE_LABEL_OFFSET_TABLE_COUNT,
).map((i) => {
  const angle = (i / DISTANCE_LABEL_OFFSET_TABLE_COUNT) * Math.PI * 2;
  return [
    Math.floor(Math.cos(angle) * DISTANCE_LABEL_OFFSET_X_PIXELS),
    Math.floor(Math.sin(angle) * DISTANCE_LABEL_OFFSET_Y_PIXELS),
  ];
});

export const getOutsideLabelOffset = (angleRad: number): PointLike => {
  const angleNormalized =
    Math.floor(
      (angleRad / (Math.PI * 2) + Math.PI / 64) * OFFSET_LOOKUP_TABLE.length,
    ) % OFFSET_LOOKUP_TABLE.length;
  const offset = OFFSET_LOOKUP_TABLE[angleNormalized];
  return offset;
};
