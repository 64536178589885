import { DialogContent, Divider, Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import distance from '@turf/distance';
import circle from '@turf/circle';
import area from '@turf/area';
import bearing from '@turf/bearing';

import {
  MeasureDistanceToolPoints,
  useMeasureDistanceToolState,
} from 'state/useMeasureDistanceToolState';
import { LatLng } from 'shared/types';
import { toLocaleStringWithAdaptiveFraction } from './layers/MeasureDistanceToolLayer/utils';

const useStyles = makeStyles()((theme) => ({
  title: {
    fontVariant: 'all-small-caps',
    textAlign: 'center',
  },
  content: {
    lineHeight: '18px',
    textAlign: 'center',
  },
}));

const getDistanceMiles = (points: MeasureDistanceToolPoints | null): number => {
  if (!points) {
    return 0;
  }
  return distance(
    [points.start.lng, points.start.lat],
    [points.end.lng, points.end.lat],
    {
      units: 'miles',
    },
  );
};

const getAreaAcres = (
  center: LatLng | undefined,
  radiusMiles: number,
): number => {
  if (!center) {
    return 0;
  }
  const circlePolygon = circle([center.lng, center.lat], radiusMiles, {
    units: 'miles',
  });
  return area(circlePolygon) / 4046.85;
};

const getBearingDegrees = (
  points: MeasureDistanceToolPoints | null,
): number => {
  if (!points) {
    return 0;
  }
  const relativeBearing = bearing(
    [points.start.lng, points.start.lat],
    [points.end.lng, points.end.lat],
  );
  // Round and normalize to 0-359.
  return (Math.round(relativeBearing) + 360) % 360;
};

export const MeasureDistanceToolDialogContent = (): JSX.Element => {
  const { points } = useMeasureDistanceToolState();
  const { t } = useTranslation();
  const style = useStyles();

  const distanceMiles = useMemo(() => getDistanceMiles(points), [points]);
  const areaAcres = useMemo(
    () => getAreaAcres(points?.start, distanceMiles),
    [distanceMiles, points],
  );
  const bearingDegrees = useMemo(() => getBearingDegrees(points), [points]);

  const distanceMilesString = toLocaleStringWithAdaptiveFraction(
    distanceMiles,
    'mi',
  );
  const areaAcresString = toLocaleStringWithAdaptiveFraction(areaAcres);
  const bearingDegreesString = `${bearingDegrees}°`;

  return (
    <>
      <DialogContent sx={{ padding: 3, paddingTop: 3, flexGrow: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={0.5} direction="row">
          <Grid item xs={3}>
            <Stack spacing={1}>
              <Typography component="h6" className={style.classes.title}>
                {t('map.poi.measureDistanceTool.distance')}
              </Typography>
              <Typography variant="body1" className={style.classes.content}>
                {distanceMilesString}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={1}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Divider orientation="vertical" />
          </Grid>{' '}
          <Grid item xs={3}>
            <Stack spacing={1}>
              <Typography component="h6" className={style.classes.title}>
                {t('map.poi.measureDistanceTool.acres')}
              </Typography>
              <Typography variant="body1" className={style.classes.content}>
                {areaAcresString}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={1}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={3}>
            <Stack spacing={1}>
              <Typography component="h6" className={style.classes.title}>
                {t('map.poi.measureDistanceTool.bearing')}
              </Typography>
              <Typography variant="body1" className={style.classes.content}>
                {bearingDegreesString}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
