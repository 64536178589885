import { useEffect } from 'react';
import { ControlPosition, useControl, Map } from 'shared/map-exports';
import { useTranslation } from 'react-i18next';

import MapLegendIcon from 'assets/map_legend.svg?react';
import { MapDrawerContentType } from 'state/useMapLayersState';
import MapControlButton from './MapControlButton';
import BaseMapboxGLController from './BaseMapboxGLController';

type LegendControlProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  setDrawerOpen: (open: boolean, content?: MapDrawerContentType) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  position?: ControlPosition | undefined;
};

const LegendControlContent = (props: LegendControlProps): JSX.Element => {
  const { setDrawerOpen } = props;
  const { t } = useTranslation();

  return (
    <MapControlButton
      text={t('map.controls.legend')}
      Icon={MapLegendIcon}
      onClick={() => setDrawerOpen(true, 'legends')}
    />
  );
};

class MapboxGLLegendController extends BaseMapboxGLController<
  LegendControlProps & { map?: Map }
> {
  content = (): JSX.Element => (
    <LegendControlContent map={this.map} {...this.props} />
  );
}

const LegendControl = (props: LegendControlProps): null => {
  const { position } = props;
  // @ts-ignore - https://github.com/visgl/deck.gl/issues/9211
  const controller = useControl(() => new MapboxGLLegendController(props), {
    position,
  }) as MapboxGLLegendController;

  controller.update(props);
  useEffect(() => controller.render(), [controller, props]);
  return null;
};

export default LegendControl;
