import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { throttle } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useMap, MarkerComponent, Popup } from 'shared/map-exports';
import { MarkerEvent } from 'react-map-gl/dist/esm/types';
import { Altitude } from 'shared/types';
import { isMobile } from 'shared/utils';
import { AircraftIcons } from './Icons';
import {
  MAX_ICON_SCALE,
  MIN_ICON_SCALE,
  SHADOW_DISTANCE_ALTITUDE_MAX_FEET,
  SHADOW_DISTANCE_BY_ALTITUDE_RANGE_PX,
  SHADOW_DISTANCE_MIN_PX,
} from './layerStyles';
import { AircraftIcon } from './AircraftIcon';
import {
  AircraftIconVariant,
  AircraftLayerFeatureCollection,
  AircraftLayerFeature,
} from './types';

type AircraftIconsProps = {
  data: AircraftLayerFeatureCollection;
  onClick: (f: AircraftLayerFeature) => void;
  onHover: (f: AircraftLayerFeature | null) => void;
};

type MarkerProps = {
  feature: AircraftLayerFeature;
  scale: number;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  showTooltip: boolean;
};

const TAIL_NUM_VISIBLE_ZOOM_LEVEL = 10; // ~3 miles

const getTooltipLabel = (aircraft: AircraftLayerFeature): string => {
  if (aircraft.properties.shortCallsign) {
    return aircraft.properties.shortCallsign;
  }
  return aircraft.properties.tailNum;
};

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has body type of BlockStatement instead of ObjectExpression.
const useStyles = makeStyles<{
  rotate: number;
  altitude?: Altitude;
  scale: number;
}>()((_, { rotate, altitude, scale }) => {
  // Calculate the x/y offset for the shadow based on the rotation.

  // The shadow should get "shorter" as the user zooms out.
  const scaleOffsetMultipler =
    ((scale - MIN_ICON_SCALE) / (MAX_ICON_SCALE - MIN_ICON_SCALE) / 3) * 2 +
    0.33;
  const shadowDistance =
    altitude === 'ground' || altitude == null
      ? 0
      : Math.ceil(
          (altitude / SHADOW_DISTANCE_ALTITUDE_MAX_FEET) *
            SHADOW_DISTANCE_BY_ALTITUDE_RANGE_PX *
            scaleOffsetMultipler +
            SHADOW_DISTANCE_MIN_PX,
        );
  const angleRad = (-1 * rotate * Math.PI) / 180 + Math.PI / 4;
  const offsetX = Math.ceil(Math.cos(angleRad) * shadowDistance);
  const offsetY = Math.ceil(Math.sin(angleRad) * shadowDistance);

  return {
    shadow: {
      filter: `drop-shadow(${offsetX}px ${offsetY}px 1.5px rgba(50,50,50,0.5))`,
      // Trick Safari/WebKit browsers to render the shadow on the GPU, which has the side-effect
      // of updating it every time the aircraft move. This avoids a problem where the shadow was
      // calculated once but then rotated along with the aircraft, making it seem like it's
      // coming from the wrong.
      //
      // See:
      // * https://github.com/mdn/browser-compat-data/issues/17726
      // * https://stackoverflow.com/questions/10814178/css-performance-relative-to-translatez0
      WebkitTransform: 'translate3d(0,0,0)',
    },
    popup: {
      '& .maplibregl-popup-tip': {
        display: 'none',
        visibility: 'hidden',
      },
      '& .maplibregl-popup-content': {
        padding: '2px 8px',
        borderRadius: '6px',
      },
    },
  };
});

const AircraftMarker = (props: MarkerProps): JSX.Element => {
  const { feature, scale, onClick, onMouseEnter, onMouseLeave, showTooltip } =
    props;
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const isMobileApp = isMobile();

  let variant: AircraftIconVariant = 'default';
  if (hovered || feature.properties.selected) {
    variant =
      feature.properties.altitude === 'ground' ? 'activeground' : 'active';
  } else if (feature.properties.altitude === 'ground') {
    variant = 'ground';
  }

  const { classes } = useStyles({
    rotate: feature.properties.rotate,
    altitude: feature.properties.altitude,
    scale,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: MarkerEvent<any, MouseEvent>): void => {
    // Prevent interaction with other layers
    e.originalEvent.stopPropagation();
    setClicked(true);
    onClick();
  };

  const handleMouseEnter = (): void => {
    setHovered(true);
    onMouseEnter();
  };

  const handleMouseLeave = (): void => {
    setHovered(false);
    onMouseLeave();
    if (clicked) {
      setClicked(false);
    }
  };

  const icon = useMemo(
    () =>
      AircraftIcons.find((i) => i.name === feature.properties.icon) ??
      AircraftIcons.find((i) => i.name === 'tankerType2'),
    [feature.properties.icon],
  );

  const finalScale = useMemo(() => {
    const iconScale = icon?.scale ?? 1.0;
    return scale * iconScale;
  }, [icon?.scale, scale]);

  // Use the aircraft's altitude as the zIndex.
  const zIndex = useMemo(() => {
    const altitude =
      feature.properties.altitude === 'ground'
        ? 0
        : (feature.properties.altitude ?? 0);
    return altitude;
  }, [feature.properties.altitude]);

  const aircraftLabel = getTooltipLabel(feature);
  return (
    <>
      <MarkerComponent
        longitude={feature.geometry.coordinates[0]}
        latitude={feature.geometry.coordinates[1]}
        rotation={feature.properties.rotate}
        anchor="center"
        style={{ zIndex }}
        onClick={handleClick}
      >
        <Box
          className={classes.shadow}
          onMouseEnter={isMobileApp ? undefined : handleMouseEnter}
          onMouseLeave={isMobileApp ? undefined : handleMouseLeave}
        >
          {icon && (
            <AircraftIcon icon={icon} variant={variant} scale={finalScale} />
          )}
        </Box>
      </MarkerComponent>
      {aircraftLabel && (showTooltip || hovered) && (
        <Popup
          longitude={feature.geometry.coordinates[0]}
          latitude={feature.geometry.coordinates[1]}
          anchor="top"
          closeButton={false}
          closeOnClick={false}
          offset={-54}
          className={classes.popup}
        >
          <Typography variant="subtitle1">{aircraftLabel}</Typography>
        </Popup>
      )}
    </>
  );
};

export const AircraftIconMarkers = (props: AircraftIconsProps): JSX.Element => {
  const { data, onClick, onHover } = props;
  const { current: mapRef } = useMap();
  const [scale, setScale] = useState<number>(MAX_ICON_SCALE);
  const map = mapRef?.getMap();

  // See https://dmitripavlutin.com/react-throttle-debounce/ for why
  // useMemo is used instead of useCallback.
  const updateScale = useMemo(
    () =>
      throttle(() => {
        const zoom = map?.getZoom() ?? 10;
        if (zoom <= 6) {
          setScale(MIN_ICON_SCALE);
        } else if (zoom >= 10) {
          setScale(MAX_ICON_SCALE);
        } else {
          // Interpolate between min/max for zooms between 6 and 10.
          setScale(
            MIN_ICON_SCALE +
              ((zoom - 6) / 4) * (MAX_ICON_SCALE - MIN_ICON_SCALE),
          );
        }
      }, 100),
    [map, setScale],
  );

  // Set the scale correctly on initial load.
  useEffect(() => {
    updateScale();
    return () => {};
  }, [updateScale]);

  useEffect(() => {
    map?.on('zoom', updateScale);
    return () => {
      map?.off('zoom', updateScale);
    };
  }, [map, updateScale]);

  const mapZoom = map?.getZoom() ? Math.round(map.getZoom()) : undefined;
  const showTooltip = !!mapZoom && mapZoom >= TAIL_NUM_VISIBLE_ZOOM_LEVEL;

  return (
    <>
      {data.features
        .filter(
          (f) =>
            isFinite(f.geometry.coordinates[0]) &&
            isFinite(f.geometry.coordinates[1]),
        )
        .map((f) => (
          <AircraftMarker
            key={f.properties.hex}
            feature={f}
            scale={scale}
            onClick={() => onClick(f)}
            onMouseEnter={() => onHover(f)}
            onMouseLeave={() => onHover(null)}
            showTooltip={showTooltip}
          />
        ))}
    </>
  );
};
