/* eslint-disable no-useless-escape */

import i18n from 'i18n/config';

export const WATCHDUTY_SCHEME = 'watchduty://';
// eslint-disable-next-line import/prefer-default-export
export const NotificationType = {
  NORMAL: 'normal',
  SILENT: 'silent',
} as const;

export const NotificationSettingTypes = {
  all: 'all',
  onlyNew: 'new_incidents_only',
  off: 'off',
  allWithSilent: 'all_with_silent',
  onlyNewWithSilent: 'only_new_with_silent',
} as const;

// Rank the user region settings from most coverage to least
export const NotificationSettingTypesCoverageOrder = [
  NotificationSettingTypes.allWithSilent,
  NotificationSettingTypes.all,
  NotificationSettingTypes.onlyNewWithSilent,
  NotificationSettingTypes.onlyNew,
  NotificationSettingTypes.off,
];

// Enum's of subscription settings possible for a region
// todo: update to typed with the key mapping pulling from NotificationOption
export const NotificationTypes = {
  ALL: {
    displayName: i18n.t('notifications.types.all'),
    key: NotificationSettingTypes.all,
  },
  NEW_INCIDENTS_ONLY: {
    displayName: i18n.t('notifications.types.first'),
    key: NotificationSettingTypes.onlyNew,
  },
  OFF: {
    displayName: i18n.t('notifications.types.off'),
    key: NotificationSettingTypes.off,
  },
} as const;

export const AppStoreLinks = {
  iosBannerCampaign:
    'https://apps.apple.com/app/apple-store/id1574452924?pt=123122876&ct=App%20Banner%20in%20Mobile%20Browsers&mt=8',
  ios: 'https://apps.apple.com/us/app/watch-duty-wildfire/id1574452924',
  android: 'https://play.google.com/store/apps/details?id=org.watchduty.app',
} as const;

export const UsStatesAbbr = {
  Arizona: 'AZ',
  Alabama: 'AL',
  Alaska: 'AK',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
} as const;

export const DEFAULT_LAT = 0;

export const DEFAULT_LON = 0;

// Random limit - allows field to resize with content up to this limit
export const TEXTAREA_MAX_ROWS = 100;

export const EvacZoneStatuses = {
  orders: 'orders',
  warnings: 'warnings',
  advisories: 'advisories',
} as const;

export const EvacZoneStyles = {
  default: 'order_warning_advisory',
  readySetGo: 'ready_set_go',
  withLevels: 'ready_set_go_with_levels',
  reverseColors: 'ready_set_go_reverse_colors',
} as const;

export const LocationTypes = {
  evacShelter: 'evac_shelter',
  animalShelter: 'animal_shelter',
  info: 'info',
  general: 'general',
  food: 'food',
} as const;

export const LinkTypes = {
  phone: 'phone',
  website: 'website',
  email: 'email',
} as const;

export const GeoEventTypes = {
  wildfire: 'wildfire',
  location: 'location',
} as const;

// There are a few more but not currently supplied to the client
export type ExternalSource =
  | 'pulsepoint'
  | 'nifc'
  | 'wildcad'
  | 'chp'
  | 'aircraft_detection'
  | 'alert_west';

export const ExternalGeoNameMap: Record<ExternalSource, string> = {
  pulsepoint: 'Pulsepoint',
  wildcad: 'WildCAD',
  nifc: 'NIFC',
  chp: 'CHP',
  aircraft_detection: 'Aircraft Circling',
  alert_west: 'Camera hit',
};

// Taken from Yup's URL validation regex but modified to not require the protocol:
// https://github.com/jquense/yup/blob/089ce8bfa8137e0dd1886bd40301b96bcc6deef7/src/string.ts#L24
export const UrlRegex =
  /^(((https|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const PhoneNumberRegex = /^(1|)?(\d{3})(\d{3})(\d{4})$/;

export const ABORT_SHARE_ERROR_STRING = 'Share canceled';

export const MAP_DOM_ELEMENT_ID = 'map-container';

export const SEARCH_DRAWER_WIDTH = 375;

export const SEARCH_CARD_MIN_SNAP_PCT = 0.1;

export const EVAC_ZONE_STROKE_COLOR = '#000000';
export const ORDERS_COLOR = '#E25345';
export const WARNINGS_COLOR = '#FBC02D';
export const ADVISORIES_DEFAULT_COLOR = '#6393F2';
export const ADVISORIES_RSG_COLOR = '#509F4E';

export const EVAC_ZONE_LAYER_COLORS = {
  [EvacZoneStyles.default]: {
    orders: ORDERS_COLOR,
    warnings: WARNINGS_COLOR,
    advisories: ADVISORIES_DEFAULT_COLOR,
  },
  [EvacZoneStyles.readySetGo]: {
    orders: ORDERS_COLOR,
    warnings: WARNINGS_COLOR,
    advisories: ADVISORIES_RSG_COLOR,
  },
  [EvacZoneStyles.withLevels]: {
    orders: ORDERS_COLOR,
    warnings: WARNINGS_COLOR,
    advisories: ADVISORIES_RSG_COLOR,
  },
  [EvacZoneStyles.reverseColors]: {
    orders: ADVISORIES_RSG_COLOR,
    warnings: WARNINGS_COLOR,
    advisories: ORDERS_COLOR,
  },
} as const;

export const EVAC_ZONE_LABEL_TRANS_KEYS = {
  [EvacZoneStyles.default]: {
    orders: 'evacZones.default.orders',
    warnings: 'evacZones.default.warnings',
    advisories: 'evacZones.default.advisories',
  },
  [EvacZoneStyles.readySetGo]: {
    orders: 'evacZones.readySetGo.orders',
    warnings: 'evacZones.readySetGo.warnings',
    advisories: 'evacZones.readySetGo.advisories',
  },
  [EvacZoneStyles.withLevels]: {
    orders: 'evacZones.withLevels.orders',
    warnings: 'evacZones.withLevels.warnings',
    advisories: 'evacZones.withLevels.advisories',
  },
  [EvacZoneStyles.reverseColors]: {
    orders: 'evacZones.reverseColors.orders',
    warnings: 'evacZones.reverseColors.warnings',
    advisories: 'evacZones.reverseColors.advisories',
  },
} as const;

export const ORDERS_PATTERN = 'EvacOrderPattern';
export const ORDERS_REVERSE_PATTERN = 'EvacReverseOrderPattern';
export const WARNINGS_PATTERN = 'EvacWarningPattern';
export const ADVISORIES_DEFAULT_PATTERN = 'EvacAdvisoryBluePattern';
export const ADVISORIES_GREEN_PATTERN = 'EvacAdvisoryGreenPattern';
export const ADVISORIES_REVERSE_PATTERN = 'EvacReverseAdvisoryPattern';

export const NOTIFICATIONS_SETTINGS_QUERY_KEY = 'notificationSettings';

export const NOTIFICATIONS_CACHE_TIME = 1000 * 60 * 60 * 24 * 30; // 30 days

export const MEMBERSHIP_PLANS = {
  yearly: 'yearly',
  proYearly: 'pro_yearly',
  proMonthly: 'pro_monthly',
} as const;

export const MEMBERSHIP_PLANS_AMOUNTS = {
  [MEMBERSHIP_PLANS.yearly]: 24.99,
  [MEMBERSHIP_PLANS.proYearly]: 79.99,
  [MEMBERSHIP_PLANS.proMonthly]: 29.99,
};

export const PRO_MEMBERSHIP_PLAN_AMOUNT_2025 = 99.99;

export const COORDINATE_DISPLAY_PRECISION = 7;
