import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
  PushNotifications,
  RegistrationError,
  Token,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import * as Sentry from '@sentry/capacitor';
import { History } from 'history';
import { registerDeviceWithWatchDuty } from 'shared/device';
import {
  getPushTokenFromLocalStorage,
  setLastPushNotificationTimestamp,
} from 'state/localStorage';
import { getCurrentPosition } from 'hooks/usePosition';
import { User } from 'shared/types';
import { MAX_CACHE_MS } from '../../state/useCacheState';

// Android push notification importance decides how the notification is displayed (heads-up notification with sound)
// @see https://developer.android.com/guide/topics/ui/notifiers/notifications#importance
const ANDROID_NOTIF_IMPORTANCE = 4;
const ANDROID_NOTIF_CHANNEL_ID = 'incidents-reports';
const ANDROID_NOTIF_SOUND = 'notification.wav';

/**
 * FCM can send silent pushes. This determines if this is a real, visible notification
 * @returns
 */
const isVisiblePushNotification = (notif: PushNotificationSchema): boolean =>
  Boolean(notif.title) || Boolean(notif.body);

/**
 * Android doesn't show a notification when the app is in the foreground, so we'll show our own.
 * We schedule a LocalNotification 1 second later since Capacitor for Android doesn't show anything in this case.
 *
 * @see:
 * - https://github.com/ionic-team/capacitor/issues/2261#issuecomment-647267061
 * - https://github.com/ionic-team/capacitor-plugins/issues/234
 */
const replayAndroidPushInFocus = (
  notification: PushNotificationSchema & { channelId?: string },
): void => {
  const {
    body,
    channelId = ANDROID_NOTIF_CHANNEL_ID,
    data,
    title,
  } = notification;
  const epoch = new Date().getTime();

  LocalNotifications.schedule({
    notifications: [
      {
        title: title as string,
        body: body as string,
        // 32-bit int - Value should be between -2147483648 and 2147483647 inclusive
        id: Math.round(epoch / 1000),
        schedule: {
          at: new Date(epoch + 1000),
          // Allow this notification to fire while in Doze Only available for Android 23+. Note that these notifications can only fire once per 9 minutes, per app.
          // @see https://capacitorjs.com/docs/apis/local-notifications#doze
          allowWhileIdle: true,
        },
        extra: data,
        channelId,
        sound: ANDROID_NOTIF_SOUND,
      },
    ],
  });
};

export const handlePushRegistrationFinished = async (
  token: Token,
  user?: User | null,
): Promise<void> => {
  const currentToken = getPushTokenFromLocalStorage();

  // if the token given to us from FCM is the same as what
  // we have locally, this is a no-op
  if (currentToken === token.value) {
    return;
  }

  const [, positionResult] = await getCurrentPosition();
  const params = {
    user,
    push_token: token.value,
    lat: positionResult?.coords?.latitude,
    lng: positionResult?.coords?.longitude,
  };
  await registerDeviceWithWatchDuty(params);
};

const handlePushRegistrationFailed = (error: RegistrationError): void => {
  // @todo upload this token to watch duty
  // eslint-disable-next-line no-console
  console.error('push registration failed', error);
};

/**
 * There is horrible behavior here where on Android, when the app is
 * in focus, the data comes over via the `extra` key instead of the `data`
 * key.  this code handles these differences
 */
const getNotificationData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notification: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { data: Record<string, any>; type: any } => {
  const {
    data: { object = '', objectType = '' } = {},
    extra: {
      object: androidObject = '',
      objectType: androidObjectType = '',
    } = {},
  } = notification;

  const dataString = object || androidObject;
  const type = objectType || androidObjectType;
  const data = dataString ? JSON.parse(dataString) : {};

  return { data, type };
};

export const setCacheBusterTsFromNotifications = async (
  setCacheBuster: (ts: number | null) => void,
): Promise<void> => {
  let response;
  try {
    response = await PushNotifications.getDeliveredNotifications();
  } catch (e) {
    // we expect this to fail for users that haven't enabled push notifications
    return;
  }

  if (!response.notifications || !response.notifications.length) return;

  const lastNotification = response.notifications[0];
  const { data } = getNotificationData(lastNotification);
  const newDate = data?.date_modified ? new Date(data.date_modified) : null;
  if (!newDate) return;

  // and only set it if its within the useable window - otherwise it will just cause re-renders downstream
  const nowMs = new Date().getTime();
  const ts = newDate.getTime();
  if (nowMs - ts > MAX_CACHE_MS) return;

  setCacheBuster(ts);
};

const handlePushReceived = async (
  notification: PushNotificationSchema,
  setCacheBuster?: (ts: number | null) => void,
): Promise<void> => {
  setLastPushNotificationTimestamp();

  if (Capacitor.getPlatform() === 'android') {
    /**
     * On Android, foreground notifications are non-clickable due to an underlying capacitor issue.
     * Additionally, these notifications use the default system sound. To address this, we have to
     * remove the delivered notification from the screen so that it's never shown and schedule a
     * local notification with the same payload that uses our custom notification sound and is clickable.
     * The above was true for Capacitor 4, no longer true in Capacitor 5.
     */
    await PushNotifications.getDeliveredNotifications()
      .then((deliveredNotif) => {
        PushNotifications.removeDeliveredNotifications(deliveredNotif);
      })
      .catch((err) => Sentry.captureException(err));
  }

  if (!isVisiblePushNotification(notification)) {
    return;
  }

  const { data } = getNotificationData(notification);

  // make a cacheBuster from the model
  const ts = data?.date_modified ? new Date(data.date_modified) : new Date();
  if (setCacheBuster) setCacheBuster(ts.getTime());

  if (Capacitor.getPlatform() === 'android') {
    replayAndroidPushInFocus(notification);
  }
};

const handlePushTapped = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: Record<string, any>,
  history: History,
  setCacheBuster?: (ts: number | null) => void,
): void => {
  const { actionId, notification } = details;
  if (actionId !== 'tap') {
    return;
  }

  const { data } = getNotificationData(notification);

  // make a cacheBuster from the model
  const ts = data?.date_modified ? new Date(data.date_modified) : new Date();
  if (setCacheBuster) setCacheBuster(ts.getTime());

  if (data.geo_event_id) {
    // Current belief is that when the app comes back from a "long-term" minimized state, there is a race condition
    //   where this updates history, and then something occurs to overwrite that history change.
    //   By delaying the history.push, we ensure this is the "last" url that the user ends up seeing.
    // See ticket: https://trello.com/c/svhXqy1D/932-fix-investigate-push-notification-tap-bug
    setTimeout(() => history.push(`/i/${data.geo_event_id}`), 500);
  }
};

const initAndroidPushChannel = async (): Promise<void> => {
  // Used when app is the background/closed
  await PushNotifications.createChannel({
    id: ANDROID_NOTIF_CHANNEL_ID,
    name: 'Incidents & Reports',
    sound: ANDROID_NOTIF_SOUND,
    importance: ANDROID_NOTIF_IMPORTANCE,
  });

  // Used when app is in the foreground
  await LocalNotifications.createChannel({
    id: ANDROID_NOTIF_CHANNEL_ID,
    name: 'Incidents & Reports',
    sound: ANDROID_NOTIF_SOUND,
    importance: ANDROID_NOTIF_IMPORTANCE,
  });
};

export const isPushNotificationsEnabled = async (): Promise<boolean> => {
  // I'm not sure this is needed, disabled for the sake of e2e tests on iOS simulator
  // if (import.meta.env.VITE_DEBUG_PUSH_TOKEN) {
  //   return true;
  // }

  if (!Capacitor.isNativePlatform()) {
    return false;
  }

  if (!Capacitor.isPluginAvailable('PushNotifications')) {
    return false;
  }

  try {
    const result = await PushNotifications.checkPermissions();
    return result.receive === 'granted';
  } catch (e) {
    return false;
  }
};

const initPushNotifications = async (
  history: History,
  setCacheBuster?: (ts: number | null) => void,
  handleTokenRegistration?: (token: Token) => Promise<void>,
): Promise<boolean> => {
  if (import.meta.env.VITE_FASTLANE_SCREENSHOT_DEMO) {
    return false;
  }

  if (!Capacitor.isNativePlatform()) {
    return false;
  }

  if (!Capacitor.isPluginAvailable('PushNotifications')) {
    return false;
  }

  const result = await PushNotifications.requestPermissions();

  if (result.receive !== 'granted') {
    return false;
  }

  await PushNotifications.removeAllListeners();

  // Note: these listeners need to come BEFORE PushNotifications.register()
  PushNotifications.addListener(
    'registration',
    handleTokenRegistration || handlePushRegistrationFinished,
  );
  PushNotifications.addListener(
    'registrationError',
    handlePushRegistrationFailed,
  );
  PushNotifications.addListener('pushNotificationReceived', (notif) => {
    handlePushReceived(notif, setCacheBuster);
  });
  PushNotifications.addListener('pushNotificationActionPerformed', (notif) => {
    handlePushTapped(notif, history, setCacheBuster);
  });
  LocalNotifications.addListener(
    'localNotificationActionPerformed',
    (notif) => {
      handlePushTapped(notif, history, setCacheBuster);
    },
  );

  // Register with Apple / Google to receive push token via APNS/FCM
  await PushNotifications.register();

  if (Capacitor.getPlatform() === 'android') {
    await initAndroidPushChannel();
  }

  return true;
};

export default initPushNotifications;
