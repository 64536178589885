import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Link,
  Alert,
  AlertTitle,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation, Trans } from 'react-i18next';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Network } from '@capacitor/network';
import {
  NativeSettings,
  IOSSettings,
  AndroidSettings,
} from 'capacitor-native-settings';
import { Capacitor } from '@capacitor/core';
import { isPushNotificationsEnabled } from 'shared/push';
import { useNotifications } from 'hooks/useNotifications';
import { getPushTokenFromLocalStorage } from 'state/localStorage';
import { NotificationSetting } from 'shared/types';
import useAppInfo from 'hooks/useAppInfo';
import { getDeviceInfo } from 'state/localStorageTyped';
import { useAuthState } from 'state';
import { API } from 'api';
import GrayButton from 'components/GrayButton';
import { NotificationKeys, NotificationStatus } from './types';
import { getInitialNotificationsState, reducer, getEmailLink } from './utils';

type NotificationItemProps = {
  title: string;
  status?: NotificationStatus;
};

type ErrorAlertProps = {
  notificationKey: NotificationKeys;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  success: {
    color: theme.palette.success.main,
  },
  textButton: {
    fontSize: theme.typography.body1.fontSize,
    textTransform: 'none',
    padding: 0,
    fontWeight: theme.typography.fontWeightBold,
  },
  androidTextButton: {
    marginBottom: 2,
    marginLeft: -2,
  },
  iosTextButton: {
    marginBottom: 3,
  },
  button: {
    borderRadius: theme.shape.borderRadius * 1.34,
    fontWeight: theme.typography.fontWeightBold,
    minHeight: 48,
  },
}));

const NotificationItem = (props: NotificationItemProps): JSX.Element => {
  const { title, status = 'idle' } = props;
  const { classes } = useStyles();

  const renderIcon = (): JSX.Element => {
    switch (status) {
      case 'pending':
        return <CircularProgress size={21} />;
      case 'success':
        return (
          <CheckCircleOutlinedIcon
            className={classes.success}
            role="img"
            aria-label="success"
          />
        );
      case 'error':
        return (
          <CancelOutlinedIcon color="error" role="img" aria-label="error" />
        );
      default:
        return (
          <RemoveCircleOutlineIcon
            color="disabled"
            role="img"
            aria-label="idle"
          />
        );
    }
  };

  return (
    <div className={classes.item}>
      <Typography>{title}</Typography>
      {renderIcon()}
    </div>
  );
};

const ErrorAlert = (props: ErrorAlertProps): JSX.Element => {
  const { notificationKey } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  if (notificationKey === 'notificationSettings') {
    const handleOpenSettings = (): void => {
      NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
    };

    return (
      <Alert severity="error" data-testid={`alert-error-${notificationKey}`}>
        <AlertTitle>
          {t('testNotifications.alerts.notificationSettings.title')}
        </AlertTitle>
        <Typography>
          <Trans i18nKey="testNotifications.alerts.notificationSettings.description">
            Please enable notifications in
            <Button
              variant="text"
              color="inherit"
              className={cx(
                classes.textButton,
                classes[`${Capacitor.getPlatform()}TextButton`],
              )}
              onClick={handleOpenSettings}
            >
              Settings
            </Button>
          </Trans>
        </Typography>
      </Alert>
    );
  }

  return (
    <Alert severity="error" data-testid={`alert-error-${notificationKey}`}>
      <AlertTitle>
        {t(`testNotifications.alerts.${notificationKey}.title`)}
      </AlertTitle>
      <Typography>
        {t(`testNotifications.alerts.${notificationKey}.description`)}
      </Typography>
    </Alert>
  );
};

const TestNotificationsContent = (): JSX.Element => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [countySubscriptions, setCountySubscriptions] = useState<
    NotificationSetting[]
  >([]);
  const [state, dispatch] = useReducer(reducer, {
    testCount: 0,
    notificationsState: getInitialNotificationsState('idle'),
  });
  const pushToken = getPushTokenFromLocalStorage();
  const { subscribedNotificationSettings } = useNotifications({
    retryQuery: false,
    queryEnabled: false,
  });
  const { user } = useAuthState();
  const appInfo = useAppInfo();
  const deviceInfo = getDeviceInfo();

  const testNotificationSettings = useCallback(async () => {
    try {
      const isEnabled = await isPushNotificationsEnabled();
      if (!isEnabled) throw new Error();
      dispatch({ notificationKey: 'notificationSettings', status: 'success' });
    } catch (err) {
      dispatch({ notificationKey: 'notificationSettings', status: 'error' });
      throw err;
    }
  }, []);

  const testDeviceRegistration = useCallback(async () => {
    try {
      if (!pushToken) throw new Error();
      dispatch({ notificationKey: 'deviceRegistration', status: 'success' });
    } catch (err) {
      dispatch({ notificationKey: 'deviceRegistration', status: 'error' });
      throw err;
    }
  }, [pushToken]);

  const testCountySubscriptions = useCallback(async () => {
    try {
      const response = await subscribedNotificationSettings.refetch();
      const notifSubscriptions = response?.data?.data ?? [];
      if (notifSubscriptions.length === 0) throw new Error();
      setCountySubscriptions(notifSubscriptions);
      dispatch({ notificationKey: 'countySubscriptions', status: 'success' });
    } catch (err) {
      dispatch({ notificationKey: 'countySubscriptions', status: 'error' });
      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const testNetworkConnection = useCallback(async () => {
    try {
      const { connected } = await Network.getStatus();
      if (!connected) throw new Error();
      dispatch({ notificationKey: 'networkConnection', status: 'success' });
    } catch (err) {
      dispatch({ notificationKey: 'networkConnection', status: 'error' });
      throw err;
    }
  }, []);

  const testSendNotification = useCallback(async () => {
    try {
      await API.post('/notifications/test_notification/', { pushToken });
      dispatch({ notificationKey: 'sendNotification', status: 'success' });
    } catch (err) {
      dispatch({ notificationKey: 'sendNotification', status: 'error' });
      throw err;
    }
  }, [pushToken]);

  const testNotifications = useCallback(async () => {
    try {
      dispatch({ type: 'init' });
      await testNotificationSettings();
      await testDeviceRegistration();
      await testCountySubscriptions();
      await testNetworkConnection();
      await testSendNotification();
      dispatch({ type: 'end' });
    } catch (err) {
      dispatch({ type: 'end' });
    }
  }, [
    testCountySubscriptions,
    testDeviceRegistration,
    testNotificationSettings,
    testNetworkConnection,
    testSendNotification,
  ]);

  useEffect(() => {
    testNotifications();
  }, [testNotifications]);

  const { testCount, notificationsState } = state;

  const notificationStateKeys = Object.keys(
    notificationsState,
  ) as NotificationKeys[];

  const errorNotificationKey = notificationStateKeys.find(
    (notifKey) => notificationsState[notifKey] === 'error',
  );

  const loading = notificationStateKeys.some(
    (notifKey) => notificationsState[notifKey] === 'pending',
  );

  const success = notificationStateKeys.every(
    (notifKey) => notificationsState[notifKey] === 'success',
  );

  return (
    <Container className={classes.container}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h2">
            <b>{t('testNotifications.title')}</b>
          </Typography>
        </Grid>

        <Grid item>
          <NotificationItem
            title={t('testNotifications.tests.notificationSettings')}
            status={notificationsState.notificationSettings}
          />
        </Grid>

        <Grid item>
          <NotificationItem
            title={t('testNotifications.tests.deviceRegistration')}
            status={notificationsState.deviceRegistration}
          />
        </Grid>

        <Grid item>
          <NotificationItem
            title={t('testNotifications.tests.countySubscriptions')}
            status={notificationsState.countySubscriptions}
          />
        </Grid>

        <Grid item>
          <NotificationItem
            title={t('testNotifications.tests.networkConnection')}
            status={notificationsState.networkConnection}
          />
        </Grid>

        <Grid item>
          <NotificationItem
            title={t('testNotifications.tests.sendNotification')}
            status={notificationsState.sendNotification}
          />
        </Grid>

        {!!errorNotificationKey && (
          <Grid item>
            <ErrorAlert notificationKey={errorNotificationKey} />
          </Grid>
        )}

        {success && (
          <Grid item>
            <Alert severity="success" data-testid="alert-success">
              <AlertTitle>
                {t('testNotifications.alerts.success.title')}
              </AlertTitle>
              <Typography paragraph>
                <Trans
                  i18nKey="testNotifications.alerts.success.description.line1"
                  values={{ count: countySubscriptions.length }}
                >
                  You will receive notifications for <b>4 counties</b>.
                </Trans>
              </Typography>
              <Typography>
                <b>{t('testNotifications.alerts.success.description.line2')}</b>
              </Typography>
            </Alert>
          </Grid>
        )}

        {testCount > 0 && (
          <Grid item>
            <Grid item container spacing={4} direction="column">
              <Grid item>
                <GrayButton
                  fullWidth
                  className={classes.button}
                  onClick={testNotifications}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    t('testNotifications.buttons.testAgain')
                  )}
                </GrayButton>
              </Grid>

              <>
                <Grid item>
                  <Typography>
                    <b>{t('testNotifications.troubleshooting.title')}</b>
                  </Typography>

                  <Typography variant="body2">
                    {t('testNotifications.troubleshooting.description')}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1">
                    <Link
                      underline="none"
                      color="textPrimary"
                      href={getEmailLink({
                        notificationsState,
                        notificationSubscriptions: countySubscriptions,
                        user,
                        deviceInfo,
                        appInfo,
                        pushToken,
                      })}
                    >
                      <b>{t('testNotifications.buttons.sendReport')}</b>
                    </Link>
                  </Typography>
                </Grid>
              </>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default TestNotificationsContent;
