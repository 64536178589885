import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { API } from 'api';
import { ExternalGeoEvent } from 'shared/types';
import { useAuthState } from 'state';

type UseExternalGeoEvents = {
  externalGeoEvents: ExternalGeoEvent[];
};

const EXTERNAL_GEO_EVENTS_URL = 'geo_events_external/';
const REFETCH_MS = 1000 * 20;

const fetchExternalGeoEvents = (): Promise<AxiosResponse<ExternalGeoEvent[]>> =>
  API.get<ExternalGeoEvent[]>(EXTERNAL_GEO_EVENTS_URL, {
    params: {
      lookbackMinutes: 30,
    },
  });

export const filterVisible = (entry?: ExternalGeoEvent): boolean => {
  // provide some client side filtering for what is shown to reporters. Pulsepoint records created
  // outside of our criteria are still written to the db but are not routed to the normal slack channels
  if (!entry) return false;
  if (entry.externalSource !== 'pulsepoint') return true;
  if (entry.channel === 'bots-extra-alerts') return false;
  return true;
};

export const useExternalGeoEvents = (): UseExternalGeoEvents => {
  const {
    permissions: { isInternalUser },
  } = useAuthState();

  const { data } = useQuery({
    queryKey: ['externalGeoEvents'],
    queryFn: () => fetchExternalGeoEvents(),
    refetchInterval: REFETCH_MS,
    enabled: isInternalUser,
  });

  const results = useMemo(() => data?.data || [], [data]);

  return { externalGeoEvents: results };
};
