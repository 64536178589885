import * as Sentry from '@sentry/capacitor';
import i18n from 'i18next';

export type Language = 'en' | 'es' | 'tl' | 'vi' | 'zh';

export const DEFAULT_LOCALE = 'en';

export const setLanguage = async (language: Language): Promise<void> => {
  try {
    await i18n.changeLanguage(language);
  } catch (err) {
    Sentry.captureException(err);
  }
};
