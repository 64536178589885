import { Device, DeviceInfo } from '@capacitor/device';
import { saveData, LOCAL_STORAGE_KEY } from '../../state/localStorage';

type SetDeviceInfoData = DeviceInfo & { uuid: string };

export const setDeviceInfo = async (): Promise<SetDeviceInfoData> => {
  const id = await Device.getId();
  const deviceInfo = await Device.getInfo();

  saveData(LOCAL_STORAGE_KEY.DEVICE_INFO, {
    ...deviceInfo,
    uuid: id.identifier,
  });

  return {
    ...deviceInfo,
    uuid: id.identifier,
  };
};
