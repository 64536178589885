import { isEqual } from 'lodash-es';
import {
  ChildLocation,
  GeoEvent,
  GeoEventCreateUpdateData,
  GeoEventPatchData,
  RelatedGeoEvent,
} from '../../shared/types';

type CompareConfigItem = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shouldPatch: (a?: any, b?: any) => boolean;
  // shouldPatch: (a?: any, b?: any) => boolean;
};

type CreateUpdateKeyNotData = Exclude<keyof GeoEventCreateUpdateData, 'data'>;

/**
 * Compare two optional strings that could be null, '' or undefined
 */
export const optionalStringsDiffer = (
  initialString?: string | null,
  editedString?: string | null,
): boolean => {
  const emptyValues = ['', undefined, null];

  if (
    emptyValues.includes(initialString) &&
    emptyValues.includes(editedString)
  ) {
    return false;
  }

  return initialString !== editedString;
};

/**
 * Compare Evac Zone Statuses between the form GeoEvent and the form fields parsed for updating
 */
export const evacZoneStatusesDiffer = (
  initialZones: GeoEvent['evacZoneStatuses'],
  editedZones: GeoEventCreateUpdateData['evacZoneStatuses'],
): boolean => {
  const initialZoneItems = initialZones.map((item) => {
    return {
      evacZone: { id: item.evacZone.id },
      status: item.status,
    };
  });

  return !isEqual(initialZoneItems, editedZones);
};

export const geoEventChildrenDiffer = (
  initialChildren: (RelatedGeoEvent | ChildLocation)[],
  editedChildren: (RelatedGeoEvent | ChildLocation)[],
): boolean => {
  const initial = initialChildren.map((item) => ({
    id: item.id,
    geoEventType: item.geoEventType,
  }));

  const updated = editedChildren.map((item) => ({
    id: item.id,
    geoEventType: item.geoEventType,
  }));

  return !isEqual(initial, updated);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNotEqual = (a: any, b: any): boolean => !isEqual(a, b);

/**
 * Convert the form values to the data type expected for PATCH requests.
 *
 * Compare the geoEvent to the formValues that have been parsed for a POST request.
 *
 * Returns a new object with a partial of GeoEventData.
 */
export const constructPatch = (
  original: GeoEvent,
  modified: GeoEventCreateUpdateData,
): GeoEventPatchData => {
  const patchDataConfigs: Record<
    keyof GeoEventCreateUpdateData['data'],
    CompareConfigItem
  > = {
    acreage: {
      shouldPatch: isNotEqual,
    },
    containment: {
      shouldPatch: isNotEqual,
    },
    isComplexParent: {
      shouldPatch: isNotEqual,
    },
    isFps: {
      shouldPatch: isNotEqual,
    },
    isPrescribed: {
      shouldPatch: isNotEqual,
    },
    temporarilyDisplayEvacZones: {
      shouldPatch: isNotEqual,
    },
    evacuationNotes: {
      shouldPatch: optionalStringsDiffer,
    },
    evacuationAdvisories: {
      shouldPatch: optionalStringsDiffer,
    },
    evacuationWarnings: {
      shouldPatch: optionalStringsDiffer,
    },
    evacuationOrders: {
      shouldPatch: optionalStringsDiffer,
    },
    links: {
      shouldPatch: isNotEqual,
    },
    prescribedDateStartLocal: {
      shouldPatch: optionalStringsDiffer,
    },
    reporterOnlyNotes: {
      shouldPatch: optionalStringsDiffer,
    },

    // These fields are never sent with PATCH
    geoEventType: { shouldPatch: () => false },
    prescribedDateStart: { shouldPatch: () => false },
  };

  const patchConfigs: Record<CreateUpdateKeyNotData, CompareConfigItem> = {
    name: {
      shouldPatch: isNotEqual,
    },
    address: {
      shouldPatch: optionalStringsDiffer,
    },
    isActive: {
      shouldPatch: isNotEqual,
    },
    notificationType: {
      shouldPatch: isNotEqual,
    },
    lat: {
      shouldPatch: isNotEqual,
    },
    lng: {
      shouldPatch: isNotEqual,
    },
    regions: {
      shouldPatch: isNotEqual,
    },
    evacZoneStatuses: {
      shouldPatch: evacZoneStatusesDiffer,
    },
    childGeoEvents: {
      shouldPatch: geoEventChildrenDiffer,
    },

    // These fields are never sent with PATCH
    id: { shouldPatch: () => false },
    geoEventType: { shouldPatch: () => false },
    reporterManaged: { shouldPatch: () => false },
    initialReport: { shouldPatch: () => false },
  };

  const rootPatchData: GeoEventPatchData = Object.keys(patchConfigs).reduce(
    (acc, key) => {
      const { shouldPatch } = patchConfigs[key];

      if (shouldPatch(original[key], modified[key])) {
        return {
          ...acc,
          [key]: modified[key],
        };
      }

      return acc;
    },
    {} as GeoEventPatchData,
  );

  const dataPatchData: GeoEventPatchData['data'] = Object.keys(
    patchDataConfigs,
  ).reduce(
    (acc, key) => {
      const { shouldPatch } = patchDataConfigs[key];

      if (shouldPatch(original.data[key], modified.data[key])) {
        return {
          ...acc,
          [key]: modified.data[key],
        };
      }

      return acc;
    },
    {} as GeoEventPatchData['data'],
  );

  return {
    ...rootPatchData,
    ...(dataPatchData && Object.keys(dataPatchData).length
      ? { data: dataPatchData }
      : {}),
  };
};
