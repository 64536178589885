import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fetchGeoEvent,
  patchGeoEvent,
  postGeoEvent,
} from './useGeoEvent.queries';
import {
  GeoEvent,
  GeoEventCreateUpdateData,
  GeoEventPatchData,
} from '../../shared/types';
import { constructPatch } from './useGeoEvent.utils';
import { UseGeoEventProps, UseGeoEvent } from './useGeoEvent.types';

export const useGeoEvent = (props: UseGeoEventProps): UseGeoEvent => {
  const { id: geoEventId, enabled = true } = props;
  const queryClient = useQueryClient();
  const createGeoEventMutation = useMutation({ mutationFn: postGeoEvent });
  const patchGeoEventMutation = useMutation({ mutationFn: patchGeoEvent });

  const {
    data: geoEvent,
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ['geoEvent', geoEventId],
    queryFn: () => fetchGeoEvent(geoEventId!),
    enabled: enabled && Boolean(geoEventId),
  });

  const handleCreateGeoEvent = async (
    data: GeoEventCreateUpdateData,
    skipDuplicationCheck?: boolean,
  ): Promise<GeoEventCreateUpdateData> => {
    return createGeoEventMutation.mutateAsync({
      geoEventData: data,
      skipDuplicationCheck,
    });
  };

  const handleUpdateGeoEvent = async (
    id: number,
    patchData: GeoEventPatchData,
  ): Promise<GeoEventCreateUpdateData | null> => {
    const mutatedGeoEvent = await patchGeoEventMutation.mutateAsync({
      id,
      geoEventData: patchData,
    });

    await Promise.all([
      // invalidate the GET for this model
      queryClient.invalidateQueries({
        queryKey: ['geoEvent', geoEventId],
      }),
      // invalidate all map data - icons etc should immediately update for this reporter
      queryClient.invalidateQueries({
        queryKey: ['geoEvents'],
      }),
      queryClient.invalidateQueries({
        queryKey: ['active-evacuation-zones'],
      }),
    ]);

    return mutatedGeoEvent;
  };

  const saveGeoEvent = (
    updatedGeoEvent: GeoEventCreateUpdateData,
    originalGeoEvent?: GeoEvent,
    skipDuplicationCheck?: boolean,
  ): Promise<GeoEventCreateUpdateData | null> => {
    if (!originalGeoEvent?.id) {
      return handleCreateGeoEvent(updatedGeoEvent, skipDuplicationCheck);
    }

    const patchData = constructPatch(originalGeoEvent, updatedGeoEvent);
    return handleUpdateGeoEvent(originalGeoEvent.id, patchData);
  };

  return {
    geoEvent,
    geoEventFetchError: error,
    isGeoEventFetchError: isError,
    isGeoEventLoading: isLoading,
    saveGeoEvent,
  };
};
