import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
  Theme,
  Typography,
  Alert,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';
import { useNotifications } from 'hooks/useNotifications';
import { useTopicSubscriptions } from 'hooks/useTopicSubscriptions';
import {
  GeoEvent,
  NotificationOption,
  NotificationType,
  Topic,
} from 'shared/types';
import { useNotificationsSettings } from 'hooks/useNotificationsSettings';
import { useSnackbarState } from 'state';
import { NotificationSettingTypes } from '../../../constants';

export const OPT_IN_TOPIC_TYPE = 'incident_topic';
export const OPT_OUT_TOPIC_TYPE = 'geo_event_opt_out';

const useStyles = makeStyles()((theme: Theme) => {
  const { spacing, typography } = theme;
  return {
    containerSection: {
      display: 'flex',
      alignItems: 'center',
    },
    containerSubSection: {
      display: 'flex',
      alignItems: 'center',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
    marginLeft: {
      marginLeft: spacing(2),
    },
    notifStatus: {
      fontWeight: typography.fontWeightMedium,
      textTransform: 'uppercase',
    },
    button: {
      textTransform: 'uppercase',
      color: 'inherit',
      fontWeight: theme.typography.fontWeightBold,
    },
  };
});

type NotificationsTurnedOffDialogProps = {
  open: boolean;
  onSetOpen: (arg0: boolean) => void;
};

export const NotificationsTurnedOffDialog = (
  props: NotificationsTurnedOffDialogProps,
): JSX.Element => {
  const { open, onSetOpen } = props;
  const { t } = useTranslation();

  const handleClickClose = (): void => onSetOpen(false);
  const handleClickSettings = (): Promise<{
    status: boolean;
  }> =>
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });

  return (
    <Dialog
      open={open}
      onClose={handleClickClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('geoEvent.notifications.notificationsDisabled')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('geoEvent.notifications.notificationsDisabledText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickClose} color="secondary">
          {t('common.cancel')}
        </Button>
        <Button onClick={handleClickSettings} color="primary" autoFocus>
          {t('common.settings')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getTopicByType = (topics: Topic[], type: string): Topic | undefined => {
  return topics.find((topic) => topic.type === type);
};

type NotificationState = {
  icon: SvgIconComponent;
  statusKey: string;
};

const NOTIF_STATE_ON: NotificationState = {
  icon: NotificationsActiveIcon,
  statusKey: 'geoEvent.notifications.on',
};
const NOTIF_STATE_OFF: NotificationState = {
  icon: NotificationsOffIcon,
  statusKey: 'geoEvent.notifications.off',
};

type NotificationsEnabledProps = {
  geoEvent: GeoEvent;
};

export const isSubscribed = (
  hasOptOut: boolean,
  hasOptIn: boolean,
  geoEvent: GeoEvent,
  regionSetting: NotificationOption | null,
): boolean => {
  const {
    data: { isPrescribed },
    notificationType,
  } = geoEvent;
  if (
    isPrescribed ||
    !regionSetting ||
    regionSetting === NotificationSettingTypes.off
  ) {
    return hasOptIn;
  }
  if (regionSetting === NotificationSettingTypes.allWithSilent) {
    return !hasOptOut;
  }
  if (
    regionSetting === NotificationSettingTypes.onlyNewWithSilent ||
    regionSetting === NotificationSettingTypes.onlyNew
  ) {
    return hasOptIn;
  }
  if (
    regionSetting === NotificationSettingTypes.all &&
    notificationType === NotificationType.Silent
  ) {
    return hasOptIn;
  }
  if (regionSetting === NotificationSettingTypes.all) {
    return !hasOptOut;
  }
  // shouldn't get here
  return false;
};

export const NotificationsEnabledSwitch = (
  props: NotificationsEnabledProps,
): JSX.Element => {
  const { geoEvent } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { subscribeToTopic, unsubscribe, getSubscriptionToTopic } =
    useTopicSubscriptions();
  const { getMostCoverageSetting, putNotificationSettings } =
    useNotifications();
  const { setSnackbar } = useSnackbarState();

  const regionSetting = getMostCoverageSetting(geoEvent.regions);
  const anyRegionSubscription =
    !!regionSetting && regionSetting !== NotificationSettingTypes.off;

  // These topics are guaranteed to exist on the GeoEvent model
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const optInTopic = getTopicByType(geoEvent.topics, OPT_IN_TOPIC_TYPE)!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const optOutTopic = getTopicByType(geoEvent.topics, OPT_OUT_TOPIC_TYPE)!;

  const optInTopicSubscription = getSubscriptionToTopic(optInTopic.id);
  const hasOptIn = !!optInTopicSubscription;
  const optOutTopicSubscription = getSubscriptionToTopic(optOutTopic.id);
  const hasOptOut = !!optOutTopicSubscription;

  const showNotificationUpgrade =
    geoEvent.regions.length === 1 &&
    anyRegionSubscription &&
    geoEvent.notificationType === NotificationType.Silent &&
    !geoEvent.data.isPrescribed &&
    !(
      regionSetting === NotificationSettingTypes.allWithSilent ||
      regionSetting === NotificationSettingTypes.onlyNewWithSilent
    );

  const [notificationsEnabled, setNotificationsEnabled] = useState(
    isSubscribed(hasOptOut, hasOptIn, geoEvent, regionSetting),
  );

  useEffect(() => {
    setNotificationsEnabled(
      isSubscribed(hasOptOut, hasOptIn, geoEvent, regionSetting),
    );
  }, [hasOptOut, hasOptIn, geoEvent, regionSetting]);

  const enableNotifications = (): void => {
    // Optimistic updates
    setNotificationsEnabled(true);
    if (optOutTopicSubscription) {
      unsubscribe.mutate(optOutTopicSubscription.id, {
        onError: () => {
          setNotificationsEnabled(false);
        },
      });
    } else {
      subscribeToTopic.mutate(optInTopic.id, {
        onError: () => {
          setNotificationsEnabled(false);
        },
      });
    }
  };

  const disableNotifications = (): void => {
    // Optimistic updates
    setNotificationsEnabled(false);
    if (hasOptIn) {
      unsubscribe.mutate(optInTopicSubscription.id, {
        onError: () => {
          setNotificationsEnabled(true);
        },
      });
    } else {
      subscribeToTopic.mutate(optOutTopic.id, {
        onError: () => {
          setNotificationsEnabled(true);
        },
      });
    }
  };

  const handleOnNotificationsChange = (): void => {
    if (notificationsEnabled) {
      disableNotifications();
    } else {
      enableNotifications();
    }
  };

  const handleEnableSilentNotifications = (): void => {
    // this should only be used if there is a single region
    if (regionSetting === NotificationSettingTypes.off) return;
    const newSetting =
      regionSetting === NotificationSettingTypes.all
        ? NotificationSettingTypes.allWithSilent
        : NotificationSettingTypes.onlyNewWithSilent;
    putNotificationSettings.mutate(
      [
        {
          region: geoEvent.regions[0],
          setting: newSetting,
        },
      ],
      {
        onSuccess: () => {
          setSnackbar(t('geoEvent.notifications.successMessage'), 'success');
        },
      },
    );
  };

  const currentNotificationState = notificationsEnabled
    ? NOTIF_STATE_ON
    : NOTIF_STATE_OFF;

  return (
    <>
      <Grid item xs={12} data-testid="notificationsEnabledComponent">
        <Box className={cx(classes.containerSection, classes.justifyBetween)}>
          <Box className={classes.containerSubSection}>
            <currentNotificationState.icon />
            <Box className={classes.marginLeft}>
              <Typography variant="body1" color="initial" component="h5">
                {t('geoEvent.notifications.notifications')}{' '}
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.notifStatus}
                >
                  {t(currentNotificationState.statusKey)}
                </Typography>
              </Typography>
            </Box>
          </Box>
          {/* We want to display the switch as both disabled and clickable.
            When the Switch is disabled it doesn't trigger the click event so
            we wrap in a span to capture the event and call the handler.
            Disabling eslint rule since it triggers unnecessary renders.
        */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label */}
          <span
            role="button"
            tabIndex={0}
            onClick={handleOnNotificationsChange}
            aria-label="notifications switch"
          >
            <Switch checked={notificationsEnabled} />
          </span>
        </Box>
      </Grid>

      {showNotificationUpgrade && (
        <Grid item xs={12}>
          <Alert severity="warning" icon={false}>
            <Grid
              container
              spacing={1}
              onClick={handleEnableSilentNotifications}
            >
              <Grid item xs={12}>
                <Typography>
                  {notificationsEnabled
                    ? t('geoEvent.notifications.silent.on')
                    : t('geoEvent.notifications.silent.off')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.button}>
                  {t('geoEvent.notifications.enableNotifications')}
                </Typography>
              </Grid>
            </Grid>
          </Alert>
        </Grid>
      )}
    </>
  );
};

export const NotificationsDisabledSwitch = (): JSX.Element => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [dialogOpenNotifs, setDialogOpenNotifs] = useState(false);

  return (
    <>
      <Grid item xs={12} data-testid="notificationsDisabledComponent">
        <Box className={cx(classes.containerSection, classes.justifyBetween)}>
          <Box className={classes.containerSubSection}>
            <NotificationsOffIcon />
            <Box className={classes.marginLeft}>
              <Typography variant="body1" color="initial" component="h5">
                {t('geoEvent.notifications.notifications')}
              </Typography>
            </Box>
          </Box>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label */}
          <span
            role="button"
            tabIndex={0}
            onClick={() => setDialogOpenNotifs(true)}
            aria-label="notifications switch"
          >
            <Switch checked={false} disabled />
          </span>
        </Box>
      </Grid>

      <NotificationsTurnedOffDialog
        open={dialogOpenNotifs}
        onSetOpen={setDialogOpenNotifs}
      />
    </>
  );
};

type NotificationSwitchProps = {
  geoEvent: GeoEvent;
};

export const NotificationSwitch = (
  props: NotificationSwitchProps,
): JSX.Element => {
  // Component to display either a disabled state if notifications are not shown or or an enabled toggle
  //  that subscribes/unsubscribes from the opt-in and opt-out topics
  const { geoEvent } = props;
  const { isPushEnabled } = useNotificationsSettings({
    regionSettingsQueryEnabled: false,
  });

  if (!isPushEnabled) {
    return <NotificationsDisabledSwitch />;
  }
  return <NotificationsEnabledSwitch geoEvent={geoEvent} />;
};
