import { ReactNode } from 'react';
import { Source } from 'shared/map-exports';
import getWatchdutyTileUrl from './getWatchdutyTileUrl';

type VisibilityToggledSourceProps = {
  children: ReactNode;
  url: string;
  visible: boolean;
  promoteId?: string;
  volatile?: boolean;
  id?: string;
};

const VisibilityToggledSource = (
  props: VisibilityToggledSourceProps,
): JSX.Element => {
  const { children, url, visible, promoteId, volatile = false, id } = props;
  const sourceUrl = visible ? url : getWatchdutyTileUrl('empty');
  return (
    <Source
      url={sourceUrl}
      type="vector"
      promoteId={promoteId}
      volatile={volatile}
      id={id}
    >
      {children}
    </Source>
  );
};

export default VisibilityToggledSource;
