import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { makeStyles } from 'tss-react/mui';
import { Link as MuiLink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Report } from 'shared/types';
import { NotificationType } from '../../constants';

type ReportMetaDataProps = {
  report: Report;
};

const useStyles = makeStyles()((theme) => ({
  icons: {
    height: 16,
    width: 16,
    paddingTop: 2,
    marginLeft: 4,
  },
}));

const ReportMetaData = (props: ReportMetaDataProps): JSX.Element => {
  const { report } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      {t('report.submittedBy')}&nbsp;
      {report.userCreated && report.userCreated.isVerified ? (
        <MuiLink
          to={`/profile/${report.userCreated.id}#allow-back`}
          component={Link}
          underline="none"
          color="secondary"
        >
          {report.userCreated.displayName}
        </MuiLink>
      ) : (
        t('report.anonymousUser')
      )}
      {report.userCreated && report.userCreated.isVerified && (
        <VerifiedIcon className={classes.icons} />
      )}
      {report.notificationType === NotificationType.SILENT && (
        <NotificationsOffIcon className={classes.icons} />
      )}
    </div>
  );
};

export default ReportMetaData;
