import { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';

type NetworkStatusAlertProps = {
  containerClassName?: string;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    position: 'absolute',
    bottom: 'calc(56px + env(safe-area-inset-bottom) / 2)',
    width: '100%',
    padding: theme.spacing(0, 2),
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000000000000,
  },
  alert: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 500,
    boxShadow: theme.shadows[3],
    minWidth: 343,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  icon: {
    paddingTop: 9,
  },
}));

const NetworkStatusAlert = (
  props: NetworkStatusAlertProps,
): JSX.Element | null => {
  const { containerClassName } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [connected, setConnected] = useState(true);
  useEffect(() => {
    const setInitialConnectionStatus = async (): Promise<void> => {
      const status = await Network.getStatus();
      setConnected(status.connected);
    };

    setInitialConnectionStatus();
  }, []);

  useEffect(() => {
    const networkListenrFn = async (): Promise<PluginListenerHandle> => {
      return Network.addListener('networkStatusChange', (status) => {
        setConnected(status.connected);
      });
    };
    const networkListener = networkListenrFn();

    return () => {
      networkListener.then((listener) => listener.remove());
    };
  }, []);

  if (connected) return null;

  return (
    <div className={cx(classes.container, containerClassName)}>
      <Alert
        severity="warning"
        classes={{
          root: classes.alert,
          icon: classes.icon,
        }}
        aria-labelledby="network-status-message"
      >
        <span id="network-status-message">
          {t('networkStatus.disconnected')}
        </span>
      </Alert>
    </div>
  );
};

export default NetworkStatusAlert;
