import { BottomSheet } from 'react-spring-bottom-sheet';
import { Backdrop, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import useMapLayersState from 'state/useMapLayersState';
import { LAYERS_DRAWER_WIDTH, MOBILE_LAYER_DRAWER_HEIGHT } from '../constants';
import { DrawerGroupContent } from '../DrawerGroupContent';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: LAYERS_DRAWER_WIDTH,
  },
  drawerPaper: {
    paddingTop: 'calc(64px + env(safe-area-inset-top))',
    paddingBottom: `env(safe-area-inset-bottom)`,
    width: LAYERS_DRAWER_WIDTH,
    zIndex: theme.zIndex.drawer + 2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'transparent',
  },
}));

export const LayersGroupDrawer = (): JSX.Element => {
  const { classes } = useStyles();
  const { groupDrawerOpen, setGroupDrawerOpen } = useMapLayersState();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('phone'));
  const { t } = useTranslation();

  if (isPhone) {
    return (
      <BottomSheet
        id="layer-group-bottom-sheet"
        open={groupDrawerOpen}
        onDismiss={() => setGroupDrawerOpen(false)}
        skipInitialTransition
        snapPoints={(): number[] => [MOBILE_LAYER_DRAWER_HEIGHT]}
        expandOnContentDrag
        header
        scrollLocking
        aria-label={t('map.layers.accessibility.layersGroupDrawer')}
      >
        <DrawerGroupContent />
      </BottomSheet>
    );
  }

  return (
    <>
      <Backdrop
        open={groupDrawerOpen}
        onClick={() => setGroupDrawerOpen(false)}
        className={classes.backdrop}
      />

      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={groupDrawerOpen}
        onClose={() => setGroupDrawerOpen(false)}
        variant="persistent"
        PaperProps={{
          'aria-label': t('map.layers.accessibility.layersGroupDrawer'),
        }}
      >
        <DrawerGroupContent />
      </Drawer>
    </>
  );
};
