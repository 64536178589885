import { useCallback, useMemo } from 'react';
import {
  Layer,
  LayerProps,
  Source,
  DataDrivenPropertyValueSpecification,
} from 'shared/map-exports';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FeatureCollection, Feature, Geometry } from 'geojson';
import { WildfireListItem } from 'shared/types';
import { getAcreageStrParts, truncateString } from 'shared/utils';
import useMapLayersState from 'state/useMapLayersState';
import { usePoisState } from 'state/usePoisState';
import {
  getGeoEventIcon,
  getGeoEventScale,
  getOpacityForGeoEventMarker,
} from '../Icons';
import { FONT_NAMES, GEO_EVENT_SOURCE_ID } from '../styles/constants';
import { DEFAULT_LOCALE } from '../../../i18n/utils';
import { MapboxFeature, useMapLayerEvents } from './useMapLayerEvents';

type GeoEventsLayerProps = {
  geoEvents: WildfireListItem[];
  isFadable?: boolean;
  selectedGeoEventId?: number;
  isEdit?: boolean;
  interactive?: boolean;
};

type GeoEventFeatureProperties = {
  id: number;
  name: string;
  icon: string;
  isActive: boolean;
  isComplexParent: boolean;
  isComplexChild: boolean;
  complexParentName?: string;
  complexChildrenCount: number;
  acreage: number | null;
  scale: number;
  complexChipOffset: number[];
  offset: number[] | null;
  opacity: number;
  index: number;
  indexInv: number;
  acreageStr: string;
  geoEventId: number;
};

const LABELS_MIN_ZOOM = 7;

const ONE_YEAR_MS = 31560000000;
const GEO_EVENT_ICONS_LAYER_ID = 'geo-event-icons';
const COMPLEX_GEO_EVENT_ICONS_LAYER_ID = 'geo-event-complex-icons';
const LABELS_LAYER_ID = 'geo-event-labels';
const COMPLEX_LABELS_LAYER_ID = 'geo-event-complex-labels';
const COMPLEX_PARENT_CHIP_LAYER_ID = 'geo-event-complex-parent-chip';

const COMPLEX_PARENT_TRUNCATE_LENGTH = 25;

const noop = (): void => {};

/**
 * Control label visibility via opacity.
 *
 * @returns an expression that shows the element when zoomed in beyond LABELS_MIN_ZOOM
 * and hides it if not. Selected geo event elements are always visible.
 */
const getLabelVisibilityExpression = (
  selectedGeoEventId: number,
): DataDrivenPropertyValueSpecification<number> => [
  'step',
  ['zoom'],
  ['case', ['==', ['get', 'id'], selectedGeoEventId], 1, 0],
  LABELS_MIN_ZOOM,
  [
    'case',
    [
      'any',
      ['!=', ['get', 'opacity'], 0],
      ['==', ['get', 'id'], selectedGeoEventId],
    ],
    1,
    0,
  ],
];

/**
 * Get the opcaity for a geo event icon. Hides the icon when in edit mode and
 * shows it when selected. Otherwise, falls back to 'opacity' property value.
 */
const getIconOpacityExpression = (
  selectedGeoEventId: number,
  isEdit: boolean,
): DataDrivenPropertyValueSpecification<number> => [
  'case',
  [
    'all',
    ['==', ['get', 'id'], selectedGeoEventId],
    ['==', ['get', 'opacity'], 0],
  ],
  1,
  // hide this icon on the edit page since <Map.WildFireGeoEventMarker> appears instead
  [
    'case',
    ['all', ['==', isEdit, true], ['==', ['get', 'id'], selectedGeoEventId]],
    0,
    ['get', 'opacity'],
  ],
];

/**
 * Ensure selected label is always on top if selected
 */
const getLabelSortExpression = (
  selectedGeoEventId: number,
): DataDrivenPropertyValueSpecification<number> => [
  'case',
  ['==', ['get', 'id'], selectedGeoEventId],
  -99999,
  ['get', 'indexInv'],
];

const getAcreageStr = (
  acreage: number | null,
  containment: number | null,
  locale: string,
): string => {
  const parts = getAcreageStrParts(acreage, containment, locale);
  if (parts[0] == null) return '';
  return parts.join(' ');
};

const getIconStyle = (
  selectedGeoEventId: number,
  isEdit: boolean,
): LayerProps => ({
  filter: ['==', ['get', 'isComplexParent'], false],
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-ignore-placement': false,
    'icon-size': ['get', 'scale'],
    'icon-offset': ['get', 'offset'],

    // For some reason, the icons need to be sorted by 'index',
    // but the labels by 'indexInv', in order to be sorted correctly
    // in both cases.
    'symbol-sort-key': ['get', 'index'],
    'symbol-z-order': 'source',
  },
  paint: {
    'icon-opacity': getIconOpacityExpression(selectedGeoEventId, isEdit),
  },
});

const getComplexIconStyle = (
  selectedGeoEventId: number,
  isEdit: boolean,
): LayerProps => {
  const normalIconStyling = getIconStyle(selectedGeoEventId, isEdit);

  return {
    ...normalIconStyling,
    filter: ['==', ['get', 'isComplexParent'], true],
    type: 'symbol',
    layout: {
      ...normalIconStyling.layout,

      // Center the complex icon so the number we show on it is centered
      'icon-anchor': 'center',

      // Needed to prevent icon disappearing when other labels overlap the child count text within this icon
      'text-allow-overlap': true,

      // Show number of children over complex icon - but not when editing since
      // we show a different draggable icon (<Map.WildFireGeoEventMarker)
      'text-font': FONT_NAMES.bold,
      'text-size': ['*', ['get', 'scale'], 10],
      'text-offset': [0, 0.2],
      ...(isEdit ? {} : { 'text-field': ['get', 'complexChildrenCount'] }),
    },
    paint: {
      ...normalIconStyling.paint,
      'text-opacity': getLabelVisibilityExpression(selectedGeoEventId),
    },
  };
};

const getComplexLabelStyle = (
  selectedGeoEventId: number,
  isEdit: boolean,
): LayerProps => ({
  filter: ['==', ['get', 'isComplexParent'], true],
  type: 'symbol',
  layout: {
    'text-font': FONT_NAMES.bold,
    'text-transform': 'uppercase',
    'text-field': ['get', 'name'],
    'text-anchor': 'top',
    'text-justify': 'center',
    'symbol-sort-key': getLabelSortExpression(selectedGeoEventId),
    'symbol-z-order': 'source',
    'icon-image': [
      'case',
      ['get', 'isActive'],
      ['literal', 'ComplexColorSquare'],
      ['literal', 'InactiveComplexColorSquare'],
    ],

    'icon-text-fit': 'both',
    'text-size': 11,
    'text-offset': ['get', 'complexChipOffset'],
  },
  paint: {
    'icon-opacity': getLabelVisibilityExpression(selectedGeoEventId),
    'text-opacity': getLabelVisibilityExpression(selectedGeoEventId),
  },
});

const labelStyleCommon: LayerProps = {
  filter: ['==', ['get', 'isComplexParent'], false],
  type: 'symbol',
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-field': [
      'format',
      ['get', 'name'],
      { 'text-font': ['literal', FONT_NAMES.bold] },
      '\n',
      {},
      ['get', 'acreageStr'],
      { 'font-scale': 0.7 },
      '\n',
      {},
    ],
    'text-anchor': 'top',
    'text-justify': 'center',
    'symbol-sort-key': ['get', 'indexInv'],
    'symbol-z-order': 'source',
    'icon-image': '',
    'icon-text-fit': 'both',
    'text-size': 16,
    'text-offset': [
      'case',
      ['get', 'isComplexChild'],
      ['literal', [0, 1.8]], // make room for the complex chip above this
      ['literal', [0, 0]],
    ],
  },
  paint: {
    'text-halo-width': 1.8,
  },
};

const labelStyleLight: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#333333',
    'text-halo-color': 'rgba(255,255,255,0.8)',
  },
};

const labelStyleDark: LayerProps = {
  ...labelStyleCommon,
  paint: {
    ...labelStyleCommon.paint,
    'text-color': '#ffffff',
    'text-halo-color': 'rgba(0,0,0,0.8)',
  },
};

const getLabelStyle = (
  lightBaseLayer: boolean,
  selectedGeoEventId: number,
): LayerProps => {
  const { layout, ...style } = lightBaseLayer
    ? labelStyleLight
    : labelStyleDark;

  return {
    ...style,
    filter: ['==', ['get', 'isComplexParent'], false],
    layout: {
      ...layout,
      'symbol-sort-key': getLabelSortExpression(selectedGeoEventId),
    },
    paint: {
      ...style.paint,
      'icon-opacity': getLabelVisibilityExpression(selectedGeoEventId),
      'text-opacity': getLabelVisibilityExpression(selectedGeoEventId),
    },
  };
};

/**
 * This styling adds an incident's parent complex incident as a chip below the
 * incident's normal label using a naive offset.
 */
const getComplexChildIncidentChipStyle = (
  selectedGeoEventId: number,
  isEdit: boolean,
): LayerProps => ({
  filter: ['==', ['get', 'isComplexChild'], true],
  type: 'symbol',
  layout: {
    'text-font': FONT_NAMES.bold,
    'text-transform': 'uppercase',
    'text-field': ['get', 'complexParentName'],
    'text-max-width': 100,
    'text-anchor': 'top',
    'text-justify': 'center',
    'symbol-sort-key': ['get', 'indexInv'],
    'symbol-z-order': 'source',
    'icon-image': [
      'case',
      ['get', 'isActive'],
      ['literal', 'ComplexColorSquare'],
      ['literal', 'InactiveComplexColorSquare'],
    ],
    'icon-text-fit': 'both',
    'text-size': 8,
    'text-offset': [0, 1],
  },
  paint: {
    'icon-opacity': getLabelVisibilityExpression(selectedGeoEventId),
    'text-opacity': getLabelVisibilityExpression(selectedGeoEventId),
  },
});

const getSortIndex = (geoEvent: WildfireListItem): number => {
  const {
    isActive,
    data: { isComplexParent, isPrescribed },
  } = geoEvent;

  if (!isActive || isPrescribed) {
    return 0;
  }

  const defaultSortIndex = Date.parse(geoEvent.dateCreated);

  if (isComplexParent) {
    return defaultSortIndex + ONE_YEAR_MS;
  }

  return defaultSortIndex;
};

const toGeojsonFeature = (
  geoEvent: WildfireListItem,
  isFadable: boolean,
  locale: string,
): Feature<Geometry, GeoEventFeatureProperties> => {
  const {
    isActive,
    id,
    name,
    parentGeoEvents,
    childGeoEvents,
    data: { acreage, isComplexParent, containment },
  } = geoEvent;
  const icon = getGeoEventIcon(geoEvent);
  const geoEventScale = getGeoEventScale(geoEvent);
  const opacity = getOpacityForGeoEventMarker(geoEvent, isFadable);
  const acreageStr = getAcreageStr(acreage, containment, locale);

  // Sort indexes for labels and text. Inactive and Rx fires on the bottom,
  // then oldest to newest.
  const index = getSortIndex(geoEvent);
  const indexInv = 1 / (index + 1);

  // Calculate scale and associated offset.
  const iconScale = icon.scale || 1.0;
  const scale = geoEventScale * iconScale;
  const offset = icon.offset
    ? [icon.offset[0] * scale, icon.offset[1] * scale]
    : [0, 0];

  // We have to manually push complex chip labels down further since the complex icon is center-anchoed
  const complexChipOffset = [offset[0], offset[1] * 1.9];

  const complexParentName = parentGeoEvents[0]
    ? truncateString(parentGeoEvents[0].name, COMPLEX_PARENT_TRUNCATE_LENGTH)
    : undefined;

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [geoEvent.lng, geoEvent.lat],
    },
    properties: {
      id,
      name,
      icon: icon.name,
      isActive,
      isComplexParent,
      isComplexChild: Boolean(complexParentName),
      acreage,
      scale,
      complexChipOffset,
      offset,
      opacity,
      index,
      indexInv,
      acreageStr,
      geoEventId: id,
      complexParentName,
      complexChildrenCount: childGeoEvents.filter(
        (ge) => ge.geoEventType === 'wildfire',
      ).length,
    },
  };
};

const GeoEventsLayer = (props: GeoEventsLayerProps): JSX.Element => {
  const {
    geoEvents,
    isFadable = false,
    selectedGeoEventId = 0,
    isEdit = false,
    interactive = true,
  } = props;
  const history = useHistory();
  const { isLightBaseLayer } = useMapLayersState();
  const { clearSelectedPois } = usePoisState();

  const handleClick = useCallback(
    (geoJsonFeatures: MapboxFeature<GeoEventFeatureProperties>[]) => {
      // If there is ambiguity about which icon the user clicked on, select the
      // largest fire that has a higher index (based on activity/date created)
      const features = [...geoJsonFeatures];
      features.sort((a, b) => {
        if (
          a.properties.isActive === b.properties.isActive &&
          a.properties.acreage &&
          b.properties.acreage
        ) {
          return b.properties.acreage - a.properties.acreage;
        }
        return a.properties.isActive ? -1 : 1;
      });

      const feature = features[0];
      const { id, opacity } = feature.properties;
      if (opacity > 0) {
        // Close any selected POI dialog modal
        // Short setTimeout() is necessary to wait for POI array to fill (see usePoisState())
        setTimeout(() => clearSelectedPois(), 10);
        history.push(`/i/${id}`);
      }
    },
    [history, clearSelectedPois],
  );

  useMapLayerEvents<GeoEventFeatureProperties>({
    layerId: GEO_EVENT_ICONS_LAYER_ID,
    onClick: interactive ? handleClick : noop,
  });
  useMapLayerEvents<GeoEventFeatureProperties>({
    layerId: COMPLEX_GEO_EVENT_ICONS_LAYER_ID,
    onClick: interactive ? handleClick : noop,
  });

  const { i18n } = useTranslation();

  // do not modify the geojson based on selectedState or it will have to re-render.
  const geojson: FeatureCollection<Geometry, GeoEventFeatureProperties> =
    useMemo(
      () => ({
        type: 'FeatureCollection',
        features: geoEvents.map((geoEvent) => {
          return toGeojsonFeature(
            geoEvent,
            isFadable,
            i18n.resolvedLanguage || DEFAULT_LOCALE,
          );
        }),
      }),
      [geoEvents, isFadable, i18n.resolvedLanguage],
    );

  return (
    <Source id={GEO_EVENT_SOURCE_ID} type="geojson" data={geojson}>
      <Layer
        id={GEO_EVENT_ICONS_LAYER_ID}
        {...getIconStyle(selectedGeoEventId, isEdit)}
      />
      <Layer
        id={LABELS_LAYER_ID}
        {...getLabelStyle(isLightBaseLayer, selectedGeoEventId)}
      />
      <Layer
        id={COMPLEX_PARENT_CHIP_LAYER_ID}
        {...getComplexChildIncidentChipStyle(selectedGeoEventId, isEdit)}
      />
      <Layer
        id={COMPLEX_GEO_EVENT_ICONS_LAYER_ID}
        {...getComplexIconStyle(selectedGeoEventId, isEdit)}
      />
      <Layer
        id={COMPLEX_LABELS_LAYER_ID}
        {...getComplexLabelStyle(selectedGeoEventId, isEdit)}
      />
    </Source>
  );
};

export default GeoEventsLayer;
