import {
  ImageLoadingStatus,
  useImageSequenceLoader,
} from 'hooks/useImageLoader';
import { useMemo } from 'react';
import {
  CameraTimeLapseFrameDetails,
  PlayerStatus,
} from './useAlertCameraPlayerState.types';

/**
 * Manages image loading for an alert camera. This will start pre-loading
 * all necessary image(s) based on the current camera and camera state and
 * return the loading status of the current image.
 *
 * If the camera is only displaying a single live image, that is the current
 * image.
 *
 * If the camera is playing a timelapse, the loading status will reflect
 * the current frame.
 *
 * If there is no image to load, this will return undefined.
 */
export const useAlertCameraImageLoader = (
  cameraId: string | undefined,
  playerStatus: PlayerStatus,
  imageUrl: string | undefined,
  timelapseFrames: CameraTimeLapseFrameDetails[],
  timelapseFrameNumber: number,
  currentTimelapseFrame: CameraTimeLapseFrameDetails | null,
): ImageLoadingStatus | undefined => {
  // Note - these calculations are split up to minimize re-creating
  // the loading status array. Each time it gets re-created, we use the
  // global Image object to determine loading status, which can cause it
  // to flip between 'loading' and 'success' states unnecessarily if the
  // image needs to be fetched from the browser cache.
  const { useSingleImageUrl, singleImageUrl } = useMemo(() => {
    if (!cameraId) {
      return { useSingleImageUrl: true, singleImageUrl: undefined };
    }
    if (playerStatus === 'streamingLive' || !currentTimelapseFrame) {
      return { useSingleImageUrl: true, singleImageUrl: imageUrl };
    }
    return { useSingleImageUrl: false, singleImageUrl: undefined };
  }, [cameraId, currentTimelapseFrame, imageUrl, playerStatus]);

  const timelapseFramesSources = useMemo(() => {
    if (useSingleImageUrl) {
      return singleImageUrl ? [singleImageUrl] : [];
    }
    return timelapseFrames.map((value) => value.imageUrl);
  }, [singleImageUrl, timelapseFrames, useSingleImageUrl]);

  const imageSequenceStatus = useImageSequenceLoader(timelapseFramesSources);

  // There are no images to load.
  if (!imageSequenceStatus) {
    return undefined;
  }

  // Loading only a single image, always the only element in the array.
  if (useSingleImageUrl) {
    return imageSequenceStatus[0];
  }

  return imageSequenceStatus[timelapseFrameNumber];
};
