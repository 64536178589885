import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import HeaderBar from 'components/HeaderBar';
import PageLayout from 'components/PageLayout';
import { useInboxMessages } from 'hooks/useInboxMessages';
import { InboxMessage } from 'shared/types';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import InboxMessageContent from 'components/InboxMessageContent';

type ContentProps = {
  message: InboxMessage;
};

const Content = (props: ContentProps): JSX.Element => {
  const { message } = props;
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('messagesInbox.title')}</title>
      </Helmet>
      <InboxMessageContent message={message} />
    </>
  );
};

const MessageDetail = (
  props: RouteComponentProps<{ messageId: string }>,
): JSX.Element => {
  const {
    match: {
      params: { messageId },
    },
  } = props;
  const { messages } = useInboxMessages();
  const { t } = useTranslation();

  const message = messages.find((msg) => msg.id.toString() === messageId);

  if (!message) {
    return (
      <PageLayout data-cname="MessageDetailPage">
        {{
          content: <LoadingAndErrors />,
          headerBar: <HeaderBar title={t('common.error')} action="back" />,
        }}
      </PageLayout>
    );
  }

  return (
    <PageLayout data-cname="MessageDetailPage">
      {{
        content: <Content message={message} />,
        headerBar: (
          <HeaderBar title={t('messagesInbox.title')} action="/" hideAction />
        ),
      }}
    </PageLayout>
  );
};

export default MessageDetail;
