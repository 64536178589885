import { TFunction } from 'i18next';
import { API } from 'api';
import { ProcessMembershipUpgradeData } from 'shared/types';
import Yup from 'shared/yup-extended';
import { IapCheckoutDetails } from 'state/useIapState';

export type ProcessIapPurchaseData = {
  clientToken: string;
  externalAccountCode: string;
  email: string;
  firstName: string;
  lastName: string;
  optInToUpdates: boolean;
  isRecurring: boolean;
  planCode: string;
};

export type FormValues = Omit<
  IapCheckoutDetails,
  'clientToken' | 'externalAccountCode' | 'autoRenew' | 'verificationToken'
>;

const isEmailAvailable = async (email: string): Promise<boolean> => {
  const response = await API.get<{ hasActiveMembership: boolean }>(
    `/recurly_integration/membership_validation/?email=${encodeURIComponent(
      email,
    )}`,
  );
  return !response.data.hasActiveMembership;
};

export const getValidationSchema = (
  t: TFunction,
  isMembershipUpgrade: boolean,
): Yup.SchemaOf<FormValues> =>
  Yup.object({
    firstName: Yup.string().required(
      t('membershipCheckout.inputs.firstName.required'),
    ),
    lastName: Yup.string().required(
      t('membershipCheckout.inputs.lastName.required'),
    ),
    email: Yup.string()
      .required(t('membershipCheckout.inputs.email.required'))
      .test({
        name: 'is-email-available',
        // eslint-disable-next-line func-names, object-shorthand
        test: async function (value) {
          if (!value || isMembershipUpgrade) {
            return true;
          }

          try {
            Yup.string()
              .email(t('membershipCheckout.inputs.email.invalid'))
              .validTLD(t('membershipCheckout.inputs.email.invalid'))
              .validateSync(value);
          } catch (error) {
            return this.createError({
              message: (error as Yup.ValidationError).message,
            });
          }

          // Check email availability
          const isAvailable = await isEmailAvailable(value);
          if (!isAvailable) {
            return this.createError({
              message: t('membershipCheckout.registeredEmail'),
              type: 'registered',
            });
          }

          return true;
        },
      }),
    optInToUpdates: Yup.boolean().required(
      t('membershipCheckout.inputs.optInToUpdates.required'),
    ),
  });

export const processIapPurchaseApi = async (
  data: ProcessIapPurchaseData,
): Promise<void> => {
  await API.post('recurly_integration/iap_create/', data);
};

export const upgradeMembershipApi = async (
  data: ProcessMembershipUpgradeData,
): Promise<void> => {
  await API.post('recurly_integration/upgrade_membership/', data);
};

export const handleValidatorSuccess = (
  callback: CdvPurchase.Callback<CdvPurchase.Validator.Response.Payload>,
  transaction: CdvPurchase.Validator.Response.NativeTransaction,
  productId: string,
): void => {
  callback({
    ok: true,
    data: {
      id: productId,
      latest_receipt: true,
      transaction,
    },
  });
};
