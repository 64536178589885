import { MapStyle } from 'shared/map-exports';
import { mapboxStyleToMaplibre } from './mapboxUtils';
import streetStyle from './parts/streetsStyle.json';
import satelliteStyle from './parts/satelliteStyle.json';
import topoStyle from './parts/topoStyle.json';

export const mapboxStreetStyle = mapboxStyleToMaplibre(
  streetStyle as unknown as MapStyle,
);

export const mapboxSatelliteStyle = mapboxStyleToMaplibre(
  satelliteStyle as unknown as MapStyle,
);

export const mapboxTopoStyle = mapboxStyleToMaplibre(
  topoStyle as unknown as MapStyle,
);
