import { LayerProps } from 'shared/map-exports';
import {
  ELECTRIC_RETAIL_LAYER_COLOR,
  ELECTRIC_RETAIL_LAYER_OPACITY,
  ELECTRIC_RETAIL_SOURCE_DATA_LAYER,
  MIN_ZOOM_VISIBILITY,
} from './constants';

export const layerFillStyle: LayerProps = {
  type: 'fill',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': ELECTRIC_RETAIL_SOURCE_DATA_LAYER,
  paint: {
    'fill-color': ELECTRIC_RETAIL_LAYER_COLOR,
    'fill-opacity': ELECTRIC_RETAIL_LAYER_OPACITY,
  },
};

export const layerStrokeStyle: LayerProps = {
  type: 'line',
  minzoom: MIN_ZOOM_VISIBILITY,
  'source-layer': ELECTRIC_RETAIL_SOURCE_DATA_LAYER,
  paint: {
    'line-color': ELECTRIC_RETAIL_LAYER_COLOR,
    'line-width': ['interpolate', ['linear'], ['zoom'], 9, 2, 18, 12],
    'line-opacity': 1,
  },
};
